import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Text, Image, Box, useColorMode } from '@chakra-ui/react';

const HeroSection9 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section
      className={
        colorMode === 'light'
          ? 'bg-primary theme-bg relative h-580px md-h-400px'
          : 'relative h-580px md-h-400px'
      }
    >
      {/* <img
        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
        alt="hero"
        className="w-full h-580px sm-h-auto object-cover bg-a2a2a2 "
      /> */}

      <div ref={elementRef} className={`fade-bottom absolute top-0 w-full h-580px md-h-400px sm-h-auto lg:flex md:flex  flex justify-center   h-580px sm-h-auto lg:px-0 md:px-0 px-5 lg:py-0 md:py-0 py-4 ${isVisible ? 'visiblebottom' : ''} `}>
        <div
          className="w-full  flex flex-col justify-center items-center"
        >
          <h1
            id="7362576958"
            className="dynamicStyle hero-header  text-center lg:mb-4 md:mb-3 mb-2 text-primary cust-textColor"
          >
            {data ? data['7362576958'] : 'Lorem ipsum generated'}
          </h1>
          <p
            id="1833731917"
            className="dynamicStyle fsize18 sm-fsize16 theme-textColor clr-fff textColor-light"
          >
            {data ? data['1833731917'] : 'Lorem ipsum generated'}
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection9;
