import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllTeams } from '../../../redux/teams/teams-action';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { Linkedin, Twitter } from 'react-feather';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

const Team6 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const Meet = [
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Michael Foster',
      role: 'motion Graphics',
    },
  ];
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  let allTeam = useSelector(state => state.Teams.Teams);
  useEffect(() => {
    dispatch(getAllTeams());
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="container m-auto lg:py-20 md:py-14 py-12 lg:px-12 md:px-8 px-5">
      <div className="lg:w-2/3 md:w-3/4 w-full mx-auto">
      <h2
        id="4436359893"
        className=" dynamicStyle cust-textColor  fsize34 md-fsize24 sm-fsize20 mb-2 text-center font-semibold text-primary"
      >
        {data ? data['4436359893'] : 'Our Instructor'}
      </h2>
      <p
        id="6763163213"
        className="dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14 text-center"
        dangerouslySetInnerHTML={{
          __html: data
            ? data['6763163213']
            : 'We are united by our passion for innovation and our commitment',
        }}
      ></p>
      </div>
      <div className="lg:pt-12 md:pt-12 pt-8">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 2.4,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          {allTeam && allTeam.length > 0
            ? allTeam.map(e => {
                return (
                  <SwiperSlide>
                    <Card
                      className={
                        colorMode === 'light'
                          ? ' w-full pt-4 pb-4 box-shadow-none themeShadow themeShadow-insert theme-bg cursor-pointer'
                          : ' w-full pt-4 pb-4 themeShadow themeShadow-insert theme-bg cursor-pointer'
                      }
                    >
                      <Image
                        src={`${process.env.REACT_APP_STORAGE_URL}${e.profilepicture}`}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`}
                        alt="img"
                        className="h-250px m-auto rounded-lg object-cover "
                      />
                      <p className="cust-textColor text-center pt-4 pb-1 fsize18 font-medium">
                        {e.name}
                      </p>
                      <p className=" textColor-light text-506380 fsize18 text-center">
                        {e.designation}
                      </p>
                      <div className="flex gap-3 pt-4 justify-center">
                        <Twitter className="text-506380 cust-textColor" />
                        <Linkedin className="text-506380 cust-textColor" />
                      </div>
                    </Card>
                  </SwiperSlide>
                );
              })
            : 'No Data'}
        </Swiper>
      </div>
    </div>
  );
};

export default Team6;
