import React, { Fragment, useEffect, useState, useRef } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
//router
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
//import components pages


import { otherLogin, getUserByToken } from './redux/auth/auth-action.js';
import Master from './pages/allSections/Master';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { getProject } from './redux/userDetails/user-actions.js';


//apis
import { getAllMenu } from './redux/menu/menu-actions.js';
import { getBrandProfile } from './redux/brandDetails/bd-actions.js';
import { getCms, getSections } from './redux/cms/cms-actions.js';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getAllPages } from './redux/pages/pages-actions';
// import BookAppointment from './pages/allSections/components/BookAppointment.jsx';
import { getGateway } from './redux/payment/gateway-actions.js';

import { connectToWS } from './redux/chat/chat-websocket';

import ScrollToTop from './components/ScrollToTop.jsx';
import { getAllForms } from './redux/forms/forms-action.js';



import Loading1 from './pages/allSections/components/Loading1.jsx';
import { startLoading, stopLoading } from './redux/Loader/loader-action.js';
import Header5 from './pages/allSections/header/Header5.jsx';
import Header6 from './pages/allSections/header/Header6.jsx';


const App = () => {
  const loading = useSelector(state => state.Loader.loading);
  console.log(loading, 'LoadingReducer');

  

  const dispatch = useDispatch();

  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  let getRoutesNames = useSelector(state => state.cms.routeNames);
  let getOtherLogin = useSelector(state => state.AuthDetails.otherLogin);
  // const [dynamicRoutes, setDynamicRoutes] = useState(['test', 'example']);
  // let menuData = useSelector(state => state.menu.menu);
  const [primaryColor, setPrimaryColor] = useState('#7a4189');
  const [secondaryColor, setSecondaryColor] = useState('#7a4189');
  const [tertiaryColor, setTertiaryColor] = useState('#7a4189');
  const [bodyColor, setbodyColor] = useState('#00000');

  const catchLogin = async () => {
    try {
      dispatch(otherLogin(''));
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      const decodedToken = token ? JSON.parse(decodeURIComponent(token)) : null;

      if (decodedToken) {
        const data = await dispatch(getUserByToken({ token: decodedToken }));

        if (data && data.code === 200) {
          Swal.fire({
            title: 'Success',
            text: `Loggedin successfully`,
            icon: 'success',
            timer: '2500',
          });
          window.history.replaceState(null, '', '/home');
        } else {
          if (data.message) {
            Swal.fire({
              title: 'Error',
              text: data.message,
              icon: 'error',
            });
          }
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong!',
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    async function mount() {
      await dispatch(getAllMenu());
      await dispatch(getProject());
      await dispatch(
        getBrandProfile({
          projectId: process.env.REACT_APP_ID,
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
        })
      );
      await dispatch(getCms());
      await dispatch(getSections());
      await dispatch(getBrandProfile());
      await dispatch(getAllForms());
      await dispatch(getGateway());
      await dispatch(getAllPages());
      dispatch(stopLoading());
      // setTimeout(() => {
      //   dispatch(stopLoading());
      // }, 2000);
      if (getOtherLogin) {
        catchLogin();
      }

      const fontToLoad = brandProfile ? brandProfile.primaryFont : 'sans-serif';
      const fontLink = document.createElement('link');

      if (fontToLoad) {
        fontLink.rel = 'stylesheet';
        fontLink.href = `https://fonts.googleapis.com/css?family=${fontToLoad.replace(
          / /g,
          '+'
        )}`;
      }
      if (brandProfile) {
        const primaryColor = brandProfile.primaryColor;
        const secondaryColor = brandProfile.secondaryColor;
        const tertiaryColor = brandProfile.tertiaryColor;
        const bodyColor = brandProfile.bodyColor;

        setPrimaryColor(primaryColor);
        setSecondaryColor(secondaryColor);
        setTertiaryColor(tertiaryColor);
        setbodyColor(bodyColor);
        console.log(bodyColor, 'brand');
      }

      document.head.appendChild(fontLink);

      fontLink.addEventListener('load', () => {
        const content = document.getElementById('root');
        content.style.fontFamily = `${fontToLoad},sans-serif`;
      });
    }

    mount();
    // eslint-disable-next-line
  }, [dispatch, primaryColor, secondaryColor, tertiaryColor]);

  connectToWS();

  const theme = extendTheme({
    styles: {
      global: props => ({
        '.cust-textColor': {
          color:
            props.colorMode === 'dark' ? '#ffffffeb !important' : '#494949',
        },
        '.navbartextColor-light': {
          color: props.colorMode === 'dark' ? '#a0aec0 !important' : '',
        },
        '.textColor-light': {
          color: props.colorMode === 'dark' ? '#a0aec0 !important' : '#808080',
        },
        '.cust-bgColor': {
          backgroundColor:
            props.colorMode === 'dark' ? '#2d3748 !important' : '',
        },
      }),
    },
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
    colors: {
      light: {
        bg: '#ffffff',
        text: '#000000',
      },
      dark: {
        bg: '#1a202c',
        text: '#ffffff',
      },
      primary: {
        500: `${primaryColor}`,
      },
      primary: {
        500: `${primaryColor}`,
      },
      secondary: {
        500: `${secondaryColor}`,
      },
      tertiary: {
        500: `${tertiaryColor}`,
      },
    },
  });

  //   dispatch(getBrandProfile());
  // }, []);
  function applyThemeToDocument() {
    if (brandProfile && brandProfile.hasOwnProperty('primaryColor'))
      document.body.style.setProperty(
        '--chakra-colors-primary',
        brandProfile.primaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty('secondaryColor'))
      document.body.style.setProperty(
        '--chakra-colors-secondary',
        brandProfile.secondaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty('tertiaryColor'))
      document.body.style.setProperty(
        '--chakra-colors-tertiary',
        brandProfile.tertiaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty('bodyColor'))
      document.body.style.setProperty(
        '--chakra-colors-chakra-body-text',
        brandProfile.bodyColor
      );
  }
  applyThemeToDocument();

  if (loading) {
    return <Loading1 />;
  }

  return (
    // <div ref={wrapperRef}>
    <ChakraProvider theme={theme}>
      <Router>
        <ScrollToTop />
        {/* <IciciHeader /> */}
        <Routes>
          {getRoutesNames &&
            !!getRoutesNames.length &&
            getRoutesNames.map((dynRoute, index) => {
              return (
                <Fragment key={dynRoute}>
                  <Route
                    key={`${dynRoute}${index}`}
                    path={`/${dynRoute.toLocaleLowerCase()}`}
                    element={<Master routeName={dynRoute} />}
                  />
                  <Route
                    key={`${dynRoute}${index}${index}`}
                    path={`/${dynRoute.toLocaleLowerCase()}/cms/:token`}
                    element={<Master routeName={dynRoute} />}
                  />
                  <Route
                    key={`${dynRoute}${index}${index}`}
                    path={`/${dynRoute.toLocaleLowerCase()}/cera`}
                    element={<Master routeName={dynRoute} />}
                  />
                  <Route
                    key={`${dynRoute}${index}${index}`}
                    path={`/${dynRoute.toLocaleLowerCase()}/:blogId`}
                    element={<Master routeName={dynRoute} />}
                  />
                      <Route path="/" element={<Navigate to="/home" replace />} />
                </Fragment>
              );
            })}
         
        </Routes>
      </Router>
    </ChakraProvider>
    // </div>
  );
};

export default App;
