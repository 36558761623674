import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Box,
    useColorMode,
  } from '@chakra-ui/react';
  import { AddIcon, MinusIcon } from '@chakra-ui/icons';
const FAQSection5 = ({ pageName }) => {
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
  
    const { colorMode, toggleColorMode } = useColorMode();
    const data = useSelector(state => state.cms.allSections[pageName]);
    const CTALink = useSelector(state => state.cms.links[pageName]);
  
    function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }

  return (
    <div className="container mx-auto lg:px-12 md:px-8 lg:py-16 md:py-14 px-5 py-10">
      
      <div className="lg:w-2/3 md:w-3/4 w-full mx-auto lg:pb-12 md:pb-12 pb-6 text-center">
        <p
          id="2042090908"
          className="dynamicStyle text-primary fsize16 md-fsize16 sm-fsize14 font-medium textColor-light"
        >
          {data ? data['2042090908'] : 'Start With The Basics'}
        </p>
        <p
          id="1002774395"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor"
        >
          {data ? data['1002774395'] : 'Frequently Asked Questions'}
        </p>
      </div>
      
      <div  className='container mx-auto  lg:px-16 md:px-6 px-3'>
        <Accordion  id="3158274575" className='dynamicStyle' allowToggle>
        {data && data['3158274575']
            ? data['3158274575'].map(e => {
                return (
          <AccordionItem
          className={
            colorMode === 'light'
              ? 'accord-bdr'
              : 'cust-bgColor bdr-btm'
          }
           mb={4}>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton className='py-4rem'>
                    <Box as="span" flex="1" textAlign="left" className='cust-textColor font-semibold fsize18 sm-fsize16 '>
                      {e.field1}
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="30px" className='inp-bdr p-2 rounded-full clr-9c9c9c' />
                    ) : (
                      <AddIcon fontSize="30px" className='inp-bdr p-2 rounded-full clr-9c9c9c' />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} className='sm-fsize14 textColor-light' 
                dangerouslySetInnerHTML={{
                    __html: e.field2,
                  }}>
                  
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          );
        })
      : 'no-data'}
          
        </Accordion>
        <div className="border-line"></div>
        <div className="border-line2"></div>
      </div>
    </div>
  )
}

export default FAQSection5
