import apis from '../../apis/client/portfolio_blogs';

export const getAllPortfolio = payload => {
  let record;
  return async dispatch => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.getAllPortfolio(payload2);
      console.log(data, 'DATAS');
      if (data) {
        dispatch(setPortfolio(data.data.data));
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const getAllCategory = payload => {
  let record;
  return async dispatch => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.getAllCategory(payload2);
      console.log(data, 'DATASS');
      if (data) {
        dispatch(setCategory(data.data.data));
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const setPortfolio = data => {
  return {
    type: 'setPortfolio',
    payload: data,
  };
};

export const setCategory = data => {
  return {
    type: 'setCategory',
    payload: data,
  };
};
