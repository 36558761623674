import {
  Button,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const ForgotPassword2 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  return (
    <>
      {/* forgot-password */}
      <div className="container mx-auto h-100vh lg:px-12 md:px-8 px-5 lg:py-12 md:py-8 py-6 flex justify-center items-center ">
        <div className="lg:w-5/12 md:w-2/3 w-full mx-auto lg:px-0 md:px-0 px-5">
          <Image
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            className="w-24 h-24 mx-auto rounded-full"
          />
          <div className="lg:mt-8 md:mt-6 mt-4">
            <h2
              id="8847997998"
              className="dynamicStyle text-center fsize36 md-fsize32 sm-fsize24 font-semibold cust-textColor"
            >
              {data ? data['8847997998'] : 'Forgot Password'}
            </h2>
            <p
              id="5777356605"
              className="dynamicStyle text-center fsize14 textColor-light"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['5777356605']
                  : 'Enter your details to reset your password',
              }}
            ></p>
            <div className="mt-8">
              <InputGroup className="mb-4">
                <Input
                  placeholder="Email ID"
                  className="cust-textColor"
                  py="6"
                />
                <InputRightElement
                  width="5rem"
                  py="6"
                  className="fsize14 font-medium underline cursor-pointer cust-textColor"
                >
                  Sent OTP
                </InputRightElement>
              </InputGroup>
              <InputGroup className="mb-4">
                <Input
                  placeholder="Confirm OTP"
                  className="cust-textColor"
                  py="6"
                />
                <InputRightElement
                  width="5.5rem"
                  py="6"
                  className="fsize14 font-medium underline cursor-pointer cust-textColor"
                >
                  Verify OTP
                </InputRightElement>
              </InputGroup>
            </div>
            <p className="fsize14 my-6 textColor-light">
              <span id="7924773593" className="dynamicStyle">
                {data ? data['7924773593'] : 'OTP not received?'}
              </span>{' '}
              <span
                id="4701159748"
                className="dynamicStyle fsize14 font-medium cursor-pointer cust-textColor"
              >
                {data ? data['4701159748'] : 'Click Here'}
              </span>
            </p>
            <Button
              id="0328733276"
              colorScheme="primary"
              py="6"
              className="dynamicStyle w-full bg-primary cust-bgColor cust-textColor buttonAnimate bdr-2px"
            >
              {data ? data['0328733276'] : 'Confirm'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword2;
