import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Text, Image, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section>
      <div className={colorMode === 'light' ? 'bg-secondary theme-bg' : ''}>
        <div className="lg:flex md:flex sm-reverse h-full items-center">
          <div
            ref={elementRef}
            className={`fade-left lg:py-0 md:py-0 py-6 lg:px-20 md:px-12 px-5 lg:w-3/5 md:w-3/5 flex items-center ${
              isVisible ? 'visibleleft' : ''
            } `}
          >
            <div>
              <h2
                id="3245782186"
                className="dynamicStyle cust-textColor fsize34 md-fsize24 fsize20 sm-fsize20 uppercase text-end text-primary font-semibold"
              >
                {data ? data['3245782186'] : 'Where to find Us'}
              </h2>
              <p
                id="1810046979"
                dangerouslySetInnerHTML={{
                  __html: data ? data['1810046979'] : 'no-data',
                }}
                className="dynamicStyle textColor-light clr-fff fsize16 sm-fsize14 theme-textColor opacity-70 mt-2 lg:leading-7 md:leading-7 leading-6"
              ></p>
            </div>
          </div>

          <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
            <div id="7853634472" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['7853634472']) : ''}
                alt="heroImage"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className=" object-cover w-full sm-h250px minh450px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection3;
