import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="lg:py-14 md:py-14 py-6">
      <div className="text-center w-1/2 md-w-80 sm-wfull mx-auto lg:mb-12 md:mb-12 mb-6 lg:px-0 md:px-0 px-5 ">
        <h3
          id="6159809925"
          className="dynamicStyle text-primary fsize16 md-fsize16 sm-fsize14 font-medium clr-ddd9d8 cust-textColor"
        >
          {data ? data['6159809925'] : 'Lorem Ipsum'}
        </h3>
        <h1
          id="0370346088"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold mb-2 cust-textColor"
        >
          {data ? data['0370346088'] : 'Lorem Ipsum'}
        </h1>
        <p
          id="3287910140"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['3287910140']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14  text-center"
        ></p>
      </div>
      <div className="lg:flex lg:flex-col container mx-auto lg:px-12 md:px-8 px-5">
        <div
          id="6654293493"
          className="dynamicStyle lg:flex lg:flex-col container mx-auto lg:px-12 md:px-0 px-5"
        >
          {data && data['6654293493']
            ? data['6654293493'].map((e, index) => {
                return (
                  <div
                    className={`${
                      index % 2 === 0
                        ? 'lg:flex md:flex  items-center lg:gap-6 hover-6f6f6f mb-6 w-full lg:mb-6 lg:px-6 md:px-4 px-4 lg:py-6 md:py-4 py-4 h-120px sm-h-200px rounded-lg box-shadow-inset bg-d2d2d2  cust-bgColor br-ddd9d8 themeShadow-alternate'
                        : 'lg:flex md:flex items-center lg:gap-6 hover-ddd9d8 mb-6 box-shadow-outer br-ddd9d8 w-full sm-h-200px lg:mb-6 lg:px-6 md:px-4 px-4 lg:py-6 md:py-4 py-4 h-120px rounded-lg theme_insertShadow'
                    }`}
                  >
                    <div className=" flex lg:flex-row md:flex-row flex-col justify-center lg:w-3/12 md:w-2/5 w-full items-center gap-2 md:gap-4 ">
                      <Image
                        src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="arrow"
                        className="object-cover lg:w-16 md:w-16 md:h-16 lg:h-16 md:w-16 h-16 w-16 rounded-full bg-EDF2F6"
                      />
                      <p className=" fsize20 sm-fsize16 font-semibold cust-textColor">
                        {e.field2}
                      </p>
                    </div>
                    <div className="w-9/12 sm-wfull">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e.field3,
                        }}
                        className=" fsize16 sm-fsize14 lg:text-left md:text-left text-center textColor-light"
                      ></p>
                    </div>
                  </div>
                );
              })
            : 'no-data'}
        </div>
      </div>
 
    </section>
  );
};

export default FeatureCard2;
