import React, { useEffect, useRef, useState } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const CardLayout2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
      <div className="text-center lg:mb-14 md:mb-14 mb-8">
        <h3
          id="3661559307"
          className="dynamicStyle fsize32 sm-fsize20 font-semibold pb-1 cust-textColor"
        >
          {data ? data['3661559307'] : 'Lorem Ipsum'}
        </h3>
        <hr className="hr-layout2" />
      </div>
      <div className="lg:flex md:flex sm-reverse cust-shadow rounded-3xl overflow-hidden lg:mb-14 md:mb-10 mb-8">
        <div className="flex items-center lg:w-1/2 md:w-1/2 w-full px-14 py-6 cust-bgColor theme-bg">
          <div className="">
            <h4 className="fsize28 sm-fsize18 font-semibold pb-4 cust-textColor">
              Projects
            </h4>
            <p className="sm-fsize14 pb-2 textColor-light">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi,
              accusamus tenetur animi voluptatem necessitatibus inventore.
            </p>

            <Button
              variant="outline"
              colorScheme="primary"
              className="theme-radiusfull mt-2 text-primary cust-textColor sm-fsize14 theme-btn bdr-2px"
            >
              View Project
            </Button>
          </div>
        </div>
        <div className="h-400px sm-h250px lg:w-1/2 md:w-1/2 w-full">
          <Image
            src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="img"
            className="h-400px sm-h250px w-full"
          />
        </div>
      </div>
      <div className="lg:flex md:flex cust-shadow rounded-3xl overflow-hidden">
        <div className="h-400px sm-h250px lg:w-1/2 md:w-1/2 w-full">
          <Image
            src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="img"
            className="h-400px sm-h250px w-full "
          />
        </div>
        <div className="flex items-center lg:w-1/2 md:w-1/2 w-full px-14 py-6 cust-bgColor theme-bg">
          <div className="">
            <h4 className="fsize28 sm-fsize18 font-semibold pb-4 cust-textColor">
              Projects
            </h4>
            <p className="sm-fsize14 pb-2 textColor-light">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi,
              accusamus tenetur animi voluptatem necessitatibus inventore.
            </p>

            <Button
              variant="outline"
              colorScheme="primary"
              className="theme-radiusfull mt-2 text-primary cust-textColor sm-fsize14 theme-btn bdr-2px"
            >
              View Project
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardLayout2;
