import API from './repositoryFactory';

const create = 'contactUs/create';

const apiBase = route => {
  return `/marcom/${route}`;
};

export default {
  createContactUs(payload) {
    return API.post(apiBase(create), payload);
  },
};
