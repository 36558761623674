import React from 'react';
import {
  Image,
  Button,
  InputGroup,
  Input,
  InputRightElement,
} from '@chakra-ui/react';
import { Star } from 'react-feather';

import { Search2Icon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';

const YourLearning = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  let allCourse = useSelector(state => state.elearningProduct.Course);
  console.log(allCourse, 'allCourse');

  return (
    <div className="container m-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <div>
        <div className="w-full flex justify-between lg:gap-16 md:gap-12 gap-4">
          <h2
            id="5315254511"
            className="dynamicStyle w-full  fsize24 sm-fsize20  font-semibold"
          >
            {data ? data['5315254511'] : 'Lorem Ipsum'}
          </h2>
          <div className="w-full">
            <InputGroup width="100%">
              <Input
                pr="4.5rem"
                placeholder="Search courses"
                className="rounded-100px"
              />
              <InputRightElement width="3.5rem">
                <Search2Icon />
              </InputRightElement>
            </InputGroup>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:pt-12 md:pt-10 pt-8 lg:pb-12 md:pb-10 pb-8">
          {allCourse && allCourse.result && allCourse.result.length > 0
            ? allCourse.result.map(item => {
                return (
                  <div className="">
                    <div className="relative ">
                      <div className="overflow-hidden">
                        <Image
                          src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                          alt="img"
                          className="h-200px w-full m-auto object-cover  custimghover"
                        />
                      </div>
                      <div className="absolute top-0">
                        <div className="bg-white px-2 py-1 flex gap-1 items-center">
                          <p className=" fsize14">4.9/5 </p>
                          <span>
                            <Star className="w-4 h-4" />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="px-5 bg-white borderall h-fit lg:py-10 md:py-6 py-4 custcardcourse">
                      <div className="flex items-center justify-between pb-2 ">
                        <p className=" fsize14">{item['course name']}</p>
                        <p className=" fsize14">
                          {' '}
                          {item.category &&
                          item.category.length &&
                          item.category[0].length > 0 &&
                          item.category[0][0].name
                            ? item.category[0][0].name
                            : ''}
                        </p>
                      </div>
                      <p className=" fsize14 font-medium">
                        {item.coursecontent}
                      </p>
                      <div className="mt-4">
                        <Button
                          width="100%"
                          colorScheme="primary"
                          className="rounded-100px"
                        >
                          Download Certificate
                        </Button>
                        {/* <Button
                  width="100%"
                  variant="outline"
                  colorScheme="primary"
                  className="rounded-100px"
                >
                  Get Started
                </Button> */}
                      </div>
                    </div>
                  </div>
                );
              })
            : 'no-datas'}
        </div>
        {/* <div className=" flex justify-center">
          <Button
            size="lg"
            colorScheme="primary"
            className="dynamicStyle rounded-full  text-white"
          >
            <a href="/elearning-course">All Course</a>
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default YourLearning;
