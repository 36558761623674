import { Card } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Facebook, Instagram } from 'react-feather';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

export const ContactDetails3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:pt-14 md:pt-12 pt-6 lg:px-12 md:px-8 px-5">
      {/* <div className="lg:flex md:flex gap-4"> */}
      <div ref={elementRef} className={`fade-bottom lg:flex md:flex w-full rounded-lg border-1px  text-center lg:p-16 md:p-12 p-6 ${isVisible ? 'visiblebottom' : ''} `}>
        <div className="lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-6">
          <h2
            id="4828765606"
            className="dynamicStyle cust-textColor fsize34 md-fsize26 sm-fsize22 font-medium pb-2 text-primary"
          >
            {data ? data['4828765606'] : 'Connect with Us'}
          </h2>
          <p
            id="4811037063"
            className="dynamicStyle text-center sm-fsize14 clr-191E22 textColor-light"
          >
            {data ? data['4811037063'] : '+5985998259+884'}
          </p>
          <p
            id="6497734695"
            className="dynamicStyle text-center sm-fsize14 clr-191E22 textColor-light"
          >
            {data ? data['6497734695'] : 'hello@company.com'}
          </p>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full">
          <h2
            id="7976773743"
            className="dynamicStyle cust-textColor fsize34 md-fsize26 sm-fsize22 font-medium pb-2 text-primary"
          >
            {data ? data['7976773743'] : 'Visit Us'}
          </h2>
          <p
            id="9712330784"
            className="dynamicStyle sm-fsize14 clr-191E22 textColor-light pb-2"
          >
            {data
              ? data['9712330784']
              : '128 southwork Street,London Jetpur,Rajkot,Gujrat'}
          </p>
          <div className="flex justify-center gap-4 ">
        <div>
          <Facebook className="cust-textColor" />
        </div>
        <div>
          <Instagram className="cust-textColor" />
        </div>
      </div>
        </div>
      </div>
      {/* <Card className="p-3 border-1px lg:w-1/2 md:w-1/2 text-center lg:p-16 md:p-12 lg:p-4 md:pb-4 lg:mb-0 md:mb-0 mt-2 bdr-l">
          
        </Card> */}
      {/* </div> */}
      
    </section>
  );
};
export default ContactDetails3;
