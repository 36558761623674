import React from 'react';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { TiSocialFacebookCircular } from 'react-icons/ti';
import { FiYoutube } from 'react-icons/fi';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const Footer5 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
    let brandProfile = useSelector(state => state.brandDetails.brandProfile);
 
  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }
  return (
    <section className='bg-theme'>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-10 py-8">
        <div className="lg:flex cust-grid justify-between items-center">
          <div className="">
            <h3 id="" className="fsize16 cursor-pointer font-semibold textColor-light ">
              About Us
            </h3>
          </div>
          <div className="">
            <h3 id="" className="fsize16 cursor-pointer font-semibold textColor-light">
              Jobs
            </h3>
          </div>
          <div className="">
            <h3 id="" className="fsize16 cursor-pointer font-semibold textColor-light">
              Blog
            </h3>
          </div>
          <div className="">
            <h3 id="" className="fsize16 cursor-pointer font-semibold textColor-light ">
              Press
            </h3>
          </div>
          <div className="">
            <h3 id="" className="fsize16 cursor-pointer font-semibold textColor-light ">
              FAQ
            </h3>
          </div>
          <div className="">
            <h3 id="" className="fsize16 cursor-pointer font-semibold textColor-light ">
              Careers
            </h3>
          </div>
          <div className="">
            <h3 id="" className="fsize16 cursor-pointer font-semibold textColor-light ">
              Contact
            </h3>
          </div>
          <div className="">
            <h3 id="" className="fsize16 cursor-pointer font-semibold textColor-light ">
              Privacy Policy
            </h3>
          </div>
          <div className="">
            <h3 id="" className="fsize16 cursor-pointer font-semibold textColor-light ">
              Sitemap
            </h3>
          </div>
          <div className="">
            <h3 id="" className="fsize16 cursor-pointer font-semibold textColor-light ">
              Term of Use
            </h3>
          </div>
        </div>

        <div className="lg:flex md:flex justify-between items-center lg:mt-4 md:mt-6 mt-8">
          <a href="/">
            <Image
              src={getSecondaryImage(brandProfile)}
              alt="footer-logo"
              className="w-10rem  sm-mx-auto"
            />
          </a>
          <div className="icons flex gap-2 my-4 sm-center">
            <a href="/">
              <div className="bg-d9d9d9 p-2 rounded-full">
                <FaWhatsapp className="w-4 h-4" />
              </div>
            </a>
            <a href="/">
              <div className="bg-d9d9d9 p-2 rounded-full">
                <FaInstagram className="w-4 h-4" />
              </div>
            </a>
            <a href="/">
              <div className="bg-d9d9d9 p-2 rounded-full">
                <TiSocialFacebookCircular className="w-4 h-4" />{' '}
              </div>
            </a>
            <a href="/">
              <div className="bg-d9d9d9 p-2 rounded-full">
                <FiYoutube className="w-4 h-4" />
              </div>
            </a>
          </div>
          <span
            id="9242832401"
            className="dynamicStyle clr-5a5a5a fsize14 sm-center textColor-light"
          >
            {data ? data['9242832401'] : '@2020-2021, All Rights Reserved.'}
          </span>
        </div>
      </div>
    </section>
  );
};

export default Footer5;
