import { Button, Image } from '@chakra-ui/react';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { useSelector } from 'react-redux/es/exports';

const DocumentSection1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="container mx-auto lg:px-12 md:px-8 px-5">
      <div className="flex items-center w-full mb-6">
        <div className="bg-primary px-5 py-2 rounded-lg ">
          <p id="9274650242" className="dynamicStyle text-white my-0 fsize15">
            {data ? data['9274650242'] : '2022 - 2023'}
          </p>
        </div>
        <hr className="w-10/12 ml-5"></hr>
      </div>
      <Swiper
        spaceBetween={30}
        observer={true}
        observeParents={true}
        className="mySwiper"
        breakpoints={{
          1536: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
        }}
      >
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <div id="3084092309" className="dynamicStyle2 w-20 h-20 mx-auto">
              <Image
                src={data ? getImage(data['3084092309']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-20 h-20 mx-auto"
                alt="women"
              />
            </div>
            <p
              id="0103120468"
              className="dynamicStyle mt-4 text-center text-dark"
            >
              {data
                ? data['0103120468']
                : 'SBI Foundation Anual Report 2022 - 2023'}
            </p>

            <Button
              colorScheme="primary"
              id="9640676071"
              className="dynamicStyle w-full mt-2"
            >
              {data ? data['9640676071'] : ' See Results'}
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <div id="7127720107" className="dynamicStyle2 w-20 h-20 mx-auto">
              <Image
                src={data ? getImage(data['7127720107']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-20 h-20 mx-auto"
                alt="women"
              />
            </div>
            <p
              id="0828787944"
              className="dynamicStyle mt-4 text-center text-dark"
            >
              {data
                ? data['0828787944']
                : 'SBI Foundation Anual Report 2022 - 2023'}
            </p>

            <Button
              colorScheme="primary"
              id="1685794492"
              className="dynamicStyle w-full mt-2"
            >
              {data ? data['1685794492'] : 'See Results'}
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <div id="8456747330" className="dynamicStyle2 w-20 h-20 mx-auto">
              <Image
                src={data ? getImage(data['8456747330']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-20 h-20 mx-auto"
                alt="women"
              />
            </div>
            <p
              id="0305128384"
              className="dynamicStyle mt-4 text-center text-dark"
            >
              {data
                ? data['0305128384']
                : 'SBI Foundation Anual Report 2022 - 2023'}
            </p>

            <Button
              colorScheme="primary"
              id="1458103843"
              className="dynamicStyle w-full mt-2"
            >
              {data ? data['1458103843'] : 'See Results'}
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <div id="4602571940" className="dynamicStyle2 w-20 h-20 mx-auto">
              <Image
                src={data ? getImage(data['4602571940']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-20 h-20 mx-auto"
                alt="women"
              />
            </div>
            <p
              id="5505748919"
              className="dynamicStyle mt-4 text-center text-dark"
            >
              {data
                ? data['5505748919']
                : 'SBI Foundation Anual Report 2022 - 2023'}
            </p>

            <Button
              colorScheme="primary"
              id="2352096344"
              className="dynamicStyle w-full mt-2"
            >
              {data ? data['2352096344'] : 'See Results'}
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <div id="9162962735" className="dynamicStyle2 w-20 h-20 mx-auto">
              <Image
                src={data ? getImage(data['9162962735']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-20 h-20 mx-auto"
                alt="women"
              />
            </div>
            <p
              id="6411786476"
              className="dynamicStyle mt-4 text-center text-dark"
            >
              {data
                ? data['6411786476']
                : 'SBI Foundation Anual Report 2022 - 2023'}
            </p>

            <Button
              colorScheme="primary"
              id="3219363461"
              className="dynamicStyle w-full mt-2"
            >
              {data ? data['3219363461'] : 'See Results'}
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="cursor-pointer border-primary rounded-lg p-4">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              className="w-20 h-20 mx-auto"
              alt="women"
            />
            <p className="mt-4 text-center text-dark">
              SBI Foundation Anual Report 2022 - 2023
            </p>
            <Button colorScheme="primary" className="bg-primary w-full mt-2">
              See Results
            </Button>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default DocumentSection1;
