import API from './repositoryFactory';

const GET = 'get';

export default {
  getBrandProfile(payload) {
    return API.post(apiBaseRoute(GET), payload);
  },
};

const apiBaseRoute = route => {
  return `miniStore/brandProfile/${route}`;
};
