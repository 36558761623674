import apis from '../../apis/client/testimonial';
export const getTestimonal = payload => {
  let record;
  return async dispatch => {
    try {
      // console.log('testicheck1');
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      let { data } = await apis.getTestimonal(payload2);
      console.log(data, 'testaction');
      if (data) {
        dispatch(setTestimonal(data.data));
      }
    } catch (error) {
      console.log(error);
    }
    return;
  };
};

export const setTestimonal = data => {
  return {
    type: 'setTestimonal',
    payload: data,
  };
};
