import { Button ,Image } from '@chakra-ui/react';
import React from 'react';
import 'swiper/css';

const BookAppointment = () => {
  return (
    <div className="container mx-auto lg:px-12 md:px-0 px-5 lg:py-16 md:py-16 py-8">
      <div className="w-full lg:flex md:flex gap-4">
        <div className="w-full">
          <div className="bg-f4f5f6 w-full p-2">
            <div className="bg-black w-12 h-12 flex flex-col items-center justify-center rounded-md">
              <p className="fsize16 clr-fff">8</p>
              <p className="uppercase fsize12 clr-fff">fri</p>
            </div>
          </div>
        </div>
        <div className="w-full relative   h-90">
          <Image
            alt="fit2"
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            className="img-fuit m-auto rounded-lg"
          />
          <div className=" absolute top-0 left-0 flex justify-center items-center h-90 sm-h-80  w-full ">
            <div className="border bg-white shadow-lg lg:w-9/12 md:w-9/12 w-11/12 rounded-lg px-4 py-4">
              <div className="py-2 w-full flex justify-center ">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="profile-img"
                  className="w-28 h-28 rounded-full"
                />
              </div>
              <div className="">
                <h2 className="fsize24 font-semibold uppercase text-center">
                  MANISH SHARMA
                </h2>
                <div className="flex justify-center items-center">
                  <p className="clr-9c9c9c fsize18 font-medium uppercase">
                    woodland
                  </p>
                  <div className="hr1"></div>
                  <p className="clr-9c9c9c fsize18 font-medium uppercase">
                    5*, 120 REVIEW
                  </p>
                </div>

                <div className="bg-white rounded-md w-full lg:mt-12 md:mt-12 mt-6">
                  <label htmlFor="" className="fsize14">
                    Booking Time
                  </label>
                  <input
                    type="time"
                    className="border-6d6d6d fsize14 font-semibold w-full px-3 py-3 rounded-md"
                  />
                </div>

                <div className="bg-white rounded-md w-full mt-4">
                  <label htmlFor="" className="fsize14">
                    Booking Date
                  </label>
                  <input
                    type="date"
                    className="border-6d6d6d fsize14 font-semibold w-full px-3 py-3 rounded-md"
                  />
                </div>

                <div className="mt-4 w-full">
                  <label htmlFor="" className="fsize14">
                    Session Type
                  </label>
                  <div className="border-6d6d6d  flex justify-between items-center px-3 py-3 bg-white rounded-md">
                    <p className="fsize14 font-semibold">Online</p>
                    <div className="online-dot"></div>
                  </div>
                </div>

                <div className="mt-12 w-full">
                  <Button
                    className="w-full fsize16 appointment-btn "
                    colorScheme="primary"
                  >
                    Book Appointment
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookAppointment;
