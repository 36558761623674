import {
  Button,
  Image,
  Input,
  Textarea,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Select,
  useColorMode,
  Radio,
  RadioGroup,
  Stack,
  HStack,
} from "@chakra-ui/react";
// import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";

const CustomHeroSectionForm2 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  const [formField, setFormField] = useState({});

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setValue,
    watch,
    trigger,
    unregister,
    reset,
  } = useForm({
    formField,
  });
  const [stepSchema, setStepSchema] = useState({
    0: [
      "firstName",
      "lastName",
      "email",
      "phone",
      // "DOB",
      "city",
      "state",
      "pincode",
      "address",
      // "educationQualification",
    ],

    1: [
      "storeName",
      "storeSize",
      "serviceProvide",
      "googleLocation",
      "partner",
      "locationOwnedOrRented",
    ],
  });
  const [validations, setValidations] = useState({});
  const steps = [
    { description: "Personal Details" },

    { description: "Partnership Details" },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const handleChange = (e, inputName, type) => {
    console.log(e);
    console.log("maxcall");
    let fields = {};
    if (type === "input") {
      fields = { ...formField, [inputName]: e.target.value };
    } else if (type === "select") {
      fields = { ...formField, [inputName]: e.target.value };
    } else if (type === "radio") {
      fields = { ...formField, [inputName]: e };
      console.log(fields, "fielddd");
    } else if (type === "multiselect") {
      let newValue = e.map((arr) => arr.name);
      fields = { ...formField, [inputName]: newValue };
    }

    setFormField(fields);
    console.log(formField, "formField");
  };

  useEffect(() => {
    let temp = {};
    stepSchema[activeStep].forEach((element) => {
      temp[element] = {
        ...register(element, {
          required: {
            value: true,
            message: `${element} is required`,
          },
        }),
      };
    });

    if (activeStep === 0) {
      unregister([...stepSchema[1]]);
    }
    setValidations({ ...validations, ...temp });
  }, [activeStep]);
  useEffect(() => {}, [errors]);
  const onSubmit = async () => {
    console.log("submit");
    try {
      let payload = {
        ...formField,
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}custom/form/partner-with-us/create`,
        payload,
        {
          headers: {
            "community-name": process.env.REACT_APP_COMMUNITY_NAME,
          },
        }
      );

      if (data && data.code === 200) {
        Swal.fire({
          title: "Thank you for your Interest",
          text: `Our team will review your application and get back to you in the next 7-10 working days.`,
          icon: "success",
          confirmButtonColor: "#2bc370",
        });
        reset();
        setFormField({});
        setActiveStep(0);
        let fields = formField;
      }
    } catch (error) {
      if (error.response.data.code === 409) {
        Swal.fire({
          title: "Application Already Submitted",
          text: "Our records indicate that you have already submitted your partnership application. If you have any questions or need further assistance, please contact our support team at info@thedetailingpal.com",
          icon: "error",
          confirmButtonColor: "#2bc370",
        });
      } else if (error.response.data.code !== 500) {
        Swal.fire({
          title: error.response.data.name,
          text: error.response.data.message,
          icon: "error",
          confirmButtonColor: "#2bc370",
        });
      } else {
        Swal.fire({
          title: "error",
          text: "Something went wrong!",
          icon: "error",
          confirmButtonColor: "#2bc370",
        });
      }
    }
  };

  // useEffect(() => {
  //   console.log(formField, "checkform");
  // }, [formField]);

  const handleTrim = () => {
    setFormField((prevState) => {
      for (let key in prevState) {
        if (typeof prevState[key] == "string") {
          prevState[key] = prevState[key].trim();
        } else {
          prevState[key] = prevState[key];
        }
      }
      return prevState;
    });
  };

  const checkValidataions = async () => {
    console.log(errors);
    const result = await trigger();
    const currentStep = console.log(
      result,
      Object.keys(stepSchema).length - 1,
      "checkErrors"
    );

    if (result && Object.keys(stepSchema).length - 1 != activeStep)
      setActiveStep(activeStep + 1);
    else if (result && Object.keys(stepSchema).length - 1 === activeStep)
      onSubmit();

    // if (!errorsCheck.length) setActiveStep(activeStep + 1);
  };

  console.log(activeStep, formField, validations, "ddd");

  // select-options
  const netWorthOption = [
    "5-10 Lakhs",
    "10-20 Lakhs",
    "20-50 Lakhs",
    "50 Lakhs and Above",
  ];

  //   const locationOwnedOrRentedOption = ["Owned", "Rented", "Other"];
  const locationFranchiseeOption = ["Yes", "No"];
  const WhenDoYouStartFranchiseeOption = [
    "A year",
    "6 Months",
    "3 Months",
    "Immediately",
    "Only Enquiring",
  ];

  const educationQualificationOption = [
    "10th Pass",
    "12th Pass",
    "Graduate",
    "Post Graduate",
    "MBA",
  ];

  const soloSourceIncomeOption = ["Yes", "No"];
  const financeBusinessOption = ["Yes", "No"];
  const PartnerOption = ["Yes", "No"];

  const storeSizeOption = [
    "500 sqft - 1500 sqft",
    "1500 sqft - 2500 sqft",
    "2500 sqft - 3500 sqft",
    "3500 sqft and Above",
  ];
  const serviceProvideOption = [
    { name: "Car Washing", id: 1 },
    { name: "Detailing", id: 2 },
    { name: "PPF", id: 3 },
    { name: "Coating", id: 4 },
    { name: "Painting", id: 5 },
    { name: "Other", id: 6 },
  ];
  const partnerOption = ["Yes", "No"];
  const locationOwnedOrRentedOption = ["Owned", "Rented"];
  // const [selectedOwner, setOwnerStatus] = useState(null);

  // const addressOption = [
  //   "0 - 2 years",
  //   "2 -5 years",
  //   "5 - 10 years",
  //   "10 and above years",
  // ];

  // const [selectedAddress, setAddressStatus] = useState(null);

  // const qualificationOption = [
  //   "10th Pass",
  //   "12th Pass",
  //   "Diploma",
  //   "Graduate",
  //   "Post Graduate",
  //   "MBA",
  // ];

  // const [selectedQualification, setQualificationStatus] = useState(null);

  // const [selectedFranchise, setFranchiseStatus] = useState(null);

  // const franchise2Option = ["Yes", "No"];

  // const [selectedFranchise2, setFranchise2Status] = useState(null);

  // const fullTimeBusinessOption = ["Yes", "No"];

  // const [selectedfullTimeBusiness, setfullTimeBusinessStatus] = useState(null);

  // const [selectedPartner, setPartner] = useState(null);

  // const soloSourceIncomeOption = ["Yes", "No"];

  // const [selectedsoloSourceIncome, setSelectedsoloSourceIncome] =
  //   useState(null);

  // const [selectedlocationFranchisee, setSelectedlocationFranchisee] =
  //   useState(null);

  // const locationOption = ["Yes", "No"];

  // const [selectedlocation, setSelectedlocation] = useState(null);

  return (
    <div className="">
      <div id="2117702427" className="dynamicStyle2 relative h-400px">
        <Image
          src={data ? getImage(data["2117702427"]) : ""}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          className="h-400px w-full object-cover"
        />
        <div className="cust-absl lg:w-4/5 md:w-4/5 w-full text-center lg:px-0 md:px-0 px-5">
          <h3
            id="9664122068"
            className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-semibold clr-fff pb-2"
          >
            {data ? data["9664122068"] : "Lorem Ipsum"}
          </h3>
          <p
            id="4535239004"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["4535239004"]
                : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            }}
            className="dynamicStyle sm-fsize14 clr-fff"
          ></p>
        </div>
      </div>
      <div className="lg:px-12 md:px-12 px-5 lg:py-12 md:py-12 py-6 lg:w-4/5 md:w-4/5 w-11/12 cust-shadow mx-auto cust-heroform1 bg-fff">
        <div className="lg:pb-10 md:pb-10 pb-6 custom-desc">
          <Stepper
            size="md"
            index={activeStep}
            className="custom overflow-x-auto ruby-text custom-touch"
          >
            {steps.map((step, index) => (
              <Step
                className="block sm-min-w-max p-2 sm-fsize14"
                onClick={async () => {
                  if (index > activeStep) {
                    const result = await trigger();
                    console.log(result, errors, "step");
                    if (result) {
                      setActiveStep(activeStep + 1);
                    }
                  } else {
                    setActiveStep(index);
                  }
                }}
              >
                <StepIndicator className="font-secondary">
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </div>

        {activeStep === 0 ? (
          <>
            <div>
              <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 pb-6">
                <div className="relative ">
                  <div className="inputArrange">
                    {" "}
                    <Input
                      type="text"
                      name={"firstName"}
                      value={formField["firstName"]}
                      {...register("firstName", {
                        required: `First name is required`,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: `Only alphabets is allowed`,
                        },
                      })}
                      onChange={(e) => handleChange(e, "firstName", "input")}
                      placeholder="Enter here"
                      className="sm-fsize14"
                    />
                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      First Name <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>

                  {errors["firstName"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"firstName"}-validation-error`}
                    >
                      {errors["firstName"]?.message}
                    </span>
                  )}
                </div>
                <div className="relative ">
                  <div className="inputArrange">
                    {" "}
                    <Input
                      type="text"
                      name={"lastName"}
                      value={formField["lastName"]}
                      {...register("lastName", {
                        required: `Last name is required`,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: `Only alphabets is allowed`,
                        },
                      })}
                      onChange={(e) => handleChange(e, "lastName", "input")}
                      placeholder="Enter here"
                      className="sm-fsize14"
                    />
                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Last Name <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>

                  {errors["lastName"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"lastName"}-validation-error`}
                    >
                      {errors["lastName"]?.message}
                    </span>
                  )}
                </div>
                <div className="relative ">
                  <div className="inputArrange">
                    {" "}
                    <Input
                      type="text"
                      placeholder="Enter here"
                      value={formField["email"]}
                      {...register("email", {
                        required: `email is required`,
                        pattern: {
                          value:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: `email is invalid`,
                        },
                      })}
                      onChange={(e) => handleChange(e, "email", "input")}
                      className="sm-fsize14"
                    />
                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Email
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>

                  {errors["email"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"email"}-validation-error`}
                    >
                      {errors["email"]?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    {" "}
                    <Input
                      type="text"
                      placeholder="Enter here"
                      {...register("phone", {
                        required: `Phone is required`,
                        pattern: {
                          value: /^\d{10}$/,
                          message: `Phone is invalid`,
                        },
                      })}
                      className="sm-fsize14"
                      onChange={(e) => {
                        if (e.target.value.length > 10) {
                          return;
                        }
                        handleChange(e, "phone", "input");
                      }}
                      value={formField["phone"]}
                    />
                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Phone
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors["phone"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"phone"}-validation-error`}
                    >
                      {errors["phone"]?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    {" "}
                    <Input
                      type="date"
                      placeholder="Enter here"
                      value={formField["DOB"]}
                      onChange={(e) => handleChange(e, "DOB", "input")}
                      className="sm-fsize14 cursor-pointer"
                    />
                    <p className="fsize15 px-1 mt-2 abslform2 bg-fff  ">
                      Date of Birth
                    </p>
                  </div>

                  {/* {errors["DOB"] && (
                        <span
                          className="fsize10 mt-1 text-danger"
                          id={`${"DOB"}-validation-error`}
                        >
                          {errors["DOB"]?.message}
                        </span>
                      )} */}
                </div>
                <div className="relative ">
                  <div className="inputArrange">
                    <Input
                      type="text"
                      placeholder="Enter here"
                      className="sm-fsize14"
                      value={formField["city"]}
                      {...register("city", {
                        required: `City is required`,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: `Only alphabets is allowed`,
                        },
                      })}
                      onChange={(e) => handleChange(e, "city", "input")}
                    />

                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      City
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors["city"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"city"}-validation-error`}
                    >
                      {errors["city"]?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Input
                      type="text"
                      placeholder="Enter here"
                      className="sm-fsize14"
                      value={formField["state"]}
                      {...register("state", {
                        required: `State is required`,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: `Only alphabets is allowed`,
                        },
                      })}
                      onChange={(e) => handleChange(e, "state", "input")}
                    />

                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      State
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors["state"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"state"}-validation-error`}
                    >
                      {errors["state"]?.message}
                    </span>
                  )}
                </div>
                <div className="relative ">
                  <div className="inputArrange">
                    {" "}
                    <Input
                      type="text"
                      value={formField["pincode"]}
                      {...register("pincode", {
                        required: `Pincode is required`,
                        pattern: {
                          value: /^\d{6}$/,
                          message: `Invalid pincode`,
                        },
                      })}
                      onChange={(e) => {
                        if (e.target.value.length > 6) {
                          return;
                        }
                        handleChange(e, "pincode", "input");
                      }}
                      placeholder="Enter here"
                      className="sm-fsize14"
                    />
                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      PinCode
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors["pincode"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"pincode"}-validation-error`}
                    >
                      {errors["pincode"]?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Textarea
                      type="textarea"
                      placeholder="Enter here"
                      className="sm-fsize14"
                      value={formField["address"]}
                      {...register("address", {
                        required: `Address is required`,
                        pattern: {
                          value: /^[a-zA-Z0-9,-/ ]*$/,
                          message: `Only alphabets is allowed`,
                        },
                      })}
                      onChange={(e) => handleChange(e, "address", "input")}
                    />

                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Address
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors["address"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"address"}-validation-error`}
                    >
                      {errors["address"]?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Select
                      placeholder="Please Select..."
                      value={formField["educationQualification"]}
                      className="fsize14"
                      onChange={(e) => {
                        handleChange(e, "educationQualification", "select");
                      }}
                    >
                      {educationQualificationOption.map((i) => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select>
                    <p className="fsize15 px-1 mt-3px abslform2 bg-fff  ">
                      Education Qualififcation
                    </p>
                  </div>
                  {/* {errors["educationQualification"] && (
                        <span
                          className="fsize10 mt-1 text-danger"
                          id={`${"educationQualification"}-validation-error`}
                        >
                          {errors["educationQualification"]?.message}
                        </span>
                      )} */}
                </div>
              </div>

              <div className="flex justify-end lg:mt-10 md:mt-10 mt-6">
                <Button
                  onClick={() => {
                    handleTrim();
                    checkValidataions();
                  }}
                  type="submit"
                  px={8}
                  className="bg-primary textwhite cust-bgColor theme-btn lg:px-6 lg:py-2 md:px-4 md:py-2 px-2 py-1  fsize14 sm-fsize10 px-1"
                  id=""
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        ) : null}
        {activeStep === 1 ? (
          <>
            <div>
              <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-6 pb-6">
                <div className="relative ">
                  <div className="inputArrange">
                    <Input
                      type="text"
                      placeholder="Enter here"
                      className="sm-fsize14"
                      value={formField["storeName"]}
                      {...register("storeName", {
                        required: `Store name is required`,
                        // pattern: {
                        //   value: /^[a-zA-Z0-9-! ]*$/,
                        //   message: `Only alphabets is allowed`,
                        // },
                      })}
                      onChange={(e) => handleChange(e, "storeName", "input")}
                    />

                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      What is your store name?
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors["storeName"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"storeName"}-validation-error`}
                    >
                      {errors["storeName"]?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Select
                      placeholder="Please Select..."
                      value={formField["storeSize"]}
                      className="fsize14"
                      {...validations["storeSize"]}
                      onChange={(e) => {
                        handleChange(e, "storeSize", "select");
                      }}
                    >
                      {storeSizeOption.map((i) => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select>

                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      What is the size of your store?
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors["storeSize"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"storeSize"}-validation-error`}
                    >
                      {errors["storeSize"]?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    {/* <Select
                      placeholder="Please Select..."
                      value={formField["serviceProvide"]}
                      className="fsize14"
                      {...validations["serviceProvide"]}
                      onChange={(e) => {
                        handleChange(e, "serviceProvide", "select");
                      }}
                    >
                      {serviceProvideOption.map((i) => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select> */}
                    <Controller
                      name="serviceProvide"
                      control={control}
                      rules={{ required: "Service provide is required" }}
                      render={({ field }) => (
                        <Multiselect
                          name="serviceProvide"
                          className={`fsize14 cust_height40`}
                          options={serviceProvideOption}
                          selectedValues={field.value}
                          onSelect={(value) => {
                            setValue("serviceProvide", value);
                            handleChange(
                              value,
                              "serviceProvide",
                              "multiselect"
                            );
                          }}
                          onRemove={(value) => {
                            setValue("serviceProvide", value);
                            handleChange(
                              value,
                              "serviceProvide",
                              "multiselect"
                            );
                          }}
                          displayValue="name"
                        />
                      )}
                    />
                    {/* <Multiselect
                      name="serviceProvide"
                      className={`fsize14 ${
                        errors["serviceProvide"] && "cust-error-border"
                      }`}
                      {...register("serviceProvide", {
                        required: `Service Provider is required`,
                      })}
                      options={serviceProvideOption}
                      selectedValues={formField["serviceProvide"]}
                      onSelect={(value) => {
                        // setFieldHandler("language", value);
                        // setLanguageData(value);
                        handleChange(value, "serviceProvide", "multiselect");
                      }}
                      onRemove={(value) => {
                        // setFieldHandler("language", value);
                        // setLanguageData(value);
                        handleChange(value, "serviceProvide", "multiselect");
                      }}
                      displayValue="name"
                    /> */}

                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      What all services do you provide at your shop?
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors["serviceProvide"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"serviceProvide"}-validation-error`}
                    >
                      {errors["serviceProvide"]?.message}
                    </span>
                  )}
                </div>
                <div className="relative ">
                  <div className="inputArrange">
                    {" "}
                    <Input
                      type="text"
                      value={formField["googleLocation"]}
                      {...validations["googleLocation"]}
                      onChange={(e) =>
                        handleChange(e, "googleLocation", "input")
                      }
                      placeholder="Enter here"
                      className="sm-fsize14"
                    />
                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Please share the Google location of your shop
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors["googleLocation"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"googleLocation"}-validation-error`}
                    >
                      {errors["googleLocation"]?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    {" "}
                    <Select
                      placeholder="Please Select..."
                      value={formField["partner"]}
                      className="fsize14"
                      {...validations["partner"]}
                      onChange={(e) => {
                        handleChange(e, "partner", "select");
                      }}
                    >
                      {partnerOption.map((i) => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select>
                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Will you have a partner?
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors["partner"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"partner"}-validation-error`}
                    >
                      {errors["partner"]?.message}
                    </span>
                  )}
                </div>

                <div className="relative ">
                  <div className="inputArrange">
                    <Select
                      placeholder="Please Select..."
                      value={formField["locationOwnedOrRented"]}
                      className="fsize14"
                      {...validations["locationOwnedOrRented"]}
                      onChange={(e) => {
                        handleChange(e, "locationOwnedOrRented", "select");
                      }}
                    >
                      {locationOwnedOrRentedOption.map((i) => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Select>

                    <p className="fsize15 flex gap-1 px-1 abslform1 bg-fff  items-center">
                      Whether the location is Owned or Rented?
                      <span className="text-danger fsize18"> *</span>
                    </p>
                  </div>
                  {errors["locationOwnedOrRented"] && (
                    <span
                      className="fsize10 mt-1 text-danger"
                      id={`${"locationOwnedOrRented"}-validation-error`}
                    >
                      {errors["locationOwnedOrRented"]?.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex justify-end gap-4 lg:mt-10 md:mt-10 mt-6">
                <Button
                  onClick={async () => {
                    setActiveStep(0);
                  }}
                  type="submit"
                  variant="outline"
                  px={8}
                  className="cust-bgColor theme-btn lg:px-6 lg:py-2 md:px-4 md:py-2 px-2 py-1  fsize14 sm-fsize10 px-1"
                  id=""
                >
                  Previous
                </Button>
                <Button
                  onClick={() => {
                    handleTrim();
                    checkValidataions();
                  }}
                  px={8}
                  className="bg-primary textwhite cust-bgColor theme-btn lg:px-6 lg:py-2 md:px-4 md:py-2 px-2 py-1  fsize14 sm-fsize10 px-1"
                  id=""
                >
                  Submit
                </Button>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CustomHeroSectionForm2;
