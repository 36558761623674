import React, { useEffect, useRef, useState } from 'react';
import { Image } from '@chakra-ui/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

const GallerySection4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:pt-8 md:pt-4 pt-2 lg:pb-16 md:pb-10 pb-6 lg:px-12 md:px-8 px-5">
      <div className="flex justify-between items-center  container mx-auto">
        <div className="w-1/2">
          <p
            id="7748566881"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium text-primary cust-textColor"
          >
            {data ? data['7748566881'] : 'Gallery'}
          </p>
          <p
            id="3172720406"
            className="dynamicStyle cust-textColor fsize34 md-fsize26 font-semibold sm-fsize22 clr-191E22"
          >
            {data ? data['3172720406'] : 'Our Latest Fabric Print Designs'}
          </p>
        </div>
        <div id="9579943303" className="dynamicStyle lg:mt-0 mt-2 ">
          <p className="text-primary theme-btn2 px-4 py-2 cust-textColor font-semibold sm-fsize14 cursor-pointer ">
            {data ? data['9579943303'] : 'View All'}
          </p>
        </div>
      </div>

      <div ref={elementRef}
          className={` fade-bottom lg:pt-8 md:pt-8 pt-8 ${
            isVisible ? 'visiblebottom' : ''
          } `}>
        <div
          id="8326889324"
          className='dynamicStyle'
        >
          <Swiper
            spaceBetween={30}
            navigation={true}
            modules={[Navigation]}
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 12,
              },
              425: {
                slidesPerView: 1.2,
                spaceBetween: 12,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 12,
              },
            }}
          >
            {data && data['8326889324']
              ? data['8326889324'].map(e => {
                  return (
                    <SwiperSlide>
                      <div className="sm-pb-4 w-full overflow-hidden">
                        <Image
                          className="gal-img custimghover"
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="image"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })
              : 'no-data'}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default GallerySection4;
