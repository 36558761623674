import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, Input, Select, useColorMode } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { handleScroll } from './fadeeffect/FadeEffect';

const EventCardSection1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6">
      <div className="flex justify-between lg:pb-12 md:pb-12 pb-6">
        <div className="w-1/2 relative">
          {/* <input className="search-inp"></input> */}
          <Input placeholder="Search" className="cust-textColor" />
          <FeatherIcon
            className="cust-textColor search-abs"
            size={20}
            icon="search"
          />
        </div>
        <div className="sm-wid-30 cust-textColor">
          <Select className=" sm-fsize14" placeholder="Select">
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
        </div>
      </div>

      <div ref={elementRef} className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-10 ${isVisible ? 'visiblebottom' : ''} `}>
        <Card className={colorMode === 'light' ? 'box-shadow-none themeShadow theme-bg ' : 'themeShadow theme-bg'}>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              className="h-56 w-full custimghover "
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text-gray pr-2 font-medium fsize14 sm-fsize12 cust-textColor">
                Mumbai{' '}
              </p>
              <p className="text-gray font-medium fsize14 sm-fsize12 cust-textColor">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 textColor-light">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className={colorMode === 'light' ? 'box-shadow-none themeShadow theme-bg ' : 'themeShadow theme-bg'}>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              className="h-56 w-full custimghover"
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text-gray pr-2 font-medium fsize14 sm-fsize12 cust-textColor">
                Mumbai{' '}
              </p>
              <p className="text-gray font-medium fsize14 sm-fsize12 cust-textColor">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 textColor-light">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className={colorMode === 'light' ? 'box-shadow-none themeShadow theme-bg ' : 'themeShadow theme-bg'}>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              className="h-56 w-full custimghover"
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text-gray pr-2 font-medium fsize14 sm-fsize12 cust-textColor">
                Mumbai{' '}
              </p>
              <p className="text-gray font-medium fsize14 sm-fsize12 cust-textColor">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 textColor-light">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className={colorMode === 'light' ? 'box-shadow-none themeShadow theme-bg ' : 'themeShadow theme-bg'}>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              className="h-56 w-full custimghover"
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text-gray pr-2 font-medium fsize14 sm-fsize12 cust-textColor">
                Mumbai{' '}
              </p>
              <p className="text-gray font-medium fsize14 sm-fsize12 cust-textColor">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 textColor-light">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className={colorMode === 'light' ? 'box-shadow-none themeShadow theme-bg ' : 'themeShadow theme-bg'}>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              className="h-56 w-full custimghover"
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text-gray pr-2 font-medium fsize14 sm-fsize12 cust-textColor">
                Mumbai{' '}
              </p>
              <p className="text-gray font-medium fsize14 sm-fsize12 cust-textColor">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 textColor-light">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className={colorMode === 'light' ? 'box-shadow-none themeShadow theme-bg ' : 'themeShadow theme-bg'}>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              className="h-56 w-full custimghover"
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text-gray pr-2 font-medium fsize14 sm-fsize12 cust-textColor">
                Mumbai{' '}
              </p>
              <p className="text-gray font-medium fsize14 sm-fsize12 cust-textColor">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 textColor-light">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className={colorMode === 'light' ? 'box-shadow-none themeShadow theme-bg ' : 'themeShadow theme-bg'}>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              className="h-56 w-full custimghover"
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text-gray pr-2 font-medium fsize14 sm-fsize12 cust-textColor">
                Mumbai{' '}
              </p>
              <p className="text-gray font-medium fsize14 sm-fsize12 cust-textColor">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 textColor-light">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className={colorMode === 'light' ? 'box-shadow-none themeShadow theme-bg ' : 'themeShadow theme-bg'}>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              className="h-56 w-full custimghover"
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text-gray pr-2 font-medium fsize14 sm-fsize12 cust-textColor">
                Mumbai{' '}
              </p>
              <p className="text-gray font-medium fsize14 sm-fsize12 cust-textColor">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 textColor-light">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
        <Card className={colorMode === 'light' ? 'box-shadow-none themeShadow theme-bg ' : 'themeShadow theme-bg'}>
          <div className="overflow-hidden bradius-card-top">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt="image"
              className="h-56 w-full custimghover"
            />
          </div>
          <div className="p-2">
            <div className="flex pt-2">
              <p className="text-gray pr-2 font-medium fsize14 sm-fsize12 cust-textColor">
                Mumbai{' '}
              </p>
              <p className="text-gray font-medium fsize14 sm-fsize12 cust-textColor">
                Jan 12,2024
              </p>
            </div>
            <div>
              <p className="font-semibold fsize16 sm-fsize14 textColor-light">
                SBI Foundation provides critical relief in the wake of
                devastating monsoon rains
              </p>
            </div>
          </div>
        </Card>
      </div>
    </section>
  );
};

export default EventCardSection1;
