import React from "react";
import Chat from "./components/Chat";

const OnlineChat = () => {
  return (
    <div>
      <Chat />
    </div>
  );
};

export default OnlineChat;
