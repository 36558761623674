import React from 'react';
import { FaLock } from 'react-icons/fa';
import { Tooltip } from '@chakra-ui/react';

const ClgBusiness = () => {
  return (
    <div>
      <div className="relative user-100vh">
        <img
          className="w-full user-100vh"
          alt="image"
          src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
        />

        <div className="absolute flex justify-center top-0 items-center w-full user-100vh">
          <div className="container mx-auto lg:px-0 md:px-0 px-4">
            <h1 className="lg:text-4xl md:text-3xl text-2xl text-white font-semibold mb-4 text-center">
              What are you looking for today?
            </h1>

            <p className="lg:text-base md:text-base text-sm text-white text-center lg:mb-24 md:mb-16 mb-8">
              Find templates for all documents drafted by legal department in
              one place.
            </p>

            <div className="flex items-center lg:gap-8 md:gap-6 gap-3 justify-center lg:px-16 md:px-12 px-0">
              <div className="cursor-pointer relative">
                <div className="edit_hover_class">
                  <img
                    className="w-full bg-ddd-card shadow relative"
                    alt="image"
                    src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  />
                  <div className="absolute top-0 left-0 flex items-center justify-center w-full bg-ddd-card">
                    <Tooltip
                      className="cust-bg-fff"
                      label=" You are not eligible to access this user login."
                      aria-label="A tooltip"
                    >
                      <p className="lock">
                        <FaLock className="lg:w-10 md:w-10 w-8 lg:h-10 md:h-10 h-8 bg-transparent p-2 rounded-full " />
                      </p>
                    </Tooltip>
                  </div>
                </div>
                <p className="lg:text-base md:text-base text-sm text-white lg:mt-4 md:mt-4 mt-2 text-center">
                  CLG User
                </p>
              </div>
              <div className="cursor-pointer">
                <img
                  className="w-full bg-ddd-card shadow"
                  alt="image"
                  src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                />
                <p className="lg:text-base md:text-base text-sm text-white lg:mt-4 md:mt-4 mt-2 text-center">
                  Business User
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClgBusiness;
