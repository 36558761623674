import { Button, Card, FormControl, FormLabel, Input, useColorMode } from '@chakra-ui/react';
import React from 'react';

const ManageAccount = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className="lg:p-6 md:p-6 border-1px rounded-3px">
      <div className="bottom-border-ebebeb">
        <h2 className="fsize20 font-semibold pb-4 clr-606060 cust-textColor">
          Manage Account
        </h2>
      </div>
      <div className="w-full lg:flex md:flex gap-4 py-6">
        <Card
        className={
          colorMode === 'light'
            ? 'lg:w-full border rounded-lg px-3 py-2 lg:mb-0 md:mb-0 mb-4'
            : 'lg:w-full rounded-lg px-3 py-2 lg:mb-0 md:mb-0 mb-4'
        }>
          <h2 className="fsize16 font-semibold cust-textColor">Activite/Deactivate account</h2>
          <p class="fsize12 clr-848484 my-1 textColor-light">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci
            ratione perspiciatis eveniet itaque eos voluptates nihil reiciendis
            quod alias velit!
          </p>
          <div className="mt-4">
            <p className="text-primary cust-textColor fsize12 font-semibold">Deactivate</p>
          </div>
        </Card>

        <Card
        className={
          colorMode === 'light'
            ? 'lg:w-full border rounded-lg px-3 py-2 lg:mb-0 md:mb-0 mb-4'
            : 'lg:w-full rounded-lg px-3 py-2 lg:mb-0 md:mb-0 mb-4'
        }>
          <h2 className="fsize16 font-semibold cust-textColor">Delete account</h2>
          <p class="fsize12 clr-848484 my-1 textColor-light">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci
            ratione perspiciatis eveniet itaque eos voluptates nihil reiciendis
            quod alias velit!
          </p>
          <div className="mt-4">
            <p className="text-primary fsize12 font-semibold cust-textColor">Delete</p>
          </div>
        </Card>
      </div>

      <div className="bottom-border-ebebeb">
        <h2 className="fsize20 font-semibold pb-4 clr-606060 cust-textColor">
          Manage Password
        </h2>
      </div>
      <div className="w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 py-6">
        <FormControl>
          <FormLabel className="fsize14 cust-textColor">Enter Current Password</FormLabel>
          <Input type="password" className='cust-textColor' />
        </FormControl>
        <FormControl>
          <FormLabel className="fsize14 cust-textColor">Enter New Password</FormLabel>
          <Input type="password" className='cust-textColor'/>
        </FormControl>
        <FormControl>
          <FormLabel className="fsize14 cust-textColor">Confirm New Password</FormLabel>
          <Input type="password" className='cust-textColor'/>
        </FormControl>
        <div className="flex items-center lg:pt-4 md:pt-4">
          <p className="fsize14 md-fsize12 sm-fsize12 cust-textColor fweight-500 mr-1">
            forgot your password?{' '}
          </p>
          <span className="text-primary textColor-light font-semibold fsize14 cursor-pointer">
            Click here
          </span>
        </div>
      </div>
      <div className="lg:mt-4 md:mt-4 mt-1">
        <Button  
        color="white"        
          px="8"
          py="6"
          className="sm-update-button bg-primary cust-bgColor"
        >
          Update Password
        </Button>
      </div>
    </div>
  );
};

export default ManageAccount;
