import React from 'react';
import Banner from './components/Banner';
import BreadCumb from './components/BreadCumb';
import Assets from './components/Assets';

const Authorassets = () => {
  return (
    <div>
      <Banner />
      <BreadCumb />
      <Assets />
    </div>
  );
};

export default Authorassets;
