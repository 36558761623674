import React from 'react';

function CardLoop() {
  return (
    <div>
      <h2> CardLoop</h2>
      <div className="grid grid-cols-4 gap-4 ">
        <div className="shadow-lg p-4 ">
          <h2></h2>
          <p></p>
        </div>
      </div>
    </div>
  );
}

export default CardLoop;
