import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux/es/exports';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ContentUpdate from '../../cms/ContentUpdate';
import FeatherIcon from 'feather-icons-react';
import { Container, Draggable } from 'react-smooth-dnd';
import { getAllForms } from '../../redux/forms/forms-action';
import Footer1 from '../allSections/footer/Footer1';
//child components
import Header1 from './header/Header1';
// import Header2 from '../header/Header2';
// import Header3 from '../header/Header3';

import Dialog from '../../cms/Dialog';
import OtpDialog from '../../cms/OTPDialog';
import { FaMoon, FaSun } from 'react-icons/fa';
import {
  verifySession,
  changeIndex,
  deleteSection,
  closeSession,
  getSections,
  getMetaByPageName,
  verifySessionCera,
  closeSessionCera,
} from '../../redux/cms/cms-actions';
import {
  useColorMode,
  useColorModeValue,
  IconButton,
  Button,
} from '@chakra-ui/react';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import SEO from '../../cms/SEO';
// let module;

import { ArrowUpIcon } from '@chakra-ui/icons';
import ElearingHeader from './extension/elearning/ElearingHeader';

const Master = ({ routeName }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  let css = useSelector(state => state.cms.allSectionsCss[routeName]);
  let classList = useSelector(state => state.cms.allSectionsClass[routeName]);
  const [mounted, setMounted] = useState(false);
  const [cmsActive, setCmsActive] = useState(false);
  const [component, setComponent] = useState([]);
  const [compChange, setCompChange] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const [isOtpDialog, setOtpIsDialog] = useState(false);
  const [isOtpDialogClosed, setOtpIsDialogClosed] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, updateSelectedData] = useState('update');
  const [isCheckSession, isSetCheckSession] = useState(false);
  const [changedSections, isChangedSections] = useState([]);
  const [isComponentChanged, setIsComponentChanged] = useState(false);
  const [selectedSection, setSelectedSection] = useState({});
  const [renderSwitch, setRenderSwitch] = useState(false);
  const [metaData, setMetaData] = useState({});
  const { colorMode, toggleColorMode } = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  // let selectedStyle = useSelector(state => state.cms.selectedTheme);
  // console.log(brandProfile, 'checkkk');
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  useEffect(() => {
    if (selectedStyle) {
      // console.log(module);
      importStyle();
      // require(`../../assets/css/variation/${selectedStyle}.scss`)
      //   .then(style => {})
      //   .catch(error => {
      //     console.error('Failed to import style:', error);
      //   });
    }
  }, [selectedStyle, colorMode]);
  //===========SEO============

  const importStyle = async () => {
    if (selectedStyle) {
      console.log(selectedStyle, 'styless');
      let module;
      // let module = await import(`../../assets/css/variation/${selectedStyle}`);
      if (colorMode === 'dark') {
        module = await import(
          `../../assets/css/variation/${selectedStyle}Dark`
        );
      } else {
        module = await import(`../../assets/css/variation/${selectedStyle}`);
      }
      let deleteStyle = document.querySelector(`#dynamicTheme1`);

      if (deleteStyle) {
        deleteStyle.parentNode.removeChild(deleteStyle);
      }

      let dynamicTheme = document.createElement('style');
      console.log('object', module, dynamicTheme);
      dynamicTheme.id = 'dynamicTheme1';
      dynamicTheme.type = 'text/css';

      dynamicTheme.textContent = module.default;
      document.head.appendChild(dynamicTheme);

      // return module.default;
    }
  };

  useEffect(() => {
    const getSEO = async () => {
      let data = await dispatch(getMetaByPageName({ pageName: routeName }));
      if (data && data.code === 200) {
        setMetaData(data.data);
      }
    };
    getSEO();
    if (routeName) {
      if (mounted) {
        setMounted(false);
      }
      mount();
    }
  }, [routeName]);
  //===========SEO============
  let sections = useSelector(state =>
    state.cms && state.cms.sectionNames
      ? state.cms.sectionNames[`${routeName}`]
      : []
  );
  // const handleCssChange = event => {
  //   dispatch(setThemeVariation(event.target.value));
  //   console.log(event.target.value, 'SELECTED');
  // };
  const { token } = useParams();
  useEffect(() => {
    async function defineSection() {
      let comps = [];
      if (!mounted) {
        // isChangedSections()
        let paths = [];

        if (sections) {
          paths = await Promise.all(
            sections.map(element => {
              if (element.path) return element.path;
            })
          );
        }

        comps = await Promise.all(
          paths.map(async x => {
            try {
              const module = await import(`${x}`);
              return module.default;
            } catch (error) {}
          })
        );
        comps = comps.filter(com => com !== undefined);

        setComponent(comps);
        isChangedSections(sections);
        setMounted(true);
      }
      if (compChange) setCompChange(false);
      if (isComponentChanged) setIsComponentChanged(false);
      addStyling();
    }
    checkSession();
    defineSection();
    dispatch(getAllForms());
  }, [component, mounted, sections, css, classList]);

  function mount() {
    let isCera = window.location.href.includes('cera');
    if (isCera) {
      localStorage.removeItem('cmsToken');
      const queryParams = new URLSearchParams(window.location.search);
      const sesssion = queryParams.get('session');

      if (!isOtpDialog && !isOtpDialogClosed) {
        localStorage.setItem('cms-session', sesssion);

        openOtpDialog();
      }
    } else if (token) {
      localStorage.removeItem('cms-session');
      if (!isOtpDialog && !isOtpDialogClosed) {
        localStorage.setItem('cmsToken', token);

        openOtpDialog();
      }
    } else if (!cmsActive) {
      removeClass();
    }
  }

  async function updateIndex() {
    try {
      let data = await dispatch(changeIndex({ sections: changedSections }));
      if (data && data.code === 200) {
        setIsComponentChanged(false);
      }
    } catch (error) {}
  }

  async function closeSessionfn() {
    try {
      // sesssion close popup
      Swal.fire({
        title: 'warning',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2bc370',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, close it!',
      }).then(async result => {
        if (result.isConfirmed) {
          let token = localStorage.getItem('cmsToken');
          let session = localStorage.getItem('cms-session');
          let data;
          if (session) {
            let closeSession = await dispatch(closeSessionCera());
            data = closeSession.data;
          } else if (token) {
            let closeSession = await dispatch(closeSession());
            data = closeSession.data;
          }
          console.log(data);
          if (data && data.code === 200 && data.data.success) {
            localStorage.removeItem('cmsToken');
            localStorage.removeItem('cms-session');
            setCmsActive(false);
            removeClass();
            setIsComponentChanged(false);
            Swal.fire({
              title: 'Session!',
              text: 'Session closed',
              icon: 'success',
              confirmButtonColor: '#2bc370',
            });
          }
        }
      });
    } catch (error) {}
  }

  async function deleteIndex(index) {
    Swal.fire({
      title: 'warning',
      text: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2bc370',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async result => {
      if (result.isConfirmed) {
        let section = changedSections[index];
        let data = await dispatch(
          deleteSection({
            id: section._id,
            pageId: section.pageId,
            pageName: section.pageName,
            sectionId: section.sectionId,
          })
        );
        if (data && data.code === 200) {
          await dispatch(getSections());
          setMounted(false);
          Swal.fire({
            title: 'Section!',
            text: `${
              section.section &&
              section.section.toLowerCase().includes('section')
                ? section.section
                : section.sectionName
                ? `${section.sectionName} section`
                : `section`
            } deleted`,
            icon: 'success',
            confirmButtonColor: '#2bc370',
          });
        }
      }
    });
  }

  async function swap(obj) {
    let tempCompArray = component;
    let temp = changedSections;

    [temp[obj.removedIndex], temp[obj.addedIndex]] = [
      temp[obj.addedIndex],
      temp[obj.removedIndex],
    ];

    [tempCompArray[obj.removedIndex], tempCompArray[obj.addedIndex]] = [
      tempCompArray[obj.addedIndex],
      tempCompArray[obj.removedIndex],
    ];
    isChangedSections(temp);
    setComponent(tempCompArray);
    setCompChange(true);
    setIsComponentChanged(true);
    setRenderSwitch(state => !state);
  }

  function addStyling() {
    let classData = document.getElementsByClassName('dynamicStyle');

    let deleteStyle = document.querySelector(
      `#${process.env.REACT_APP_COMMUNITY_NAME}`
    );

    if (deleteStyle) {
      deleteStyle.parentNode.removeChild(deleteStyle);
    }
    let styleElement = document.createElement('style');
    styleElement.id = process.env.REACT_APP_COMMUNITY_NAME;
    styleElement.type = 'text/css';
    let classString = '';
    if (classData && classData.length > 0) {
      for (let i = 0; i < classData.length; i++) {
        if (
          (classData && classData[i].id && css && css[classData[i].id]) ||
          (css && css[classData[i].id] === '')
        ) {
          classData[i].style.cssText = css[classData[i].id];
        }
        classData[i].classList.add(`dynamic_${classData[i].id}`);
        if (
          classData &&
          classData[i].id &&
          classList &&
          classList[classData[i].id]
        ) {
          classString =
            classString + classList[classData[i].id].replace(/"/g, '');
          classString = classString.replace(/,/g, '');
        }
      }
    }
    styleElement.textContent = classString;
    document.head.appendChild(styleElement);
  }

  function removeClass() {
    let classData = document.getElementsByClassName('dynamicStyle');
    let classData2 = document.getElementsByClassName('dynamicStyle2');
    for (let i = 0; i < classData2.length; i++) {
      classData2[i].classList.remove('imagecms-plus');
    }
    for (let i = 0; i < classData.length; i++) {
      classData[i].classList.remove('dynamicStyle');
    }
  }

  function addClass() {
    let classData = document.getElementsByClassName('dynamicStyle');
    let classData2 = document.getElementsByClassName('dynamicStyle2');

    for (let i = 0; i < classData2.length; i++) {
      if (classData2[i]) {
        classData2[i].addEventListener('click', () => {
          updateSelectedData(classData2[i].id);
          setIsShow(true);
        });
      }
      classData2[i].classList.add('imagecms-plus');
    }
    for (let i = 0; i < classData.length; i++) {
      if (classData[i]) {
        classData[i].addEventListener('click', () => {
          updateSelectedData(classData[i].id);
          setIsShow(true);
        });
      }
      classData[i].classList.add('elementui-css');
    }
  }

  const isSideBarOpen = async id => {
    if (localStorage.getItem('cmsToken')) {
      let data = await dispatch(verifySession());
      if (data && data.data.success) {
        updateSelectedData(id);
        setIsShow(true);
      } else removeClass();
    }
  };

  function closeSidebar() {
    updateSelectedData('');
    setIsShow(false);
  }

  async function closeDialog() {
    updateSelectedData('');
    let data = await dispatch(getSections());
    // setTimeout(() => {

    setMounted(false);
    // }, 100);

    setIsDialog(false);
  }

  function openDialog(index) {
    changedSections[index]['index'] = index;
    setSelectedSection(changedSections[index]);
    setIsDialog(true);
  }

  function closeOtpDialog() {
    setOtpIsDialogClosed(true);
    setOtpIsDialog(false);
    isSetCheckSession(true);
    checkSession();
  }

  function openOtpDialog() {
    setOtpIsDialogClosed(false);
    setOtpIsDialog(true);
  }

  async function checkSession() {
    let token = localStorage.getItem('cmsToken');
    let session = localStorage.getItem('cms-session');
    console.log('session');
    if (token) {
      let data = await dispatch(verifySession());
      if (data && data.data.success) {
        setTimeout(() => {
          addClass();
          setCmsActive(true);
        }, 100);
      } else removeClass();
    }

    if (session) {
      let data = await dispatch(verifySessionCera());
      if (data && data.success) {
        setTimeout(() => {
          addClass();
          setCmsActive(true);
        }, 100);
      } else removeClass();
    }
  }

  function enableCms() {
    isSetCheckSession(true);
    closeOtpDialog();
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.favIcon
        : 'https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png'
      : 'https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png';
  }

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const HandleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  // mount();
  return (
    <>
      <SEO metaData={metaData} />
      <section className={selectedStyle ? 'theme-bodyColor' : ''}>
        <Helmet>
          {/* <title>{brandProfile.projectName}</title> */}
          <meta name="description" content="sections" />
          <meta name="keyword" content="sections" />
          <link
            rel={'shortcut icon'}
            href={getFavicon(brandProfile)}
            type={'image/x-icon'}
          />
        </Helmet>

        {isShow ? (
          <ContentUpdate
            selectedElement={selectedData}
            pageName={routeName}
            isShow={isShow}
            closeSidebar={closeSidebar}
          />
        ) : (
          ''
        )}
        {isDialog ? (
          <>
            <div className="flex justify-center items-center popup fixed left-0">
              <div className="bg-white rounded-xl w-popup lg:py-6 md:py-6 lg:px-8 md:px-8 p-4 relative">
                <Dialog
                  selectedSection={selectedSection}
                  closePopup={() => closeDialog()}
                  selectedRoute={routeName}
                />
                <div className="absolute right-8 top-8">
                  <FeatherIcon
                    icon="x"
                    className="cursor-pointer text-brained"
                    onClick={() => {
                      closeDialog();
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        {isOtpDialog ? (
          <>
            <div className="flex justify-center items-center  popup fixed left-0">
              <div className="bg-white rounded-xl w-popuptr  relative">
                <div className="flex items-center justify-between px-4 py-3 auth-popupsetP">
                  <h5 className="fsize25 text-bold ">Authentication</h5>

                  <FeatherIcon
                    icon="x"
                    className="cursor-pointer"
                    onClick={() => {
                      closeOtpDialog();
                    }}
                  />
                </div>

                <OtpDialog token={token} enableCms={enableCms} />
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        {/* <DragComponent /> */}
        {/* <button onClick={addStyling}>Click here</button> */}

        {/* <HeroSection1 /> */}
        {/* <Box className="fixed loding-bg top-0 zindex-9 flex justify-center items-center left-0 h-full w-full">
        <p className="fsize20 font-semibold fsize18"> Loading...</p>
      </Box> */}
        {location.pathname === '/elearning-sections' && <ElearingHeader />}
        {location.pathname !== '/elearning-sections' && <Header1 />}
        {/* <Header1 /> */}
        <div
          className={`scrolltoTop-css ${isVisible ? 'show' : ''}`}
          onClick={HandleScrollToTop}
        >
          <Button py="6" px="1">
            <IconButton
              size="md"
              fontSize="lg"
              variant="info"
              color="current"
              className=""
              icon={<ArrowUpIcon />}
            />
          </Button>
        </div>

        <div className='pt-20'>
          {cmsActive ? (
            <div className="toggleswitch-css">
              <Button onClick={toggleColorMode} py="6">
                {' '}
                <IconButton
                  size="md"
                  fontSize="lg"
                  aria-label={`Switch to ${text} mode`}
                  variant="info"
                  color="current"
                  icon={<SwitchIcon />}
                />{' '}
                {colorMode === 'light' ? 'Light Mode' : 'Dark Mode'}
              </Button>
            </div>
          ) : (
            ''
          )}

          {cmsActive ? (
            <Container onDrop={e => swap(e)}>
              {component.map((Path, index) => {
                if (Path) {
                  return (
                    <Draggable>
                      <div>
                        <Path
                          key={index}
                          onLoad={() => mount()}
                          isShow={isShow}
                          isSideBarOpen={isSideBarOpen}
                          pageName={routeName}
                        />
                        <div className="zindex9 relative">
                          <div className="flex items-center justify-center">
                            <div className="drag-seccss flex justify-center items-center cursor-allscroll">
                              <FeatherIcon icon="grid" />
                            </div>
                          </div>
                          <p className="dragcss"></p>
                          <div className="flex items-center justify-center">
                            <div className="flex gap-3 justify-center items-center groupicons px-2">
                              <FeatherIcon
                                icon="plus-circle"
                                size={18}
                                className="cursor-pointer"
                                onClick={() => {
                                  openDialog(index);
                                }}
                              />
                              <FeatherIcon
                                icon="copy"
                                size={18}
                                className="cursor-pointer"
                              />
                              <FeatherIcon
                                icon="trash"
                                size={18}
                                className="cursor-pointer"
                                onClick={() => deleteIndex(index)}
                              />
                              <FeatherIcon
                                icon="x"
                                size={18}
                                className="cursor-pointer"
                                onClick={() => closeSessionfn()}
                              />
                              {isComponentChanged ? (
                                <FeatherIcon
                                  icon="check"
                                  size={18}
                                  onClick={() => updateIndex()}
                                  className="cursor-pointer"
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Draggable>
                  );
                }
              })}{' '}
            </Container>
          ) : (
            component.map((Path, index) => {
              if (Path) {
                return (
                  <Path
                    key={index}
                    onLoad={() => mount()}
                    pageName={routeName}
                  />
                );
              }
            })
          )}
        </div>

        <Footer1 />

        {/* <HeroSection1 />
      <div>
        <div className="flex items-center justify-center">
          <div className="drag-seccss flex justify-center items-center cursor-allscroll">
            <FeatherIcon icon="grid" />
          </div>
        </div>
        <p className="dragcss"></p>
        <div className="flex items-center justify-center">
          <div className="flex gap-4 justify-center items-center groupicons">
            <FeatherIcon
              icon="plus-circle"
              size={18}
              className="cursor-pointer"
            />
            <FeatherIcon icon="copy" size={18} className="cursor-pointer" />
            <FeatherIcon icon="x-circle" size={18} className="cursor-pointer" />
          </div>
        </div>
      </div>
      <AboutSection1 />
      <FeatureShowcase1 />
      <ServicesSection1 />
      <BannerSection1 />
      <TestimonialSection1 />
      <AboutSection2 />*/}
      </section>
    </>
  );
};

export default Master;
