import React from 'react';
import Banner from './components/Banner';
import Filetabs from './components/Authortab';

const AuthorTabs = () => {
  return (
    <div>
      <Banner />
      <Filetabs />
    </div>
  );
};

export default AuthorTabs;
