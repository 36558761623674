const initialState = {
  stripe: {},
};
const stripe = (state = initialState, action) => {
  switch (action.type) {
    case 'setStripe':
      return { ...state, stripe: action.payload };

    default:
      return state;
  }
};

export default stripe;
