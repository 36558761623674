import React from 'react';
import UpcomingEvents from './components/UpcomingEvents';
import SocialSection from './components/SocialSection';
import Activities from './components/Activities';
import SearchByLocation from './components/SearchByLocation';
import FeedSection from './components/FeedSection';
import Challenges from './components/Challenges';
import ScoreBoard from './components/ScoreBoard';

const Sport = () => {
  return (
    <div>
      <ScoreBoard />
      <UpcomingEvents />
      <SocialSection />
      <Activities />
      <SearchByLocation />
      <FeedSection />
      <Challenges />
      <ScoreBoard />
    </div>
  );
};

export default Sport;
