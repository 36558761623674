const initialState = {
  Products: [],
};
const ecom_Product = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_PRODUCTS':
      return { ...state, Products: action.payload };

         case "ADD_SINGLE_PRODUCT":
      return {
        ...state,
        singleProduct: action.payload,
      };
    default:
      return state;
  }
};

export default ecom_Product;
