import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux/es/exports';
import { Image, useColorMode } from '@chakra-ui/react';

const AddBanner1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="lg:pt-0 pt-8">
      <div
      className={
        colorMode === 'light'
          ? 'discover-h bg-white lg:px-0 px-4 lg:pt-12'
          : 'discover-h theme-bg lg:px-0 px-4 lg:pt-12'
      }
      >
        <div className="lg:w-2/3 md:3/4 w-full mx-auto">
        <p
          id="3261002709"
          className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-center text-primary cust-textColor"
          data-aos="fade-up"
          data-aos-once="true"
        >
          {data ? data['3261002709'] : 'Discover More'}
        </p>
        <h3
          id="9562756771"
          className="dynamicStyle fsize30 sm-fsize20 text-center textColor-light font-semibold"
          data-aos="fade-up"
          data-aos-once="true"
        >
          {data ? data['9562756771'] : 'SBI Foundation: Nurturing the Roots'}
        </h3>
        </div>

        <div id="8774217206" className="dynamicStyle2 mt-6 lg:mt-12 md:mt-12 w-full">
          <Image
            src={data ? getImage(data['8774217206']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            className="lg:w-6/12 mx-auto iframe-h rounded-2xl"
            alt="home"
          />
        </div>
      </div>
      <div
      className={
        colorMode === 'light'
          ? 'bg-secondary theme-bg lg:h-40 h-24'
          : 'theme-bg lg:h-40 h-24'
      }
      ></div>
    </section>
  );
};

export default AddBanner1;
