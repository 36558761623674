// import axios from "axios";
import apis from '../../apis/client/Forms/ecom_Product';

export const getAllProducts = payload => {
  return async dispatch => {
    try {
      let payload = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };

      let { data } = await apis.getAllProducts(payload);

      if (data) dispatch(setAllProducts(data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setAllProducts = data => {
  return {
    type: 'SET_ALL_PRODUCTS',
    payload: data,
  };
};
export const addSingleProduct = (data) => {
  console.log(data);
  return {
    type: "ADD_SINGLE_PRODUCT",
    payload: data,
  };
};