import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image } from '@chakra-ui/react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import Fade from 'react-reveal/Fade';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import { useDispatch } from 'react-redux';
import testimonial from '../../../apis/client/testimonial';
import { handleScroll } from './fadeeffect/FadeEffect';

const Testimonial3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  // console.log(getAllTestimonial);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    dispatch(getTestimonal());
    return () => {
      cleanp1();
    };
  }, []);
  return (
    <>
      {getAllTestimonial ? (
        <div className="container mx-auto lg:flex md:flex block items-center lg:px-12 md:px-8 px-5 py-5">
          <div
            ref={elementRef}
            className={`fade-left w-40 sm-wfull ${
              isVisible ? 'visibleleft' : ''
            } `}
          >
            <p
              id="1841169736"
              className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium text-primary cust-textColor"
            >
              {data ? data['1841169736'] : 'Lorem Ipsum'}
            </p>
            <h1
              id="4446525163"
              className="lg:mb-2 md:mb-2 cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold dynamicStyle"
            >
              {data ? data['4446525163'] : 'Lorem Ipsum'}
            </h1>
            <p
              id="6116504466"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['6116504466']
                  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle textColor-light fsize16 md-fsize14 sm-fsize14"
            >
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus
          consectetur euismod aenean. */}
            </p>
          </div>
          <div className="md:ml-8 w-60 sm-wfull lg:mt-0 md:mt-0 mt-6">
            <Swiper
              spaceBetween={30}
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="mySwiper container relative mx-auto overflow-x-hidden pb-2 newswiper"
              autoplay={{
                delay: '1000',
              }}
              navigation={{
                nextEl: '.image-swiper-button-nexts',
                prevEl: '.image-swiper-button-prevs',
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                991: {
                  slidesPerView: 1,
                  spaceBetween: 12,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 12,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 12,
                },
                325: {
                  slidesPerView: 1,
                  spaceBetween: 12,
                },
              }}
            >
              <div className="swiper-button image-swiper-button-prevtest bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
                </svg>
              </div>
              <div className="swiper-button image-swiper-button-nexttest bg-white rounded-full p-1 cursor-pointer">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
                </svg>
              </div>
              {getAllTestimonial && getAllTestimonial.data.result.length > 0
                ? getAllTestimonial.data.result.map(testimonial => {
                    return (
                      <SwiperSlide>
                        <div className="lg:p-6 md:p-4 p-4 theme-radius border-1px cust-shadow theme_insertShadow m-1 rounded-lg">
                          <div className="flex justify-center w-full text-center">
                            <div className="">
                              <div className="w-full flex items-center h-28">
                                <Image
                                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                  src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                                  alt="test-img"
                                  className="w-24 h-24 mx-auto bg-d9d9d9 rounded-full"
                                />
                              </div>
                              <div className="">
                                {/* <h2 className="fsize24 sm-fsize18 font-semibold lg:mb-2">
                                  {`${testimonial.firstName}` +
                                    '\n' +
                                    `${testimonial.lastName}`}
                                </h2> */}
                                <h2 className="fsize24 sm-fsize18 font-semibold lg:mb-2 cust-textColor">
                                  {testimonial.name}
                                </h2>
                                {/* <p className="fsize18 sm-fsize16">CEO</p> */}
                                <p className="fsize16 sm-fsize14 lg:mb-6 textColor-light">
                                  {testimonial.testimonial}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })
                : 'No Data'}
            </Swiper>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Testimonial3;
