import React, { useEffect, useRef, useState } from 'react';
import { Image, Button } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection20 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="herosection20-img relative">
      <div id="9468849523" className="w-full dynamicStyle2">
        <Image
          src={data ? getImage(data['9468849523']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="hero"
          className="herosection20-img object-cover "
        />
      </div>
      <div className="absolute top-0 left-0 w-full flex items-center justify-center herosection20-img text-center">
        <div ref={elementRef} className={`fade-bottom lg:px-12 md:px-8 px-5 ${isVisible ? 'visiblebottom' : ''} `}>
          <h3
            id="0786727363"
            className="cust-textColor fsize40 md-fsize28 sm-fsize24 font-semibold text-primary dynamicStyle"
          >
            {data ? data['0786727363'] : 'EDUCATE'}
          </h3>
          <p
            id="5789578239"
            dangerouslySetInnerHTML={{
              __html: data ? data['5789578239'] : 'no-data',
            }}
            className="fsize16 md-fsize16 sm-fsize14 lg:w-8/12 mx-auto cust-textColor dynamicStyle"
          ></p>
          <div className="lg:mt-8 md:mt-8 mt-6 flex items-center lg:gap-3 gap-2 justify-center">
            <Button
              colorScheme="whiteAlpha"
              id="3946890428"
              borderRadius={0}
              className="dynamicStyle theme-btn cust-textColor sm-fsize13 buttonAnimate bdr-2px"
              px="5"
              variant="outline"
            >
              {' '}
              <span onClick={() => handleClick('3946890428')}>
                {data ? data['3946890428'] : 'Lorem ipsum'}
              </span>
            </Button>
            <Button
              colorScheme="transparent"
              id="9729220378"
              className="border-0 text-whites theme-btn cust-textColor sm-fsize13 dynamicStyle "
            >
             <span onClick={() => handleClick('9729220378')}>
                {data ? data['9729220378'] : 'Lorem ipsum'}
              </span>
              <FeatherIcon
                icon="arrow-right"
                size="18"
                className=" ml-1"
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection20;
