import apis from '../../apis/client/index';
export const getGateway = payload => {
  return async dispatch => {
    try {
      let payload = {
        isDeleted: true,
        isActive: true,
        varified: 'success',
      };
      let { data } = await apis.gateway.getGateway(payload);

      if (data && data.data && data.data.length)
        dispatch(setGateway(data.data[0]));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setGateway = data => {
  return {
    type: 'setGateway',
    payload: data,
  };
};
