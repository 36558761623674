import { Button, Input, Select } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import AddNewCoachPopUp from './AddNewCoachPopUp';
import ViewCoachPopUp from './ViewCoachPopUp';

const FindCoach = () => {
  const [isOpenBooking, setIsOpenBooking] = useState(false);
  const onCloseBooking = () => setIsOpenBooking(false);
  const onOpenNewBooking = () => setIsOpenBooking(true);

  const [viewProfile, setViewProfile] = useState(false);
  const closeProfile = () => setViewProfile(false);
  const openProfile = () => setViewProfile(true);

  return (
    <div className="lg:p-6 md:p-6 border-1px rounded-3px">
      <AddNewCoachPopUp isOpen={isOpenBooking} onClose={onCloseBooking} />
      <ViewCoachPopUp isOpen={viewProfile} onClose={closeProfile} />

      <div className="lg:flex justify-between  mb-6">
        <h2 className=" fsize20 font-semibold  clr-606060 lg:mb-0 md:mb-4 mb-2">
          My Booking
        </h2>
        <div className="flex gap-4">
          <Select placeholder="Status" className="fsize14">
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
          <Select placeholder="Month" className="fsize14">
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
          <Button px={8} className="fsize14" onClick={onOpenNewBooking}>
            Add New
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
        <div className="px-4 py-3 bg-f4f5f6 rounded-lg">
          <div className="flex justify-between items-center mb-1">
            <h2 className="fsize16 font-semibold">Cardiovascular</h2>
            <FaEllipsisV className="cursor-pointer" />
          </div>
          <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-2 gap-4 py-2">
            <Input
              type="date"
              className="fsize12 bg-fff font-semibold w-full pd-6px rounded-md input-border-none"
            />
            <Input
              type="date"
              className="fsize12 bg-fff  font-semibold w-full pd-6px rounded-md input-border-none"
            />
            <Input
              type="time"
              className="fsize12 bg-fff  font-semibold w-full pd-6px rounded-md input-border-none"
            />
            <Button
              colorScheme="success"
              variant="outline"
              className="fsize12 bg-fff"
            >
              Approved
            </Button>
          </div>
          <div className="w-full mt-2">
            <Button
              colorScheme="tertiary"
              className="w-100per fsize12"
              onClick={openProfile}
            >
              View
            </Button>
          </div>
        </div>
        <div className="px-4 py-3 bg-f4f5f6 rounded-lg">
          <div className="flex justify-between items-center mb-1">
            <h2 className="fsize16 font-semibold">Cardiovascular</h2>
            <FaEllipsisV className="cursor-pointer" />
          </div>
          <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-2 gap-4 py-2">
            <Input
              type="date"
              className="fsize12 bg-fff font-semibold w-full pd-6px rounded-md input-border-none"
            />
            <Input
              type="date"
              className="fsize12 bg-fff  font-semibold w-full pd-6px rounded-md input-border-none"
            />
            <Input
              type="time"
              className="fsize12 bg-fff  font-semibold w-full pd-6px rounded-md input-border-none"
            />
            <Button
              colorScheme="success"
              variant="outline"
              className="fsize12 bg-fff"
            >
              Pending
            </Button>
          </div>
          <div className="w-full mt-2">
            <Button
              colorScheme="tertiary"
              className="w-100per fsize12"
              onClick={openProfile}
            >
              View
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindCoach;
