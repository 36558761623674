import API from './repositoryFactory';

const createTestimonal = 'CreateTestimonal';
const getTestimonal = 'get';
const updateTestimonal = 'updateTestimonal';
const deleteTestimonal = 'deleteTestimonal';

const testimonal = route => {
  return `portfolio/testimonial/${route}`;
};
export default {
  createTestimonal(payload) {
    return API.post(testimonal(createTestimonal), payload);
  },

  getTestimonal(payload) {
    return API.post(testimonal(getTestimonal), payload);
  },

  updateTestimonal(payload) {
    return API.post(testimonal(updateTestimonal), payload);
  },

  deleteTestimonal(payload) {
    return API.post(testimonal(deleteTestimonal), payload);
  },
};
