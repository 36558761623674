import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';

const Statistics2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="bg-primary lg:py-10 py-8 relative">
      <div className="absolute top-4 left-0 lg:block hidden mt-12">
        <hr className="border-hr"></hr>
      </div>
      <div className="container mx-auto lg:px-12 md:px-12 px-4">
        <div className="lg:flex items-start w-full">
          <div className="lg:w-4/12 lg:pr-20">
            <h5
              className="fsize35 sm-fsize24 text-white font-semibold dynamicStyle"
              id="6000652470"
            >
              {data ? data['6000652470'] : 'Lorem Ipsum'}
            </h5>
          </div>
          <div className="lg:w-8/12 lg:mt-0 mt-5 grid lg:grid-cols-2 grid-cols-1 gap-10">
            <div
              className="flex gap-3 items-center"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="dynamicStyle2 w-full" id="0830013258">
                <Image
                  src={data ? getImage(data['0830013258']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="impacted"
                  className="lg:w-20 w-12 lg:h-20 h-12"
                />
              </div>
              <div>
                <h6
                  className="fsize30 sm-fsize17 text-white font-semibold dynamicStyle"
                  id="5574832773"
                >
                  {data ? data['5574832773'] : 'Lorem Ipsum'}
                </h6>
                <p
                  className="fsize18 sm-fsize14 text-white dynamicStyle"
                  id="5878011643"
                >
                  {data ? data['5878011643'] : 'Lorem Ipsum'}
                </p>
              </div>
            </div>

            <div
              className="flex gap-3 items-center"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="dynamicStyle2 w-full" id="1869722570">
                <Image
                  src={data ? getImage(data['1869722570']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="impacted"
                  className="lg:w-20 w-12 lg:h-20 h-12"
                />
              </div>
              <div>
                <h6
                  className="fsize30 sm-fsize17 text-white font-semibold dynamicStyle"
                  id="4400359148"
                >
                  {data ? data['4400359148'] : 'Lorem Ipsum'}
                </h6>
                <p
                  className="fsize18 sm-fsize14 text-white dynamicStyle"
                  id="9223576025"
                >
                  {data ? data['9223576025'] : 'Lorem Ipsum'}
                </p>
              </div>
            </div>

            <div
              className="flex gap-3 items-center"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="dynamicStyle2 w-full" id="5787093859">
                <Image
                  src={data ? getImage(data['5787093859']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="impacted"
                  className="lg:w-20 w-12 lg:h-20 h-12"
                />
              </div>
              <div>
                <h6
                  className="fsize30 sm-fsize17 text-white font-semibold dynamicStyle"
                  id="8579758495"
                >
                  {data ? data['8579758495'] : 'Lorem Ipsum'}
                </h6>
                <p
                  className="fsize18 sm-fsize14 text-white dynamicStyle"
                  id="7508203325"
                >
                  {data ? data['7508203325'] : 'Lorem Ipsum'}
                </p>
              </div>
            </div>

            <div
              className="flex gap-3 items-center"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="dynamicStyle2 w-full" id="1270915927">
                <Image
                  src={data ? getImage(data['1270915927']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="impacted"
                  className="lg:w-20 w-12 lg:h-20 h-12"
                />
              </div>
              <div>
                <h6
                  className="fsize30 sm-fsize17 text-white font-semibold dynamicStyle"
                  id="0389621687"
                >
                  {data ? data['0389621687'] : 'Lorem Ipsum'}
                </h6>
                <p
                  className="fsize18 sm-fsize14 text-white dynamicStyle"
                  id="0487717407"
                >
                  {data ? data['0487717407'] : 'Lorem Ipsum'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statistics2;
