import React, { useEffect, useRef, useState } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection23 = ({ pageName }) => {
  const elementRef = useRef(null);
  const { colorMode, toggleColorMode } = useColorMode();
  const [isVisible, setIsVisible] = useState(false);
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className={colorMode === 'light' ? 'bg-f8f8f8' : ''}>
      <div className="lg:flex md:flex lg:gap-12 md:gap-12 gap-4 container mx-auto  sm-reverse  lg:py-16 md:py-16 lg:px-12 md:px-8 px-5">
        <div className="lg:w-1/2 md:w-1/2">
          <div
            ref={elementRef}
            className={`fade-left w-full relative zindex9 ${
              isVisible ? 'visibleleft' : ''
            } `}
          >
            <div id="6417334104" className="dynamicStyle2 ">
              <Image
                src={data ? getImage(data['6417334104']) : ''}
                alt="heroImage"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full sm-h250px rounded-sm md-h-350px minh450px object-cover rounded-lg"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 lg:py-12 md:py-0 py-6 relative zindex9 flex flex-col justify-center">
          <h3
            id="7089343175"
            className="dynamicStyle fsize36 md-fsize24 sm-fsize20 font-semibold text-primary cust-textColor"
          >
            {data ? data['7089343175'] : 'Lorem Ipsum'}
          </h3>

          <p
            id="9823602934"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['9823602934']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14  mt-2 textColor-light lg:leading-7 md:leading-7 leading-6"
          ></p>

          <Button
            width="180px"
            height="50px"
            className={
              colorMode === 'light'
                ? 'dynamicStyle bg-primary  lg:mt-6 md:mt-6 mt-4  theme-btn sm-fsize14 clr-fff  bdr-2px'
                : 'dynamicStyle cust-bgColor  theme-btn lg:mt-6 md:mt-6 mt-4 sm-fsize14 clr-fff buttonAnimate bdr-2px'
            }
            id="8069555374"
          >
            <span onClick={() => handleClick('8069555374')}>
              {data ? data['8069555374'] : 'Lorem Ipsum'}
            </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default AboutSection23;
