import React, { useEffect, useRef, useState } from 'react';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

const CardText = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section>
      <div className="container mx-auto lg:pb-16 md:pb-16 pb-8 lg:pt-12 md:pt-12 pt-8 lg:px-12 md:px-8 px-5">
        <div className="text-center lg:w-2/3 md:w-3/4 w-full mx-auto">
          <h2
            id="4976480268"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 uppercase text-primary cust-textColor font-semibold"
          >
            {data ? data['4976480268'] : 'What We Do'}
          </h2>
          
        </div>
        <div
            ref={elementRef}
            className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mt-12 md:mt-12 mt-6 lg:gap-8 md:gap-8 gap-6 ${
              isVisible ? 'visiblebottom' : ''
            } `}
          >
            <Card
              className={
                colorMode === 'light'
                  ? 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
                  : 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
              }
            >
              <h2
                id="1213524592"
                className="dynamicStyle lg:text-2xl md:text-xl text-lg lheight text-primary cust-textColor  font-semibold pl-2"
              >
                {data ? data['1213524592'] : 'Educate'}
              </h2>
              <p
                id="0111982049"
                dangerouslySetInnerHTML={{
                  __html: data ? data['0111982049'] : 'no-data',
                }}
                className="dynamicStyle fsize16 text-dark sm-fsize14 textColor-light col-62 lg:leading-7 md:leading-7 leading-6"
              ></p>
            </Card>
            <Card
              className={
                colorMode === 'light'
                  ? 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
                  : 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
              }
            >
              <h2
                id="2415028268"
                className="dynamicStyle lg:text-2xl md:text-xl text-lg lheight text-primary cust-textColor  font-semibold pl-2"
              >
                {data ? data['2415028268'] : 'Educate'}
              </h2>
              <p
                id="6787662183"
                dangerouslySetInnerHTML={{
                  __html: data ? data['6787662183'] : 'no-data',
                }}
                className=" dynamicStyle fsize16 text-dark sm-fsize14 textColor-light col-62 lg:leading-7 md:leading-7 leading-6"
              ></p>
            </Card>
            <Card
              className={
                colorMode === 'light'
                  ? 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
                  : 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
              }
            >
              <h2
                id="9289452004"
                className="dynamicStyle lg:text-2xl md:text-xl text-lg lheight text-primary cust-textColor  font-semibold pl-2"
              >
                {data ? data['9289452004'] : 'Educate'}
              </h2>
              <p
                id="2638899178"
                dangerouslySetInnerHTML={{
                  __html: data ? data['2638899178'] : 'no-data',
                }}
                className="dynamicStyle fsize16 text-dark sm-fsize14 textColor-light col-62 lg:leading-7 md:leading-7 leading-6"
              ></p>
            </Card>
          </div>
        <div
            ref={elementRef}
            className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mt-12 md:mt-12 mt-6 lg:gap-8 md:gap-8 gap-6 ${
              isVisible ? 'visiblebottom' : ''
            } `}
          >
            <Card
              className={
                colorMode === 'light'
                  ? 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
                  : 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
              }
            >
              <h2
                id="1213524592"
                className="dynamicStyle lg:text-2xl md:text-xl text-lg lheight text-primary cust-textColor  font-semibold pl-2"
              >
                {data ? data['1213524592'] : 'Educate'}
              </h2>
              <p
                id="0111982049"
                dangerouslySetInnerHTML={{
                  __html: data ? data['0111982049'] : 'no-data',
                }}
                className="dynamicStyle fsize16 text-dark sm-fsize14 textColor-light col-62 lg:leading-7 md:leading-7 leading-6"
              ></p>
            </Card>
            <Card
              className={
                colorMode === 'light'
                  ? 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
                  : 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
              }
            >
              <h2
                id="2415028268"
                className="dynamicStyle lg:text-2xl md:text-xl text-lg lheight text-primary cust-textColor  font-semibold pl-2"
              >
                {data ? data['2415028268'] : 'Educate'}
              </h2>
              <p
                id="6787662183"
                dangerouslySetInnerHTML={{
                  __html: data ? data['6787662183'] : 'no-data',
                }}
                className=" dynamicStyle fsize16 text-dark sm-fsize14 textColor-light col-62 lg:leading-7 md:leading-7 leading-6"
              ></p>
            </Card>
            <Card
              className={
                colorMode === 'light'
                  ? 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
                  : 'cust-shadow rounded-lg p-5 themeShadow theme-bg'
              }
            >
              <h2
                id="9289452004"
                className="dynamicStyle lg:text-2xl md:text-xl text-lg lheight text-primary cust-textColor  font-semibold pl-2"
              >
                {data ? data['9289452004'] : 'Educate'}
              </h2>
              <p
                id="2638899178"
                dangerouslySetInnerHTML={{
                  __html: data ? data['2638899178'] : 'no-data',
                }}
                className="dynamicStyle fsize16 text-dark sm-fsize14 textColor-light col-62 lg:leading-7 md:leading-7 leading-6"
              ></p>
            </Card>
          </div>
      </div>
    </section>
  );
};

export default CardText;
