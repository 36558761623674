import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Image, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const Team9 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section
      className={
        colorMode === 'light'
          ? 'lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6 theme-bg bg-grad'
          : 'lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6'
      }
    >
      <div
        ref={elementRef}
        className={`fade-bottom container mx-auto overflow-x-hidden lg:px-12 md:px-8 px-5 md:mt-3 ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none p-4  w-full relative'
                : 'p-4 w-full relative'
            }
          >
            <div
              id="8969416683"
              className="dynamicStyle2 flex justify-center w-full "
            >
              <Image
                src={data ? getImage(data['8969416683']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-28 h-28 lg:mb-4 mb-2 rounded-full border p-3"
              />
            </div>
            <h2
              id="5446303354"
              className="dynamicStyle fsize18 sm-fsize16  font-semibold text-center cust-textColor clr-fff theme-textColor"
            >
              {data ? data['5446303354'] : 'Lorem Ipsum'}
            </h2>
            <p
              id="6374644869"
              className="dynamicStyle text-gray fsize16 sm-fsize14 text-center textColor-light clr-fff theme-textColor"
            >
              {data ? data['6374644869'] : 'Lorem Ipsum'}
            </p>
            <div className="absolute-line"></div>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none p-4 w-full border-left relative'
                : 'p-4 w-full border-left relative'
            }
          >
            <div
              id="6008209530"
              className="dynamicStyle2 flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data['6008209530']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-28 h-28 lg:mb-4 mb-2 rounded-full border p-3"
              />
            </div>
            <h2
              id="6030375002"
              className="dynamicStyle fsize18 sm-fsize16 font-semibold text-center cust-textColor clr-fff theme-textColor"
            >
              {data ? data['6030375002'] : 'Lorem Ipsum'}
            </h2>
            <p
              id="2836020428"
              className="dynamicStyle text-gray fsize16 sm-fsize14 text-center textColor-light clr-fff theme-textColor"
            >
              {data ? data['2836020428'] : 'Lorem Ipsum'}
            </p>
            <div className="absolute-line1"></div>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none p-4 w-full border-left relative'
                : 'p-4 w-full border-left relative'
            }
          >
            <div
              id="9717409424"
              className="dynamicStyle2 flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data['9717409424']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-28 h-28 lg:mb-4 mb-2 rounded-full border p-3"
              />
            </div>
            <h2
              id="6436571680"
              className="dynamicStyle fsize18 sm-fsize16 font-semibold text-center cust-textColor clr-fff theme-textColor"
            >
              {data ? data['6436571680'] : 'Lorem Ipsum'}
            </h2>
            <p
              id="4462117433"
              className="dynamicStyle text-gray fsize16 sm-fsize14 text-center textColor-light clr-fff theme-textColor"
            >
              {data ? data['4462117433'] : 'Lorem Ipsum'}
            </p>
            <div className="absolute-line2"></div>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none p-4 w-full border-left '
                : 'p-4 w-full border-left '
            }
          >
            <div
              id="9972361576"
              className="dynamicStyle2 flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data['9972361576']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-28 h-28 lg:mb-4 mb-2 rounded-full border p-3"
              />
            </div>
            <h2
              id="0599011983"
              className="dynamicStyle fsize18 sm-fsze16 font-semibold text-center cust-textColor clr-fff theme-textColor"
            >
              {data ? data['0599011983'] : 'Lorem Ipsum'}
            </h2>
            <p
              id="4504189454"
              className="dynamicStyle text-gray fsize16 sm-fsize14 text-center textColor-light clr-fff theme-textColor"
            >
              {data ? data['4504189454'] : 'Lorem Ipsum'}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team9;
