import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const Services1 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  return (
    <div
      className={
        colorMode === 'light'
          ? 'container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8 theme-bg'
          : 'container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
      }
    >
      <div className="text-center lg:mb-16 md:mb-12 mb-8">
        <p
          id="0231676834"
          className="dynamicStyle fsize40 md-fsize32 sm-fsize20 font-semibold text-primary pb-2"
        >
          {data ? data['0231676834'] : 'Services'}
        </p>
        <hr className="hr-blg" />
      </div>
      <div
        id="4745928847"
        className="dynamicStyle grid lg:grid-cols-2 md:grid-cols-2 lg:gap-10 md:gap-8 gap-6"
      >
        {data && data['4745928847']
          ? data['4745928847'].map((e, index) => {
              return (
                <div className="flex gap-4">
                  <div>
                    <FeatherIcon
                      icon="check"
                      size={18}
                      className="mt-1 cust-textColor"
                    />
                  </div>
                  <div>
                    <p className="font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor">
                    {e.field1}
                    </p>
                    <p className="sm-fsize14 textColor-light">
                    {e.field2}
                    </p>
                  </div>
                </div>
              );
            })
          : 'no-card'}
        {/* <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div>
        <div className='flex gap-4'>
        <div>
          <FeatherIcon icon="check" size={18} className="mt-1 cust-textColor" />
        </div>
        <div>
            <p className='font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor'>Lorem Ipsum</p>
            <p className='sm-fsize14 textColor-light'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam, voluptatibus!</p>
        </div>
        </div> */}
      </div>
    </div>
  );
};

export default Services1;
