import React from 'react';
import ChatBox from './ChatBox';
import { Search } from 'react-feather';
import { Image } from '@chakra-ui/react';

const OnlineChat = () => {
  return (
    <div className="w-full flex gap-4">
      <div className="w-2/5 border  py-2 px-4">
        <h2 className="fsize20 font-semibold pb-2 pb-1 clr-606060">Chat</h2>
        <div className="pb-4 relative flex items-center">
          <input
            type="text"
            className="fsize14 w-full border px-3 py-2 rounded-md"
            placeholder="search"
          />
          <Search className="text-primary w-4 absolute right-4" />
        </div>
        <div className="flex flex-col gap-5">
          <div className="bg-f4f4f48c rounded-sm p-2 flex gap-3">
            <div className="relative w-16 h-12">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="logo"
                className="w-12 h-12 rounded-full"
              />
              <div className="online-dot absolute bottom-2 right-1"></div>
            </div>
            <div className="w-full">
              <div className="flex justify-between items-center w-full">
                <h2 className="fsize16 font-semibold">Manish</h2>
                <span className="fsize10 clr-515151">2 min ago</span>
              </div>
              <p className="fsize12">Lorem ipsum dolor sit.</p>
            </div>
          </div>
          <div className="bg-f4f4f48c rounded-sm p-2 flex gap-3">
            <div className="relative w-16 h-12">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="logo"
                className="w-12 h-12 rounded-full"
              />
              <div className="online-dot absolute bottom-2 right-1"></div>
            </div>
            <div className="w-full">
              <div className="flex justify-between items-center w-full">
                <h2 className="fsize16 font-semibold">Manish</h2>
                <span className="fsize10 clr-515151">2 min ago</span>
              </div>
              <p className="fsize12">Lorem ipsum dolor sit.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-3/5">
        <ChatBox />
      </div>
    </div>
  );
};

export default OnlineChat;
