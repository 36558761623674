import React from 'react';
import FeatherIcon from 'feather-icons-react';
import 'swiper/css';
import { Image, Card, useColorMode } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux';

const ProductCategories1 = ({ pageName }) => {

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  let allTeam = useSelector(state => state.Teams.Teams);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-12 py-8 lg:flex md:flex gap-2">
      <div className="lg:w-1/3 md:w-1/3 flex items-center lg:mb-0 md:mb-0 mb-4">
        <div>
          <h3 id="8550842503"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-bold text-primary cust-textColor">
             {data ? data['8550842503'] : 'Explore by Category'}
          </h3>
          <p id="0417949383"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['0417949383']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle text-black fsize16 md-fsize16 sm-fsize14 mt-2 sm-fsize14 textColor-light">
            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
            aliquid voluptates dolore delectus, unde ab quisquam quos placeat
            eos labore? */}
          </p>
          <p className="mt-6 cust-textColor sm-fsize14 font-semibold text-black">
            <span id="4136484784"
              className="dynamicStyle flex items-center cursor-pointer">
              {data ? data['4136484784'] : 'All Categories'}
              <FeatherIcon
                className=" cust-textColor ml-3 text-black"
                size={18}
                icon="arrow-right"
              />
            </span>
          </p>
        </div>
      </div>
      <div id="4240594015"
          className="dynamicStyle lg:w-3/4 md:w-3/4">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper px-4 py-6"
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1.5,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1.5,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1.5,
              spaceBetween: 25,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>

          {data && data['4240594015']
            ? data['4240594015'].map((e, index) => {
                return (
          <SwiperSlide className="">
            <Card className="p-3 cust-shadow themeShadow theme-bg rounded-lg hvr-scale cursor-pointer">
              <div className="pb-4 img-h-300 sm-h-250px">
                <Image
                  className="h-full w-full"
                  src={e ? getImage(e.field1) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <p className="text-black cust-textColor">{e.field2}</p>
            </Card>
          </SwiperSlide>
          );
        })
      : 'no-card'}
          {/* <SwiperSlide className="">
            <Card className="p-3 cust-shadow themeShadow theme-bg rounded-lg hvr-scale cursor-pointer">
              <div className="pb-4 img-h-300 sm-h-250px">
                <Image
                  className="h-full w-full"
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                />
              </div>
              <p className="text-black cust-textColor">Arm Sofas</p>
            </Card>
          </SwiperSlide>
          <SwiperSlide className="">
            <Card className="p-3 cust-shadow themeShadow theme-bg rounded-lg hvr-scale cursor-pointer">
              <div className="pb-4 img-h-300 sm-h-250px">
                <Image
                  className="h-full w-full"
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                />
              </div>
              <p className="text-black cust-textColor">Arm Sofas</p>
            </Card>
          </SwiperSlide>
          <SwiperSlide className="">
            <Card className="p-3 cust-shadow themeShadow theme-bg rounded-lg hvr-scale cursor-pointer">
              <div className="pb-4 img-h-300 sm-h-250px">
                <Image
                  className="h-full w-full"
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                />
              </div>
              <p className="text-black cust-textColor">Arm Sofas</p>
            </Card>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </div>
  );
};

export default ProductCategories1;
