import React from 'react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import { Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const FeatureCard21 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
  return (
    <div
    className={
      colorMode === 'light'
        ? 'bg-1c1c1c theme-bg'
        : 'theme-bg'
    }
    >
      <div className="container mx-auto lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5">
        <div className="lg:w-3/4 md:w-3/4 sm-wfull mx-auto text-center">
          <p
            id="0285495512"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold pb-2 cust-textColor text-primary"
          >
            {data ? data['0285495512'] : 'Lorem Ipsum'}
          </p>
          <p id="8414272995"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['8414272995']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle fsize16 sm-fsize14 textColor-light">
            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Consectetur, beatae?. */}
          </p>
        </div>
        
        <div id="0904982233"
          className="dynamicStyle lg:mt-12 md:mt-12 mt-6">
        
          <Swiper
            spaceBetween={40}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-nexts',
              prevEl: '.image-swiper-button-prevs',
            }}
            className=" "
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              820: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              300: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
            }}
          >
            {/* <div className="swiper-button  test-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button test-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div> */}
            <div className="swiper-button image-swiper-button-prevs cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-nexts cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {data && data['0904982233']
            ? data['0904982233'].map((e, index) => {
                return (
            <SwiperSlide>
              <div className="relative">
                <Image
                  src={e ? getImage(e.field1) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="h-450px"
                  alt="Image"
                />
                <div className="text-center absolute bottom-8 left-0 p-4 w-full">
                  <p className="font-medium cust-textColor text-black">
                  {e.field2}
                  </p>
                  <p className="fsize20 sm-fsize18 font-semibold textColor-light text-black">
                  {e.field3}
                  </p>
                </div>
              </div>
            </SwiperSlide>
             );
            })
          : 'no-card'}
            {/* <SwiperSlide>
              <div className="relative">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="h-450px"
                  alt="Image"
                />
                <div className="text-center absolute bottom-8 left-0 p-4 w-full">
                  <p className="font-medium cust-textColor text-black">
                    Careers
                  </p>
                  <p className="fsize20 sm-fsize18 font-semibold textColor-light text-black">
                    Lorem ipsum dolor sit amet consectetur.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="h-450px"
                  alt="Image"
                />
                <div className="text-center absolute bottom-8 left-0 p-4 w-full">
                  <p className="font-medium cust-textColor text-black">
                    Careers
                  </p>
                  <p className="fsize20 sm-fsize18 font-semibold textColor-light text-black">
                    Lorem ipsum dolor sit amet consectetur.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="h-450px"
                  alt="Image"
                />
                <div className="text-center absolute bottom-8 left-0 p-4 w-full">
                  <p className="font-medium cust-textColor text-black">
                    Careers
                  </p>
                  <p className="fsize20 sm-fsize18 font-semibold textColor-light text-black">
                    Lorem ipsum dolor sit amet consectetur.
                  </p>
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>
         
        </div>
      </div>
    </div>
  );
};

export default FeatureCard21;
