import { Button, Image } from '@chakra-ui/react';
import React from 'react';
import FeatherIcon from 'feather-icons-react';
import ImageGallery from 'react-image-gallery';

const ProductDetails4 = () => {
  const colors = [
    {
      color: 'red',
    },
    {
      color: 'green',
    },
    {
      color: 'blue',
    },
    {
      color: 'orange',
    },
  ];
  const sizes = [
    {
      size: 'X',
    },
    {
      size: 'XL',
    },
    {
      size: 'XXL',
    },
    {
      size: 'XXXL',
    },
  ];
  const images = [
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/SBI-foundation/SBI-foundation/home/Frame%20125.png',
    },
    {
      original:
        'https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127',
      thumbnail:
        'https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127',
    },
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];

  return (
    <div className="container mx-auto gap-8 lg:px-12 md:px-8 px-5 lg:py-20 md:py-12 py-8 lg:flex ">
      <div className="lg:w-1/2 md:w-full w-full">
      <ImageGallery items={images} />
      </div>
      <div className="lg:w-1/2 md:w-full w-full lg:mt-0 mt-20">
        <div className="w-full flex items-center justify-between">
          <h3 className="fsize14 font-semibold textColor-light">Loungewear</h3>
          <p className="fsize12 flex gap-1 items-center font-medium textColor-light"><FeatherIcon icon="star" size={12} /> (101)</p>
        </div>
        <h2 className="fsize44 leading-12 md-fsize36 sm-fsize24 font-semibold cust-textColor">
          Lorem ipsum dolor sit amet.
        </h2>
        <div className="mt-3 textColor-light">
          <p className="fsize14 lg:leading-7 md:leading-7 leading-6">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi amet
            dolorem veniam autem accusantium sequi eveniet, necessitatibus ad
            nihil, maxime excepturi? Aliquid atque dignissimos cupiditate ullam
            quibusdam ex. Neque autem libero nulla distinctio perspiciatis ipsa,
            cupiditate porro quo temporibus doloremque?
          </p>
          <p className="fsize14 lg:leading-7 md:leading-7 leading-6 mt-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi amet
            dolorem veniam autem accusantium sequi eveniet, necessitatibus ad
            nihil, maxime excepturi? Aliquid atque dignissimos cupiditate ullam
            quibusdam ex. Neque autem libero nulla distinctio perspiciatis ipsa,
            cupiditate porro quo temporibus doloremque?
          </p>
        </div>
        <div className="mt-3">
          <p className="fsize12 font-semibold cust-textColor">
            Select Color*
          </p>
          <div className="flex gap-2 mt-1">
            {colors
              ? colors.map(col => {
                  return (
                    <p className="border-1px px-3 py-1 rounded-sm fsize14 cursor-pointer textColor-light">
                      {col.color}
                    </p>
                  );
                })
              : 'no-data'}
          </div>
        </div>
        <div className="mt-3">
          <p className="fsize12 font-semibold cust-textColor">
            Select Color*
          </p>
          <div className="flex gap-2 mt-1">
            {sizes
              ? sizes.map(siz => {
                  return (
                    <p className="border-1px px-5 py-1 rounded-sm fsize14 cursor-pointer textColor-light">
                      {siz.size}
                    </p>
                  );
                })
              : 'no-data'}
          </div>
          
        </div>
        <div className="flex items-center gap-3 mt-4 cust-textColor">
            <p className='fsize16 strikethrough'>12345</p>
            <p className='fsize18 font-medium'>12345</p>
            <p className='fsize14'>(10% off)</p>
            <p className='fsize12 font-medium'>inclusive of all taxes</p>
          </div>
        <div className="lg:mt-6 md:mt-6 mt-4">
        <Button py={4} fontWeight="500" className='fsize16 w-full theme-btn2'>Add to Bag</Button>
        <Button py={4} fontWeight="500" variant="outline" className='fsize16 w-full mt-2 theme-btn2'>Add to Wishlist</Button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails4;
