import React, { useEffect, useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Button, Card, Image, useColorMode } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { CopyIcon } from '@chakra-ui/icons';
import { handleScroll } from '../../components/fadeeffect/FadeEffect';

const ProductSection6 = () => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const category = [
    { name: 'category 1' },
    { name: 'category 2' },
    { name: 'category 3' },
    { name: 'category 4' },
  ];
    // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8 container mx-auto">
      <h2
        id=""
        className="font-semibold cust-textColor fsize34 md-fsize24 sm-fsize20 text-primary relative inline-block"
      >
        Lorem Ipsum
      </h2>
      <div className="lg:mt-8 md:mt-6 mt-6">
        {/* <HStack spacing={4}>
          {category.map(size => (
            <Tag size={size} variant="solid" colorScheme="teal" p="4px" >
              Teal
            </Tag>
            
          ))}
        </HStack> */}
        <div className="flex flex-wrap gap-2 mb-6">
          {category.map((cat, index) => (
            <p
              key={index}
              className=" py-2 px-4 theme-btn2 bg-primary text-white fsize12 cust-bgColor rounded-full"
            >
              {cat.name}
            </p>
          ))}
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
        >
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            className="mySwiper p-2"
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 2,
                spaceBetween: 12,
              },
              820: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <Card className="cursor-pointer theme-bg themeShadow">
                <div className="relative image-product overflow-hidden bradius-card-top">
                  <Image
                    className="img-h-220 w-full opct"
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <div className="absolute right-0 top-0 flex gap-2 p-2 bg-d9d9d9 rounded-bl-md">
                    <div className="">
                      <p className="fsize14 textColor-light">4.5/5</p>
                    </div>
                    <div>
                      <FeatherIcon
                        className="filled-blk "
                        icon="star"
                        size={18}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    colorMode === 'light'
                      ? 'p-4 bg-d9d9d9 theme-bg themeShadow bradius-card-bottom'
                      : 'p-4 theme-bg themeShadow bradius-card-bottom'
                  }
                >
                  <div className="flex items-center justify-between">
                    <p className="fsize14 textColor-light">Lorem Ipsum</p>
                    <div className="bg-fff flex items-center gap-2 px-3 py-1 rounded-full">
                      <p className="fsize10">fghfhsd</p>
                      <CopyIcon />
                    </div>
                  </div>
                  <div className="">
                    <p className="lg:w-2/3 mt-2 fsize18 sm-fsize16 font-semibold text-black cust-textColor lineClamp2">
                      Motion Graphics: Create a Nice Typography Animations
                    </p>
                  </div>
                  <div className="pt-2 flex items-center justify-between">
                    <p className="fsize14 font-medium text-black cust-textColor">
                      250 <span className="clr-b8b8b8">/ person</span>
                    </p>
                    <Button className="bg-primary theme-btn2 clr-fff cust-bgColor cust-textColor buttonAnimate bdr-2px lg:py-3 md:py-3 py-2">
                      Book Now
                    </Button>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className="cursor-pointer theme-bg themeShadow">
                <div className="relative image-product overflow-hidden bradius-card-top">
                  <Image
                    className="img-h-220 w-full opct"
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <div className="absolute right-0 top-0 flex gap-2 p-2 bg-d9d9d9 rounded-bl-md">
                    <div className="">
                      <p className="fsize14 textColor-light">4.5/5</p>
                    </div>
                    <div>
                      <FeatherIcon
                        className="filled-blk "
                        icon="star"
                        size={18}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    colorMode === 'light'
                      ? 'p-4 bg-d9d9d9 theme-bg themeShadow bradius-card-bottom'
                      : 'p-4 theme-bg themeShadow bradius-card-bottom'
                  }
                >
                  <div className="flex items-center justify-between">
                    <p className="fsize14 textColor-light">Lorem Ipsum</p>
                    <div className="bg-fff flex items-center gap-2 px-3 py-1 rounded-full">
                      <p className="fsize10">fghfhsd</p>
                      <CopyIcon />
                    </div>
                  </div>
                  <div className="">
                    <p className="lg:w-2/3 mt-2 fsize18 sm-fsize16 font-semibold text-black cust-textColor lineClamp2">
                      Motion Graphics: Create a Nice Typography Animations
                    </p>
                  </div>
                  <div className="pt-2 flex items-center justify-between">
                    <p className="fsize14 font-medium text-black cust-textColor">
                      250 <span className="clr-b8b8b8">/ person</span>
                    </p>
                    <Button className="bg-primary theme-btn2 clr-fff cust-bgColor cust-textColor buttonAnimate bdr-2px lg:py-3 md:py-3 py-2">
                      Book Now
                    </Button>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className="cursor-pointer theme-bg themeShadow">
                <div className="relative image-product overflow-hidden bradius-card-top">
                  <Image
                    className="img-h-220 w-full opct"
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <div className="absolute right-0 top-0 flex gap-2 p-2 bg-d9d9d9 rounded-bl-md">
                    <div className="">
                      <p className="fsize14 textColor-light">4.5/5</p>
                    </div>
                    <div>
                      <FeatherIcon
                        className="filled-blk "
                        icon="star"
                        size={18}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    colorMode === 'light'
                      ? 'p-4 bg-d9d9d9 theme-bg themeShadow bradius-card-bottom'
                      : 'p-4 theme-bg themeShadow bradius-card-bottom'
                  }
                >
                  <div className="flex items-center justify-between">
                    <p className="fsize14 textColor-light">Lorem Ipsum</p>
                    <div className="bg-fff flex items-center gap-2 px-3 py-1 rounded-full">
                      <p className="fsize10">fghfhsd</p>
                      <CopyIcon />
                    </div>
                  </div>
                  <div className="">
                    <p className="lg:w-2/3 mt-2 fsize18 sm-fsize16 font-semibold text-black cust-textColor lineClamp2">
                      Motion Graphics: Create a Nice Typography Animations
                    </p>
                  </div>
                  <div className="pt-2 flex items-center justify-between">
                    <p className="fsize14 font-medium text-black cust-textColor">
                      250 <span className="clr-b8b8b8">/ person</span>
                    </p>
                    <Button className="bg-primary theme-btn2 clr-fff cust-bgColor cust-textColor buttonAnimate bdr-2px lg:py-3 md:py-3 py-2">
                      Book Now
                    </Button>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className="cursor-pointer theme-bg themeShadow">
                <div className="relative image-product overflow-hidden bradius-card-top">
                  <Image
                    className="img-h-220 w-full opct"
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                  />
                  <div className="absolute right-0 top-0 flex gap-2 p-2 bg-d9d9d9 rounded-bl-md">
                    <div className="">
                      <p className="fsize14 textColor-light">4.5/5</p>
                    </div>
                    <div>
                      <FeatherIcon
                        className="filled-blk "
                        icon="star"
                        size={18}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    colorMode === 'light'
                      ? 'p-4 bg-d9d9d9 theme-bg themeShadow bradius-card-bottom'
                      : 'p-4 theme-bg themeShadow bradius-card-bottom'
                  }
                >
                  <div className="flex items-center justify-between">
                    <p className="fsize14 textColor-light">Lorem Ipsum</p>
                    <div className="bg-fff flex items-center gap-2 px-3 py-1 rounded-full">
                      <p className="fsize10">fghfhsd</p>
                      <CopyIcon />
                    </div>
                  </div>
                  <div className="">
                    <p className="lg:w-2/3 mt-2 fsize18 sm-fsize16 font-semibold text-black cust-textColor lineClamp2">
                      Motion Graphics: Create a Nice Typography Animations
                    </p>
                  </div>
                  <div className="pt-2 flex items-center justify-between">
                    <p className="fsize14 font-medium text-black cust-textColor">
                      250 <span className="clr-b8b8b8">/ person</span>
                    </p>
                    <Button className="bg-primary theme-btn2 clr-fff cust-bgColor cust-textColor buttonAnimate bdr-2px lg:py-3 md:py-3 py-2">
                      Book Now
                    </Button>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default ProductSection6;
