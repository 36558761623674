import React from 'react';
import FeatherIcon from 'feather-icons-react';
import {
  Accordion,
  AccordionItem,
  Box,
  Textarea,
  Input,
  AccordionButton,
  Button,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

const Form = () => {
  let [value, setValue] = React.useState('');

  let handleInputChange = e => {
    let inputValue = e.target.value;
    setValue(inputValue);
  };
  return (
    <div className="lg:py-16 md:py-10 py-10">
      <div className="container mx-auto lg:px-12 md:px-8 px-5">
        <div className="lg:flex md:flex block items-start w-full">
          <div className="lg:w-1/2 md:w-1/2 w-full lg:pr-10 md:pr-5">
            <p className="fsize14 text-secondary font-semibold">Connect us</p>
            <h2 className="fsize35 md-fsize21 sm-fsize22 font-semibold text-primary">
              Reach out to us
            </h2>
            <p className="fsize18 md-fsize13 sm-fsize14 mt-2 text-secondary font-medium">
              Feel free to connect with the support team incase you need any
              assistance with the platform.
            </p>
            <div className="mt-5 grid lg:grid-cols-2 grid-cols-1 gap-4">
              <div className="p-1 bg-primary rounded-full flex gap-3 items-center">
                <div className="rounded-full w-10 h-10 flex justify-center items-center bg-white">
                  <FeatherIcon
                    icon="phone"
                    size={19}
                    className="text-secondary"
                  />
                </div>
                <p className="fsize16 text-white">+91 1234567890</p>
              </div>
              <div className="p-1 bg-primary rounded-full flex gap-3 items-center">
                <div className="rounded-full w-10 h-10 flex justify-center items-center bg-white">
                  <FeatherIcon
                    icon="mail"
                    size={19}
                    className="text-secondary"
                  />
                </div>
                <p className="fsize16 text-white">abcd@gmail.com</p>
              </div>
            </div>
            <div className="mt-10">
              <p className="fsize14 sm-fsize13 text-secondary font-semibold">
                FAQ
              </p>
              <h2 className="fsize25 md-fsize21 sm-fsize22 font-semibold text-primary">
                Frequently Ask Question
              </h2>
              <div className="mt-6">
                <Accordion>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          Section 1 title
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <p className="md-fsize15 sm-fsize14">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          Section 2 title
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <p className="md-fsize15 sm-fsize14">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          Section 3
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <p className="md-fsize15 sm-fsize14">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          Section 4
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <p className="md-fsize15 sm-fsize14">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 w-full lg:pl-10 lg:pr-10 md:pl-5 md:pr-1 lg:mt-0 md:mt-0 mt-8">
            <div className="cust-shadow lg:px-6 md:px-4 px-4 lg:py-16 md:py-12 py-6 bg-white rounded-lg">
              <div>
                <label>Full Name</label>
                <Input placeholder="Basic usage" className="mt-2" />
              </div>
              <div className="lg:mt-8 md:mt-4 mt-3">
                <label>Employee ID</label>
                <Input placeholder="Basic usage" className="mt-2" />
              </div>
              <div className="lg:mt-8 md:mt-4 mt-3">
                <label>Message</label>
                <Textarea
                  value={value}
                  onChange={handleInputChange}
                  className="mt-2"
                  placeholder="Here is a sample placeholder"
                  size="sm"
                />
              </div>
              <div className="lg:mt-8 md:mt-4 mt-3">
                <p className="text-gray fsize15 font-medium">
                  If you want to partner with us email us at{' '}
                  <span>info@gmail.com</span>
                </p>
                <Button
                  colorScheme="primary"
                  className="w-full"
                  borderRadius={50}
                  mt={3}
                >
                  Send your response
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
