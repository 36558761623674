import React, { useEffect, useRef, useState } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const AddBanner5 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-5">
      <div className=" lg:block md:block hidden">
        <div className="grid lg:grid-cols-3 md:grid-cols-3 lg:gap-6 md:gap-4 gap-2">
          <div
            id="1842495680"
            className="dynamicStyle2 h-500px md-h-450 sm-h200px relative"
          >
            <div className=" gradients">
              <Image
                className="h-500px md-h-450 sm-h200px w-full gradients"
                src={data ? getImage(data['1842495680']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <p
              id="9565846593"
              className="dynamicStyle absolute bottom-10 z-50 left-6 text-white font-semibold fsize20"
            >
              {data ? data['9565846593'] : 'Women'}
            </p>
          </div>
          <div className="h-500px md-h-450 sm-h200px text-center p-4 flex items-center bg-c2bfbe cust-bgColor">
            <div>
              <h2
                id="3441028231"
                className="dynamicStyle fsize28 md-fsize24 sm-fsize20 font-semibold  cust-textColor"
              >
                {data ? data['3441028231'] : 'Shop Your Favourite Designers'}
              </h2>
              <p
                id="5770825582"
                dangerouslySetInnerHTML={{
                  __html: data ? data['5770825582'] : 'no-data',
                }}
                className="dynamicStyle pt-2 md-fsize15 sm-fsize14 textColor-light"
              >
                {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit
                consequuntur natus maiores */}
              </p>
              <Button
                px={8}
                py={4}
                id="0479491564"
                className={
                  colorMode === 'light'
                    ? 'theme-btn dynamicStyle bg-primary bdr-0px lg:mt-6 md:mt-6 mt-4 sm-fsize14 clr-fff'
                    : 'theme-btn dynamicStyle bg-darkprimary bdr-0px lg:mt-6 md:mt-6 mt-4 cust-textColor sm-fsize14 '
                }
              >
                <span onClick={() => handleClick('0479491564')}>
                  {data ? data['0479491564'] : 'Discover More'}
                </span>
              </Button>
            </div>
          </div>
          <div
            id="5694563640"
            className="dynamicStyle2 h-500px md-h-450 sm-h200px relative"
          >
            <div className=" gradients">
              <Image
                className="h-500px md-h-450 sm-h200px w-full gradients"
                src={data ? getImage(data['5694563640']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <p
              id="1930724839"
              className="dynamicStyle absolute bottom-10 z-50 left-6 text-white font-semibold fsize20"
            >
              {data ? data['1930724839'] : 'Men'}
            </p>
          </div>
        </div>
      </div>
      <div className="lg:hidden md:hidden block">
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div id="1842495680" className="dynamicStyle2 sm-h250px relative">
            <div className=" gradients">
              <Image
                className="sm-h250px w-full"
                src={data ? getImage(data['1842495680']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <p
              id="9565846593"
              className="dynamicStyle absolute bottom-8 z-50 left-4 text-white font-semibold sm-fsize16"
            >
              {data ? data['9565846593'] : 'Women'}
            </p>
          </div>
          <div
            id="5694563640"
            className="dynamicStyle2 h-500px md-h-450 sm-h250px relative"
          >
            <div className=" gradients">
              <Image
                className="sm-h250px w-full"
                src={data ? getImage(data['5694563640']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>

            <p
              id="1930724839"
              className="dynamicStyle absolute bottom-8 z-50 left-4 text-white font-semibold sm-fsize16"
            >
              {data ? data['1930724839'] : 'Men'}
            </p>
          </div>
        </div>
        <div className="sm-h200px text-center p-4 flex items-center bg-c2bfbe cust-bgColor">
          <div>
            <h2
              id="3441028231"
              className="dynamicStyle fsize28 md-fsize24 sm-fsize20 font-semibold  cust-textColor"
            >
              {data ? data['3441028231'] : 'Shop Your Favourite Designers'}
            </h2>
            <p
              id="5770825582"
              dangerouslySetInnerHTML={{
                __html: data ? data['5770825582'] : 'no-data',
              }}
              className="dynamicStyle pt-2 md-fsize15 sm-fsize14 textColor-light"
            >
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit
              consequuntur natus maiores */}
            </p>
            <Button
              px={8}
              py={4}
              id="0479491564"
              className={
                colorMode === 'light'
                  ? 'theme-btn dynamicStyle bg-primary bdr-0px lg:mt-6 md:mt-6 mt-4 sm-fsize14 clr-fff'
                  : 'theme-btn dynamicStyle bg-darkprimary bdr-0px lg:mt-6 md:mt-6 mt-4 cust-textColor sm-fsize14 '
              }
            >
              <span onClick={() => handleClick('0479491564')}>
                {data ? data['0479491564'] : 'Discover More'}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner5;
