import React, { useEffect, useRef, useState } from 'react';
import { handleScroll } from './fadeeffect/FadeEffect';
import { useSelector } from 'react-redux/es/exports';
import { Image, useColorMode } from '@chakra-ui/react';

const GallerySection1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:py-8 md:py-8 py-6">
      <div className="text-center lg:w-3/5 md:w-3/5 mx-auto flex flex-col justify-center lg:mb-12 md:mb-12 mb-6 lg:px-0 md:px-0 px-5">
        <h3
          id="3971382466"
          className="dynamicStyle cust-textColor text-primary letter-spacing4px fsize16 md-fsize16 sm-fsize14 font-medium"
        >
          {data ? data['3971382466'] : 'Lorem Ipsum'}
        </h3>
        <h1
          id="9869730702"
          className="dynamicStyle cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold mb-2"
        >
          {data ? data['9869730702'] : 'Lorem Ipsum'}
        </h1>
        <div className="">
          <p
            id="2639870322"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['2639870322']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14 text-center"
          >
            {/* In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content. */}
          </p>
        </div>
      </div>

      <div
        ref={elementRef}
        className={`fade-bottom grid lg:grid-cols-2 md:grid-cols-2 gap-8 w-full lg:px-20 md:px-8 px-5 ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <div id="1942932864" className="w-full dynamicStyle2">
          <Image
            src={data ? getImage(data['1942932864']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="imagee"
            className={
              colorMode === 'light'
                ? 'w-full object-cover shadow-ffffff br-16px h-280px '
                : 'w-full object-cover shadow-ffffff br-16px h-280px '
            }
            
          />
        </div>
        <div id="2369443622" className="w-full dynamicStyle2">
          <Image
            src={data ? getImage(data['2369443622']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="imagee"
            className="w-full object-cover shadow-ffffff br-16px h-280px"
          />
        </div>
        <div
          id="4525831073"
          className="lg:col-span-2 md:col-span-2 w-full dynamicStyle2"
        >
          <Image
            src={data ? getImage(data['4525831073']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="imagee"
            className="w-full object-cover shadow-ffffff br-16px h-280px"
          />
        </div>
      </div>
    </section>
  );
};

export default GallerySection1;
