import apis from '../../apis/client/Forms/ecom_ sub_Category';

export const getAllSubCategorys = payload => {
  return async dispatch => {
    try {
      let payload = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let { data } = await apis.getAllSubCategorys(payload);
      console.log(data,'reduxdata');
      if (data) dispatch(setAllSubCategory(data.data));

    } catch (error) {}
  };
};

export const setAllSubCategory = data => {
  return {
    type: 'SET_ALL_SUB_CATEGORY',
    payload: data,
  };
};
