import API from "./repositoryFactory";

//message
const getMessage = "getMessage";
const sendMessage = "sendMessage";

const upload = "upload";
const messages = "get";

//channel
const createChannel = "createChannel";
const deleteChannel = "deleteChannel";
const getChannels = "getChannels";
const addToGroupChannel = "addToGroupChannel";
const createGroupChannel = "createGroupChannel";
const removeFromGroupChannel = "removeFromGroupChannel";
const renameGroupChannel = "renameGroupChannel";

const people = "get";

const chatRouteMessage = (route) => {
  return `chat/message/${route}`;
};

const chatRouteChannel = (route) => {
  return `chat/channel/${route}`;
};

export default {
  //message
  getMessage(payload) {
    return API.post(chatRouteMessage(getMessage), payload);
  },
  sendMessage(payload) {
    return API.post(chatRouteMessage(sendMessage), payload);
  },
  // getMessage(payload) {
  //   return API.post(chatRouteMessage(messages),payload);
  // },

  upload(payload) {
    return API.post(chatRouteMessage(upload), payload);
  },

  //channel
  createChannel(payload) {
    return API.post(chatRouteChannel(createChannel), payload);
  },
  deleteChannel(payload) {
    return API.post(chatRouteChannel(deleteChannel), payload);
  },
  getChannels(payload) {
    return API.post(chatRouteChannel(getChannels), payload);
  },
  addToGroupChannel(payload) {
    return API.post(chatRouteChannel(addToGroupChannel), payload);
  },
  createGroupChannel(payload) {
    return API.post(chatRouteChannel(createGroupChannel), payload);
  },
  removeFromGroupChannel(payload) {
    return API.post(chatRouteChannel(removeFromGroupChannel), payload);
  },
  renameGroupChannel(payload) {
    return API.post(chatRouteChannel(renameGroupChannel), payload);
  },

  getPeople(payload) {
    return API.post(chatRouteChannel(people), payload);
  },
};
