import { HamburgerIcon } from '@chakra-ui/icons';
import React from 'react';
import { NavLink } from 'react-router-dom';

const Header5 = () => {
  return (
    <nav>
      <div class="wrapper">
        <div class="logo">
          <NavLink to="">Logo</NavLink>
        </div>
        <input type="radio" name="slider" id="menu-btn" />
        <input type="radio" name="slider" id="close-btn" />
        <ul class="nav-links">
          <label for="close-btn" class="btn close-btn">
            <HamburgerIcon />
          </label>
          <li>
            <NavLink to="">Home</NavLink>
          </li>
          <li>
            <NavLink to="">About</NavLink>
          </li>
          <li>
            <NavLink to="" class="desktop-item">
              Dropdown Menu
            </NavLink>
            <input type="checkbox" id="showDrop" />
            <label for="showDrop" class="mobile-item">
              Dropdown Menu
            </label>
            <ul class="drop-menu">
              <li>
                <NavLink to="">Drop menu 1</NavLink>
              </li>
              <li>
                <NavLink to="">Drop menu 2</NavLink>
              </li>
              <li>
                <NavLink to="">Drop menu 3</NavLink>
              </li>
              <li>
                <NavLink to="">Drop menu 4</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="" class="desktop-item">
              Mega Menu
            </NavLink>
            <input type="checkbox" id="showMega" />
            <label for="showMega" class="mobile-item">
              Mega Menu
            </label>
            <div class="mega-box">
              <div class="content">
                
                <div class="row">
                  <header>Design Services</header>
                  <ul class="mega-links">
                    <li>
                      <NavLink to="">Graphics</NavLink>
                    </li>
                    <li>
                      <NavLink to="">Vectors</NavLink>
                    </li>
                    <li>
                      <NavLink to="">Business cards</NavLink>
                    </li>
                    <li>
                      <NavLink to="">Custom logo</NavLink>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <header>Email Services</header>
                  <ul class="mega-links">
                    <li>
                      <NavLink to="">Personal Email</NavLink>
                    </li>
                    <li>
                      <NavLink to="">Business Email</NavLink>
                    </li>
                    <li>
                      <NavLink to="">Mobile Email</NavLink>
                    </li>
                    <li>
                      <NavLink to="">Web Marketing</NavLink>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <header>Security services</header>
                  <ul class="mega-links">
                    <li>
                      <NavLink to="">Site Seal</NavLink>
                    </li>
                    <li>
                      <NavLink to="">VPS Hosting</NavLink>
                    </li>
                    <li>
                      <NavLink to="">Privacy Seal</NavLink>
                    </li>
                    <li>
                      <NavLink to="">Website design</NavLink>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <header>Security services</header>
                  <ul class="mega-links">
                    <li>
                      <NavLink to="">Site Seal</NavLink>
                    </li>
                    <li>
                      <NavLink to="">VPS Hosting</NavLink>
                    </li>
                    <li>
                      <NavLink to="">Privacy Seal</NavLink>
                    </li>
                    <li>
                      <NavLink to="">Website design</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li>
            <NavLink to="">Feedback</NavLink>
          </li>
        </ul>

        <label for="menu-btn" class="btn menu-btn">
          <i class="fas fa-bars"></i>
        </label>
      </div>
    </nav>
  );
};

export default Header5;
