import React from 'react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const GallerySection8 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="container mx-auto lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5">
      <p
        id="7799104687"
        className="dynamicStyle fsize40 md-fsize32 sm-fsize26 font-semibold text-center cust-textColor lg:pb-6 md:pb-3 pb-1"
      >
        {data ? data['7799104687'] : 'Collections'}
      </p>
      <div className="">
        <Swiper
          spaceBetween={0}
          effect={'coverflow'}
          modules={[EffectCoverflow, Navigation]}
          autoplay={{
            delay: '3000',
          }}
          loop={true}
          navigation={{
            nextEl: '.image-swiper-button-nexts',
            prevEl: '.image-swiper-button-prevs',
          }}
          className="mySwiper gallery8 lg:mt-5 md:mt-5 mt-2"
          coverflowEffect={{
            rotate: -15,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          disableOnInteraction={true}
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            820: {
              slidesPerView: 2.9,
              spaceBetween: 40,
            },
            768: {
              slidesPerView: 2.9,
              spaceBetween: 40,
            },
            640: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            300: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prevs cursor-pointer w-8 h-8 flex items-center justify-center bg-white rounded-full">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-nexts cursor-pointer w-8 h-8 flex items-center justify-center bg-white rounded-full">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              className="gallery-8-img"
              alt="Image"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              className="gallery-8-img"
              alt="Image"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              className="gallery-8-img"
              alt="Image"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              className="gallery-8-img"
              alt="Image"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              className="gallery-8-img"
              alt="Image"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default GallerySection8;
