import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Button } from '@chakra-ui/react';

const FeedSection = () => {
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <div className="lg:flex md:flex w-full borderall rounded-lg p-4 mb-6">
        <div className="w-30 sm-w-100  mr-8">
          <img
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt=""
            className="w-full rounded-lg"
          />
        </div>
        <div className="w-70 ">
          <div className="flex items-center justify-between">
            <div className="">
              <h2 className="fsize22 md-fsize18 sm-fsize16 font-semibold mt-2">
                Turf Name
              </h2>
              <div className="flex items-center">
                <FeatherIcon
                  className="text-primary mr-1"
                  size={12}
                  icon="map-pin"
                />
                <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
              </div>
            </div>

            <p className="text-primary bg-secondary fsize14 sm-fsize12 px-4 py-1 rounded-full">
              tournament
            </p>
          </div>
          <p className="fsize14 text-gray lineClamp3 mt-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum vitae
            voluptatum architecto quaerat tempora, quam porro enim asperiores
            labore facere. Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Illum vitae voluptatum architecto quaerat tempora, quam porro
            enim asperiores labore facere.
          </p>
          <div className="lg:flex md:flex items-center justify-between lg:mt-6 md:mt-6 mt-3">
            <p className="text-primary fsize14">
              last date of register: 22 August, 2024
            </p>
            <p className="fsize12 font-medium">
              schedule: <span className="fsize12 clr-7c7c7c">Aug 2nd 22</span>
            </p>
          </div>
          <div className="mt-4">
            <Button colorScheme="primary" className="sm-w-100">
              Participate
            </Button>
          </div>
        </div>
      </div>
      <div className="lg:flex md:flex w-full borderall rounded-lg p-4 mb-6">
        <div className="w-30 sm-w-100 mr-8">
          <img
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt=""
            className="w-full rounded-lg"
          />
        </div>
        <div className="w-70 ">
          <div className="flex items-center justify-between">
            <div className="">
              <h2 className="fsize22 md-fsize18 sm-fsize16 font-semibold mt-2">
                Turf Name
              </h2>
              <div className="flex items-center">
                <FeatherIcon
                  className="text-primary mr-1"
                  size={12}
                  icon="map-pin"
                />
                <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
              </div>
            </div>

            <p className="text-primary bg-secondary fsize14 sm-fsize12 px-4 py-1 rounded-full">
              tournament
            </p>
          </div>
          <p className="fsize14 text-gray lineClamp3 mt-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum vitae
            voluptatum architecto quaerat tempora, quam porro enim asperiores
            labore facere. Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Illum vitae voluptatum architecto quaerat tempora, quam porro
            enim asperiores labore facere.
          </p>
          <div className="lg:flex md:flex items-center justify-between lg:mt-6 md:mt-6 mt-3">
            <p className="text-primary fsize14">
              last date of register: 22 August, 2024
            </p>
            <p className="fsize12 font-medium">
              schedule: <span className="fsize12 clr-7c7c7c">Aug 2nd 22</span>
            </p>
          </div>
          <div className="mt-4">
            <Button colorScheme="primary" className="sm-w-100">
              Participate
            </Button>
          </div>
        </div>
      </div>
      <div className="lg:flex md:flex w-full borderall rounded-lg p-4 mb-6">
        <div className="w-30 sm-w-100 mr-8">
          <img
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt=""
            className="w-full rounded-lg"
          />
        </div>
        <div className="w-70 ">
          <div className="flex items-center justify-between">
            <div className="">
              <h2 className="fsize22 md-fsize18 sm-fsize16 font-semibold mt-2">
                Turf Name
              </h2>
              <div className="flex items-center">
                <FeatherIcon
                  className="text-primary mr-1"
                  size={12}
                  icon="map-pin"
                />
                <p className="fsize12 clr-7c7c7c ">Thane, Mumbai</p>
              </div>
            </div>

            <p className="text-primary bg-secondary fsize14 sm-fsize12 px-4 py-1 rounded-full">
              tournament
            </p>
          </div>
          <p className="fsize14 text-gray lineClamp3 mt-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum vitae
            voluptatum architecto quaerat tempora, quam porro enim asperiores
            labore facere. Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Illum vitae voluptatum architecto quaerat tempora, quam porro
            enim asperiores labore facere.
          </p>
          <div className="lg:flex md:flex items-center justify-between lg:mt-6 md:mt-6 mt-3">
            <p className="text-primary fsize14">
              last date of register: 22 August, 2024
            </p>
            <p className="fsize12 font-medium">
              schedule: <span className="fsize12 clr-7c7c7c">Aug 2nd 22</span>
            </p>
          </div>
          <div className="mt-4">
            <Button colorScheme="primary" className="sm-w-100">
              Participate
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedSection;
