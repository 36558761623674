import React from 'react';
import { Image } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { Autoplay, Navigation } from 'swiper/modules';

const Testimonial12 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  let getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  return (
    <>
      {getAllTestimonial ? (
    <section className="container mx-auto lg:py-16 md:py-12 lg:px-12 md:px-8 py-8 px-5">
      <div className="lg:w-2/3 md:w-2/3 w-full mx-auto text-center lg:pb-12 md:pb-12 pb-4">
        <p
          id="0982090727"
          className="dynamicStyle text-primary fsize34 md-fsize24 sm-fsize20 cust-textColor lg:pb-2 md:pb-2 pb-1 font-semibold"
        >
          {data ? data['0982090727'] : 'Testimonial'}
        </p>
        <p
          id="7076631542"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['7076631542']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle fsize16 md-fsize16 sm-fsize14 textColor-light"
        ></p>
      </div>
      <div className="">
        {/* <div className="swiper-button custswiper image-swiper-button-next">
          <IoIosArrowForward />
        </div>
        <div className="swiper-button custswiper image-swiper-button-prev">
          <IoIosArrowBack />
        </div> */}
        <Swiper
          spaceBetween={5}
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            1536: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            1280: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            991: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            820: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
          }}
        >
          {getAllTestimonial &&
          getAllTestimonial.data &&
          getAllTestimonial.data.result &&
          getAllTestimonial.data.result.length > 0
            ? getAllTestimonial.data.result.map((testimonial, index) => {
                return (
                  <SwiperSlide className="p-2 lg:pl-20 md:pl-10 relative">
                    <div className="relative">
                      <div className="h-400px w-350px sm-wfull sm-h-250px">
                        <Image
                          src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="img"
                          className="h-400px w-350px sm-wfull sm-h-250px w-full"
                        />
                      </div>
                      <div className="absl-test12 bg-29166f lg:w-2/3 md:w-2/3 mar-test12 lg:py-10 md:py-6 py-4 px-4 cust-bgColor theme-bg themeShadow">
                        <p className="clr-fff md-fsize14 lg:leading-7 md:leading-7 leading-6 px-4 bdr-test12 textColor-light sm-fsize14">
                          {testimonial.testimonial}
                        </p>

                        <div className="flex items-center lg:gap-4 md:gap-4 gap-2 lg:pt-8 md:pt-8 pt-6">
                          <div className="lg:h-16 lg:w-16 md:h-16 md:w-16 h-14 w-14">
                            <Image
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                              alt="img"
                              className="lg:h-16 lg:w-16 md:h-16 md:w-16 h-14 w-14 rounded-full object-cover"
                            />
                          </div>
                          <div className="">
                            <p className="font-semibold clr-fff cust-textColor sm-fsize14">
                              {testimonial.name}
                            </p>
                            {/* <p className='clr-fff fsize14 sm-fsize12 textColor-light'>Event Planner</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            : 'No Data'}
          {/* <SwiperSlide className="p-2 lg:pl-20 md:pl-10 relative">
            <div className="relative">
              <div className="h-400px w-350px sm-wfull sm-h-250px">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="h-400px w-350px sm-wfull sm-h-250px w-full"
                />
              </div>
              <div className="absl-test12 bg-29166f lg:w-2/3 md:w-2/3 mar-test12 lg:py-10 md:py-6 py-4 px-4 cust-bgColor theme-bg themeShadow">
                <p className="clr-fff md-fsize14 lg:leading-7 md:leading-7 leading-6 px-4 bdr-test12 textColor-light sm-fsize14">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Aliquid eligendi accusantium quasi, earum accusamus corporis
                  in sed unde! Cumque, vitae! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Quam, tempora. Cumque, vitae! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Quam, tempora.
                </p>

                <div className='flex items-center lg:gap-4 md:gap-4 gap-2 lg:pt-8 md:pt-8 pt-6'>
                  <div className='lg:h-16 lg:w-16 md:h-16 md:w-16 h-14 w-14'>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className='lg:h-16 lg:w-16 md:h-16 md:w-16 h-14 w-14 rounded-full object-cover'
                  />
                  </div>
                  <div className=''>
                    <p className='font-semibold clr-fff cust-textColor sm-fsize14'>Maria Davidson</p>
                    <p className='clr-fff fsize14 sm-fsize12 textColor-light'>Event Planner</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="p-2 lg:pl-20 md:pl-10 relative">
            <div className="relative">
              <div className="h-400px w-350px sm-wfull sm-h-250px">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="h-400px w-350px sm-wfull sm-h-250px w-full"
                />
              </div>
              <div className="absl-test12 bg-29166f lg:w-2/3 md:w-2/3 mar-test12 lg:py-10 md:py-6 py-4 px-4 cust-bgColor theme-bg themeShadow">
                <p className="clr-fff md-fsize14 lg:leading-7 md:leading-7 leading-6 px-4 bdr-test12 textColor-light sm-fsize14">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Aliquid eligendi accusantium quasi, earum accusamus corporis
                  in sed unde! Cumque, vitae! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Quam, tempora. Cumque, vitae! Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Quam, tempora.
                </p>

                <div className='flex items-center lg:gap-4 md:gap-4 gap-2 lg:pt-8 md:pt-8 pt-6'>
                  <div className='lg:h-16 lg:w-16 md:h-16 md:w-16 h-14 w-14'>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="img"
                    className='lg:h-16 lg:w-16 md:h-16 md:w-16 h-14 w-14 rounded-full object-cover'
                  />
                  </div>
                  <div className=''>
                    <p className='font-semibold clr-fff cust-textColor sm-fsize14'>Maria Davidson</p>
                    <p className='clr-fff fsize14 sm-fsize12 textColor-light'>Event Planner</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </section>
    ) : (
      ''
    )}
  </>
  );
};

export default Testimonial12;
