import API from './repositoryFactory';
const get = 'getCombinedMenu';
const clientMenu = 'clientMenu';

const apiBase = route => {
  return `menu/twoHierarchyMenu/${route}`;
};
const apiBaseClientMenu = route => {
  return `menu/${route}`;
};

export default {
  // getAllMenu() {
  //   return API.post(apiBase(get));
  // },
  getAllMenu() {
    return API.get(apiBaseClientMenu(clientMenu));
  },
};
