import { Button, Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux/es/exports';

import { useNavigate } from 'react-router-dom';

const AboutSection27 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="container mx-auto lg:px-12 md:px-8 lg:py-28 md:py-20 px-5 py-12">
      <div className="lg:flex md:flex  items-center sm-custdirection">
        <div className="lg:w-1/2 md:w-1/2 w-full">
          <h2
            id="8464769407"
            className="dynamicStyle fsize34 sm-fsize20 font-semibold text-primary cust-textColor"
          >
            {data ? data['8464769407'] : 'Tips in travelling in Winter'}
          </h2>
          <p
            id="4015144493"
            className="dynamicStyle textColor-light fsize16 sm-fsize14 mt-2 lg:mr-12"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['4015144493']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
          ></p>

          <Button
        
            className={
              colorMode === 'light'
                ? 'dynamicStyle bg-primary lg:mt-8 md:mt-8 mt-6 text-primary theme-btn clr-fff fsize16 cust-textColor sm-fsize14 buttonAnimate bdr-2px'
                : 'dynamicStyle mt-6 theme-btn clr-fff fsize16 cust-textColor sm-fsize14'
            }
            id="0149062478"
          >
            <span onClick={() => handleClick('0149062478')}>
              {data ? data['0149062478'] : 'Know More'}
            </span>
          </Button>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full flex lg:gap-6 md:gap-5 gap-3 items-center lg:pb-0 md:pb-0 pb-6">
          <div id="3746464229" className="w-1/2 dynamicStyle2">
            <Image
              src={data ? getImage(data['3746464229']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md h-450px md-h-350px sm-h250px object-cover"
            />
          </div>
          <div className="w-1/2">
            <div id="3805078797" className="w-full dynamicStyle2">
              <Image
                src={data ? getImage(data['3805078797']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="rounded-md h-350px md-h-250px sm-h-200px object-cover"
              />
            </div>
            <div id="7025679861" className=" w-full dynamicStyle2">
              <Image
                src={data ? getImage(data['7025679861']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
                className="rounded-md h-350px md-h-250px sm-h-200px mt-4 object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection27;
