import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { Button, Image } from '@chakra-ui/react';
import {
  pixabaySearch,
  getmasterSections,
  addSection,
  mediaUpload,
  mediaFetch,
  getSections,
} from '../redux/cms/cms-actions';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import axios from 'axios';
const Dialog = props => {
  const [check, SetCheck] = useState('03');
  const [search, setSearch] = useState('');
  const [imageData, setImageData] = useState([]);
  const [masterSections, setMasterSections] = useState([]);
  const [filteredMasterSection, setFilteredMasterSection] = useState([]);
  const [selectedMasterSection, setSelectedMasterSection] = useState({});
  const [unusedMasterSection, setUnusedMasterSection] = useState([]);
  const [renderMasterSection, setRenderMasterSection] = useState([]);
  const [myMedia, setMyMedia] = useState([]);
  const [mySection, setMySection] = useState([]);
  const [searchSection, setSearchSection] = useState('');
  const dispatch = useDispatch();
  const menuOption = [
    // {
    //   id: '01',
    //   name: 'Admin Images',
    //   icons: 'shopping-cart',
    // },
    // {
    //   id: '02',
    //   name: 'AI Recommadation',
    //   icons: 'user',
    // },

    {
      id: '03',
      name: 'Section Library',
      icons: 'credit-card',
    },
    {
      id: '04',
      name: 'My Section',
      icons: 'credit-card',
    },
    // {
    //   id: '05',
    //   name: 'Extensions',
    //   icons: 'credit-card',
    // },
  ];
  console.log(renderMasterSection, 'addmaster');
  let selectedSectionState = useSelector(state =>
    state.cms && state.cms.sectionNames
      ? state.cms.sectionNames[`${props.selectedRoute}`]
      : []
  );

  async function addSectionToPage() {
    try {
      let payload = {
        index: props.selectedSection.index + 2,
        section: selectedMasterSection,
        pageId: props.selectedSection.pageId,
        pageName: props.selectedSection.pageName,
      };
      let data = await dispatch(addSection(payload));
      props.closePopup();

      if (data && data.code === 200) {
        Swal.fire({
          title: 'Section!',
          text: `${
            selectedMasterSection.sectionName.toLowerCase().includes('section')
              ? `${selectedMasterSection.sectionName} ${selectedMasterSection.variations}`
              : `${selectedMasterSection.sectionName} ${selectedMasterSection.variations} section`
          } added`,
          icon: 'success',
          confirmButtonColor: '#2bc370',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Section!',
        text: `Failed to add section`,
        icon: 'error',
        confirmButtonColor: '#2bc370',
      });
    }
  }

  useEffect(() => {
    const getMasterSections = async () => {
      try {
        let masterSectionFilterData;
        let data = await dispatch(getmasterSections());
        await dispatch(getSections());
        setMasterSections(data);
        masterSectionFilterData = data.filter(
          masterSec =>
            !selectedSectionState.some(
              selectedSection =>
                selectedSection['sectionId'] === masterSec['sectionId']
            )
        );
        setUnusedMasterSection(masterSectionFilterData);
        return data;
      } catch (error) {}
    };
    getMasterSections();
  }, [check]);

  useEffect(() => {
    const debounceTimeout = setTimeout(async () => {
      let filteredMasterSectionData = unusedMasterSection;
      if (searchSection) {
        filteredMasterSectionData = filteredMasterSectionData.filter(section =>
          section.sectionName
            .toLowerCase()
            .trim()
            .includes(searchSection.toLowerCase().trim())
        );
      }
      setRenderMasterSection(filteredMasterSectionData);
    }, 500);

    const getMySection = async () => {
      let mySectionFilter = masterSections
        .filter(masterSection =>
          selectedSectionState.some(
            selectedSection =>
              selectedSection['sectionId'] === masterSection['sectionId']
          )
        )
        .sort((a, b) => {
          const sortOrderA = selectedSectionState.find(item => {
            // console.log(item, a);
            return item.sectionId === a.sectionId;
          }).index;
          const sortOrderB = selectedSectionState.find(
            item => item.sectionId === b.sectionId
          ).index;
          // console.log(sortOrderA, sortOrderB);
          return sortOrderA - sortOrderB;
        });
      setMySection(mySectionFilter);
    };

    if (check === '04') {
      getMySection();
    }
    return () => clearTimeout(debounceTimeout);
  }, [searchSection, check, masterSections, unusedMasterSection]);

  const searchMasterSections = e => {
    setSearchSection(e.target.value);
  };

  function getInput(e) {
    setSearch(e.target.value);
  }

  async function searchQuery() {
    let payload = {
      search: search,
      type: 'photo',
    };
    let data = await dispatch(pixabaySearch(payload));

    setImageData(data.hits);
  }

  function setDefaultImage(e) {
    e.target.src = `${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`;
  }

  async function SaveUsingRemote(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    const filename = url.substring(url.lastIndexOf('/') + 1);
    const file = new File([blob], filename, { type: blob.type });
    await beforeUpload(file);
  }

  async function beforeUpload(file) {
    let formData = new FormData();
    const fileType = file.type;
    formData.append('file', file);
    if (
      fileType.startsWith('image/jpeg') ||
      fileType.startsWith('image/png') ||
      fileType.startsWith('image/gif')
    ) {
      formData.append('type', 'image');
    } else if (
      fileType.startsWith('video/mp4') ||
      fileType.startsWith('video/webm') ||
      fileType.startsWith('video/ogg')
    ) {
      formData.append('type', 'video');
    } else if (fileType.startsWith('image/svg+xml')) {
      formData.append('type', 'svg');
    } else {
      this.isShowDetailsAddons = false;

      return;
    }
    let URL = process.env.REACT_APP_BASE_URL.split('/api/')[0];

    formData.append('Storeendpoint', URL);
    let data = dispatch(mediaUpload(formData));

    return false;
  }

  async function fetchMedia() {
    let { data } = await dispatch(mediaFetch());
    // console.log(data);
    setMyMedia(data);
  }

  // getMasterSections();
  let getAllforms = useSelector(state => state.Forms.Forms);
  console.log(getAllforms, 'dataform');
  return (
    <div>
      <h5 className="fsize24 font-semibold text-lighgray">My Media</h5>
      <div className="resp-height pr-2">
        <div className="flex gap-2 lg:px-5 items-center ">
          {menuOption.map((e, index) => {
            return (
              <div
                key={index}
                className={
                  check === e.id
                    ? 'bg-brained tabs cursor-pointer'
                    : 'bg-grays tabs cursor-pointer'
                }
                onClick={() => {
                  SetCheck(e.id);
                  if (e.id === '02') fetchMedia();
                }}
              >
                <p
                  className={
                    check === e.id
                      ? 'text-white fsize14 sm-fsize11'
                      : 'text-lighgray font-medium fsize14 sm-fsize11'
                  }
                >
                  {e.name}
                </p>
              </div>
            );
          })}
        </div>

        {check === '01' ? (
          <div className="bg-f4 rounded-lg p-4  ">
            <div className="flex justify-between">
              <div className="relative w-1/2">
                <input
                  placeholder="Admin Images..."
                  className="w-full p-3 border rounded-lg remove-outline fsize14"
                  value={search}
                  onChange={getInput}
                />
                <div className="absolute top-0 right-0 m-3">
                  <FeatherIcon
                    icon="search"
                    className="svgicon-gray cursor-pointer"
                    onClick={() => {
                      searchQuery();
                    }}
                  />
                </div>
              </div>

              <button className="cms-mediabtn relative">
                <FeatherIcon
                  size={24}
                  icon="upload-cloud"
                  className="text-gray cursor-pointer text-white mx-auto"
                />{' '}
                <p>Select from media</p>
                <input
                  type="file"
                  className="absolute top-0 left-0 w-full h-full opacity-0 "
                />
              </button>
            </div>
            <div className="hei-define lg:mt-8 mt-4">
              <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
                {imageData.map((e, index) => {
                  return (
                    <div className="border" key={index}>
                      <Image
                        alt="new"
                        src={e.largeImageURL}
                        className="w-full h-img1"
                        onClick={() => {
                          SaveUsingRemote(e.largeImageURL);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {check === '02' ? (
          <div className="bg-f4 rounded-lg p-4 ">
            <div className="lg:w-8/12">
              {/* <div className="relative">
              <input
                value={search}
                placeholder="AI Images..."
                className="w-full p-3 border rounded-lg fsize14"
                onChange={getInput}
              />
              <div className="absolute top-0 right-0 m-3">
                <FeatherIcon
                  icon="search"
                  className="svgicon-gray cursor-pointer"
                />
              </div>
            </div> */}
            </div>
            <div className="hei-define lg:mt-8 mt-4">
              <section id="photos">
                {myMedia.map((e, index) => {
                  return (
                    <Image
                      key={index}
                      alt="new"
                      src={process.env.REACT_APP_STORAGE_URL + e.link}
                      className=" "
                    />
                  );
                })}
              </section>
            </div>
          </div>
        ) : (
          ''
        )}
        {check === '03' ? (
          <div className="bg-f4 rounded-lg p-4  ">
            <div className="lg:w-8/12">
              <div className="relative">
                <input
                  value={searchSection}
                  onChange={searchMasterSections}
                  placeholder="Please input"
                  className="w-full p-3 border bg-white rounded-lg remove-outline fsize14"
                />
                <div className="absolute top-0 right-0 m-3">
                  <FeatherIcon icon="search" className="svgicon-gray " />
                </div>
              </div>
            </div>
            <div className="hei-define lg:mt-8 mt-4">
              {/* {renderMasterSection &&
                renderMasterSection.length > 0 &&
                renderMasterSection.map((e, index) => {
                  return <h2>{e.sectionName}</h2>;
                })} */}

              <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
                {renderMasterSection &&
                  renderMasterSection.length > 0 &&
                  renderMasterSection.map((e, index) => {
                    return (
                      <div key={index} className="addcms_card">
                        <div className="relative h-img1">
                          <Image
                            alt="new"
                            src={
                              e.wireframes && e.wireframes.length
                                ? `${process.env.REACT_APP_STORAGE_URL_IND}${e.wireframes}`
                                : ''
                            }
                            onError={event => setDefaultImage(event)}
                            onClick={() => {
                              setSelectedMasterSection(e);
                            }}
                            className={
                              selectedMasterSection._id === e._id
                                ? 'w-full h-img1 green-overlay'
                                : 'w-full h-img1'
                            }
                          />
                          {selectedMasterSection._id === e._id ? (
                            <div className="absolute w-full h-img1 flex justify-center items-center top-0 left-0">
                              <FeatherIcon
                                icon="check"
                                className="text-success"
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="text-center mt-2">
                          <p className="text-lighgray">{`${e.sectionName} ${e.variations}`}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {renderMasterSection && renderMasterSection.length <= 0 && (
                <div className=" text-center">No Sections</div>
              )}
            </div>
          </div>
        ) : (
          ''
        )}
        {check === '04' ? (
          <div className="bg-f4 rounded-lg p-4  ">
            {/* <div className="lg:w-8/12">
              <div className="relative">
                <input
                  value={searchSection}
                  onChange={searchMasterSections}
                  placeholder="Please input"
                  className="w-full p-3 border rounded-lg remove-outline fsize14"
                />
                <div className="absolute top-0 right-0 m-3">
                  <FeatherIcon icon="search" className="svgicon-gray" />
                </div>
              </div>
            </div> */}
            <div className="hei-define lg:mt-8 mt-4">
              <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
                {mySection &&
                  mySection.length > 0 &&
                  mySection.map((e, index) => {
                    return (
                      <div key={index} className="addcms_card">
                        <div className="relative h-img1">
                          <Image
                            alt="new"
                            src={
                              e.wireframes && e.wireframes.length
                                ? `${process.env.REACT_APP_STORAGE_URL_IND}${e.wireframes}`
                                : ''
                            }
                            onError={event => setDefaultImage(event)}
                            onClick={() => {
                              setSelectedMasterSection(e);
                            }}
                            className="w-full h-img1"
                          />
                          {/* {selectedMasterSection._id === e._id ? (
                            <div className="absolute w-full h-img1 flex justify-center items-center top-0 left-0">
                              <FeatherIcon
                                icon="check"
                                className="text-success"
                              />
                            </div>
                          ) : (
                            ''
                          )} */}
                        </div>
                        <div className="text-center mt-2">
                          <p className="text-lightgray">{`${e.sectionName} ${e.variations}`}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {mySection && mySection.length <= 0 && (
                <div className=" text-center">No Sections</div>
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        {/* Extension */}
        {check === '05' ? (
          <div className="bg-f4 rounded-lg p-4">
            {' '}
            <div className="flex gap-4">
              <div className="relative w-1/2">
                <input
                  placeholder="Search..."
                  className="w-full p-3 border rounded-lg remove-outline fsize14"
                  value={search}
                />
                <div className="absolute top-0 right-0 m-3">
                  <FeatherIcon
                    icon="search"
                    className="svgicon-gray cursor-pointer"
                  />
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex h-full items-center flex-wrap gap-4">
                  {getAllforms
                    ? getAllforms.map(e => {
                        return (
                          <p className="text-lighgray font-medium fsize12 chipslist-cs">
                            {e.formName}
                          </p>
                        );
                      })
                    : ''}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {selectedMasterSection && check !== '04' ? (
        <div className="mt-6 text-center">
          <Button
            className="bg-brained textwhite"
            px="12"
            onClick={() => addSectionToPage()}
          >
            Add
          </Button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Dialog;
