import { Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const Works = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const allPortfolio = useSelector(state => state.portfolio.Portfolio);
  console.log(allPortfolio.result);

  const [portfolioData, setPortfolioData] = useState([]);
  const [portfolioCat, setPortfolioCat] = useState([]);

  const finalData = () => {
    let finalArray = [];
    allPortfolio.result.map(portfolio => {
      portfolio.subcategoryId.map(subPort => {
        let data = {
          id: subPort.adminId,
          portfolioName: portfolio.name,
          portfolioImage: portfolio.image,
          subPortfolioName: subPort.name,
          subPortfolioImage: subPort.image,
          subPortfolioDate: subPort.createdAt,
        };
        finalArray.push(data);
      });
    });
    console.log(finalArray);
    if (finalArray.length > 0) {
      setPortfolioData(finalArray);
    }
  };

  useEffect(() => {
    finalData();
  }, []);

  // console.log(portfolioData, 'portfolioData');

  const handlePortfolioCategory = name => {
    setPortfolioCat([]);
    let filterarray = [];
    portfolioData.map(e => {
      if (e.portfolioName === name) {
        console.log(e, 'sss');
        filterarray.push(e);
      }
    });
    setPortfolioCat(filterarray);
    // console.log(portfolioCat, 'portfolioCat');
  };

  return (
    <div className="lg:px-0 md:px-0 px-5 lg:py-0 md:py-0 py-8 ">
      <div className="flex">
        <h2 className="fsize32 sm-fsize24 font-semibold cust-textColor ">Portfolio</h2>
      </div>

      <div className="mt-3">
        <Tabs>
          <TabList className="tab-header">
            <Tab className="sm-px-05rem">All</Tab>
            {allPortfolio &&
              allPortfolio.result &&
              allPortfolio.result.length > 0 &&
              allPortfolio.result.map(item => (
                <Tab
                  className="sm-px-05rem"
                  onClick={() => handlePortfolioCategory(item.name)}
                >
                  {item.name}
                </Tab>
              ))}

            {/* <Tab className="sm-px-05rem">Design</Tab>
            <Tab className="sm-px-05rem">Logo</Tab> */}
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              {
                <div className="lg:mt-8 md:mt-6 mt-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 w-full gap-4">
                  {portfolioData &&
                    portfolioData.length > 0 &&
                    portfolioData.reverse().map(port => (
                      <div
                      className={
                        colorMode === 'light'
                          ? 'bg-f3f6f6 lg:p-5 md:p-5 p-3 br-12px'
                          : 'bg-darkprimary lg:p-5 md:p-5 p-3 br-12px'
                      }
                     >
                        <Image
                          src={
                            process.env.REACT_APP_STORAGE_URL +
                            port.subPortfolioImage
                          }
                          alt=""
                          className="w-full h-200px object-cover br-12px"
                        />
                        <div className="mt-3">
                          <div className="flex items-center gap-2">
                            <p className="fsize12 cust-textColor">
                              {new Date(port.subPortfolioDate).toDateString()}
                            </p>
                            <p className="black-dot cust-bgColor"></p>
                            <p className="fsize12 cust-textColor">{port.portfolioName}</p>
                          </div>
                          <h2 className="fsize16 font-medium cust-textColor cust-textColor">
                            {port.subPortfolioName}
                          </h2>
                        </div>
                      </div>
                    ))}
                </div>
              }
            </TabPanel>
            <TabPanel px={0}>
            {
                <div className="lg:mt-8 md:mt-6 mt-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 w-full gap-4">
                  {portfolioCat &&
                    portfolioCat.length > 0 &&
                    portfolioCat.reverse().map(port => (
                      <div className={
                        colorMode === 'light'
                          ? 'bg-f3f6f6 lg:p-5 md:p-5 p-3 br-12px'
                          : 'bg-darkprimary lg:p-5 md:p-5 p-3 br-12px'
                      }>
                        <Image
                          src={
                            process.env.REACT_APP_STORAGE_URL +
                            port.subPortfolioImage
                          }
                          alt=""
                          className="w-full h-200px object-cover br-12px"
                        />
                        <div className="mt-3">
                          <div className="flex items-center gap-2">
                            <p className="fsize12 cust-textColor">
                              {new Date(port.subPortfolioDate).toDateString()}
                            </p>
                            <p className="black-dot cust-bgColor"></p>
                            <p className="fsize12 cust-textColor">{port.portfolioName}</p>
                          </div>
                          <h2 className="fsize16 font-medium cust-textColor">
                            {port.subPortfolioName}
                          </h2>
                        </div>
                      </div>
                    ))}
                </div>
              }
            </TabPanel>
            <TabPanel px={0}>
            {
                <div className="lg:mt-8 md:mt-6 mt-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 w-full gap-4">
                  {portfolioCat &&
                    portfolioCat.length > 0 &&
                    portfolioCat.reverse().map(port => (
                      <div className={
                        colorMode === 'light'
                          ? 'bg-f3f6f6 lg:p-5 md:p-5 p-3 br-12px'
                          : 'bg-darkprimary lg:p-5 md:p-5 p-3 br-12px'
                      }>
                        <Image
                          src={
                            process.env.REACT_APP_STORAGE_URL +
                            port.subPortfolioImage
                          }
                          alt=""
                          className="w-full h-200px object-cover br-12px"
                        />
                        <div className="mt-3">
                          <div className="flex items-center gap-2">
                            <p className="fsize12 cust-textColor">
                              {new Date(port.subPortfolioDate).toDateString()}
                            </p>
                            <p className="black-dot cust-bgColor"></p>
                            <p className="fsize12 cust-textColor">{port.portfolioName}</p>
                          </div>
                          <h2 className="fsize16 font-medium cust-textColor">
                            {port.subPortfolioName}
                          </h2>
                        </div>
                      </div>
                    ))}
                </div>
              }
            </TabPanel>           
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default Works;
