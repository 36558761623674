import {
  Button,
  Select,
  Input,
  Image,
  Textarea,
  Divider,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import {
  setIsEditUserProfile,
  SaveBasicProfileDetails,
  getUserByToken,
} from '../../../../redux/auth/auth-action';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux/es/exports';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UploaderBox from '../../../../components/UploaderBox';
import { ThreeDots } from 'react-loader-spinner';

const EditProfile = () => {
  const dispatch = useDispatch();
  let { IsEditUserProfile, user } = useSelector(state => state.AuthDetails);
  // let IsEditUserProfile = useSelector(
  //   (state) => state.AuthDetails.IsEditUserProfile
  // );
  const setIsEditUserProfileHandler = () => {
    dispatch(setIsEditUserProfile(!IsEditUserProfile));
  };

  const [selectValue, setSelectvalue] = useState([]);
  const [userMobileCountry, setUserMobileCountry] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [userImagePreview, setUserImagePreview] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);

  const [userLoading, setUserLoading] = useState(false);

  const [fitnessGoalOptions, setFitnessGoalOptions] = useState([
    { name: 'Lose weight', id: 1 },
    { name: 'Build muscle', id: 2 },
    { name: 'Improve cardiovascular endurance', id: 3 },
    { name: 'Increase flexibility', id: 4 },
    { name: 'Enhance agility and balance', id: 5 },
    { name: 'Reduce body fat percentage', id: 6 },
    { name: 'Improve posture', id: 7 },
    { name: 'Enhance athletic performance', id: 8 },
    { name: 'Boost overall fitness level', id: 9 },
    { name: 'Other', id: 10 },
  ]);

  const onSelect = (selectedList, selectedItem) => {
    // Handle the select event

    setSelectvalue(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    // Handle the remove event

    setSelectvalue(selectedList);
  };

  useEffect(() => {
    populateData();
  }, [user]);

  const populateData = async () => {
    if (user.userImage) {
      setFieldHandler('userImagePreview', user.userImage);
    }
    if (user.coverImage) {
      setFieldHandler('coverImagePreview', user.coverImage);
    }
    await formik.setFieldValue(
      'firstName',
      user.firstName ? user.firstName : ''
    );
    await formik.setFieldValue('lastName', user.lastName ? user.lastName : '');
    await formik.setFieldValue('gender', user.gender ? user.gender : '');
    await formik.setFieldValue('dob', user.dob ? formatDate(user.dob) : null);
    await formik.setFieldValue('email', user.email ? user.email : '');
    await formik.setFieldValue('bio', user.bio ? user.bio : '');
    await formik.setFieldValue('city', user.city ? user.city : '');
    await formik.setFieldValue(
      'countryCode',
      user.countryCode ? user.countryCode : ''
    );
    await formik.setFieldValue('mobile', user.mobile ? user.mobile : '');
    await formik.setFieldValue('fitnessGoal', filterFitnessGoalData(user));

    setUserMobileCountry(`${user.countryCode}${user.mobile}`);
  };

  const filterFitnessGoalData = user => {
    if (user.fitnessGoal && user.fitnessGoal.length > 0) {
      let filterData = fitnessGoalOptions.filter(fgo =>
        user.fitnessGoal.includes(fgo.name)
      );
      return filterData;
    }
  };

  const formatDate = date => {
    const [day, month, year] = new Intl.DateTimeFormat('en-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
      .format(new Date(date))
      .split('/');

    return `${year}-${month}-${day}`;
  };

  const {
    isOpen: isOpenCover,
    onOpen: onOpenCover,
    onClose: onCloseCover,
  } = useDisclosure();
  const {
    isOpen: isOpenImage,
    onOpen: onOpenImage,
    onClose: onCloseImage,
  } = useDisclosure();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    countryCode: '',
    gender: '',
    bio: '',
    dob: null,
    city: '',

    userImage: null,
    coverImage: null,
    preference: [],
    fitnessGoal: [],

    coverImagePreview: null,
    userImagePreview: null,
  };

  const validationRules = Yup.object({
    firstName: Yup.string()
      .trim()
      .min(3, 'First name must be at least 3 characters')
      .max(25, 'First name must be at most 25 characters')
      .required('First name is required')
      .matches(/^[a-zA-Z ]*$/, 'Only alphabets is allowed'),
    lastName: Yup.string()
      .trim()
      .min(3, 'Last name must be at least 3 characters')
      .max(25, 'Last name must be at most 25 characters')
      .required('Last name is required')
      .matches(/^[a-zA-Z ]*$/, 'Only alphabets is allowed'),
    email: Yup.string()
      .trim()
      .lowercase()
      .min(5, 'Email must be at least 5 characters')
      .max(50, 'Email must be at most 100 characters')
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Email is invalid'
      ),
    mobile: Yup.string().required('Mobile number is required'),
    countryCode: Yup.string().required('Country code is required'),
    gender: Yup.string().required('Gender is required'),
    dob: Yup.string().required('DOB is required'),
    fitnessGoal: Yup.array(Yup.object()).required('FitnessGoal is required'),
    bio: Yup.string()
      .trim()
      .min(3, 'Bio must be at least 3 characters')
      .max(25, 'Bio must be at most 25 characters')
      .required('Bio is required'),
  });

  const handlePhoneChange = async (value, country, e, formattedValue) => {
    const dialCountryCode = country.dialCode;
    const phoneNumber = value.replace(`${dialCountryCode}`, '');
    await formik.setFieldValue('mobile', phoneNumber);
    await formik.setFieldValue('countryCode', dialCountryCode);
    validateField('mobile', formik.values.mobile);
  };

  const validateField = (fieldName, value) => {
    try {
      validationRules.fields[fieldName].validateSync(value, {
        abortEarly: false,
      });
      formik.setFieldError(fieldName, undefined);
      formik.setFieldTouched(fieldName, true);
      return true;
    } catch (error) {
      formik.setFieldError(fieldName, error.errors[0]);
      formik.setFieldTouched(fieldName, true);
      return false;
    }
  };

  const handleOnSubmit = async values => {
    let payload = new FormData();

    if (formik.values.firstName) {
      payload.append('firstName', formik.values.firstName);
    }
    if (formik.values.lastName) {
      payload.append('lastName', formik.values.lastName);
    }
    if (formik.values.email) {
      payload.append('email', formik.values.email);
    }
    if (formik.values.mobile) {
      payload.append('mobile', formik.values.mobile);
    }
    if (formik.values.countryCode) {
      payload.append('countryCode', formik.values.countryCode);
    }
    if (formik.values.gender) {
      payload.append('gender', formik.values.gender);
    }
    if (formik.values.dob) {
      payload.append('dob', formik.values.dob);
    }
    if (formik.values.city) {
      payload.append('city', formik.values.city);
    }
    if (formik.values.userImage) {
      payload.append('userImage', formik.values.userImage);
    }
    if (formik.values.coverImage) {
      payload.append('coverImage', formik.values.coverImage);
    }
    if (formik.values.fitnessGoal && formik.values.fitnessGoal.length > 0) {
      let value = formik.values.fitnessGoal.map(val => val.name);
      payload.append('fitnessGoal', JSON.stringify(value));
    }
    if (formik.values.preference) {
      payload.append('preference', formik.values.preference);
    }
    if (formik.values.bio) {
      payload.append('bio', formik.values.bio);
    }

    if (user._id) {
      payload.append('userId', user._id);
    }

    try {
      setUserLoading(true);
      let data = await dispatch(SaveBasicProfileDetails(payload));

      setUserLoading(false);
      if (data && data.code == 200) {
        await dispatch(getUserByToken({ token: user.token }));
        Swal.fire({
          title: 'Success',
          text: `User updated successfully`,
          icon: 'success',
          timer: '2500',
        });
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: 'Error',
            text: data.message,
            icon: 'error',
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong!',
        icon: 'error',
      });
    } finally {
      setUserLoading(false);
    }
  };

  const handleTrim = event => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const setFieldHandler = async (field, value) => {
    await formik.setFieldValue(field, value);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  const getImage = image => {
    if (image.includes('data:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  };

  return (
    <div>
      {/* editcoverpopup */}

      <Modal isOpen={isOpenCover} onClose={onCloseCover}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader className='cust-textColor '> Upload Cover Image </ModalHeader>

          <ModalBody>
            <div>
              {!formik.values.coverImage && (
                <UploaderBox
                  accept="image/*"
                  onFileSelect={async file =>
                    setFieldHandler('coverImage', file)
                  }
                  onPreview={async prev =>
                    setFieldHandler('coverImagePreview', prev)
                  }
                />
              )}
              {formik.values.coverImage && formik.values.coverImagePreview && (
                <div className="relative">
                  <Image
                    src={
                      formik.values.coverImagePreview
                        ? getImage(formik.values.coverImagePreview)
                        : null
                    }
                    className="object-contain w-full h-200px"
                  />
                  <FeatherIcon
                    onClick={() => {
                      setFieldHandler('coverImage', null);
                      setFieldHandler('coverImagePreview', null);
                    }}
                    icon="x"
                    className="border bg-fff rounded-full absolute top-2 right-4 cursor-pointer"
                    size={16}
                  />
                </div>
              )}
            </div>
          </ModalBody>

          <ModalFooter className="mt-4">
            <Button
              variant="outline"
              colorScheme="secondary"
              mr={3}
              onClick={() => {
                onCloseCover();
              }}
              className='cust-textColor'
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenImage} onClose={onCloseImage}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className='cust-textColor'> Upload User Image </ModalHeader>

          <ModalBody>
            {/* <div>
              {!formik.values.userImage && (
                <UploaderBox
                  accept="image/*"
                  onFileSelect={async (file) =>
                    setFieldHandler("userImage", file)
                  }
                  onPreview={async (prev) =>
                    setFieldHandler("userImagePreview", prev)
                  }
                />
              )}
              {formik.values.userImage && formik.values.userImagePreview && (
                <div className="relative">
                  {" "}
                  <Image
                    src={
                      formik.values.userImagePreview
                        ? getImage(formik.values.userImagePreview)
                        : null
                    }
                    className="object-contain w-full h-200px"
                  />
                  <FeatherIcon
                    onClick={() => {
                      setFieldHandler("userImage", null);
                      setFieldHandler("userImagePreview", null);
                    }}
                    icon="x"
                    className="border bg-fff rounded-full absolute top-2 right-4 cursor-pointer"
                    size={16}
                  />
                </div>
              )}
            </div> */}
          </ModalBody>

          <ModalFooter className="mt-4">
            <Button
              variant="outline"
              colorScheme="secondary"
              mr={3}
              onClick={() => {
                onCloseImage();
              }}
              className='cust-textColor'
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <div className="border-1px rounded-3px lg:py-4 md:py-4 py-3 lg:px-6 md:px-4 px-3">
        <form className="w-full" onSubmit={formik.handleSubmit}>
          <h2 className="fsize18 font-semibold lg:pb-4 md:pb-4 pb-1 cust-textColor">
            Edit User Profile
          </h2>
          <div className="w-full relative">
            <Image
              src={
                formik.values.coverImagePreview
                  ? getImage(formik.values.coverImagePreview)
                  : null
              }
              fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="user-background"
              className="lg:rounded-lg md:rounded-lg w-full object-cover h-300px sm-h-200px"
            />
            <FeatherIcon
              onClick={onOpenCover}
              icon="edit"
              className="cursor-pointer absolute right-2 top-2 w-6 h-6 edit_icon  p-1 rounded-full"
            />
          </div>
          <div className="lg:flex md:flex justify-between items-end pb-6 minus3rem-mtop sm-minus4rem-mtop ">
            <div className="py-2 relative w-max lg:mx-0 md:mx-0 mx-auto lg:ml-8 ">
              <Image
                src={
                  formik.values.userImagePreview
                    ? getImage(formik.values.userImagePreview)
                    : null
                }
                fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="profile-pic"
                className="w-32 h-32 rounded-full bg-6f6f6f text-center border3px-fff"
              />
              <FeatherIcon
                onClick={onOpenImage}
                icon="edit"
                className="cursor-pointer absolute right-1 bottom-5 w-6 h-6 edit_icon p-1 rounded-full"
              />
            </div>
            <div className="flex gap-4 justify-center lg:mb-0 md:mb-6">
              <Button
                isLoading={userLoading}
                spinner={<ThreeDots visible={true} color="white" radius={3} />}
                type="submit"
                
                className="fsize14 clr-fff bg-primary cust-bgColor"
              >
                Save changes
              </Button>
              <Button
                onClick={() => setIsEditUserProfileHandler()}
                
                className="fsize14 clr-fff bg-secondary cust-bgColor"
              >
                Cancel
              </Button>
            </div>
          </div>
          <div className="py-4">
            <h2 className="fsize18 font-semibold mb-4 clr-606060 cust-textColor">
              Basic Details
            </h2>
            <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
              <div className="w-full">
                <FormControl
                  isInvalid={
                    !!formik.errors.firstName && formik.touched.firstName
                  }
                >
                  <label className="clr-7b7b7b fsize14 cust-textColor">First Name</label>
                  <Input
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    type="text"
                    className="w-full border-6d6d6d rounded-lg px-4 py-3 mt-2 fsize14 cust-textColor"
                    placeholder="Enter your first name"
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <FormErrorMessage>
                      {formik.errors.firstName}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl
                  isInvalid={
                    !!formik.errors.lastName && formik.touched.lastName
                  }
                  className="mb-4"
                >
                  <label className="clr-7b7b7b fsize14 cust-textColor">Last Name</label>
                  <Input
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    type="text"
                    className="w-full border-6d6d6d rounded-lg px-4 mt-2 py-3 fsize14 cust-textColor"
                    placeholder="Enter your last name"
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <FormErrorMessage>
                      {formik.errors.lastName}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
              <div className="w-full">
                <FormControl
                  isInvalid={!!formik.errors.mobile && formik.touched.mobile}
                >
                  <label className="clr-7b7b7b fsize14 cust-textColor">Mobile Number</label>
                  <PhoneInput
                    disabled
                    name="mobile"
                    country={'sg'}
                    enableSearch={true}
                    className="mt-2 phoneinput w-full mt-2 themefamily"
                    value={userMobileCountry}
                    onChange={handlePhoneChange}
                  />
                  {formik.touched.mobile && formik.errors.mobile && (
                    <FormErrorMessage>{formik.errors.mobile}</FormErrorMessage>
                  )}
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl
                  isInvalid={!!formik.errors.email && formik.touched.email}
                  className="mb-4"
                >
                  <label className="clr-7b7b7b fsize14 cust-textColor">Email Address</label>
                  <Input
                    readOnly
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14 cust-textColor"
                    placeholder="Enter your email address"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
              <div className="w-full">
                <FormControl
                  isInvalid={!!formik.errors.dob && formik.touched.dob}
                >
                  <label className="clr-7b7b7b fsize14 cust-textColor">Date of Birth</label>
                  <Input
                    name="dob"
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    type="date"
                    className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14 cust-textColor"
                    placeholder="DD/MM/YYYY"
                  />
                  {formik.touched.dob && formik.errors.dob && (
                    <FormErrorMessage>{formik.errors.dob}</FormErrorMessage>
                  )}
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl
                  isInvalid={!!formik.errors.gender && formik.touched.gender}
                >
                  <label className="clr-7b7b7b fsize14 cust-textColor">Gender</label>
                  <Select
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    className="text-gray mt-2 cust-textColor"
                    placeholder="Select option"
                  >
                    <option> Male</option>
                    <option> Female</option>
                    <option>Other </option>
                  </Select>
                  {formik.touched.gender && formik.errors.gender && (
                    <FormErrorMessage>{formik.errors.gender}</FormErrorMessage>
                  )}
                </FormControl>
              </div>
            </div>

            <Divider className="mt-8" />

            <h2 className="fsize18 font-semibold mt-2 mb-4 clr-606060 cust-textColor">
              Other Details
            </h2>

            <div>
              <FormControl
                isInvalid={!!formik.errors.bio && formik.touched.bio}
              >
                <label className="clr-7b7b7b fsize14 cust-textColor">Bio</label>
                <Textarea
                  value={formik.values.bio}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  name="bio"
                  className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14"
                  placeholder="Type here"
                  rows="4"
                ></Textarea>
                {formik.touched.bio && formik.errors.bio && (
                  <FormErrorMessage>{formik.errors.bio}</FormErrorMessage>
                )}
              </FormControl>
            </div>

            {/* <Divider className="mt-8" /> */}

            {/* <div>
              <h2 className="fsize18 font-semibold clr-606060 mb-4">
                Your Fitness Goals
              </h2>
              <FormControl
                isInvalid={
                  !!formik.errors.fitnessGoal && formik.touched.fitnessGoal
                }
              >
                <label className="clr-7b7b7b fsize14 cust-textColor ">Select Goals</label>
                <Multiselect
                  name="fitnessGoal"
                  className="w-full  rounded-lg  fsize14 mt-2 cust-multiselect"
                  options={fitnessGoalOptions}
                  selectedValues={formik.values.fitnessGoal}
                  onSelect={async value => {
                    await setFieldHandler('fitnessGoal', value);
                    // setFitnessGoalData(value);
                  }}
                  onRemove={async value => {
                    await setFieldHandler('fitnessGoal', value);
                    // setFitnessGoalData(value);
                  }}
                  displayValue="name"
                />{' '}
                {formik.touched.fitnessGoal && formik.errors.fitnessGoal && (
                  <FormErrorMessage>
                    {formik.errors.fitnessGoal}
                  </FormErrorMessage>
                )}
              </FormControl>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
