import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({
  metaData: { metaTitle, metaDescription, metaKeyword, metaCanonical },
}) {
  return (
    <Helmet>
      {metaTitle ? <title>{metaTitle}</title> : ''}
      {metaTitle ? <meta property="og:title" content={metaTitle} /> : ''}
      {metaTitle ? <meta name="twitter:title" content={metaTitle} /> : ''}

      {metaDescription ? (
        <meta name="description" content={metaDescription} />
      ) : (
        ''
      )}
      {metaDescription ? (
        <meta property="og:description" content={metaDescription} />
      ) : (
        ''
      )}
      {metaDescription ? (
        <meta name="twitter:description" content={metaDescription} />
      ) : (
        ''
      )}

      {/* {type ? <meta property="og:type" content={type} /> : ''}
      {type ? <meta name="twitter:card" content={type} /> : ''}

      {name ? <meta name="twitter:creator" content={name} /> : ''} */}

      {metaKeyword ? <meta name="keywords" content={metaKeyword} /> : ''}
      {metaCanonical ? <link rel="canonical" href={metaCanonical} /> : ''}
    </Helmet>
  );
}
