import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { handleScroll } from './fadeeffect/FadeEffect';

const FAQSection3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div
      className={
        colorMode === 'light'
          ? 'bg-191919 theme-bg lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
          : 'theme-bg lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
      }
    >
      <div className="container mx-auto">
        <div className="lg:w-2/3 md:w-3/4 w-full mx-auto  text-center lg:my-8 md:my-6 my-4">
          <h2 id='1304142799' className="dynamicStyle text-primary fsize36 md-fsize24 sm-fsize24 font-medium mb-2 cust-textColor theme-textColor">
          {data ? data['1304142799'] : 'Questions Thats Comes in your Mind'}
          </h2>
          <p id='4590447378' className="dynamicStyle lg:w-full md:w-2/3 w-full mx-auto fsize16 sm-fsize14 textColor-light"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['4590447378']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}>
         
          </p>
        </div>
      </div>
      <div ref={elementRef} className={`fade-bottom container mx-auto lg:py-8 md:py-6 py-4 lg:px-16 md:px-6 px-3 border-about2 border-about1 relative  ${isVisible ? 'visiblebottom' : ''} `}>
        <Accordion  id="4621206821" className='dynamicStyle' allowToggle>
        {data && data['4621206821']
            ? data['4621206821'].map(e => {
                return (
          <AccordionItem
          className={
            colorMode === 'light'
              ? 'border-primary theme-bg bg-black theme-border-0 themeShadow'
              : 'cust-bgColor theme-bg border-1px'
          }
           mb={4}>
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" className='text-primary cust-textColor theme-textColor' p={2}>
                      {e.field1}
                    </Box>
                    {isExpanded ? (
                      <MinusIcon fontSize="12px" className='clr-fff theme-textColor' />
                    ) : (
                      <AddIcon fontSize="12px" className='clr-fff theme-textColor' />
                    )}
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} className='clr-fff fsize14 textColor-light theme-textColor' 
                dangerouslySetInnerHTML={{
                    __html: e.field2,
                  }}>
                  
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          );
        })
      : 'no-data'}
          
        </Accordion>
        <div className="border-line"></div>
        <div className="border-line2"></div>
      </div>
    </div>
  );
};

export default FAQSection3;
