import React, { useEffect, useRef, useState } from 'react';
import { ArrowRight } from 'react-feather';
import { Card, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard6 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:py-16 md:py-16 py-12 lg:px-12 md:px-8 px-5">
      <p
        id="4874395738"
        className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium text-primary  cust-textColor"
      >
        {data ? data['4874395738'] : 'Fresh Arrivals'}
      </p>
      <p
        id="6594863381"
        className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-medium lg:mb-12 md:mb-12 mb-6 clr-191E22  cust-textColor"
      >
        {data ? data['6594863381'] : 'Discover New Fabric Prints'}
      </p>
      <div
        ref={elementRef}
        className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
      >
        <div
          id="1006455019"
          className="dynamicStyle w-full grid lg:grid-cols-3 md:grid-cols-3 lg:gap-12 md:gap-4 gap-6"
        >
          {data && data['1006455019']
            ? data['1006455019'].map(e => {
                return (
                  <Card className="themeShadow theme-bg">
                    <div className=" w-full overflow-hidden bradius-card-top">
                      <Image
                        className="lg:h-60 h-52 w-full lg:pb-4 md:pb-4 pb-2 sm-w-full custimghover css-0"
                        src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="img"
                      />
                    </div>
                    <p className=" mb-2 font-semibold md-fsize14 sm-fsize14 cust-textColor dynamic_3470222065 px-2">
                      {e.field2}
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e.field3,
                      }}
                      className=" fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 textColor-light px-2"
                    ></p>
                    <hr className="w-full lg:mb-4 md:mb-2 mb-2 hr-w " />
                    <div className="flex lg:w-1/4  cursor-pointer text-primary  cust-textColor cust-textColor ml-2 mb-2">
                      <p className=" sm-fsize14">{e.field4}</p>
                      <ArrowRight className=" ml-2 sm-arrow" />
                    </div>
                  </Card>
                );
              })
            : 'no-data'}
        </div>
      </div>
    </section>
  );
};

export default FeatureCard6;
