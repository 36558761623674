const initialState = {
  // Message: {},
  // People: {},
  ContactLoadMore: [],
  MessageLoadMore: [],
  WS: null,
};
const chatDetails = (state = initialState, action) => {
  switch (action.type) {
    // case "setMessage":
    //   return { ...state, Message: action.payload };
    // case "setPeople":
    //   return { ...state, People: action.payload };
    case "setContactLoadMore":
      return { ...state, ContactLoadMore: action.payload };
    case "setDeletedContact":
      let ContactLoadMoreData = state.ContactLoadMore.filter(
        (contact) => contact._id !== action.payload._id
      );
      console.log(
        ContactLoadMoreData,
        state.ContactLoadMore,
        action.payload._id,
        "ContactLoadMoreData"
      );
      return { ...state, ContactLoadMore: ContactLoadMoreData };
    case "setMessageLoadMore":
      console.log(action.payload, "message set");
      return { ...state, MessageLoadMore: action.payload };
    case "setWS":
      return { ...state, WS: action.payload };
    default:
      return state;
  }
};

export default chatDetails;
