import {
  Button,
  Card,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
} from '@chakra-ui/react';
import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProductSection7 = ({ pageName }) => {
    // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  return (
    <div className="container mx-auto lg:py-16 lg:px-12 md:py-12 md:px-12 px-5 py-6">
      <div className="w-full flex justify-between items-center">
        <h2 id='4250074025' className="dynamicStyle font-semibold fsize34 md-fsize24 sm-fsize20 text-primary cust-textColor">
        {data ? data['4250074025'] : 'Product'}
        </h2>
        <Button
          borderRadius="2px"
          height="fit-content"
          px={8}
          py={3}
          className={
            colorMode === 'light'
              ? 'dynamicStyle fsize16 bg-primary clr-fff border-1px theme-btn'
              : 'dynamicStyle fsize16  border-1px theme-btn'
          }
          id='8784845098'
        >
          <span onClick={() => handleClick('8784845098')}>{data ? data['8784845098'] : 'Expore All'}</span>
          
        </Button>
      </div>
      <div className="lg:mt-8 md:mt-8 mt-4">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper p-2"
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <Card
            className={
              colorMode === 'light'
                ? 'p-4 cust-shadow themeShadow theme-bg relative'
                : 'p-4 themeShadow theme-bg relative'
            }
            >
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="bg-primary w-12 h-12 rounded-lg"
              />
              <h2 className="cust-textColor my-2 fsize20 md-fsize18 fsize16 font-medium">
                Product
              </h2>
              <div className="flex flex-wrap gap-2 mb-3">
                <p className="fsize11 font-medium px-3 py-1 rounded-full bg-f4f5f6">
                  Full Time
                </p>
                <p className="fsize11 font-medium px-3 py-1 rounded-full bg-f4f5f6">
                  Min: 1 year
                </p>
                <p className="fsize11 font-medium px-3 py-1 rounded-full bg-f4f5f6">
                  Full Time
                </p>
              </div>
              <p className="lineClamp2 fsize14 textColor-light">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic,
                accusantium! A corrupti ipsa et.
              </p>
              <div className="lg:mt-6 md:mt-4 mt-3 flex gap-4">
                <Button
                  className={
                    colorMode === 'light'
                      ? 'bg-primary clr-fff fsize14 w-full theme-btn2 lg:py-3 md:py-3 py-2'
                      : 'border-1px fsize14 w-full theme-btn2 lg:py-3 md:py-3 py-2'
                  }
                >
                  Buy Now
                </Button>
                <Button
                  colorScheme="primary"
                  variant="outline"
                  className={
                    colorMode === 'light'
                      ? 'fsize14 w-full theme-btn2 lg:py-3 md:py-3 py-2'
                      : 'cust-textColor fsize14 w-full theme-btn2 lg:py-3 md:py-3 py-2'
                  }
                >
                  Add to Cart
                </Button>
              </div>
              <div className="absolute top-3 right-3">
                <Menu>
                  <MenuButton>
                    <FeatherIcon
                      icon="more-horizontal"
                      className="cursor-pointer cust-textColor"
                    />
                  </MenuButton>
                  <MenuList>
                    <MenuItem className="cust-textColor">Download</MenuItem>
                    <MenuItem className="cust-textColor">Download</MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card
            className={
              colorMode === 'light'
                ? 'p-4 cust-shadow themeShadow theme-bg relative'
                : 'p-4 themeShadow theme-bg relative'
            }
            >
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt=""
                className="bg-primary w-12 h-12 rounded-lg"
              />
              <h2 className="cust-textColor my-2 fsize20 md-fsize18 fsize16 font-medium">
                Product
              </h2>
              <div className="flex flex-wrap gap-2 mb-3">
                <p className="fsize11 font-medium px-3 py-1 rounded-full bg-f4f5f6">
                  Full Time
                </p>
                <p className="fsize11 font-medium px-3 py-1 rounded-full bg-f4f5f6">
                  Min: 1 year
                </p>
                <p className="fsize11 font-medium px-3 py-1 rounded-full bg-f4f5f6">
                  Full Time
                </p>
              </div>
              <p className="lineClamp2 fsize14 textColor-light">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic,
                accusantium! A corrupti ipsa et.
              </p>
              <div className="lg:mt-6 md:mt-4 mt-3 flex gap-4">
                <Button
                  className={
                    colorMode === 'light'
                      ? 'bg-primary clr-fff fsize14 w-full theme-btn2 lg:py-3 md:py-3 py-2'
                      : 'border-1px fsize14 w-full theme-btn2 lg:py-3 md:py-3 py-2'
                  }
                >
                  Buy Now
                </Button>
                <Button
                  colorScheme="primary"
                  variant="outline"
                  className={
                    colorMode === 'light'
                      ? 'fsize14 w-full theme-btn2 lg:py-3 md:py-3 py-2'
                      : 'cust-textColor fsize14 w-full theme-btn2 lg:py-3 md:py-3 py-2'
                  }
                >
                  Add to Cart
                </Button>
              </div>
              <div className="absolute top-3 right-3">
                <Menu>
                  <MenuButton>
                    <FeatherIcon
                      icon="more-horizontal"
                      className="cursor-pointer cust-textColor"
                    />
                  </MenuButton>
                  <MenuList>
                    <MenuItem className="cust-textColor">Download</MenuItem>
                    <MenuItem className="cust-textColor">Download</MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </Card>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default ProductSection7;
