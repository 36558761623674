import React from 'react';
import Banner from './components/Banner';
import FileTabs from './components/Filetabs';

const ReviewTab = () => {
  return (
    <div>
      <Banner />
      <FileTabs />
    </div>
  );
};

export default ReviewTab;
