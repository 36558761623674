import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard5 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 py-8 lg:py-16 md:py-16 py-8">
        <p
          className="font-medium text-primary text-center fsize16 sm-fsize14 dynamicStyle cust-textColor"
          id="1059803787"
        >
          {data ? data['1059803787'] : 'Lorem Ipsum'}
        </p>
        <h2
          className="fsize34 md-fsize24 sm-fsize20 font-semibold text-center cust-textColor dynamicStyle"
          id="5182522828"
        >
          {data ? data['5182522828'] : 'Lorem Ipsum'}
        </h2>

        <div id="2474479136" className="dynamicStyle">
          <div
            ref={elementRef}
            className={`fade-bottom  lg:mt-12 md:mt-12 mt-6 lg:flex md:flex md:gap-8 gap-6 flex-wrap justify-center w-full ${
              isVisible ? 'visiblebottom' : ''
            } `}
          >
            {data && data['2474479136']
              ? data['2474479136'].map(e => {
                  return (
                    <Card className="lg:w-1/4 md:w-1/4 w-full lg:mb-0 mb-6 themeShadow theme-bg">
                      <div className=" w-full overflow-hidden bradius-card-top">
                        <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          className="w-full h-40 custimghover "
                          alt="board"
                        />
                      </div>
                      <p className="theme-textColor text-primary text-center fsize18 mt-2 mb-2 cust-textColor">
                        {e.field2}
                      </p>
                    </Card>
                  );
                })
              : 'no-data'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard5;
