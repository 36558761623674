import React from 'react';
import { useSelector } from 'react-redux';

const FeatureCard22 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

  return (
    <section className="container mx-auto  lg:px-12 md:px-8 px-5 py-8">
      <div
        id="3325196387"
        className="dynamicStyle grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8"
      >
        {data && data['3325196387']
          ? data['3325196387'].map(e => {
              return (
                <div className="feature-card22">
                  <span>{e.field1}</span>
                  <h2>{e.field2}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e.field3,
                    }}
                  ></p>
                  <a href="">Read More</a>
                </div>
              );
            })
          : 'no-data'}
        {/* <div className="feature-card22">
          <span>2</span>
          <h2>Card Hover</h2>
          <p>
            {' '}
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, sunt?
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, sunt?
          </p>
          <a href="">Read More</a>
        </div>
        <div className="feature-card22">
          <span>3</span>
          <h2>Card Hover</h2>
          <p>
            {' '}
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, sunt?
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, sunt?
          </p>
          <a href="">Read More</a>
        </div> */}
      </div>
    </section>
  );
};

export default FeatureCard22;
