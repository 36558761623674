import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection22 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

    const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const [check, setcheck] = useState(1);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className={
        colorMode === 'light'
          ? 'w-full flex items-center justify-center text-center bg-grad theme-bg'
          : 'w-full flex items-center justify-center text-center cust-bgColor'
      }>
      <div className="lg:px-12 md:px-10 px-5 lg:py-20 md:py-10 py-10 ">
        <div ref={elementRef} className={`fade-bottom border-about1 border-about2 lg:py-16 md:py-10 py-10 relative ${isVisible ? 'visiblebottom' : ''} `}>
        <div className="flex items-center justify-center ">
          <div className="grid grid-cols-2 rounded-full overflow-hidden theme-btn2">
            <div
              className={
                check === 1
                  ? 'px-5 py-3 bg-primary text-dark'
                  : 'px-5 py-3 bg-grays text-white'
              }
              onClick={() => setcheck(1)}
            >
              <p className="fsize14 sm-fsize13 dynamicStyle theme-textColor" id="9797232488">
                {data ? data['9797232488'] : 'EDUCATE'}
              </p>
            </div>
            <div
              className={
                check === 2
                  ? 'px-5 py-3 bg-primary text-dark'
                  : 'px-5 py-3 bg-grays text-white'
              }
              onClick={() => setcheck(2)}
            >
              <p className="fsize14 sm-fsize13 dynamicStyle theme-textColor" id="2694721714">
                {data ? data['2694721714'] : 'EDUCATE'}
              </p>
            </div>
          </div>
        </div>
        {check === 1 ? (
          <>
            <div className="lg:mt-10 md:mt-8 mt-5">
              <p
                id="6456519630"
                dangerouslySetInnerHTML={{
                  __html: data ? data['6456519630'] : 'no-data',
                }}
                className="fsize18 md-fsize16 sm-fsize14 theme-textColor lg:w-8/12 mx-auto mt-2 text-white dynamicStyle"
              ></p>
            </div>
          </>
        ) : null}
        {check === 2 ? (
          <>
            <div className="lg:mt-10 md:mt-8 mt-5">
              <p
                id="3352536786"
                dangerouslySetInnerHTML={{
                  __html: data ? data['3352536786'] : 'no-data',
                }}
                className="fsize18 md-fsize16 sm-fsize14 theme-textColor lg:w-8/12 mx-auto mt-2 text-white dynamicStyle"
              ></p>
            </div>
          </>
        ) : null}
        <div className='border-line'></div>
        <div className='border-line2'></div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection22;
