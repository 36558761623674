import React from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const GallerySection12 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:py-12 md:py-10 py-8 container mx-auto lg:px-12 md:px-10 px-5">
      <div className="lg:flex md:flex block gap-4">
        <div className="lg:w-6/12 md:w-6/12 w-full lg:flex md:flex block gap-2">
          <div className="lg:w-1/12 md:w-1/12 w-full">
            <h4 id="6465652270" className="dynamicStyle fsize30 md-fsize22 sm-fsize20 font-semibold text-primary textColor-light rotate-text h-gallery12 sm-h-auto">
            {data ? data['6465652270'] : 'Lorem Ipsum'}
            </h4>
          </div>
          <div
            id="2634852265"
            className="dynamicStyle2 lg:w-11/12 md:w-11/12 w-full lg:mt-0 md:mt-0 mt-2"
          >
            <Image
              src={data ? getImage(data['2634852265']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="h-gallery12 w-full"
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 lg:w-6/12 md:w-6/12 w-full lg:mt-0 md:mt-0 mt-4">
          <div id="3555364007" className="dynamicStyle2 h-minigallery12">
            <Image
              src={data ? getImage(data['3555364007']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="h-minigallery12 w-full"
            />
          </div>
          <div id="0176194854" className="dynamicStyle2 relative h-minigallery12">
            <Image
              src={data ? getImage(data['0176194854']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="h-minigallery12 w-full"
            />
            <div className="absolute top-0 left-0 mt-4">
              <div className="bg-primary cust-bgColor px-4 py-1">
                <p className="fsize12 clr-fff uppercase cust-textColor">Sale</p>
              </div>
            </div>
          </div>
          <div id="9959928993" className="dynamicStyle2 h-minigallery12">
            <Image
              src={data ? getImage(data['9959928993']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="h-minigallery12 w-full"
            />
          </div>
          <div id="6878013588" className="dynamicStyle2 h-minigallery12">
            <Image
              src={data ? getImage(data['6878013588']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="h-minigallery12 w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection12;
