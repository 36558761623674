import React, { useEffect, useState } from 'react';
import { Image, Badge, Card, useColorMode } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay } from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getAllBlogs, getBlogs } from '../../../redux/blogs/blogs-actions';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import Aos from 'aos';
import 'aos/dist/aos.css';

const CardImage = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let Blogs = useSelector(state => state.BlogDetails.Blogs);
  const [isLoading, setIsLoading] = useState(false);

  // console.log('blogdata', allBlog);
  useEffect(() => {
    handleGetAllBlog();
    Aos.init();
  }, []);

  const handleGetAllBlog = async () => {
    try {
      setIsLoading(true);
      let payload = {
        isActive: true,
        offset: 0,
        limit: 12,
      };
      await dispatch(getBlogs(payload));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlogDetail = blogId => {
    navigate(`/blog detail/${blogId}`);
  };

  return (
    <div className=" container mx-auto lg:py-16 md:py-8 py-8 lg:px-10 md:px-8 px-5">
      <div className="lg:w-2/3 md:w-2/3 mx-auto text-center lg:mb-8 md:mb-8 mb-4">
        <h2
          id="0579988455"
          className="dynamicStyle fsize34 md:fsize24 sm-fsize20 text-primary cust-textColor font-semibold"
        >
          {data ? data['0579988455'] : 'BLOGS'}
        </h2>
        <p
          id="8568575289"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['8568575289']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className=" dynamicStyle text-gray mt-2 lg:text-base md:text-base text-sm textColor-light"
        ></p>
      </div>

      {isLoading ? (
        <div className="flex justify-center align-center">
          <ThreeDots visible={true} color="#ed1b24" radius={9} />
        </div>
      ) : (
        <>
          {Blogs && Blogs.result && Blogs.result.length > 0 ? (
            <Swiper
              spaceBetween={25}
              slidesPerView={3.2}
              observer={true}
              observeParents={true}
              modules={[Navigation]}
              className="mySwiper p-2"
              autoplay={{
                delay: '1000',
              }}
              loop
              navigation={{
                nextEl: '.image-swiper-button-next',
                prevEl: '.image-swiper-button-prev',
              }}
              breakpoints={{
                1536: {
                  slidesPerView: 3.2,
                  spaceBetween: 25,
                },
                1280: {
                  slidesPerView: 3.2,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 3.1,
                  spaceBetween: 25,
                },
                991: {
                  slidesPerView: 2.8,
                  spaceBetween: 25,
                },
                767: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
              }}
            >
              {Blogs.result.map((e, i) => {
                return (
                  <SwiperSlide key={e._id}>
                    {' '}
                    <Card
                      p="2"
                      className={
                        colorMode === 'light'
                          ? 'cursor-pointer themeShadow theme-bg'
                          : 'cursor-pointer themeShadow theme-bg'
                      }
                      
                      onClick={() => handleBlogDetail(e._id)}
                    >
                      <div className='overflow-hidden rounded-xl'>
                      <Image
                        height="275px"
                        src={process.env.REACT_APP_STORAGE_URL + e.image}
                        alt="heroImage"
                        className="w-full custimghover object-cover rounded-xl sm-h250px md-h200px w-full"
                      />
                      </div>
                      <div className="p-2 lg:mt-4 md:mt-4">
                        <div className="flex items-center justify-between mb-4 ">
                          <Badge
                            borderRadius="full"
                            px="3"
                            py="0.5" 
                            className={
                              colorMode === 'light'
                                ? 'bg-B2F5EA card-badge-blue'
                                : 'theme-bg'
                            }                           
                           
                          >
                            {e.categoryID &&
                            e.categoryID.length > 0 &&
                            e.categoryID[0].categoryName
                              ? e.categoryID[0].categoryName
                              : ''}
                          </Badge>
                          <p className="text-gray text-sm">
                            {new Date(e.createdAt).toDateString()}
                          </p>
                        </div>

                        <h4 className="font-semibold text-lg lineClamp2 cust-textColor">
                          {e.title}
                        </h4>
                        <p
                          dangerouslySetInnerHTML={{ __html: e.content }}
                          className="mt-2 text8787 line-clamp-1 lg:text-sm md:text-sm text-sm"
                        ></p>
                      </div>
                    </Card>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <div className="flex justify-center align-center">
              No Blog Found
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CardImage;
