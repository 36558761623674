import React, { useEffect, useRef, useState } from 'react';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import Marquee from 'react-fast-marquee';

const Clients4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);


  return (
    <section>
      <div
      >
        <div id="5313610794" className="dynamicStyle py-6">
          <Marquee loop={0} autoFill={true}> 
            {data && data['5313610794']
              ? data['5313610794'].map(e => {
                  return (
                      <div className="w-full border-side lg:p-5 md:p-4 p-3">
                        <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt=""
                          className="object-contain d-block img-custom"
                        />
                      </div>
                  );
                })
              : 'no-data'}
          
          </Marquee>
        </div>
      </div>
    </section>
  );
};

export default Clients4;
