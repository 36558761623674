import {
  Avatar,
  Button,
  Card,
  Image,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FaShoppingBag } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Select } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

const ElearingHeader = () => {
  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  let userData = useSelector(state => state.AuthDetails.user);
  let allCategory = useSelector(
    state => state.elearningProduct.elearningCategory
  );
    // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  //primarylogo
  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }
  return (
    <section>
      <Card
        className="box-shadow-none theme-bg lg:py-4 md:py-4 py-4 lg:px-12 md:px-8 px-5"
        borderRadius={0}
      >
        <div className="w-full flex justify-between items-center">
          <a href="/">
            <Image
              src={getPrimaryImage(brandProfile)}
              alt="logo"
              className="lg:w-32 w-16 h-12 object-contain"
            />
          </a>
          <div className="w-1/2">
            <div className="lg:w-2/3 w-full mx-auto relative">
              <input
                placeholder="Search anything"
                className={
                  colorMode === 'light'
                    ? 'w-full pl-4 pr-10 py-2 border-1px bg-white rounded-full remove-outline fsize14'
                    : 'w-full pl-4 pr-10 py-2 border-1px bg-darkprimary cust-textColor  rounded-full remove-outline fsize14'
                }
              />
              <div className="absolute top-0 right-3 my-2">
                <FeatherIcon
                  icon="search"
                  className="svgicon-gray cursor-pointer w-6"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-2 items-center ">
            <div className="cust-textColor lg:block md:block hidden">
              <Select
                placeholder="Category"
                className={
                  colorMode === 'light'
                    ? 'font-semibold fsize14 bg-f4f5f6 border-1px'
                    : 'font-semibold fsize14 bg-darkprimary border-1px'
                }
                border={0}
                width="9.5rem"
              >
                {allCategory &&
                allCategory.result &&
                allCategory.result.length > 0
                  ? allCategory.result.map(category => (
                      <option
                        className="my-2"
                        key={category.name}
                        value={category.name}
                      >
                        {category.name}
                      </option>
                    ))
                  : 'no-data'}
              </Select>
            </div>
            <div className="px-2">
              <FaShoppingBag className="cust-textColor" />
            </div>
            <div className="lg:hidden md:hidden block cust-textColor">
              <HamburgerIcon onClick={onOpen} />
            </div>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton className="cust-textColor" />
                <DrawerHeader>
                  {userData && userData._id && userData.token ? (
                    <a href="/user-profile" className="flex">
                      <div className="flex gap-2 items-center ">
                        <Avatar
                          name="profile"
                          size="sm"
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        />
                        <h2 className="font-semibold textColor-light">
                          {userData.firstName}
                        </h2>
                      </div>
                    </a>
                  ) : (
                    <div className=""></div>
                  )}
                </DrawerHeader>

                <DrawerBody>
                  <div className="pt-6">
                    <div className="cust-textColor">
                      <Select
                        placeholder="Category"
                        className={
                          colorMode === 'light'
                            ? 'font-semibold fsize14 bg-f4f5f6 w-full'
                            : 'font-semibold border-1px fsize14 w-full'
                        }
                      >
                        {allCategory &&
                        allCategory.result &&
                        allCategory.result.length > 0
                          ? allCategory.result.map(category => (
                              <option key={category.name} value={category.name}>
                                {category.name}
                              </option>
                            ))
                          : 'no-data'}
                      </Select>
                    </div>
                  </div>
                </DrawerBody>

                <DrawerFooter>
                  {userData && userData._id && userData.token ? (
                    ''
                  ) : (
                    <>
                      <Button
                        px="8"
                        colorScheme="primary"
                        variant="outline"
                        onClick={() => {
                          navigate('/login');
                          onClose();
                        }}
                        className={
                          colorMode === 'light'
                            ? 'w-full mr-4 text-black font-semibold  lg:px-8 md:px-8 px-6 lg:py-2 md:py-2 py-4 buttonAnimate  bdr-2px '
                            : 'w-full mr-4 cust-textColor font-semibold  lg:px-8 md:px-8 px-6 lg:py-2 md:py-2 py-4  bdr-2px border-1px'
                        }
                      >
                        Login
                      </Button>
                      <Button
                        px="8"
                        colorScheme="primary"
                        onClick={() => {
                          navigate('/register');
                          onClose();
                        }}
                        className={
                          colorMode === 'light'
                            ? 'w-full text-white font-semibold lg:px-8 md:px-8 px-6 lg:py-2 md:py-2 py-4  buttonAnimate bdr-2px'
                            : 'w-full cust-textColor bg-darkprimary font-semibold lg:px-8 md:px-8 px-6 lg:py-2 md:py-2 py-4  bdr-2px'
                        }
                      >
                        Register
                      </Button>
                    </>
                  )}
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
            {userData && userData._id && userData.token ? (
              <>
                <a href="/user-profile" className="flex">
                  <div className="flex gap-2 items-center mob-hidden">
                    <Avatar
                      name="profile"
                      size="sm"
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    />
                    <h2 className="font-semibold textColor-light">
                      {userData.firstName}
                    </h2>
                  </div>
                </a>
              </>
            ) : (
              <>
                <Button
                  px="8"
                  onClick={() => {
                    navigate('/login');
                    onClose();
                  }}
                  className={
                    colorMode === 'light'
                      ? 'text-black theme-btn2 font-semibold  lg:px-8 md:px-8 px-6 lg:py-2 md:py-2 py-4 lg:block md:block  mob-hidden  bdr-2px '
                      : 'cust-textColor theme-btn2  cust-bgColor font-semibold  lg:px-8 md:px-8 px-6 lg:py-2 md:py-2 py-4 lg:block md:block mob-hidden  bdr-2px border-1px'
                  }
                >
                  Login
                </Button>
                <Button
                  px="8"
                  colorScheme="primary"
                  onClick={() => {
                    navigate('/register');
                    onClose();
                  }}
                  className={
                    colorMode === 'light'
                      ? 'text-white theme-btn2  font-semibold lg:px-8 md:px-8 px-6 lg:py-2 md:py-2 py-4 lg:block md:block mob-hidden buttonAnimate bdr-2px'
                      : 'cust-textColor theme-btn2  bg-darkprimary font-semibold lg:px-8 md:px-8 px-6 lg:py-2 md:py-2 py-4 lg:block md:block mob-hidden  bdr-2px'
                  }
                >
                  Register
                </Button>
              </>
            )}
          </div>
        </div>
      </Card>
    </section>
  );
};

export default ElearingHeader;
