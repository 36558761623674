import {
  Button,
  Checkbox,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const Register2 = ({pageName}) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  return (
    <>
      {/* register */}
      <div className="container mx-auto lg:py-20 md:py-32 py-8 flex justify-center items-center ">
        <div className="lg:w-5/12 md:w-2/3 w-full mx-auto lg:px-0 md:px-0 px-5">
          <Image
            src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            className="w-24 h-24 mx-auto rounded-full"
          />
          <div className="lg:mt-8 md:mt-6 mt-4">
            <h2 id='4997459793' className="dynamicStyle text-center fsize36 md-fsize32 sm-fsize24 font-semibold cust-textColor">
              {data ? data['4997459793'] : 'Register'}
            </h2>
            <p  className=" text-center fsize14 textColor-light">
              <span id='3264096024' className='dynamicStyle'>
                {data ? data['3264096024'] : 'Already have an account?'}
              </span>{' '}
              <span id='9817233111' className="dynamicStyle cursor-pointer fsize14 font-medium cust-textColor">
                {data ? data['9817233111'] : 'Click here to Login'}
              </span>
            </p>
            <div className="mt-8">
              <div className="lg:flex md:flex gap-4">
                <Input
                  placeholder="First Name"
                  className="mb-4 cust-textColor"
                  py="6"
                />
                <Input
                  placeholder="Last Name"
                  className="mb-4 cust-textColor"
                  py="6"
                />
              </div>
              <InputGroup className="mb-4">
                <Input
                  placeholder="Email ID"
                  className="cust-textColor"
                  py="6"
                />
                <InputRightElement
                  width="5rem"
                  py="6"
                  className="fsize14 font-medium underline cursor-pointer cust-textColor"
                >
                  Sent OTP
                </InputRightElement>
              </InputGroup>
              <InputGroup className="mb-4">
                <Input
                  placeholder="Confirm OTP"
                  className="cust-textColor"
                  py="6"
                />
                <InputRightElement
                  width="5.5rem"
                  py="6"
                  className="fsize14 font-medium underline cursor-pointer cust-textColor"
                >
                  Verify OTP
                </InputRightElement>
              </InputGroup>
              <Input
                placeholder="Contact Number"
                className="mb-4 cust-textColor"
                py="6"
              />
              <Input
                placeholder="Password"
                className="mb-4 cust-textColor"
                py="6"
              />
              <Input
                placeholder="Confirm Password"
                className="cust-textColor"
                py="6"
              />
            </div>

            <div className="my-6 textColor-light">
              <Checkbox id='7669954185' className='dynamicStyle'>
                {data
                  ? data['7669954185']
                  : 'By Signing in you agree to our Terms and Conditions'}
              </Checkbox>
            </div>
            <Button
              colorScheme="primary"
              py="6"
              id='3175056241'
              className="dynamicStyle w-full bg-primary cust-textColor cust-bgColor"
            >
              {data ? data['3175056241'] : 'Register'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register2;
