import React from 'react';
import { useColorMode, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const FeatureCard20 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div
      className={colorMode === 'light' ? 'bg-3d3d3d theme-bg' : 'cust-bgColor'}
    >
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
        <div className="w-3/4 mx-auto text-center">
          <h3
            id="8596987245"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold text-primary cust-textColor"
          >
            {data ? data['8596987245'] : 'Lorem ipsum dolor sit'}
          </h3>
        </div>
        <div
          id="6747786779"
          className="dynamicStyle grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 lg:mt-12 md:mt-12 mt-6"
        >
          {data && data['6747786779']
            ? data['6747786779'].map((e, index) => {
                return (
                  <div
                    className={
                      colorMode === 'light'
                        ? 'px-6 lg:py-20 md:py-20 py-14 bdr-gray-2px theme-border-0 rounded-lg text-center theme-bg themeShadow'
                        : 'px-6 lg:py-20 md:py-20 py-14 bdr-gray-2px theme-border-0 rounded-lg text-center theme-bg themeShadow'
                    }
                  >
                    <p
                      className={
                        colorMode === 'light'
                          ? 'text-white fsize18 font-semibold pb-4 pt-8 theme-textColor'
                          : 'fsize18 font-semibold pb-4 pt-8 cust-textColor '
                      }
                    >
                      {e.field1}
                    </p>
                    <p
                      className={
                        colorMode === 'light'
                          ? 'text-gray-400 sm-fsize14 pb-4'
                          : 'textColor-light sm-fsize14 pb-4'
                      }
                      dangerouslySetInnerHTML={{
                        __html: e.field2,
                      }}
                    >
                      {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus */}
                    </p>
                    <Button className="mt-6 text-primary  fsize16 cust-textColor  sm-fsize14 buttonAnimate theme-btn bdr-2px">
                      {e.field3}
                    </Button>
                  </div>
                );
              })
            : 'no-card'}
          {/* <div className="px-6 lg:py-20 md:py-20 py-14 bdr-gray-2px rounded-lg text-center">
            <p
              className={
                colorMode === 'light'
                  ? 'text-white fsize18 font-semibold pb-4 pt-8'
                  : 'fsize18 font-semibold pb-4 pt-8 cust-textColor'
              }
            >
              Lorem Ipsum
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray-400 sm-fsize14 pb-4'
                  : 'textColor-light sm-fsize14 pb-4'
              }
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus
            </p>
            <Button
            colorScheme="primary"
            className="mt-6 text-primary clr-fff fsize16 cust-textColor sm-fsize14 buttonAnimate bdr-2px cust-bgColor"
            id=""
          >
            Learn More
          </Button>
          </div>
          <div className="px-6 lg:py-20 md:py-20 py-14 bdr-gray-2px rounded-lg text-center">
            <p
              className={
                colorMode === 'light'
                  ? 'text-white fsize18 font-semibold pb-4 pt-8'
                  : 'fsize18 font-semibold pb-4 pt-8 cust-textColor'
              }
            >
              Lorem Ipsum
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray-400 sm-fsize14 pb-4'
                  : 'textColor-light sm-fsize14 pb-4'
              }
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus
            </p>
            <Button
            colorScheme="primary"
            className="mt-6 text-primary clr-fff fsize16 cust-textColor sm-fsize14 buttonAnimate bdr-2px cust-bgColor"
            id=""
          >
            Learn More
          </Button>
          </div>
          <div className="px-6 lg:py-20 md:py-20 py-14 bdr-gray-2px rounded-lg text-center">
            <p
              className={
                colorMode === 'light'
                  ? 'text-white fsize18 font-semibold pb-4 pt-8'
                  : 'fsize18 font-semibold pb-4 pt-8 cust-textColor'
              }
            >
              Lorem Ipsum
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray-400 sm-fsize14 pb-4'
                  : 'textColor-light sm-fsize14 pb-4'
              }
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus
            </p>
            <Button
            colorScheme="primary"
            className="mt-6 text-primary clr-fff fsize16 cust-textColor sm-fsize14 buttonAnimate bdr-2px cust-bgColor"
            id=""
          >
            Learn More
          </Button>
          </div>
          <div className="px-6 lg:py-20 md:py-20 py-14 bdr-gray-2px rounded-lg text-center">
            <p
              className={
                colorMode === 'light'
                  ? 'text-white fsize18 font-semibold pb-4 pt-8'
                  : 'fsize18 font-semibold pb-4 pt-8 cust-textColor'
              }
            >
              Lorem Ipsum
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray-400 sm-fsize14 pb-4'
                  : 'textColor-light sm-fsize14 pb-4'
              }
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus
            </p>
            <Button
            colorScheme="primary"
            className="mt-6 text-primary clr-fff fsize16 cust-textColor sm-fsize14 buttonAnimate bdr-2px cust-bgColor"
            id=""
          >
            Learn More
          </Button>
          </div>
          <div className="px-6 lg:py-20 md:py-20 py-14 bdr-gray-2px rounded-lg text-center">
            <p
              className={
                colorMode === 'light'
                  ? 'text-white fsize18 font-semibold pb-4 pt-8'
                  : 'fsize18 font-semibold pb-4 pt-8 cust-textColor'
              }
            >
              Lorem Ipsum
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray-400 sm-fsize14 pb-4'
                  : 'textColor-light sm-fsize14 pb-4'
              }
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus
            </p>
            <Button
            colorScheme="primary"
            className="mt-6 text-primary clr-fff fsize16 cust-textColor sm-fsize14 buttonAnimate bdr-2px cust-bgColor"
            id=""
          >
            Learn More
          </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard20;
