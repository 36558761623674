import React, { useState } from 'react';
import { Button, Image, Input, useDisclosure } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
} from '@chakra-ui/react';
import { Radio, RadioGroup, Select } from '@chakra-ui/react';
import UserEffect from '../../../../../components/usereeffect/UserEffect';

const Assets = () => {
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = useState('1');
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h5 className="text-white fsize17">Add Folder</h5>
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <div className="py-3 px-1">
              <div className="">
                <label className="text-primary">Folder Name</label>
                <Input placeholder="Enter" className="mt-2" />
              </div>
              <div className="mt-3">
                <RadioGroup onChange={setValue} value={value}>
                  <Stack direction="row">
                    <Radio value="1">Public</Radio>
                    <Radio value="2">Private</Radio>
                  </Stack>
                </RadioGroup>
                {value === '2' ? (
                  <>
                    <div className="mt-2">
                      <label className="text-primary">Select Department</label>
                      <Select placeholder="Select option" className="mt-2">
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                      </Select>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="flex items-center gap-3 mt-5">
                <Button colorScheme="primary" className="w-full">
                  Submit
                </Button>
                <Button colorScheme="secondary" className="w-full">
                  Cancel
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="lg:px-12 md:px-8 px-5 lg:py-10 py-8 container mx-auto">
        <div className="flex items-center justify-between gap-3">
          <div className="flex items-center gap-3">
            <h2 className="fsize25 text-primary cust-textColor">Assets</h2>
            <FeatherIcon icon="edit" size="20" className="text-primary cust-textColor" />
          </div>
          <Button
            colorScheme="primary"
            variant="outline"
            className="text-primary cust-textColor cust-bgColor"
            onClick={onOpen}
          >
            <FeatherIcon icon="plus" size={17} />
            Add Folder
          </Button>
        </div>
        <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-4 mt-8">
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
              style={{ transform: transform }}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            />
            <p className="fsize18 textColor-light text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
              style={{ transform: transform }}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            />
            <p className="fsize18 textColor-light text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
              style={{ transform: transform }}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            />
            <p className="fsize18 textColor-light text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
              style={{ transform: transform }}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            />
            <p className="fsize18 textColor-light text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
              style={{ transform: transform }}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            />
            <p className="fsize18 textColor-light text-gray mt-5 text-center">Folder 1</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assets;
