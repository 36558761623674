import React from 'react';
import ImageGallery from 'react-image-gallery';
import FeatherIcon from 'feather-icons-react';
import { Button, useColorMode } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/exports';

const ProductDetails1 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const images = [
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      original:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      thumbnail:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];

  const navigate = useNavigate();

  const AddToCart = () => {
    navigate('/ecommerce/my-cart');
  };

  const AddToWishlist = () => {
    navigate('/ecommerce/wishlist');
  };

  const data2 = [
    {
      title: 'Display',
      content:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus hic impedit illum, repellat quibusdam earum autem nostrum sed eveniet maxime.',
    },
    {
      title: 'Display1',
      content:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus hic impedit illum, repellat quibusdam earum autem nostrum sed eveniet maxime.',
    },
  ];

  const data1 = [
    {
      title: 'Display',
      content:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus hic impedit illum, repellat quibusdam earum autem nostrum sed eveniet maxime.',
    },
    {
      title: 'Display1',
      content:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus hic impedit illum, repellat quibusdam earum autem nostrum sed eveniet maxime.',
    },
  ];

  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-12 py-8">
      <div className="w-full lg:flex md:flex gap-6 lg:mb-14 md:pb-14">
        <div className="lg:w-full md:w-1/2">
          <ImageGallery items={images} />
        </div>
        <div className="lg:w-full md:w-1/2">
          <h2 className="font-semibold fsize28 sm-fsize20 lg:mb-4 md:mb-4 mb-2 mt-6 cust-textColor">
            Apple Iphone 14 Pro
          </h2>
          <div className="flex items-center gap-2 md:pb-2 sm-pb-2">
            <FeatherIcon
              icon="star"
              className="cursor-pointer w-4 h-4 cust-textColor"
            />
            <FeatherIcon
              icon="star"
              className="cursor-pointer w-4 h-4 cust-textColor"
            />
            <FeatherIcon
              icon="star"
              className="cursor-pointer w-4 h-4 cust-textColor"
            />
            <FeatherIcon
              icon="star"
              className="cursor-pointer w-4 h-4 cust-textColor"
            />
            <FeatherIcon
              icon="star"
              className="cursor-pointer w-4 h-4 cust-textColor"
            />
          </div>
          <div className="lg:flex md:flex items-center gap-2 mb-4 cust-textColor">
            <p className="fsize18 sm-fsize16 font-medium sm-pb-1">₹123456</p>
            <p className="fsize16 sm-fsize14 font-medium strikethrough sm-pb-1">
              ₹123456
            </p>
            <p className="fsize14 font-medium">(10% off) inclusive all taxes</p>
          </div>
          <p className="fsize14 lg:mb-4 md:mb-4 mb-4 sm-lh-24 md-lh-24 lg:leading-6 cust-textColor">
            The iPhone 14 models come in blue, purple, midnight, starlight, and
            (PRODUCT)RED, plus Apple added a new yellow color in March. Unlike
            the iPhone 14 Pro models, the iPhone 14 and 14 Plus continue to have
            a notch at the top of the display that houses the TrueDepth camera.
            Like the iPhone 13 models, the iPhone 14 and iPhone 14 Plus include
            Super Retina XDR OLED displays that support 1200 nits peak
            brightness, a 2,000,000:1 contrast ratio, Dolby Vision, and True
            Tone support for matching the white balance of the display to the
            lighting in the room.
          </p>
          <div className="lg:w-full flex justify-between">
            <div className="lg:w-full">
              <h2
                id="1502223203"
                className="dynamicStyle cust-textColor fsize16 sm-fsize14 font-semibold lg:mb-4 md:mb-2 mb-2"
              >
                {data ? data['1502223203'] : 'Color:'}
              </h2>
              <div className="flex">
                <div className="lg:mr-2">
                  <div className="chips-select fsize14 theme-btn2 sm-fsize12">
                    <p>Deep purple</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-full">
              <h2
                id="0413592705"
                className="dynamicStyle cust-textColor fsize16 sm-fsize14 font-semibold lg:mb-4 md:mb-2 mb-2"
              >
                {data ? data['0413592705'] : 'Size:'}
              </h2>
              <div className="w-full">
                <select
                  name=""
                  id=""
                  className="border-1px bg-transparent cust-textColor w-full px-2 py-2 rounded-lg md-font-14 sm-fsize12"
                >
                  <option value="">128gb</option>
                  <option value="">256gb</option>
                </select>
              </div>
            </div>
          </div>
          <div className="w-full lg:mt-4 mt-4">
            <h2
              id="6448951598"
              className="dynamicStyle cust-textColor fsize16 font-semibold lg:mb-4"
            >
              {data ? data['6448951598'] : 'Quantity:'}
            </h2>
            <div className="counter lg:mt-4 md:mt-4 mt-2">
              <div className="flex items-center">
                <span className="cursor-pointer p-2 cust-textColor">-</span>
                <input
                  type="text"
                  className="counter-input cust-textColor"
                  value="1"
                />
                <span className="cursor-pointer p-2 cust-textColor">+</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:mt-8 md:mt-8 mt-4 flex lg:gap-6 md:gap-6 gap-4">
            <div className=" w-full">
              <Button
                onClick={AddToCart}
                colorScheme="primary"
                height="fit-content"
                className="dynamicStyle theme-btn2 cust-textColor bg-primary cust-bgColor text-white font-semibold py-3 w-full sm-fsize14"
                id="0074254276"
              >
                {data ? data['0074254276'] : 'Add to cart'}
              </Button>
            </div>
            <div className=" w-full">
              <Button
                onClick={AddToWishlist}
                variant="outline"
                colorScheme="primary"
                height="fit-content"
                className="dynamicStyle  theme-btn2 cust-textColor cust-bgColor font-semibold w-full py-3 sm-fsize14"
                id="1372020659"
              >
                {data ? data['1372020659'] : 'Wishlist'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:mb-10 md:mb-6 sm-py-8">
        <div className="w-3/4">
          <p
            id="7595406848"
            className="dynamicStyle cust-textColor fsize16 sm-fsize14 font-semibold lg:mb-2 mb-1"
          >
            {data ? data['7595406848'] : 'Product Information'}
          </p>
          <h2 className="cust-textColor fsize36 md-font-28 sm-fsize18 lg:mb-4 md:mb-4 mb-4">
            Apple Iphone 14 Pro
          </h2>
          <div className="">
            <h2
              id="4590260616"
              className="dynamicStyle cust-textColor fsize18 font-semibold lg:mb-4 md:mb-4 mb-3"
            >
              {data ? data['4590260616'] : 'Description'}
            </h2>
            <div className="">
              <ul className="list-none">
                <li className="textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
                  adipisci odio debitis maxime quis blanditiis.
                </li>
                <li className="textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
                  adipisci odio debitis maxime quis blanditiis.
                </li>
                <li className="textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
                  adipisci odio debitis maxime quis blanditiis.
                </li>
                <li className="textColor-light">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
                  adipisci odio debitis maxime quis blanditiis.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:flex md:flex gap-6">
        <div className="lg:w-full">
          <h2
            id="2552648406"
            className="dynamicStyle cust-textColor fsize20 lg:mb-4 mb-4"
          >
            {data ? data['2552648406'] : 'Technical Specifications'}
          </h2>
          {data2 && data2.length > 0
            ? data2.map((item, index) => (
                <div key={index}
                className={
                  colorMode === 'light'
                    ? 'border-1px lg:w-full flex'
                    : 'border-1px lg:w-full flex'
                }
                >
                  <div className="lg:w-full border-r-1px p-4 sm-w-25 md-w-30">
                    <p className="fsize16 sm-fsize14 cust-textColor font-semibold">
                      {item.title}
                    </p>
                  </div>
                  <div className="lg:w-full p-4 sm-w-75 md-w-70">
                    <p className="fsize16 sm-fsize14 textColor-light">
                      {item.content}
                    </p>
                  </div>
                </div>
              ))
            : 'no-data'}
        </div>
        <div className="lg:w-full">
          <h2
            id="8456911288"
            className="dynamicStyle cust-textColor fsize20 lg:mb-4 md:mb-4 sm-py-4"
          >
            {data ? data['8456911288'] : 'Additional Information'}
          </h2>
          {data1
            ? data1.map((item, index) => (
                <div key={index}  className={
                  colorMode === 'light'
                    ? 'border-1px lg:w-full flex'
                    : 'border-1px lg:w-full flex'
                }>
                  <div className="lg:w-full border-r-1px p-4 sm-w-25 md-w-30">
                    <p className="cust-textColor fsize16 sm-fsize14 font-semibold">
                      {item.title}
                    </p>
                  </div>
                  <div className="lg:w-full p-4 sm-w-75 md-w-70">
                    <p className="textColor-light fsize16 sm-fsize14">
                      {item.content}
                    </p>
                  </div>
                </div>
              ))
            : 'no-data'}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails1;
