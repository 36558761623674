import React, { useEffect, useState } from 'react';
import {
  SpeechSynthesizer,
  AudioConfig,
  SpeechConfig,
  SpeakerAudioDestination,
} from 'microsoft-cognitiveservices-speech-sdk';
import { useDispatch, useSelector } from 'react-redux/es/exports';

import {
  setSelectedModel,
  setSynthensizer,
} from '../../../../../redux/Aiassist/Aiassist-actions';

const Synthensizer = async (dispatch, targetLanguage, neuralModels) => {
  if (targetLanguage in neuralModels) {
    const neuralModel = neuralModels[targetLanguage].model;
    dispatch(setSelectedModel(neuralModel));
    const speechConfig = SpeechConfig.fromSubscription(
      // (await handler()).speechKey,
      process.env.REACT_APP_SPEECH_KEY,
      process.env.REACT_APP_SPEECH_REGION
    );
    speechConfig.speechSynthesisVoiceName = neuralModel;
    const audioConfig = AudioConfig.fromDefaultSpeakerOutput();
    const synthensizer = new SpeechSynthesizer(speechConfig, audioConfig);

    dispatch(setSynthensizer(synthensizer));
  } else {
  }
};
export default Synthensizer;
