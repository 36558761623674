import API from '../repositoryFactory';

const getTag = 'get/ecom_tags';

const getTags = route => {
  return `forms/${route}`;
};

export default {
  getAllTags(payload) {
    return API.post(getTags(getTag), payload);
  },
};
