import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FaQuoteRight } from 'react-icons/fa';
import ReactStars from 'react-rating-stars-component';
import { Autoplay, Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const Testimonial9 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  let getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  console.log(getAllTestimonial, 'check-test');
  useEffect(() => {
    async function mount() {
      await dispatch(getTestimonal());
    }
    mount();
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-8 container m-auto">
      <div class="text-center lg:pb-12 md:pb-8 pb-6 lg:w-2/3 md:w-3/4 mx-auto">
        <h2
          id="6867065781"
          class="dynamicStyle mb-2 fsize30 sm-fsize20 text-primary cust-textColor font-semibold"
        >
          {data ? data['6867065781'] : 'Lorem Ipsum'}
        </h2>
      
          <p
            id="2583623679"
            className=" dynamicStyle  sm-fsize14 textColor-light"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['2583623679']
                : ' publishing and graphic design, Lorem ipsum is a placeholder text',
            }}
          ></p>
        
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
      >
        <Swiper
          spaceBetween={30}
          navigation={true}
          modules={[Navigation]}
          className="h-swipertestimonial testimonial_padd lg:p-4 p-1"
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 12,
            },
            820: {
              slidesPerView: 1.7,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
          }}
        >
          {getAllTestimonial &&
          getAllTestimonial.data &&
          getAllTestimonial.data.result &&
          getAllTestimonial.data.result.length > 0
            ? getAllTestimonial.data.result.map((testimonial, index) => {
                return (
                  <SwiperSlide>
                    {' '}
                    <div
                      className={
                        colorMode === 'light'
                          ? 'lg:mt-10 md:mt-10 mt-12 bg-lightShadownone relative'
                          : 'lg:mt-10 md:mt-10 mt-12 relative'
                      }
                    >
                      <Card
                        className={
                          colorMode === 'light'
                            ? 'rounded-xl bg-white theme-radius bg-lightShadownone pt-10 pb-8 px-4 pb-4 relative z-30 themeShadow theme-bg'
                            : 'rounded-xl bg-white theme-radius cust-shadow pt-10 pb-8 px-4 pb-4 relative z-30 themeShadow  theme-bg'
                        }
                      >
                        <p className="text-center sm-fsize14 lineClam4 textColor-light">
                          {testimonial.testimonial}
                        </p>
                        <div className="flex gap-4 justify-center items-center lg:pt-4 md:pt-4 pt-2">
                          <div className="">
                            <Image
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                              alt="testimonial"
                              className="img-wh-70 rounded-full object-cover"
                            />
                          </div>
                          <div>
                            <p className="font-semibold fsize18 sm-fsize16 cust-textColor">
                              {testimonial.name}
                            </p>
                            {/* <p className="fsize14 sm-fsize12">Developer</p> */}
                          </div>
                        </div>
                      </Card>
                      <div className="topc-abs z-40 flex justify-center w-full">
                        <div className="img-wh-65 rounded-full flex items-center justify-center bg-primary cust-bgColor">
                          <FaQuoteRight size="23" className="text-white" />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            : 'No Data'}
        </Swiper>
      </div>
    </div>
  );
};
export default Testimonial9;
