import React, { useEffect, useRef, useState } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection17 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  const handleErorrImage = e => {
    e.target.src =
      'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg';
  };
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section
      className={colorMode === 'light' ? 'bg-secondary theme-bg' : ''}
    >
      {' '}
      <div className="lg:flex md:flex sm-reverse h-full items-center">
        <div
          ref={elementRef} className={`fade-left lg:w-3/5 md:w-3/5 w-full lg:px-20 md:px-12 px-5 lg:py-0 md:py-8 py-6 ${isVisible ? 'visibleleft' : ''} `} 
        >
          <div className="flex items-center gap-4">
            <p id="2993923750" className="text-primary fsize16 md-fsize16 sm-fsize14 dynamicStyle">
              {data ? data['2993923750'] : 'EDUCATE'}
            </p>
            <span className="block h-2 w-2 bg-primary rounded-full cust-bgColor"> </span>
            <p id="5447826286" className="text-primary fsize16 md-fsize16 sm-fsize14 dynamicStyle">
              {data ? data['5447826286'] : 'CONNECT'}
            </p>
            <span className="block h-2 w-2 bg-primary rounded-full cust-bgColor"> </span>
            <p id="8265697376" className="text-primary fsize16 md-fsize16 sm-fsize14 dynamicStyle">
              {data ? data['8265697376'] : 'MOTIVATE'}
            </p>
          </div>

          <div className=" lg:mt-4 md:mt-3 mt-2">
            <h2
              id="9553655522"
              className="lg:text-4xl text-end theme-textColor md:text-2xl text-xl text-white  font-semibold dynamicStyle"
            >
              {' '}
              {data
                ? data['9553655522']
                : 'STAY INFORMED WITH OUR LATEST ARTICLES'}
            </h2>
            <p
              id="7701515500"
              dangerouslySetInnerHTML={{
                __html: data ? data['7701515500'] : 'no-data',
              }}
              className="text-white opacity-70 theme-textColor lg:mt-4 md:mt-3 mt-2 sm-fsize14 dynamicStyle lg:leading-7 md:leading-7 leading-6"
            ></p>
          </div>
          <div className="flex lg:gap-6 md:gap-6 gap-4 lg:pt-8 md:pt-8 pt-6">
            <Button
              colorScheme="primary"
              id="7169376012"
              className="dynamicStyle btn-dual sm-btn-dual theme-btn cust-bgColor clr-white sm-fsize14 buttonAnimate bdr-2px"
            >
              <span onClick={() => handleClick('7169376012')}>
                {data ? data['7169376012'] : 'Lorem ipsum'}
              </span>
            </Button>
            <Button
              id="5764594541"
              className="dynamicStyle btn-dual sm-btn-dual theme-btn sm-fsize14 buttonAnimate bdr-2px"
            >
              <span onClick={() => handleClick('5764594541')}>
                {data ? data['5764594541'] : 'Lorem ipsum'}
              </span>
            </Button>
          </div>
        </div>
        <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
          <div id="7060135456" className="dynamicStyle2 w-full">
            {' '}
            <Image
              src={data ? getImage(data['7060135456']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              className="object-cover w-full sm-h250px minh450px"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection17;
