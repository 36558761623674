import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { Button, Card, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const FeatureCard26 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="">
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-8">
        <div className="lg:flex md:flex lg:px-5 relative">
          <div id="3844731678" className="dynamicStyle2 lg:w-7/12 md:w-7/12">
            <Image
              src={data ? getImage(data['3844731678']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="lg:h-96 md:h-72 h-52 w-full"
            />
          </div>
          <div className="lg:absolute md:absolute bottom-0 right-0 lg:w-6/12 md:w-6/12 lg:pl-6">
            <h3
              id="7123646034"
              className="dynamicStyle fsize50 md-fsize32 sm-fsize22 sm-bg-fas font-semibold bg-white cust-bgColor lg:w-max md:w-max px-2 cust-textColor theme_insertShadow theme-bg text-dark"
            >
              {data ? data['7123646034'] : 'Lorem Ipsum'}
            </h3>
            <div className="bg-white theme_insertShadow sm-bg-fas theme-bg cust-bgColor lg:p-8 md:p-4 p-4 lg:mt-12 md:mt-4 mt-2">
              <div className="">
                <p
                  id="9648480547"
                  className="dynamicStyle  text-gray textColor-light font-semibold fsize18 md-fsize14 sm-fsize13"
                >
                  {data ? data['9648480547'] : 'Lorem Ipsum'}
                </p>
                <h6
                  id="3120868598"
                  className="dynamicStyle fsize18 cust-textColor font-semibold md-fsize16 sm-fsize16 lg:mt-3 md:mt-1 mt-2"
                >
                  {data ? data['3120868598'] : 'Lorem Ipsum'}
                </h6>
                <p
                  id="5942335758"
                  dangerouslySetInnerHTML={{
                    __html: data ? data['5942335758'] : 'Lorem ipsum generated',
                  }}
                  className="dynamicStyle fsize14 md-fsize13 sm-fsize13 mt-2 lineClamp3 textColor-light"
                ></p>
              </div>
            </div>
          </div>
        </div>
        <div
          id="5977237698"
          className="dynamicStyle w-full lg:mt-8 md:mt-6 mt-4"
        >
          <Swiper
            spaceBetween={25}
            slidesPerView={3.2}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper lg:p-2 p-1"
            autoplay={{
              delay: '1000',
            }}
            loop
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3.5,
                spaceBetween: 25,
              },
              1280: {
                slidesPerView: 3.5,
                spaceBetween: 25,
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 25,
              },
              991: {
                slidesPerView: 2.3,
                spaceBetween: 25,
              },
              768: {
                slidesPerView: 2.3,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button  image-swiper-button-prev bg-primary rounded-full p-1 cursor-pointer sm-hidden z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-primary rounded-full p-1 cursor-pointer sm-hidden z-10">
              <svg
                stroke="currentColor"
                color="white"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {data && data['5977237698']
              ? data['5977237698'].map((e, index) => {
                  return (
                    <SwiperSlide>
                      <Card className="cursor-pointer">
                        <div className="relative feature26 ">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img"
                            className="lg:h-80 md:h-72 h-60 w-full rounded-lg"
                          />

                          <div className="flex justify-center feature26absolute">
                            <div className="featurehover26 shadow lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2">
                              <p
                                id=""
                                className="dynamicStyle font-semibold fsize16 md-fsize13 sm-fsize13"
                              >
                                {e.field2}
                              </p>
                              <div className="">
                                <p
                                  id=""
                                  className="dynamicStyle fsize14 md-fsize13 sm-fsize13 mt-1 lineClamp2"
                                >
                                  {e.field3}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  );
                })
              : 'no-card'}
            {/* <SwiperSlide>
              <Card className="cursor-pointer">
                <div className="relative feature26 ">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="lg:h-80 md:h-72 h-60 w-full rounded-lg"
                  />

                  <div className="flex justify-center feature26absolute">
                    <div className="featurehover26 shadow lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2">
                      <p
                        id=""
                        className="dynamicStyle font-semibold fsize16 md-fsize13 sm-fsize13"
                      >
                        March 15, 2019
                      </p>
                      <div className="">
                        <p
                          id=""
                          className="dynamicStyle fsize14 md-fsize13 sm-fsize13 mt-1 lineClamp2"
                        >
                          publishing and graphic design, Lorem ipsum is a
                          placeholder text
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className="cursor-pointer">
                <div className="relative feature26 ">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="lg:h-80 md:h-72 h-60 w-full rounded-lg"
                  />

                  <div className="flex justify-center feature26absolute">
                    <div className="featurehover26 shadow lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2">
                      <p
                        id=""
                        className="dynamicStyle font-semibold fsize16 md-fsize13 sm-fsize13"
                      >
                        March 15, 2019
                      </p>
                      <div className="">
                        <p
                          id=""
                          className="dynamicStyle fsize14 md-fsize13 sm-fsize13 mt-1 lineClamp2"
                        >
                          publishing and graphic design, Lorem ipsum is a
                          placeholder text
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card className="cursor-pointer">
                <div className="relative feature26 ">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="lg:h-80 md:h-72 h-60 w-full rounded-lg"
                  />

                  <div className="flex justify-center feature26absolute">
                    <div className="featurehover26 shadow lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2">
                      <p
                        id=""
                        className="dynamicStyle font-semibold fsize16 md-fsize13 sm-fsize13"
                      >
                        March 15, 2019
                      </p>
                      <div className="">
                        <p
                          id=""
                          className="dynamicStyle fsize14 md-fsize13 sm-fsize13 mt-1 lineClamp2"
                        >
                          publishing and graphic design, Lorem ipsum is a
                          placeholder text
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard26;
