import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Textarea, useColorMode } from '@chakra-ui/react';
const ContactForm4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  return (
    <div className="container mx-auto lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5">
      <div className="text-center lg:mb-14 md:mb-14 mb-8">
        <h3
          id="8517439113"
          className="dynamicStyle fsize32 sm-fsize20 font-semibold pb-1 cust-textColor"
        >
          {data ? data['8517439113'] : 'Lorem Ipsum generate'}
        </h3>
        <hr className="hr-layout2" />
      </div>
      <div className="lg:w-2/5 md:w-2/5 sm-wfull lg:mb-0 md:mb-0 mb-10 mx-auto">
        <div className="mb-4">
          <p
            id="3789387916"
            className="dynamicStyle pb-2 cust-textColor sm-fsize14"
          >
            {data ? data['3789387916'] : 'Lorem Ipsum'}
          </p>
          <Input placeholder="Enter your name" className="" />
        </div>
        <div className="mb-4">
          <p id="9380597060"
            className="dynamicStyle pb-2 cust-textColor sm-fsize14"
          >
            {data ? data['9380597060'] : 'Lorem Ipsum'}</p>
          <Input placeholder="Enter your email id" className="" />
        </div>
        <div className="mb-4">
          <p id="3318039690"
            className="dynamicStyle pb-2 cust-textColor sm-fsize14"
          >
            {data ? data['3318039690'] : 'Lorem Ipsum'}</p>
          <Textarea placeholder="Enter your message" className="" />
        </div>

        <div className="flex justify-end w-full pt-2">
          <Button
            px={8}
            py={4}
            id="8542082242"
            className={
              colorMode === 'light'
                ? 'dynamicStyle bg-primary bdr-0px sm-fsize14 clr-fff theme-btn'
                : 'dynamicStyle cust-bgColor bdr-0px cust-textColor sm-fsize14 theme-btn'
            }
          >
            {data ? data['8542082242'] : 'Enquire Now'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContactForm4;
