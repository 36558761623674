import React, { useEffect, useRef, useState } from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection8 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="py-8 lg:py-16">
      <div className="container mx-auto lg:px-12 md:px-8 px-5">
        <div className="lg:flex md:flex items-center">
          <div
            ref={elementRef}
            className={`fade-left lg:w-6/12 md:w-6/12 ${
              isVisible ? 'visibleleft' : ''
            } `}
          >
            <div id="5824890590" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['5824890590']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="scholarship"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                className="scholarship-img rounded-lg object-cover"
              />
            </div>
          </div>
          <div className="lg:w-6/12 md:w-6/12 lg:mt-0 mt-4 md:pl-5 lg:pl-10">
            <p
              className="fsize16 sm-fsize14 textprimary font-medium cust-textColor dynamicStyle"
              id="0617189122"
            >
              {data ? data['0617189122'] : 'Lorem Ipsum'}
            </p>
            <h5
              className="fsize34 md-fsize24 sm-fsize20 font-semibold  cust-textColor  dynamicStyle"
              id="3247560565"
            >
              {data ? data['3247560565'] : 'Lorem Ipsum'}
            </h5>
            <p
              className="fsize16 sm-fsize14 textColor-light mt-2 dynamicStyle"
              id="7112740983"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['7112740983']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
            ></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection8;
