import { Image } from '@chakra-ui/react';
import React from 'react';

const Workflow = () => {
  return (
    <div className="relative">
      <Image
        src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/backgound.png"
        className="w-full h-work object-cover"
        alt="Image"
      />
      <div className="absolute top-0 left-0 h-work w-full">
        <div className="container mx-auto lg:px-16 md:px-10 px-5 lg:py-16 md:py-12 py-5">
          <div className="flex items-center justify-center">
            <div className="w-2/12 h-24">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/card1.png"
                className="w-24 h-24 object-contain mx-auto"
                alt="Image"
              />
              <p className="mt-2 text-center fsize14 text-white">
                Business Requirement
              </p>
            </div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/arrow.png"
              className="mx-4"
              alt="Image"
            />
            <div className="w-2/12 h-24">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/card2.png"
                className="w-24 h-24 object-contain mx-auto"
                alt="Image"
              />
              <p className="mt-2 text-center fsize14 text-white">Draft</p>
            </div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/arrow.png"
              className="mx-4"
              alt="Image"
            />
            <div className="w-2/12 h-24">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/card3.png"
                className="w-24 h-24 object-contain mx-auto"
                alt="Image"
              />
              <p className="mt-2 text-center fsize14 text-white">Document</p>
            </div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/arrow.png"
              className="mx-4"
              alt="Image"
            />
            <div className="w-2/12 h-24">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/card4.png"
                className="w-24 h-24 object-contain mx-auto"
                alt="Image"
              />
              <p className="mt-2 text-center fsize14 text-white">Deploy</p>
            </div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/arrow.png"
              className="mx-4"
              alt="Image"
            />
            <div className="w-2/12 h-24">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/card5.png"
                className="w-24 h-24 object-contain mx-auto"
                alt="Image"
              />
              <p className="mt-2 text-center fsize14 text-white">
                Prototype Ready in 24 Hours
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute z-40 top-0 left-0 h-work w-full flex items-end justify-center">
        <Image
          src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/girlcard.png"
          className="w-96 h-96 object-contain"
          alt="Image"
        />
      </div>
      <div className="absolute top-0 left-0 h-work w-full flex items-center">
        <div className="container mx-auto lg:px-32 lg:mt-20 flex">
          <Image
            src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/arrow-left 1.png"
            className="lg:w-full h-72"
            alt="Image"
          />

          <Image
            src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/arrow-right 1.png"
            className="lg:w-full h-72"
            alt="Image"
          />
        </div>
      </div>
      <div className="absolute bottom-0 right-0 mb-5">
        <div className="bg-white px-5 py-3 radius-card">
          <p className="fsize15 font-medium text-gray">Goodbye Angle</p>
          <h4 className="fsize22 font-semibold text-dark">Hello BR<span className='col-ffc1'>AI</span>NED !!!</h4>
        </div>
      </div>
    </div>
  );
};

export default Workflow;
