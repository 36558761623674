import React, { useEffect } from 'react';
import { Image, Box, AbsoluteCenter, Divider } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { otherLogin } from '../../redux/auth/auth-action';
import { getLoginGateway } from '../../redux/loginGateway/loginGateway-actions';

const OtherAuth = () => {
  const dispatch = useDispatch();
  let loginGateway = useSelector(state => state.loginGateway.LoginGateway);

  const handleGoogle = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}auth/google`, '_self');
    dispatch(otherLogin('google'));
  };

  const handleFacebook = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}auth/facebook`, '_self');
    dispatch(otherLogin('facebook'));
  };

  const handleX = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}auth/x`, '_self');
    dispatch(otherLogin('x'));
  };

  const handleLinkedin = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}auth/linkedin`, '_self');
    dispatch(otherLogin('linkedin'));
  };

  const handleApple = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}auth/apple`, '_self');
    dispatch(otherLogin('apple'));
  };

  const getLoginGatewayDetail = async () => {
    try {
      let payload = {
        isActive: true,
      };
      await dispatch(getLoginGateway(payload));
      // console.log(loginGateway);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLoginGatewayDetail();
  }, []);

  return (
    <>
      {loginGateway && loginGateway.length > 0 && (
        <Box className="sm-px-30px" position="relative" padding="10">
          <Divider />
          <AbsoluteCenter bg="white" px="4">
            <p id="8012950153" className="dynamicStyle">
              {' '}
              {/* {data ? data['8012950153'] : 'or'} */}
              or
            </p>
          </AbsoluteCenter>
        </Box>
      )}
      <div className="flex gap-1 justify-center lg:mb-0 md:mb-0 mb-8">
        {loginGateway &&
          loginGateway.length > 0 &&
          loginGateway.map(gateway => (
            <>
              {gateway.provider === 'google' && (
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/brained/sign-in-icons/google.png"
                  alt="google"
                  className="w-10 h-10 cursor-pointer"
                  onClick={handleGoogle}
                />
              )}
              {gateway.provider === 'facebook' && (
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/brained/sign-in-icons/facebook.png"
                  alt="facebook"
                  className="w-10 h-10 cursor-pointer"
                  onClick={handleFacebook}
                />
              )}
              {gateway.provider === 'x' && (
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/brained/sign-in-icons/x.png"
                  alt="x"
                  className="w-10 h-10 cursor-pointer"
                  onClick={handleX}
                />
              )}
              {gateway.provider === 'linkedin' && (
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/brained/sign-in-icons/linkedin.png"
                  alt="linkedin"
                  className="w-10 h-10 cursor-pointer"
                  onClick={handleLinkedin}
                />
              )}
              {gateway.provider === 'apple' && (
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/brained/sign-in-icons/apple.png"
                  alt="apple"
                  className="w-10 h-10 cursor-pointer"
                  onClick={handleApple}
                />
              )}
            </>
          ))}
      </div>
    </>
  );
};

export default OtherAuth;
