import { DownloadIcon } from '@chakra-ui/icons';
import { Button, Card, Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  FaCalendarAlt,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaTwitter,
} from 'react-icons/fa';
import FeatherIcon from 'feather-icons-react';
import { FiMail } from 'react-icons/fi';
import About from './components/About';
import Blogs from './components/Blogs';
import Resume from './components/Resume';
import Works from './components/Works';
import ContactUs from './components/ContactUs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCategory,
  getAllPortfolio,
} from '../../../redux/portfolio/portfolio-actions';

const Profile2 = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useDispatch();
  const menuItems = [
    {
      id: '01',
      name: 'About',
      icon: 'user',
    },
    {
      id: '02',
      name: 'Resume',
      icon: 'file-text',
    },
    {
      id: '03',
      name: 'Works',
      icon: 'briefcase',
    },
    {
      id: '04',
      name: 'Blogs',
      icon: 'server',
    },
    {
      id: '05',
      name: 'Contact',
      icon: 'phone',
    },
  ];

  const [selectedMenu, setSelectedMenu] = useState('01');

  const handleMenu = menu => {
    setSelectedMenu(menu);
  };
  console.log(selectedMenu);

  useEffect(() => {
    dispatch(getAllPortfolio());
    dispatch(getAllCategory());
  }, []);

  return (
    <section className="container mx-auto lg:pt-20 md:pt-8 pt-8 lg:px-12 md:px-4 px-5">
      <div className="w-full md-w-90 mx-auto lg:flex-row md:flex-col flex-col flex  items-start  lg:py-12 md:py-8 relative ">
        <div className="w-30 md-w-100 sm-w-100 fixed-side bg-white cust-bgColor br-20px lg:mr-6 md:mr-6">
          <div className="w-full ">
            <div className="w-full profile-img">
              <Image src='https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg' className="bg-primary cust-bgColor w-full h-full object-cover" />
            </div>
            <div className="mt-3 text-center">
              <h3 className="fsize20 font-medium cust-textColor">
                Monalisa Ashley
              </h3>
              <div
                className={
                  colorMode === 'light'
                    ? 'mt-1 bg-f3f6f6 rounded-sm inline-block lg:px-3 md:px-3 px-2 lg:py-1 md:py-1 py-1'
                    : 'mt-1 border-1px rounded-sm inline-block lg:px-3 md:px-3 px-2 lg:py-1 md:py-1 py-1'
                }
              >
                <p className="fsize14 font-medium cust-textColor">
                  Ui/Ux Designer
                </p>
              </div>
              <div className="flex justify-center gap-3 py-4">
                <FaFacebookF
                  className={
                    colorMode === 'light'
                      ? 'social-media-icon pad-10px bg-f3f6f6'
                      : 'social-media-icon pad-10px border-1px clr-fff'
                  }
                />
                <FaTwitter
                  className={
                    colorMode === 'light'
                      ? 'social-media-icon pad-10px bg-f3f6f6'
                      : 'social-media-icon pad-10px border-1px clr-fff'
                  }
                />
                <FaInstagram
                  className={
                    colorMode === 'light'
                      ? 'social-media-icon pad-10px bg-f3f6f6'
                      : 'social-media-icon pad-10px border-1px clr-fff'
                  }
                />
                <FaLinkedinIn
                  className={
                    colorMode === 'light'
                      ? 'social-media-icon pad-10px bg-f3f6f6'
                      : 'social-media-icon pad-10px border-1px clr-fff'
                  }
                />
              </div>
            </div>
            <div
             className={
              colorMode === 'light'
                ? 'mt-3 bg-f3f6f6 lg:py-4 md:py-3 py-2 lg:px-6 md:px-4 px-2 rounded-lg'
                : 'mt-3 border-1px lg:py-4 md:py-3 py-2 lg:px-6 md:px-4 px-3 rounded-lg'
            }
           >
              <div className="flex w-full gap-2 py-3 items-center ">
                <FaMobileAlt className="social-icon pad-10px" />
                <div className="">
                  <p className="fsize12 cust-textColor">Phone</p>
                  <a href="tel: 1234567890">
                    <p className="font-medium fsize16 textColor-light">+123 456 7890</p>
                  </a>
                </div>
              </div>
              <div
              className={
                colorMode === 'light'
                  ? 'bottom-border-e3e3e3'
                  : 'border-b-1px'
              }></div>
              <div className="flex w-full gap-2 py-3 items-center ">
                <FiMail className="social-icon pad-10px" />
                <div className="">
                  <p className="fsize12 cust-textColor">Email</p>
                  <a href="mailto:example@ail.com">
                    <p className="font-medium fsize16 textColor-light">Example@Mail.com</p>
                  </a>
                </div>
              </div>
              <div
              className={
                colorMode === 'light'
                  ? 'bottom-border-e3e3e3'
                  : 'border-b-1px'
              }></div>
              <div className="flex w-full gap-2 py-3 items-center ">
                <FaMapMarkerAlt className="social-icon pad-10px" />
                <div className="">
                  <p className="fsize12 cust-textColor">Location</p>
                  <p className="font-medium fsize16 textColor-light">Mumbai, India</p>
                </div>
              </div>
              <div
              className={
                colorMode === 'light'
                  ? 'bottom-border-e3e3e3'
                  : 'border-b-1px'
              }></div>
              <div className="flex w-full gap-2 py-3 items-center ">
                <FaCalendarAlt className="social-icon pad-10px" />
                <div className="">
                  <p className="fsize12 cust-textColor">Birthday</p>
                  <p className="font-medium fsize16 textColor-light">May 27, 1999</p>
                </div>
              </div>
            </div>
            <div className="mt-6 flex justify-center">
              <Button
              className={
                colorMode === 'light'
                  ? 'btn-2'
                  : 'btn-2 cust-textColor cust-bgColor border'
              }
>
                <DownloadIcon mr={1} /> Download Cv
              </Button>
            </div>
          </div>
        </div>
        <div className="w-70 md-w-100 minwidth-70 sm-w-100">
          <div className="w-full  lg:flex md:flex justify-end lg:mt-0 md:mt-6 mt-6">
            <div className="cust-bgColor sm-overflow-x flex gap-4  rounded-lg justify-center w-60 md-w-80 sm-w-full bg-fff p-4">
              {menuItems.map((menu, index) => (
                <div
                  key={index}
                  onClick={() => handleMenu(menu.id)}
                  className={
                    selectedMenu === menu.id
                      ? 'tab-title-active flex flex-col justify-center items-center cursor-pointer'
                      : 'tab-title flex flex-col justify-center items-center cursor-pointer'
                  }
                >
                  <FeatherIcon
                    icon={menu.icon}
                    className="w-1/2 mx-auto fsize24 mb-2"
                  />
                  <p className="fsize14 leading-none">{menu.name}</p>
                </div>
              ))}
            </div>
          </div>
          {selectedMenu === '01' && (
            <div className="w-full minheight-800 bg-white br-20px my-6 cust-bgColor">
              <About />
            </div>
          )}
          {selectedMenu === '02' && (
            <div className="w-full minheight-800 br-20px my-6 ">
              <Resume />
            </div>
          )}
          {selectedMenu === '03' && (
            <div className="w-full minheight-800 bg-white br-20px my-6 cust-bgColor lg:px-12 lg:py-8 md:px-8 md:py-6">
              <Works />
            </div>
          )}
          {selectedMenu === '04' && (
            <div className="w-full minheight-800 bg-white br-20px my-6 cust-bgColor lg:px-12 lg:py-8 md:px-8 md:py-6">
              <Blogs />
            </div>
          )}
          {selectedMenu === '05' && (
            <div className="w-full minheight-800 bg-white br-20px my-6 cust-bgColor lg:px-12 lg:py-8 md:px-8 md:py-6">
              <ContactUs />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Profile2;
