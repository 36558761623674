import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import {
  Select,
  Button,
  useColorMode,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';

const AboutSection29 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const faqData = [
    {
      title: 'Professional Trainers Tutorials',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'Professional Trainers Tutorials',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'Professional Trainers Tutorials',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      title: 'Professional Trainers Tutorials',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
  ];
  return (
    <div className="container mx-auto lg:px-12 md:px-8 lg:py-16 md:py-16 px-5 py-10">
      <div className="lg:flex md:flex lg:gap-14 md:gap-10">
        <div className="w-45per sm-wfull lg:pr-20 lg:pb-0 md:pb-0 pb-10">
          <h1
            id="8428083361"
            className="dynamicStyle fsize34 md-fsize28 sm-fsize20 font-semibold pb-6 text-primary cust-textColor"
          >
            {data ? data['8428083361'] : 'Popular like by our clients'}
          </h1>

          <div>
            <Accordion allowToggle>
              {faqData.map((faq, index) => (
                <div key={index} className="py-1 mb-2">
                  <AccordionItem className="cust-shadow font-medium sm-fsize14 bg-faf9ff text-black cust-bgColor cust-textColor rounded-lg themeShadow theme-bg">
                    <h2>
                      <AccordionButton className="">
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          className="font-medium"
                        >
                          {faq.title}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} className="textColor-light fsize14 font-normal">
                      {faq.content}
                    </AccordionPanel>
                  </AccordionItem>
                </div>
              ))}
              
            </Accordion>
          </div>
        </div>
        <div className="w-55per h-full sm-wfull bg-d9d9d9 px-6 py-10 rad-rev-24px cust-bgColor theme-bg themeShadow">
          <p
            id="7508967941"
            className="dynamicStyle fsize40 md-fsize32 sm-fsize24 font-semibold cust-textColor"
          >
            {data ? data['7508967941'] : 'What people choose more often'}
          </p>
          <p
            id="9052000072"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['9052000072']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle text-gray-400 pt-4 sm-fsize14 textColor-light"
          >
            {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Perferendis harum ducimus dolores aliquid reiciendis. Eius autem
            blanditiis animi. Animi, excepturi! */}
          </p>
          <div className="lg:mt-6 md:mt-6 mt-4 flex gap-4">
            <Button
              className={
                colorMode === 'light'
                  ? 'dynamicStyle bg-primary clr-fff fsize14 sm-fsize12 btn-mdsize theme-btn2'
                  : 'dynamicStyle border-1px fsize14 sm-fsize12 btn-mdsize theme-btn2'
              }
              id="1045299988"
            >
              <span onClick={() => handleClick('1045299988')}>
                {data ? data['1045299988'] : 'Learn More'}
              </span>
            </Button>
            <Button
              colorScheme="primary"
              variant="outline"
              className={
                colorMode === 'light'
                  ? 'dynamicStyle fsize14 sm-fsize12 btn-mdsize theme-btn2'
                  : 'dynamicStyle border-1px cust-textColor fsize14 sm-fsize12 btn-mdsize theme-btn2'
              }
              id="4714361447"
            >
              <span onClick={() => handleClick('4714361447')}>
                {data ? data['4714361447'] : 'Learn More'}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection29;
