// import axios from "axios";
import apis from '../../apis/client/Forms/elerarning_Product';

export const getAllElearningCategory = payload => {
  return async dispatch => {
    try {
      let payload = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };

      let { data } = await apis.getAllElearningCategory(payload);

      if (data) dispatch(setAllCategory(data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllCourses = payload => {
  return async dispatch => {
    try {
      let payload = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };

      let { data } = await apis.getAllCourses(payload);

      if (data) dispatch(setAllCourse(data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setAllCategory = data => {
  return {
    type: 'SET_ALL_CATEGORY',
    payload: data,
  };
};

export const setAllCourse = data => {
  return {
    type: 'SET_ALL_COURSE',
    payload: data,
  };
};

export const selectedCourse = course  => {
  return {
    type: 'SET_SELECTED_COURSE',
    payload: course ,
  };
};
