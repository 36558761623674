const initialState = {
  Forms: [],
  FormSchema: [],
  FormInput: [],
  FormSchemaByFormId: [],
};
const Forms = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FORMS':
      return { ...state, Forms: action.payload };

    case 'SET_FORMS_SCHEMA':
      return { ...state, FormSchema: action.payload };

    case 'SET_INPUT_BY_FORM_ID':
      return { ...state, FormInput: action.payload };

    case 'SET_SCHEMA_BY_FORM_ID':
      return { ...state, FormSchemaByFormId: action.payload };

    default:
      return state;
  }
};

export default Forms;
