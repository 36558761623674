import React from 'react';
import {
  Button,
  Image,
  Input,
  InputRightElement,
  InputGroup,
} from '@chakra-ui/react';
function NewsletterSection2() {
  return (
    <div>
      <div class="text-center lg:py-10 md:py-10 py-8 bg-primary">
        <div class="text-center lg:w-3/5 md:w-4/5 w-full mx-auto lg:px-0 md:px-0 px-5">
          <h2 class="text-2xl font-semibold ">Newsletter</h2>

          <p class="fsize16 sm-fsize14 mt-2">
            You are just not another email address for us. You are our heart and
            soul (Community). We will never spam you or leak your data.
          </p>
        </div>

        {/* <div class="relative lg:w-3/5 md:w-3/5 mx-auto mt-8">
          <div class="absolute top-4 lg:left-6 md:left-6 left-4">
            <feather-icon icon="MailIcon" svgClasses="w-5 h-5" />
          </div>
          <Input
            placeholder="Enter Email for exclusive investment deals and offers."
            class="bg-secondary button-h50px lg:pl-16 md:pl-16 pl-12 text-xs"
          ></Input>

          <Button
            variant="primary"
            size="sm"
            class="font-normal lg:absolute lg:mt-0 md:mt-0 mt-2 md:absolute top-0 lg:right-0 right-0 lg:px-10 md:px-8 px-8 lg:py-2 md:py-2 py-3"
          >
            Join Now
          </Button>
        </div> */}
        <div className="lg:w-3/5 md:w-4/5 w-full mx-auto py-6 lg:px-0 md:px-0 px-5">
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              placeholder="Enter Email for exclusive investment deals and offers."
              _placeholder={{ opacity: 1, color: 'white' }}
            />
            <InputRightElement width="6rem">
              <Button h="2.5rem" size="md">
                Join Now
              </Button>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>
    </div>
  );
}

export default NewsletterSection2;
