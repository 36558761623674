import React, { useEffect, useRef, useState } from 'react';
import { Star } from 'react-feather';
import { Image, Card } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Rating from 'react-rating';
import FeatherIcon from 'feather-icons-react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Autoplay, Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';

const Testimonial11 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const dispatch = useDispatch();
  const getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  console.log(getAllTestimonial, 'getAllTestimonial');
  useEffect(() => {
    dispatch(getTestimonal());
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <>
      {getAllTestimonial ? (
        <section className="container mx-auto lg:py-16 md:py-12 lg:px-12 md:px-8 py-8 px-5">
          <div className="lg:w-2/3 md:w-2/3 w-full mx-auto text-center lg:pb-12 md:pb-12 pb-6">
            <p
              id="7593905926"
              className="dynamicStyle text-primary fsize34 md-fsize24 sm-fsize20 cust-textColor pb-2 font-semibold"
            >
              {data ? data['7593905926'] : 'Testimonial'}
            </p>
            <p
              id="0483968157"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['0483968157']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle fsize16 md-fsize16 sm-fsize14 textColor-light"
            ></p>
          </div>
          <div
            ref={elementRef}
            className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
          >
            {/* <div className="swiper-button custswiper image-swiper-button-next">
          <IoIosArrowForward />
        </div>
        <div className="swiper-button custswiper image-swiper-button-prev">
          <IoIosArrowBack />
        </div> */}
            <Swiper
              spaceBetween={5}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                },
                1280: {
                  slidesPerView: 2.5,
                  spaceBetween: 5,
                },
                1024: {
                  slidesPerView: 2.5,
                  spaceBetween: 5,
                },
                991: {
                  slidesPerView: 2.5,
                  spaceBetween: 5,
                },
                820: {
                  slidesPerView: 1.8,
                  spaceBetween: 5,
                },
                768: {
                  slidesPerView: 1.8,
                  spaceBetween: 5,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                325: {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
              }}
            >
              {getAllTestimonial &&
              getAllTestimonial.data &&
              getAllTestimonial.data.result &&
              getAllTestimonial.data.result.length > 0
                ? getAllTestimonial.data.result.map((testimonial, index) => {
                    return (
                      <SwiperSlide className="p-2 ">
                        <Card
                          key={index}
                          className="cust-shadow p-4 rounded-xl theme-radius theme_insertShadow theme-bg"
                        >
                          <div className="cmnt-h lg:pb-4 md:pb-4 pb-2">
                            <p className="fsize16 sm-fsize14 cust-textColor lineClamp3 textColor-light">
                              {testimonial.testimonial}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <div className="flex gap-4">
                              <div>
                                <Image
                                  className="cust-hw-50 rounded-full"
                                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                  src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                                  alt="testimonial-image"
                                />
                              </div>
                              <div>
                                <p className="cust-textColor">
                                  {testimonial.name}
                                </p>
                                <p className="fsize12 textColor-light">
                                  {new Date(
                                    testimonial.createdAt
                                  ).toDateString()}{' '}
                                  • 6 min read
                                </p>
                              </div>
                            </div>
                            <div className="cust-textColor">
                              <Rating
                                initialRating={3}
                                className="flex_rating"
                                readonly={true}
                                emptySymbol={
                                  <FeatherIcon
                                    icon="star"
                                    className="mr-1 textColor-light"
                                    size={18}
                                  />
                                }
                                fullSymbol={
                                  <FeatherIcon
                                    icon="star"
                                    className="mr-1 fillstroke"
                                    size={18}
                                  />
                                }
                                fractions={2}
                              />
                            </div>
                          </div>
                        </Card>
                      </SwiperSlide>
                    );
                  })
                : 'No Data'}
            </Swiper>
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default Testimonial11;
