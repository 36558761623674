import { Image } from '@chakra-ui/react';
import React from 'react';
import { Mail } from 'react-feather';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AboutSection33 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="relative h-800px md-h-700px sm-h550px">
      <div id="4434300980" className="dynamicStyle2">
        <Image
          src={data ? getImage(data['4434300980']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="about-section"
          className="h-800px md-h-700px sm-h550px w-full object-cover"
        />
      </div>
      <div className="h-800px md-h-700px sm-h550px absolute top-0 left-0 w-full flex items-center">
        <div className="lg:w-1/2 md:w-1/2 lg:pl-20 md:pl-12 md:px-0 lg:py-20 md:py-16 py-8">
          <h2
            id="2540904796"
            className="dynamicStyle fsize48 md-fsize36 sm-fsize24 lheight48  sm-lh-24 font-light"
          >
            {data ? data['2540904796'] : 'CONTACT'}
          </h2>
          <h2
            id="0345129371"
            className="dynamicStyle fsize64 md-fsize48 md-lh-48 sm-fsize32 sm-lh-46 font-semibold font-semibold"
          >
            {data ? data['0345129371'] : 'NINTH AVENUE'}
          </h2>
          <p
            className="dynamicStyle fsize14 lg:mt-4 md:mt-3 mt-2 mb-3"
            id="3432837490"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['3432837490']
                : ' publishing and graphic design, Lorem ipsum is a placeholder text',
            }}
          ></p>
          <div
            className="bg-primary inline-block px-6 py-2 rounded-sm dynamicStyle"
            id="4563443883"
          >
          
              <span onClick={() => handleClick('4563443883')} className="flex items-center gap-1 clr-fff">
                <Mail className="h-4" />
                {data ? data['4563443883'] : 'abc@abc.com'}
              </span>
          
          </div>
          <div className="grid grid-cols-2 gap-6 lg:mt-12 md:mt-10 mt-6">
            <div className="">
              <h2
                className="fsize20 mb-2 text-primary dynamicStyle"
                id="8901705131"
              >
                {data ? data['8901705131'] : 'Title'}
              </h2>
              <p
                className="fsize14 dynamicStyle"
                id="1373062413"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['1373062413']
                    : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                }}
              ></p>
            </div>
            <div className="">
              <h2
                className="fsize20 mb-2 text-primary dynamicStyle"
                id="7205738697"
              >
                {data ? data['7205738697'] : 'Title'}
              </h2>
              <p
                className="fsize14 dynamicStyle"
                id="8881344881"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['8881344881']
                    : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                }}
              ></p>
            </div>
            <div className="">
              <h2
                className="fsize20 mb-2 text-primary dynamicStyle"
                id="1197209835"
              >
                {data ? data['1197209835'] : 'Title'}
              </h2>
              <p
                className="fsize14 dynamicStyle"
                id="2049600438"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['2049600438']
                    : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                }}
              ></p>
            </div>
            <div className="">
              <h2
                className="fsize20 mb-2 text-primary dynamicStyle"
                id="8288609570"
              >
                {data ? data['8288609570'] : 'Title'}
              </h2>
              <p
                className="fsize14 dynamicStyle"
                id="4137971000"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['4137971000']
                    : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection33;
