import React, { useEffect, useRef, useState } from 'react';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

const EventCardSection3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6">
      <div ref={elementRef} className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-6 ${isVisible ? 'visiblebottom' : ''} `}>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer  box-shadow-none  themeShadow theme-bg'
              : 'cursor-pointer themeShadow theme-bg'
          }
        >
          <div
            id="1176229560"
            className="dynamicStyle2 w-full overflow-hidden bradius-card-top "
          >
            <Image
              src={data ? getImage(data['1176229560']) : ''}
              className="w-full h-52  custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <p
              className="text-gray cust-textColor mt-2 dynamicStyle fsize14 sm-fsize12"
              id="7322048255"
            >
              {data ? data['7322048255'] : 'Lorem Ipsum'}
            </p>
            <h2
              className="text-dark textColor-light fsize16 font-semibold dynamicStyle sm-fsize14"
              id="5451623460"
              dangerouslySetInnerHTML={{
                __html: data ? data['5451623460'] : 'User Agreement',
              }}
            ></h2>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer  box-shadow-none  themeShadow  theme-bg'
              : 'cursor-pointer themeShadow theme-bg' 
          }
        >
          <div
            id="1844348859"
            className="dynamicStyle2 w-full overflow-hidden bradius-card-top "
          >
            <Image
              src={data ? getImage(data['1844348859']) : ''}
              className="w-full h-52  custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <p
              className="text-gray cust-textColor mt-2 dynamicStyle fsize14 sm-fsize12"
              id="3873946009"
            >
              {data ? data['3873946009'] : 'Lorem Ipsum'}
            </p>
            <h2
              className="text-dark textColor-light fsize16 font-semibold dynamicStyle sm-fsize14"
              id="1090768505"
              dangerouslySetInnerHTML={{
                __html: data ? data['1090768505'] : 'User Agreement',
              }}
            ></h2>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer  box-shadow-none  themeShadow  theme-bg'
              : 'cursor-pointer themeShadow theme-bg' 
          }
        >
          <div
            id="0397121083"
            className="dynamicStyle2 w-full overflow-hidden bradius-card-top "
          >
            <Image
              src={data ? getImage(data['0397121083']) : ''}
              className="w-full h-52  custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <p
              className="text-gray cust-textColor mt-2 dynamicStyle fsize14 sm-fsize12"
              id="7903332446"
            >
              {data ? data['7903332446'] : 'Lorem Ipsum'}
            </p>
            <h2
              className="text-dark textColor-light fsize16 font-semibold dynamicStyle sm-fsize14"
              id="8228016057"
              dangerouslySetInnerHTML={{
                __html: data ? data['8228016057'] : 'User Agreement',
              }}
            ></h2>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer  box-shadow-none  themeShadow  theme-bg'
              : 'cursor-pointer themeShadow theme-bg' 
          }
        >
          <div
            id="7438307643"
            className="dynamicStyle2 w-full overflow-hidden bradius-card-top "
          >
            <Image
              src={data ? getImage(data['7438307643']) : ''}
              className="w-full h-52  custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <p
              className="text-gray cust-textColor mt-2 dynamicStyle fsize14 sm-fsize12"
              id="1005203743"
            >
              {data ? data['1005203743'] : 'Lorem Ipsum'}
            </p>
            <h2
              className="text-dark textColor-light fsize16 font-semibold dynamicStyle sm-fsize14"
              id="7463533382"
              dangerouslySetInnerHTML={{
                __html: data ? data['7463533382'] : 'User Agreement',
              }}
            ></h2>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer  box-shadow-none  themeShadow  theme-bg'
              : 'cursor-pointer themeShadow theme-bg' 
          }
        >
          <div
            id="0172380050"
            className="dynamicStyle2 w-full overflow-hidden bradius-card-top "
          >
            <Image
              src={data ? getImage(data['0172380050']) : ''}
              className="w-full h-52  custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <p
              className="text-gray cust-textColor mt-2 dynamicStyle fsize14 sm-fsize12"
              id="2311879349"
            >
              {data ? data['2311879349'] : 'Lorem Ipsum'}
            </p>
            <h2
              className="text-dark textColor-light fsize16 font-semibold dynamicStyle sm-fsize14"
              id="4947145059"
              dangerouslySetInnerHTML={{
                __html: data ? data['4947145059'] : 'User Agreement',
              }}
            ></h2>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer  box-shadow-none  themeShadow  theme-bg'
              : 'cursor-pointer themeShadow theme-bg' 
          }
        >
          <div
            id="1962499590"
            className="dynamicStyle2 w-full overflow-hidden bradius-card-top "
          >
            <Image
              src={data ? getImage(data['1962499590']) : ''}
              className="w-full h-52  custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <p
              className="text-gray cust-textColor mt-2 dynamicStyle fsize14 sm-fsize12"
              id="9701518923"
            >
              {data ? data['9701518923'] : 'Lorem Ipsum'}
            </p>
            <h2
              className="text-dark textColor-light fsize16 font-semibold dynamicStyle sm-fsize14"
              id="4640543951"
              dangerouslySetInnerHTML={{
                __html: data ? data['4640543951'] : 'User Agreement',
              }}
            ></h2>
          </div>
        </Card>
      </div>
    </section>
  );
};

export default EventCardSection3;
