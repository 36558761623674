import { Button ,Image } from '@chakra-ui/react';
import React from 'react';

import { FaUserCheck } from 'react-icons/fa';

const UserProfile = () => {
  return (
    <section className="container mx-auto lg:py-12 md:py-8 py-4 ">
      <div className="w-full ">
        <Image
          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
          alt="user-background"
          className="lg:rounded-lg md:rounded-lg w-full object-cover h-300px "
        />
      </div>
      <div className="lg:flex md:flex block gap-8 items-end justify-between minus3rem-mtop lg:px-0 md:px-0 px-5">
        <div className="lg:flex md:flex items-end gap-4 lg:ml-8">
          <div className="lg:pt-0 md:pt-4 pt-0">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="profile"
              className="mx-auto object-cover relative z-10 rounded-full border6px-fff h-40 w-10rem"
            />
          </div>
          <div className="lg:mb-4 lg:text-left md:text-left text-center">
            <h2 className="fsize28 font-semibold">MANISH SHARMA</h2>
            <div className="lg:flex justify-center items-center">
              <p className="clr-9c9c9c fsize20 md-fsize18px   font-medium">
                WOODLANDS
              </p>
              <div className="hr1 lg:block md:hidden hidden"></div>
              <div className="lg:flex md:flex items-center lg:gap-0 gap-4">
                <p className="clr-9c9c9c fsize20 md-fsize18px   font-medium">
                  5, 120 REVIEW
                </p>
                <p className="flex items-center justify-center gap-2 lg:ml-8 clr-9c9c9c fsize20 md-fsize18px   font-medium">
                  <FaUserCheck className="clr-ec1c24" /> 10K ACTIVE MEMBERS
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-col justify-center gap-4 lg:mb-4">
          <Button
            width="180px"
            md-width="120px"
            height="50px"
            variant="outline"
            colorScheme="primary"
            className="lg:mt-8 md:mt-0 mt-6 fsize14"
          >
            BOOK APPOINTMENT
          </Button>
          <Button
            width="180px"
            height="50px"
            colorScheme="primary"
            className="lg:mt-8 md:mt-0 mt-6 fsize14"
          >
            CHAT
          </Button>
        </div>
      </div>
    </section>
  );
};

export default UserProfile;
