import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';

const Statistics1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="bg-primary lg:py-10 py-8 relative">
      <div className="absolute top-4 left-0 lg:block hidden mt-12">
        <hr className="border-hr"></hr>
      </div>
      <div className="container mx-auto lg:px-12 md:px-12 px-4">
        <div className="lg:flex items-start w-full">
          <div className="lg:w-4/12 lg:pr-20">
            <h5
              className="fsize35 sm-fsize24 text-white font-semibold dynamicStyle"
              id="6275260856"
            >
              {data ? data['6275260856'] : 'Lorem Ipsum'}
            </h5>
          </div>
          <div className="lg:w-8/12 lg:mt-0 mt-5 grid lg:grid-cols-2 grid-cols-1 gap-10">
            <div
              className="flex gap-3 items-center"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="dynamicStyle2 w-full" id="3424711024">
                <Image
                  src={data ? getImage(data['3424711024']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="impacted"
                  className="lg:w-20 w-12 lg:h-20 h-12"
                />
              </div>
              <div>
                <h6
                  className="fsize30 sm-fsize17 text-white font-semibold dynamicStyle"
                  id="4734919980"
                >
                  {data ? data['4734919980'] : 'Lorem Ipsum'}
                </h6>
                <p
                  className="fsize18 sm-fsize14 text-white dynamicStyle"
                  id="4401976311"
                >
                  {data ? data['4401976311'] : 'Lorem Ipsum'}
                </p>
              </div>
            </div>

            <div
              className="flex gap-3 items-center"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="dynamicStyle2 w-full" id="5671251216">
                <Image
                  src={data ? getImage(data['5671251216']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="impacted"
                  className="lg:w-20 w-12 lg:h-20 h-12"
                />
              </div>
              <div>
                <h6
                  className="fsize30 sm-fsize17 text-white font-semibold dynamicStyle"
                  id="2506377484"
                >
                  {data ? data['2506377484'] : 'Lorem Ipsum'}
                </h6>
                <p
                  className="fsize18 sm-fsize14 text-white dynamicStyle"
                  id="5249490255"
                >
                  {data ? data['5249490255'] : 'Lorem Ipsum'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statistics1;
