import { Button, Card, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import React, { useEffect, useRef, useState } from 'react';
import { handleScroll } from './fadeeffect/FadeEffect';

const Cardtext2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="lg:py-12 py-8 container mx-auto px-4 lg:px-12 md:px-8">
      <div className="text-center lg:w-2/3 md:w-3/4 w-full mx-auto">
        <h2 className="fsize34 md-fsize24 sm-fsize20 uppercase  text-primary cust-textColor font-semibold">
          What We Do
        </h2>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-6 lg:mt-12 md:mt-12 mt-6 ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <Card
          className={
            colorMode === 'light'
              ? 'cust-shadow themeShadow theme-bg rounded-lg lg:p-5 md:p-4 p-6'
              : 'themeShadow theme-bg rounded-lg lg:p-5 md:p-4 p-6'
          }
        >
          <h5
            className="fsize18 sm-fsize16 text-dark cust-textColor text-center font-semibold dynamicStyle"
            id="6714256754"
          >
            {data ? data['6714256754'] : 'Lorem Ipsum'}
          </h5>
          <div className="flex justify-center mt-2">
            <Button
              className={
                colorMode === 'light'
                  ? 'mt-2 bg-primary theme-btn bdr-2px clr-fff sm-fsize13 lg:px-4 px-5 dynamicStyle buttonAnimate bdr-2px'
                  : 'mt-2 bdr-2px theme-btn sm-fsize13 lg:px-4 px-5 dynamicStyle  bdr-2px'
              }
              size="md"
              id="8399951329"
            >
              {data ? data['8399951329'] : 'Lorem Ipsum'}
            </Button>
          </div>
        </Card>

        <Card
          className={
            colorMode === 'light'
              ? 'cust-shadow themeShadow theme-bg rounded-lg lg:p-5 md:p-4 p-6'
              : 'themeShadow theme-bg rounded-lg lg:p-5 md:p-4 p-6'
          }
        >
          <h5
            className="fsize18 sm-fsize16 text-dark cust-textColor text-center font-semibold dynamicStyle"
            id="1321629097"
          >
            {data ? data['1321629097'] : 'Lorem Ipsum'}
          </h5>
          <div className="flex justify-center mt-2">
            <Button
              className={
                colorMode === 'light'
                  ? 'mt-2 bg-primary theme-btn bdr-2px clr-fff sm-fsize13 lg:px-4 px-5 dynamicStyle buttonAnimate bdr-2px'
                  : 'mt-2 bdr-2px theme-btn sm-fsize13 lg:px-4 px-5 dynamicStyle  bdr-2px'
              }
              size="md"
              id="3715346425"
            >
              {data ? data['3715346425'] : 'Lorem Ipsum'}
            </Button>
          </div>
        </Card>

        <Card
          className={
            colorMode === 'light'
              ? 'cust-shadow themeShadow theme-bg rounded-lg lg:p-5 md:p-4 p-6'
              : 'themeShadow theme-bg rounded-lg lg:p-5 md:p-4 p-6'
          }
        >
          <h5
            className="fsize18 sm-fsize16 text-dark cust-textColor text-center font-semibold dynamicStyle"
            id="0974332954"
          >
            {data ? data['0974332954'] : 'Lorem Ipsum'}
          </h5>
          <div className="flex justify-center mt-2">
            <Button
              className={
                colorMode === 'light'
                  ? 'mt-2 bg-primary theme-btn bdr-2px clr-fff sm-fsize13 lg:px-4 px-5 dynamicStyle buttonAnimate bdr-2px'
                  : 'mt-2 bdr-2px theme-btn sm-fsize13 lg:px-4 px-5 dynamicStyle  bdr-2px'
              }
              size="md"
              id="3335115058"
            >
              {data ? data['3335115058'] : 'Lorem Ipsum'}
            </Button>
          </div>
        </Card>
      </div>
    </section>
  );
};

export default Cardtext2;
