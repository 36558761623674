import apis from "../../apis/client/chat";
import { store } from "../../redux/store";

//message
// export const getMessage = (payload) => {
//   let record;
//   return async (dispatch) => {
//     try {
//       let { data } = await apis.getMessage(payload);

//       if (data) {
//         await dispatch(setMessage(data.data.messages));
//         record = data;
//       }
//     } catch (error) {
//       console.log(error);
//     }
//     return record;
//   };
// };
export const getMessage = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.getMessage(payload);

      if (data) {
        // await dispatch(setMessage(data.data));
        record = data;
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};
export const sendMessage = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.sendMessage(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const upload = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.upload(payload);

      if (data) {
        console.log(data, "upload");
        record = data;
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

//channel
export const getChannels = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.getChannels(payload);
      // dispatch(setPeople(data.data));

      record = data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};
export const createChannel = (payload) => {
  let record;
  return async (dispatch) => {
    // try {
    let { data } = await apis.createChannel(payload);

    if (data) {
      record = data;
    }
    // } catch (error) {
    //   console.log(error);
    //   return error.response.data;
    // }
    return record;
  };
};
export const deleteChannel = (payload) => {
  let record;
  return async (dispatch) => {
    // try {
    let { data } = await apis.deleteChannel(payload);

    if (data?.data && data?.code === 200) {
      dispatch(setDeletedContact(data.data));
    }

    if (data) {
      record = data;
    }
    // } catch (error) {
    //   console.log(error);
    //   return error.response.data;
    // }
    return record;
  };
};
export const addToGroupChannel = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.addToGroupChannel(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};
export const createGroupChannel = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.createGroupChannel(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};
export const removeFromGroupChannel = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.removeFromGroupChannel(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};
export const renameGroupChannel = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.renameGroupChannel(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const getPeople = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.getPeople(payload);
      // dispatch(setPeople(data.data.peoples));

      record = data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

// export const connectToWS = () => {
//   console.log("WS");
//   let ws;

//   try {
//     const ws = new WebSocket(
//       process.env.REACT_APP_WS_BASE_URL,
//       store.getState().AuthDetails.user.token
//     );
//     console.log("WS", ws);
//     // dispatch(setWS(ws));
//     // setWS(ws)
//     ws.onopen = function (e) {
//       console.log("WS CONNECTED");
//     };

//     ws.onmessage = function (e) {
//       try {
//         console.log("on message");
//         const eventData = JSON.parse(e.data);
//         console.log("eventData", eventData);
//         // if (eventData.type === "sendMessage") {
//         //   handleMessage(eventData.data);
//         // }
//       } catch (error) {
//         console.log(error);
//       }
//     };

//     ws.onclose = function (e) {
//       setTimeout(() => {
//         console.log("WS Disconnected. Trying to reconnect.");
//         connectToWS();
//       }, 1000);
//     };

//     // dispatch(setWS(ws));
//   } catch (error) {
//     console.log(error);
//   }
//   return ws;
// };

// export const disconnectToWS = () => {
//   return async (dispatch) => {
//     try {
//       let ws = store.getState().chat.WS;
//       if (ws) {
//         ws.close();
//         dispatch(setWS(null));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };

// export const setMessage = (data) => {
//   return {
//     type: "setMessage",
//     payload: data,
//   };
// };
// export const setPeople = (data) => {
//   return {
//     type: "setPeople",
//     payload: data,
//   };
// };
export const setWS = (data) => {
  return {
    type: "setWS",
    payload: data,
  };
};

export const setDeletedContact = (data) => {
  return {
    type: "setDeletedContact",
    payload: data,
  };
};
export const setContactLoadMore = (data) => {
  return {
    type: "setContactLoadMore",
    payload: data,
  };
};
export const setMessageLoadMore = (data) => {
  return {
    type: "setMessageLoadMore",
    payload: data,
  };
};
