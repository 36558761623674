import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto mx-auto md:px-8 lg:px-12 px-5 lg:py-16 md:py-16 py-8">
      <div className="w-full lg:flex  gap-8">
        <div className="lg:w-1/2  w-full">
          <div
            ref={elementRef}
            className={`fade-left w-full h-full ${
              isVisible ? 'visibleleft' : ''
            } `}
          >
            <div id="7347724826" className="dynamicStyle2">
              <Image
                src={data ? getImage(data['7347724826']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="about"
                className="object-cover w-full h-full md-h-350px br-28px border-top12px"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 lg:mb-0 md:mt-6 mt-6 w-full">
          <h2
            id="4101440900"
            className={
              colorMode === 'light'
                ? 'dynamicStyle lg:pt-0 md:pt-0 pt-4 uppercase fsize16 md-fsize16 sm-fsize14 font-medium text-primary '
                : 'dynamicStyle lg:pt-0 md:pt-0 pt-4 uppercase fsize16 md-fsize16 sm-fsize14 font-medium text-white'
            }
          >
            {data ? data['4101440900'] : 'Lorem Ipsum'}
          </h2>
          <h1
            id="4160252202"
            className="dynamicStyle cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold lg:leading-12 md:leading-9 lg:mb-2 md:mb-2 mb-2  sm-lh-32px"
          >
            {data ? data['4160252202'] : 'Lorem Ipsum'}
          </h1>
          <p
            id="8330960685"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['8330960685']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle textColor-light fsize16 sm-fsize14 text-gray lg:leading-7 md:leading-7 leading-6"
          ></p>

          <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-4  mt-6">
            <Card
              className={
                colorMode === 'light'
                  ? 'cust-shadow py-6 px-5 themeShadow theme-bg'
                  : 'cust-shadow py-6 px-5 themeShadow theme-bg'
              }
            >
              <h2
                id="3124594170"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data['3124594170'] : 'When We Started'}
              </h2>
              <p
                id="9329055318"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['9329055318']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle cust-textColor fsize14 text-gray cust-textColor textColor-light"
              ></p>
            </Card>
            <Card
              className={
                colorMode === 'light'
                  ? 'cust-shadow py-6 px-5 themeShadow theme-bg'
                  : 'cust-shadow py-6 px-5 themeShadow theme-bg'
              }
            >
              <h2
                id="9613322167"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data['9613322167'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="0112669977"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['0112669977']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle fsize14 text-gray cust-textColor textColor-light"
              ></p>
            </Card>
            <Card
              className={
                colorMode === 'light'
                  ? 'cust-shadow py-6 px-5 themeShadow theme-bg'
                  : 'cust-shadow py-6 px-5 themeShadow theme-bg'
              }
            >
              <h2
                id="3145495928"
                className="dynamicStyle fsize18 font-semibold cust-textColor"
              >
                {data ? data['3145495928'] : 'Lorem Ipsum'}
              </h2>
              <p
                id="3975286171"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['3975286171']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle fsize14 text-gray textColor-light"
              ></p>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection4;
