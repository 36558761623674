import React, { useEffect, useRef, useState } from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection10 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="lg:relative lg:h-100vh">
      <div id="6329253597" className="dynamicStyle2 cus-hbanner w-full">
        <Image
          src={data ? getImage(data['6329253597']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          className="h-100vh lg:block hidden w-full"
          alt="nurtings"
        />
      </div>

      <div className="lg:absolute top-0 left-0 w-full lg:h-100vh flex items-center">
        <div className="container mx-auto lg:px-12 md:px-8 lg:py-20 px-5 py-8">
          <div className="flex cust-reverse items-center">
            <div
              className="lg:w-6/12 md:w-6/12 w-full lg:mt-0 mt-4 lg:pr-10"
             
            >
              <small
                className="fsize16 sm-fsize14 text-primary cust-textColor font-medium dynamicStyle"
                id="5997002658"
              >
                {data ? data['5997002658'] : 'Lorem Ipsum'}
              </small>
              <h2
                className="fsize34 md-fsize24 sm-fsize20 font-semibold  cust-textColor dynamicStyle"
                id="0288510504"
              >
                {data ? data['0288510504'] : 'Lorem Ipsum'}
              </h2>
              <p
                className="fsize16 sm-fsize14 textColor-light col-79 mt-2 dynamicStyle"
                id="0473913753"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['0473913753']
                    : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                }}
              ></p>
            </div>
            <div className="lg:w-6/12 md:w-6/12 w-full w-full">
              <div  ref={elementRef} className={`fade-right cus-hbanner w-full ${isVisible ? 'visibleright' : ''} `}>
              <div id="2181540252" className='dynamicStyle2'>
                <Image
                  src={data ? getImage(data['2181540252']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  className="map-img object-cover"
                  alt="map"
                  style={{ transform: transform }}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection10;
