import { Button, Image, Card, useColorMode } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';

const SubscriptionPlan5 = ({ pageName }) => {
  const [check, setcheck] = useState(1);
  const data = useSelector(state => state.cms.allSections[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  return (
    <div
      className={
        colorMode === 'light'
          ? 'bg-f4f5f6 lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
          : 'lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
      }
    >
      <div className="container mx-auto">
        <div className="lg:w-8/12 md:w-2/3 w-full mx-auto text-center lg:mb-10 md:mb-12 mb-8">
          <h2
            id="6541741105"
            className="dynamicStyle fsize36 md-fsize28 sm-fsize24 font-semibold mb-2 cust-textColor text-primary"
          >
            {data ? data['6541741105'] : 'Flagship Programs'}
          </h2>
          <p
            id="9878926808"
            className="dynamicStyle fsize16 sm-fsize14 textColor-light"
          >
            {data ? data['9878926808'] : 'Flagship Programs'}
          </p>
        </div>
        <div className="flex justify-center">
          <div className="bg-fa cust-bgColor rounded-full p-2 flex items-center">
            <div
              className={
                check === 1
                  ? ' cursor-pointer tabchecked px-4 py-2 rounded-full'
                  : 'fsize14 textColor-light cursor-pointer tabnone px-4 py-2 rounded-full'
              }
              onClick={() => setcheck(1)}
            >
              <p id="7505131302" className="dynamicStyle fsize14">
                {data ? data['7505131302'] : 'Flagship Programs'}
              </p>
            </div>

            <div
              className={
                check === 2
                  ? ' cursor-pointer tabchecked px-4 py-2 rounded-full'
                  : 'fsize14 textColor-light cursor-pointer tabnone px-4 py-2 rounded-full'
              }
              onClick={() => setcheck(2)}
            >
              <p id="6756749477" className="dynamicStyle fsize14">
                {data ? data['6756749477'] : 'Flagship Programs'}
              </p>
            </div>
          </div>
        </div>
        {check === 1 ? (
          <>
            <div className="w-full flex justify-center lg:mt-12 md:mt-12 mt-6">
              <Card 
              className={
                colorMode === 'light'
                  ? 'lg:w-7/12 flex gap-3 bg-white lg:p-5 md:p-5 p-3 rounded-lg mx-auto themeShadow theme-bg'
                  : 'lg:w-7/12 flex gap-3 bg-white lg:p-5 md:p-5 p-3 rounded-lg mx-auto themeShadow theme-bg'
              }
              >
                <div className="lg:flex md:flex grid grid-cols-1 gap-4">
                  <div className="lg:w-1/2">
                    <div className={
                      colorMode === 'light'
                        ? 'lg:p-8 md:p-6 p-4 rounded-lg bghoverplan'
                        : 'lg:p-8 md:p-6 p-4 rounded-lg bghoverplanDark'
                    }>
                      <div className="flex items-center gap-2 lg:mb-3 md:mb-3 mb-2">
                        <div className="dynamicStyle2" id="4134772569">
                          <Image
                            src={data ? getImage(data['4134772569']) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img12"
                            className="w-6 h-6"
                          />
                        </div>
                        <h4 className="fsize22 md-fsize20 sm-fsize18 font-semibold plancolor cust-textColor">
                          Stater Plan
                        </h4>
                      </div>
                      <p className="fsize16 plancolor textColor-light">
                        What you'll Get
                      </p>
                      <div
                      className={
                        colorMode === 'light'
                          ? 'border-plan pb-5 pt-2'
                          : 'border-plan-dark pb-5 pt-2'
                      }
                      >
                        <div className="flex items-start gap-2 lg:mt-4 md:mt-3 mt-2">
                          <div
                          className={
                            colorMode === 'light'
                              ? 'bgcheck flex items-center justify-center w-1/12'
                              : 'bgcheckDark flex items-center justify-center w-1/12'
                          }
                          >
                            <FeatherIcon icon="check" size="12" />
                          </div>
                          <p className="sm-fsize13 fsize14 plancolor textColor-light w-11/12">
                            Edit up to 10 hours of podcast audio files
                          </p>
                        </div>
                        <div className="flex items-start gap-2 lg:mt-3 md:mt-3 mt-2">
                          <div className={
                            colorMode === 'light'
                              ? 'bgcheck flex items-center justify-center w-1/12'
                              : 'bgcheckDark flex items-center justify-center w-1/12'
                          }>
                            <FeatherIcon icon="check" size="12" />
                          </div>
                          <p className="sm-fsize13 fsize14 plancolor textColor-light w-11/12">
                            Set up own core loop page
                          </p>
                        </div>
                      </div>
                      <h2 className="fsize26 md-fsize28 sm-fsize18 font-semibold plancolor lg:mt-12 md:mt-6 mt-4 cust-textColor">
                        Free
                      </h2>
                      <Button
                        id="6714857322"
                        borderRadius="6"
                        className={
                          colorMode === 'light'
                            ? 'theme-btn2 lg:py-3 md:py-3 py-2 clr-fff bg-primary bg-darkprimary fsize14 sm-fsize14 w-full lg:mt-2 md:mt-2 mt-3 dynamicStyle'
                            : 'theme-btn2 lg:py-3 md:py-3 py-2 clr-fff bg-primary bg-darkprimary fsize14 sm-fsize14 buttonAnimate w-full lg:mt-2 md:mt-2 mt-3 dynamicStyle'
                        }
                      >
                        <span onClick={() => handleClick('6714857322')}>
                          {data ? data['6714857322'] : 'Lorem ipsum'}
                        </span>
                      </Button>
                    </div>
                  </div>
                  <div className="lg:w-1/2">
                    <div className={
                      colorMode === 'light'
                        ? 'lg:p-8 md:p-6 p-4 rounded-lg bghoverplan'
                        : 'lg:p-8 md:p-6 p-4 rounded-lg bghoverplanDark'
                    }>
                      <div className="flex items-center gap-2 lg:mb-3 md:mb-3 mb-2">
                        <div className="dynamicStyle2" id="4465460138">
                          <Image
                            src={data ? getImage(data['4465460138']) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img12"
                            className="w-6 h-6"
                          />
                        </div>
                        <h4 className="fsize22 md-fsize20 sm-fsize18 font-semibold plancolor cust-textColor">
                          Professional Plan
                        </h4>
                      </div>
                      <p className="fsize16 plancolor textColor-light">
                        What you'll Get
                      </p>
                      <div  className={
                        colorMode === 'light'
                          ? 'border-plan pb-5 pt-2'
                          : 'border-plan-dark pb-5 pt-2'
                      }>
                        <div className="flex items-start gap-2 lg:mt-4 md:mt-3 mt-2">
                          <div className={
                            colorMode === 'light'
                              ? 'bgcheck flex items-center justify-center w-1/12'
                              : 'bgcheckDark flex items-center justify-center w-1/12'
                          }>
                            <FeatherIcon icon="check" size="12" />
                          </div>
                          <p className="sm-fsize13 fsize14 plancolor textColor-light w-11/12">
                            Edit up to 10 hours of podcast audio files
                          </p>
                        </div>
                        <div className="flex items-start gap-2 lg:mt-3 md:mt-3 mt-2">
                          <div className={
                            colorMode === 'light'
                              ? 'bgcheck flex items-center justify-center w-1/12'
                              : 'bgcheckDark flex items-center justify-center w-1/12'
                          }>
                            <FeatherIcon icon="check" size="12" />
                          </div>
                          <p className="sm-fsize13 fsize14 plancolor textColor-light w-11/12">
                            Set up own core loop page
                          </p>
                        </div>
                      </div>
                      <h2 className="fsize26 md-fsize28 sm-fsize18 font-semibold plancolor lg:mt-12 md:mt-6 mt-4 cust-textColor">
                        4800<sub className="font-medium fsize13">/month</sub>
                      </h2>
                      <Button
                        id="2198009234"
                        borderRadius="6"
                        className={
                          colorMode === 'light'
                            ? 'theme-btn2 lg:py-3 md:py-3 py-2 clr-fff bg-primary bg-darkprimary fsize14 sm-fsize14 w-full lg:mt-2 md:mt-2 mt-3 dynamicStyle'
                            : 'theme-btn2 lg:py-3 md:py-3 py-2 clr-fff bg-primary bg-darkprimary fsize14 sm-fsize14 buttonAnimate w-full lg:mt-2 md:mt-2 mt-3 dynamicStyle'
                        }
                      >
                        <span onClick={() => handleClick('2198009234')}>
                          {data ? data['2198009234'] : 'Lorem ipsum'}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </>
        ) : null}
        {check === 2 ? (
          <>
            <div className="w-full flex justify-center lg:mt-12 md:mt-12 mt-6">
              <Card className={
                colorMode === 'light'
                  ? 'lg:w-7/12 flex gap-3 bg-white lg:p-5 md:p-5 p-3 rounded-lg mx-auto themeShadow theme-bg'
                  : 'lg:w-7/12 flex gap-3 bg-white lg:p-5 md:p-5 p-3 rounded-lg mx-auto themeShadow theme-bg'
              }>
                <div className="lg:flex md:flex grid grid-cols-1 gap-4">
                  <div className="lg:w-1/2">
                    <div className={
                      colorMode === 'light'
                        ? 'lg:p-8 md:p-6 p-4 rounded-lg bghoverplan'
                        : 'lg:p-8 md:p-6 p-4 rounded-lg bghoverplanDark'
                    }>
                      <div className="flex items-center gap-2 lg:mb-3 md:mb-3 mb-2">
                        <div className="dynamicStyle2" id="5209568984">
                          <Image
                            src={data ? getImage(data['5209568984']) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img12"
                            className="w-6 h-6"
                          />
                        </div>
                        <h4 className="fsize22 md-fsize20 sm-fsize18 font-semibold plancolor cust-textColor">
                          Stater Plan
                        </h4>
                      </div>
                      <p className="fsize16 plancolor textColor-light">
                        What you'll Get
                      </p>
                      <div  className={
                        colorMode === 'light'
                          ? 'border-plan pb-5 pt-2'
                          : 'border-plan-dark pb-5 pt-2'
                      }>
                        <div className="flex items-start gap-2 lg:mt-4 md:mt-3 mt-2">
                          <div className={
                            colorMode === 'light'
                              ? 'bgcheck flex items-center justify-center w-1/12'
                              : 'bgcheckDark flex items-center justify-center w-1/12'
                          }>
                            <FeatherIcon icon="check" size="12" />
                          </div>
                          <p className="sm-fsize13 fsize14 plancolor textColor-light w-11/12">
                            Edit up to 10 hours of podcast audio files
                          </p>
                        </div>
                        <div className="flex items-start gap-2 lg:mt-3 md:mt-3 mt-2">
                          <div className={
                            colorMode === 'light'
                              ? 'bgcheck flex items-center justify-center w-1/12'
                              : 'bgcheckDark flex items-center justify-center w-1/12'
                          }>
                            <FeatherIcon icon="check" size="12" />
                          </div>
                          <p className="sm-fsize13 fsize14 plancolor textColor-light w-11/12">
                            Set up own core loop page
                          </p>
                        </div>
                      </div>
                      <h2 className="fsize26 md-fsize28 sm-fsize18 font-semibold plancolor lg:mt-12 md:mt-6 mt-4 cust-textColor">
                        Free
                      </h2>
                      <Button
                        id="0173421189"
                        borderRadius="6"
                        className={
                          colorMode === 'light'
                            ? 'theme-btn2 lg:py-3 md:py-3 py-2 clr-fff bg-primary bg-darkprimary fsize14 sm-fsize14 w-full lg:mt-2 md:mt-2 mt-3 dynamicStyle'
                            : 'theme-btn2 lg:py-3 md:py-3 py-2 clr-fff bg-primary bg-darkprimary fsize14 sm-fsize14 buttonAnimate w-full lg:mt-2 md:mt-2 mt-3 dynamicStyle'
                        }
                      >
                        <span onClick={() => handleClick('0173421189')}>
                          Get Started
                        </span>
                      </Button>
                    </div>
                  </div>
                  <div className="lg:w-1/2">
                    <div 
                    className={
                      colorMode === 'light'
                        ? 'lg:p-8 md:p-6 p-4 rounded-lg bghoverplan'
                        : 'lg:p-8 md:p-6 p-4 rounded-lg bghoverplanDark'
                    }
                    >
                      <div className="flex items-center gap-2 lg:mb-3 md:mb-3 mb-2">
                        <div className="dynamicStyle2" id="5209568984">
                          <Image
                            src={data ? getImage(data['5209568984']) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="img12"
                            className="w-6 h-6"
                          />
                        </div>
                        <h4 className="fsize22 md-fsize20 sm-fsize18 font-semibold plancolor cust-textColor">
                          Professional Plan
                        </h4>
                      </div>
                      <p className="fsize16 plancolor textColor-light">
                        What you'll Get
                      </p>
                      <div  className={
                        colorMode === 'light'
                          ? 'border-plan pb-5 pt-2'
                          : 'border-plan-dark pb-5 pt-2'
                      }>
                        <div className="flex items-start gap-2 lg:mt-4 md:mt-3 mt-2">
                          <div className={
                            colorMode === 'light'
                              ? 'bgcheck flex items-center justify-center w-1/12'
                              : 'bgcheckDark flex items-center justify-center w-1/12'
                          }>
                            <FeatherIcon icon="check" size="12" />
                          </div>
                          <p className="sm-fsize13 fsize14 plancolor textColor-light w-11/12">
                            Edit up to 10 hours of podcast audio files
                          </p>
                        </div>
                        <div className="flex items-start gap-2 lg:mt-3 md:mt-3 mt-2">
                          <div className={
                            colorMode === 'light'
                              ? 'bgcheck flex items-center justify-center w-1/12'
                              : 'bgcheckDark flex items-center justify-center w-1/12'
                          }>
                            <FeatherIcon icon="check" size="12" />
                          </div>
                          <p className="sm-fsize13 fsize14 plancolor textColor-light w-11/12">
                            Set up own core loop page
                          </p>
                        </div>
                      </div>
                      <h2 className="fsize26 md-fsize28 sm-fsize18 font-semibold plancolor lg:mt-12 md:mt-6 mt-4 cust-textColor">
                        4800<sub className="font-medium fsize13">/month</sub>
                      </h2>
                      <Button
                        id="0173421189"
                        borderRadius="6"
                        className={
                          colorMode === 'light'
                            ? 'theme-btn2 lg:py-3 md:py-3 py-2 clr-fff bg-primary bg-darkprimary fsize14 sm-fsize14 w-full lg:mt-2 md:mt-2 mt-3 dynamicStyle'
                            : 'theme-btn2 lg:py-3 md:py-3 py-2 clr-fff bg-primary bg-darkprimary fsize14 sm-fsize14 buttonAnimate w-full lg:mt-2 md:mt-2 mt-3 dynamicStyle'
                        }
                      >
                        <span onClick={() => handleClick('0173421189')}>
                          Get Started
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default SubscriptionPlan5;
