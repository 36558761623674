import React, { useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Image } from '@chakra-ui/react';
import RejectedFiles from './RejectedFiles';
import ApprovalFiles from './ApprovalFiles';
import NearbyDueDate from './NearbyDueDate';
import ApprovalResults from './ApprovalResults';

const Filetabs = () => {
  const [check, SetCheck] = useState('01');
  const menuOption = [
    {
      id: '01',
      name: 'Pending Approvals',
      description: 'Microsoft word file',
    },
    {
      id: '02',
      name: 'Nearing Due Date',
      description: 'Microsoft word file',
    },
    {
      id: '03',
      name: 'Approved Files',
      description: 'Microsoft word file',
    },
    {
      id: '04',
      name: 'Rejected Files',
      description: 'Microsoft word file',
    },
  ];
  return (
    <div className="lg:py-10 md:py-10 py-8">
      <div className="container mx-auto lg:px-12 md:px-8 px-5">
        <Swiper
          spaceBetween={20}
          className="mySwiper p-2"
          breakpoints={{
            1536: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
          }}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
        >
          <div className="swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <>
            {menuOption.map(e => {
              return (
                <SwiperSlide>
                  <div
                    className={
                      check === e.id
                        ? 'bg-white cust-shadow p-4 rounded-md cursor-pointer'
                        : 'border p-4 rounded-md cursor-pointer'
                    }
                    onClick={() => SetCheck(e.id)}
                  >
                    <Image
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="icon"
                      className="w-24 h-24 mx-auto"
                    />
                    <h4 className="fsize17 text-primary mt-2 text-center font-semibold">
                      {e.name}
                    </h4>
                    <p className="fsize13 text-gray text-center">
                      {e.description}
                    </p>
                  </div>
                </SwiperSlide>
              );
            })}
          </>
        </Swiper>

        <div className="mt-10">
          {check === '01' ? (
            <div className="">
              <ApprovalResults />
            </div>
          ) : (
            ''
          )}
          {check === '02' ? (
            <div className="">
              <NearbyDueDate />
            </div>
          ) : (
            ''
          )}
          {check === '03' ? (
            <div className="">
              <ApprovalFiles />
            </div>
          ) : (
            ''
          )}
          {check === '04' ? (
            <div className="">
              <RejectedFiles />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Filetabs;
