import React from 'react';
import FeatherIcon from 'feather-icons-react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Image,
  Card,
  useColorMode,
} from '@chakra-ui/react';
import { FaAlignRight } from 'react-icons/fa';

const UserCard = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className="container mx-auto lg:py-20 md:py-12 py-8 lg:px-12 md:px-8 px-5">
      <div className="w-full mb-20 flex justify-between">
        <div className="lg:w-1/2  w-2/3">
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Search"
              className="w-full border cust-bgColor h-12 rounded-sm lg:px-6 md:px-4 px-2"
            />
            <FeatherIcon
              icon="search"
              className="absolute right-4 top-2 mt-1 cust-textColor"
            />
          </div>
        </div>
        <Menu>
          <MenuButton>
            {/* <FeatherIcon icon="align-right" /> */}
            <FaAlignRight className="fsize22 cust-textColor" />
          </MenuButton>
          <MenuList>
            <MenuItem>1</MenuItem>
            <MenuItem>2</MenuItem>
            <MenuItem>3</MenuItem>
          </MenuList>
        </Menu>
      </div>

      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-16 gap-20">
        <Card
          className={
            colorMode === 'light'
              ? 'relative bg-f4f5f6 rounded-lg p-4 text-center box-shadow-none'
              : 'relative rounded-lg p-4 text-center'
          }
        >
          <div className="absolute w-full flex justify-center left-0 topminus35">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="profile-img"
              className="w-28 h-28 rounded-full  border3px-fff"
            />
          </div>
          <div className="pt-16">
            <h2 className="fsize20 font-semibold cust-textColor">
              MANISH SHARMA
            </h2>
            <div className="flex justify-center items-center">
              <p className="clr-9c9c9c fsize14 font-medium textColor-light">
                SINGAPORE, SG.01
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize14 font-medium textColor-light">
                5, 120 REVIEW
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 pt-4">
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM Trainer</p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM Trainer</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 pt-2">
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM </p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM </p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM </p>
              </div>
            </div>
            <div className="flex justify-center items-center mt-4">
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                MENTOR
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                TEACHEAR
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                LIFELOG TEACHER
              </p>
            </div>
            <div className="flex justify-center items-center gap-4 mt-6 w-full">
              <Button
                variant="outline"
                colorScheme="primary"
                className="w-1/3 rounded-lg  py-2 fsize14 font-semibold cust-textColor"
              >
                CHAT
              </Button>
              <Button
                colorScheme="primary"
                className="w-2/3 rounded-lg border py-2 fsize14 cust-textColor   font-semibold"
              >
                VIEW PROFILE
              </Button>
            </div>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'relative bg-f4f5f6 rounded-lg p-4 text-center box-shadow-none'
              : 'relative rounded-lg p-4 text-center'
          }
        >
          <div className="absolute w-full flex justify-center left-0 topminus35">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="profile-img"
              className="w-28 h-28 rounded-full  border3px-fff"
            />
          </div>
          <div className="pt-16">
            <h2 className="fsize20 font-semibold cust-textColor">
              MANISH SHARMA
            </h2>
            <div className="flex justify-center items-center">
              <p className="clr-9c9c9c fsize14 font-medium textColor-light">
                SINGAPORE, SG.01
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize14 font-medium textColor-light">
                5, 120 REVIEW
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 pt-4">
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM Trainer</p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM Trainer</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 pt-2">
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM </p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM </p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM </p>
              </div>
            </div>
            <div className="flex justify-center items-center mt-4">
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                MENTOR
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                TEACHEAR
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                LIFELOG TEACHER
              </p>
            </div>
            <div className="flex justify-center items-center gap-4 mt-6 w-full">
              <Button
                variant="outline"
                colorScheme="primary"
                className="w-1/3 rounded-lg  py-2 fsize14 font-semibold cust-textColor"
              >
                CHAT
              </Button>
              <Button
                colorScheme="primary"
                className="w-2/3 rounded-lg border py-2 fsize14 cust-textColor   font-semibold"
              >
                VIEW PROFILE
              </Button>
            </div>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'relative bg-f4f5f6 rounded-lg p-4 text-center box-shadow-none'
              : 'relative rounded-lg p-4 text-center'
          }
        >
          <div className="absolute w-full flex justify-center left-0 topminus35">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="profile-img"
              className="w-28 h-28 rounded-full  border3px-fff"
            />
          </div>
          <div className="pt-16">
            <h2 className="fsize20 font-semibold cust-textColor">
              MANISH SHARMA
            </h2>
            <div className="flex justify-center items-center">
              <p className="clr-9c9c9c fsize14 font-medium textColor-light">
                SINGAPORE, SG.01
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize14 font-medium textColor-light">
                5, 120 REVIEW
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 pt-4">
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM Trainer</p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM Trainer</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 pt-2">
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM </p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM </p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM </p>
              </div>
            </div>
            <div className="flex justify-center items-center mt-4">
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                MENTOR
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                TEACHEAR
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                LIFELOG TEACHER
              </p>
            </div>
            <div className="flex justify-center items-center gap-4 mt-6 w-full">
              <Button
                variant="outline"
                colorScheme="primary"
                className="w-1/3 rounded-lg py-2 fsize14 font-semibold cust-textColor"
              >
                CHAT
              </Button>
              <Button
                colorScheme="primary"
                className="w-2/3 rounded-lg border py-2 fsize14 cust-textColor font-semibold"
              >
                VIEW PROFILE
              </Button>
            </div>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'relative bg-f4f5f6 rounded-lg p-4 text-center box-shadow-none'
              : 'relative rounded-lg p-4 text-center'
          }
        >
          <div className="absolute w-full flex justify-center left-0 topminus35">
            <Image
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="profile-img"
              className="w-28 h-28 rounded-full  border3px-fff"
            />
          </div>
          <div className="pt-16">
            <h2 className="fsize20 font-semibold cust-textColor">
              MANISH SHARMA
            </h2>
            <div className="flex justify-center items-center">
              <p className="clr-9c9c9c fsize14 font-semibold textColor-light">
                SINGAPORE, SG.01
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize14 font-semibold textColor-light">
                5, 120 REVIEW
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 pt-4">
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM Trainer</p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM Trainer</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 pt-2">
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM </p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor">GYM </p>
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'bg-white rounded-sm p-2'
                    : 'rounded-sm p-2 border'
                }
              >
                <p className="fsize12 font-semibold cust-textColor cust-textColor">GYM </p>
              </div>
            </div>
            <div className="flex justify-center items-center mt-4">
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                MENTOR
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                TEACHEAR
              </p>
              <div className="hr1"></div>
              <p className="clr-9c9c9c fsize10 font-semibold textColor-light">
                LIFELOG TEACHER
              </p>
            </div>
            <div className="flex justify-center items-center gap-4 mt-6 w-full">
              <Button
                variant="outline"
                colorScheme="primary"
                className="w-1/3 rounded-lg py-2 fsize14 font-semibold cust-textColor"
              >
                CHAT
              </Button>
              <Button
                colorScheme="primary"
                className="w-2/3 rounded-lg border py-2 fsize14 cust-textColor font-semibold"
              >
                VIEW PROFILE
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default UserCard;
