import React, { useEffect, useRef, useState } from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection9 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="">
      <div className="container mx-auto px-4 lg:px-12 md:px-8 px-5 py-8 lg:py-20">
        <div className="flex cust-reverse items-center">
          <div
            className="lg:w-6/12 md:w-6/12 lg:mt-0 mt-4 lg:pr-10"
          >
            <h2
              className="fsize34 md-fsize24 sm-fsize20 font-semibold textprimary cust-textColor dynamicStyle"
              id="3725827689"
            >
              {data ? data['3725827689'] : 'Lorem Ipsum'}
            </h2>
            <p
              className="fsize16 sm-fsize14 textColor-light mt-2 dynamicStyle"
              id="0414522803"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['0414522803']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
            ></p>
          </div>
          <div className="lg:w-6/12 md:w-6/12 w-full lg:pl-10">
            <div  ref={elementRef} className={`fade-right cus-hbanner w-full ${isVisible ? 'visibleright' : ''} `}>
             <div id="6162638972" className='dynamicStyle2'>
              <Image
                src={data ? getImage(data['6162638972']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="creating"
                className="creating-img rounded-lg object-cover"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection9;
