import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection18 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section
      className={
        colorMode === 'light'
          ? 'relative h-400px sm-h-250px bg-primary theme-bg'
          : 'relative h-400px sm-h-250px'
      }
    >
      <div className="absolute top-0 w-full h-400px sm-h-250px lg:flex md:flex items-center flex justify-center lg:px-0 md:px-0 px-5 lg:py-0 md:py-0 py-4">
        <div ref={elementRef} className={`fade-bottom w-full flex flex-col justify-center items-center ${isVisible ? 'visiblebottom' : ''} `}>
          <h3
            id="7231264687"
            className="dynamicStyle fsize50 md-fsize40  sm-fsize28 font-semibold text-center text-primary cust-textColor"
          >
            {data ? data['7231264687'] : 'Lorem ipsum'}
          </h3>
        </div>
      </div>
    </section>
  );
};

export default HeroSection18;
