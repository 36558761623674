// import axios from "axios";
import apis from "../../apis/client/blogs";
export const getAllBlogs = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      //   let { data } = await apis.user.getProject(payload);
      let { data } = await apis.getAllBlogs(payload2);

      console.log(data.data.blogs, "project");
      if (data) {
        await dispatch(setBlog(data.data.blogs));
        record = data.data.blogs;
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const getBlogs = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      //   let { data } = await apis.user.getProject(payload);
      let { data } = await apis.getBlogs(payload2);
      dispatch(setBlogs(data.data));

      record = data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const getBlogsLoadMore = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      //   let { data } = await apis.user.getProject(payload);
      let { data } = await apis.getBlogs(payload2);
      // dispatch(setBlogsLoadMore(data.data));

      record = data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const getBlogsWithoutUpadate = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      //   let { data } = await apis.user.getProject(payload);
      let { data } = await apis.getBlogs(payload2);
      if (data) {
        dispatch(setRelevantBlogs(data.data));
        record = data;
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const getSingleBlogs = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let payload2 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      //   let { data } = await apis.user.getProject(payload);
      let { data } = await apis.getBlogs(payload2);
      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};

export const getAllCategory = (payload) => {
  return async (dispatch) => {
    try {
      let payload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      //   let { data } = await apis.user.getProject(payload);
      let { data } = await apis.getAllCategory(payload);

      console.log(data.data, "project1");
      if (data) dispatch(setCategory(data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setBlog = (data) => {
  return {
    type: "setBlog",
    payload: data,
  };
};
export const setBlogs = (data) => {
  return {
    type: "setBlogs",
    payload: data,
  };
};
export const setBlogsLoadMore = (data) => {
  return {
    type: "setBlogsLoadMore",
    payload: data,
  };
};

export const setCategory = (data) => {
  return {
    type: "setCategory",
    payload: data,
  };
};

export const addSingleBlog = (data) => {
  return {
    type: "ADD_SINGLE_BLOG",
    payload: data,
  };
};

export const setSelectedBlog = (data) => {
  return {
    type: "setSelectedBlog",
    payload: data,
  };
};

export const setRelevantBlogs = (data) => {
  return {
    type: "setRelevantBlogs",
    payload: data,
  };
};
