import API from './repositoryFactory';

const get = 'team_members';

const getTeam = route => {
  return `forms/get/${route}`;
};

export default {
  getAllTeam(payload) {
    return API.post(getTeam(get), payload);
  },
};
