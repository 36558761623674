import { Card, Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard10 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8">
      <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center lg:mb-12 md:mb-12 mb-6">
        <h2
          id="1567180607"
          className="dynamicStyle font-semibold text-secondary cust-textColor fsize34 md-fsize24 sm-fsize20  relative inline-block"
        >
          {data ? data['1567180607'] : 'Welcome to My World'}
        </h2>

        <p
          id="5481263633"
          className="dynamicStyle fsize16 sm-fsize14 mt-2 textColor-light"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['5481263633']
              : "I'm a young tech enthasist and entrepreneur who love to take risk. I grew up in a tech family in New York City.",
          }}
        ></p>
      </div>
      <div id="8861370889" className="dynamicStyle">
        <div
          ref={elementRef}
          className={`fade-bottom  w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          {data && data['8861370889']
            ? data['8861370889'].map(e => {
                return (
                  <Card
                    className={
                      colorMode === 'light'
                        ? 'item-card border-e0e0e0 theme_insertShadow theme-bg'
                        : 'item-card theme_insertShadow theme-bg'
                    }
                  >
                    <div className="">
                      <Image
                        src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="w-12 h-12 bg-gray"
                      />
                    </div>

                    <div className="">
                      <h3 className="fsize24 sm-fsize20 cust-textColor mt-2 lg:mb-4 md:mb-3 mb-2 font-medium">
                        {e.field2}
                      </h3>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: e.field3,
                        }}
                        className="fsize16 sm-fsize14 textColor-light"
                      ></p>
                    </div>
                  </Card>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard10;
