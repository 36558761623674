import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Avatar,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useDisclosure } from '@chakra-ui/react';

const Audit = () => {
  const [size, setSize] = React.useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const handleClick = newSize => {
    setSize(newSize);
    onOpen();
  };
  return (
    <div>
      <Drawer
        isOpen={isOpen}
        placement="right"
        size={size}
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton mt={1} />
          <DrawerHeader>
            <p className="text-white fsize15">Logs</p>
          </DrawerHeader>
          <DrawerBody className="bg-fa">
            <div className="py-3">
              <div className="grid grid-cols-1 gap-4">
                <div className="bg-white shadow-sm p-4 rounded-lg">
                  <div className="flex items-center gap-3">
                    <Avatar
                      name="Dan Abrahmov"
                      src="https://bit.ly/dan-abramov"
                    />
                    <div>
                      <h3 className="fsize14 font-semibold text-primary">
                        Reatly funding directory{' '}
                        <span className="text-gray font-normal">has been</span>
                      </h3>
                      <p className="fsize14 text-gray">
                        Updated by
                        <span className="text-primary mx-1 font-semibold">
                          Dealing officer
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-white shadow-sm p-4 rounded-lg">
                  <div className="flex items-center gap-3">
                    <Avatar
                      name="Dan Abrahmov"
                      src="https://bit.ly/dan-abramov"
                    />
                    <div>
                      <h3 className="fsize14 font-semibold text-primary">
                        Reatly funding directory{' '}
                        <span className="text-gray font-normal">has been</span>
                      </h3>
                      <p className="fsize14 text-gray">
                        Updated by
                        <span className="text-primary mx-1 font-semibold">
                          Dealing officer
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-6 relative">
                <hr></hr>
                <div className="absolute top-0 mt-minus left-0 flex justify-center w-full">
                  {' '}
                  <p className="text-primary bg-white fsize12 px-2">
                    Yesterday
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <div className="bg-white shadow-sm p-4 rounded-lg">
                  <div className="flex items-center gap-3">
                    <Avatar
                      name="Dan Abrahmov"
                      src="https://bit.ly/dan-abramov"
                    />
                    <div>
                      <h3 className="fsize14 font-semibold text-primary">
                        Reatly funding directory{' '}
                        <span className="text-gray font-normal">has been</span>
                      </h3>
                      <p className="fsize14 text-gray">
                        Updated by
                        <span className="text-primary mx-1 font-semibold">
                          Dealing officer
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-white shadow-sm p-4 rounded-lg">
                  <div className="flex items-center gap-3">
                    <Avatar
                      name="Dan Abrahmov"
                      src="https://bit.ly/dan-abramov"
                    />
                    <div>
                      <h3 className="fsize14 font-semibold text-primary">
                        Reatly funding directory{' '}
                        <span className="text-gray font-normal">has been</span>
                      </h3>
                      <p className="fsize14 text-gray">
                        Updated by
                        <span className="text-primary mx-1 font-semibold">
                          Dealing officer
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <div className="flex items-center mb-5 gap-2">
        <h2 className="fsize25 sm-fsize19 font-semibold text-primary" id="">
          Audit Logs
        </h2>
      </div>
      <TableContainer mt={5}>
        <Table variant="simple" style={{ overflow: 'auto' }}>
          <Thead>
            <Tr>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Sr No.
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                File Name
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Author
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Approver
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Leadership Team
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Status
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Action
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Employee
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">ABC</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4 text-success">
                Approved
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <Popover>
                  <PopoverTrigger>
                    <Button>
                      <FeatherIcon icon="more-vertical" className="text-gray" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <p
                        className="text-gray fsize16 p-2 cursor-pointer"
                        onClick={() => handleClick('sm')}
                        key={size}
                      >
                        View
                      </p>
                      <p className="text-gray fsize16 p-2 cursor-pointer">
                        Comment
                      </p>
                      <p className="text-gray fsize16 p-2 cursor-pointer">
                        Download
                      </p>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Employee
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">ABC</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4 text-success">
                Approved
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <Popover>
                  <PopoverTrigger>
                    <Button>
                      <FeatherIcon icon="more-vertical" className="text-gray" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <p
                        className="text-gray fsize16 p-2 cursor-pointer"
                        onClick={() => handleClick('sm')}
                        key={size}
                      >
                        View
                      </p>
                      <p className="text-gray fsize16 p-2 cursor-pointer">
                        Comment
                      </p>
                      <p className="text-gray fsize16 p-2 cursor-pointer">
                        Download
                      </p>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Employee
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">ABC</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4 text-success">
                Approved
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <Popover>
                  <PopoverTrigger>
                    <Button>
                      <FeatherIcon icon="more-vertical" className="text-gray" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <p
                        className="text-gray fsize16 p-2 cursor-pointer"
                        onClick={() => handleClick('sm')}
                        key={size}
                      >
                        View
                      </p>
                      <p className="text-gray fsize16 p-2 cursor-pointer">
                        Comment
                      </p>
                      <p className="text-gray fsize16 p-2 cursor-pointer">
                        Download
                      </p>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Employee
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">ABC</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4 text-success">
                Approved
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <Popover>
                  <PopoverTrigger>
                    <Button>
                      <FeatherIcon icon="more-vertical" className="text-gray" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <p
                        className="text-gray fsize16 p-2 cursor-pointer"
                        onClick={() => handleClick('sm')}
                        key={size}
                      >
                        View
                      </p>
                      <p className="text-gray fsize16 p-2 cursor-pointer">
                        Comment
                      </p>
                      <p className="text-gray fsize16 p-2 cursor-pointer">
                        Download
                      </p>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Employee
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">ABC</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4 text-success">
                Approved
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <Popover>
                  <PopoverTrigger>
                    <Button>
                      <FeatherIcon icon="more-vertical" className="text-gray" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <p
                        className="text-gray fsize16 p-2 cursor-pointer"
                        onClick={() => handleClick('sm')}
                        key={size}
                      >
                        View
                      </p>
                      <p className="text-gray fsize16 p-2 cursor-pointer">
                        Comment
                      </p>
                      <p className="text-gray fsize16 p-2 cursor-pointer">
                        Download
                      </p>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Audit;
