import apis from '../../apis/client';

export const createContact = payload => {
  return async dispatch => {
    try {
      let { data } = await apis.marcom.createContactUs(payload);

      return data;
    } catch (error) {
      return error.response.data;
    }
  };
};
