import brandprofile from './brandProfile';
import cms from './cms';
import fileUpload from './fileUpload';
import menu from './menu';
import marcom from './marcom';
import auth from './auth';
import blogs from './blogs';
import user from './user';
import newsletter from './newsletter';
import stripe from './stripe';
import gateway from './gateway';
import chat from './chat';
import testimonial from './testimonial';
import loginGateway from './loginGateway';
import form from './Forms/form';
import ecom_Product from './Forms/ecom_Product';
import ecom_Tags from './Forms/ecom_Tags';
import ecom_Category from './Forms/ecom_Category';
import ecom_Sub_Category from './Forms/ecom_ sub_Category';
import portfolio from './portfolio_blogs';
import teams from './teams';

export default {
  brandprofile: brandprofile,
  cms: cms,
  fileUpload: fileUpload,
  getmenu: menu,
  marcom: marcom,
  auth: auth,
  blogs: blogs,
  user: user,
  newsletter: newsletter,
  stripe: stripe,
  gateway: gateway,
  chat: chat,
  testimonial: testimonial,
  loginGateway: loginGateway,
  form: form,
  product: ecom_Product,
  tag: ecom_Tags,
  category: ecom_Category,
  subCategory: ecom_Sub_Category,
  portfolio: portfolio,
  teams,
};
