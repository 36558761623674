import { Image, Select, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HeroSection28 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const TripOptions = [
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Goa', label: 'Goa' },
  ];

  const PickupOptions = [
    { value: 'Feb18, 2023', label: 'Feb18, 2023' },
    { value: 'Feb22, 2023', label: 'Feb22, 2023' },
    { value: 'March24, 2023', label: 'March24, 2023' },
  ];

  const PickupTimeOptions = [
    { value: '3:00 PM', label: '3:00 PM' },
    { value: '2:10 AM', label: '2:10 AM' },
    { value: '5:00 PM', label: '5:00 PM' },
  ];

  const [trip, setTrip] = useState(TripOptions[0].value);
  const [pickup, setPickup] = useState(PickupOptions[0].value);
  const [pickupTime, setPickupTime] = useState(PickupTimeOptions[0].value);

  const handleTrip = e => {
    setTrip(e.target.value);
  };

  const handlePickup = e => {
    setPickup(e.target.value);
  };

  const handlePickupTime = e => {
    setPickupTime(e.target.value);
  };

  return (
    <section>
      <div>
        <div className="relative">
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/mountain.jpg"
              className="relative h-450px sm-h300px w-full object-cover"
              alt="herosection"
            />
          </div>
          <div className="lg:absolute md:absolute bottom-0 left-0 w-full  minus-margintop">
            <div className="cust-bgColor z-10 lg:shadow md:shadow lg:w-7/12 md:w-10/12 mx-auto bg-white p-4 lg:flex md:flex gap-6 justify-between items-center themeShadow lg:rounded-lg md:rounded-lg">
              <div className="lg:gap-10 md:gap-12 gap-6 grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2">
                <div className="lg:pl-0 md:pl-0 pl-4">
                  <p className="h-2rem clr-b8b8b8 cust-textColor flex items-center fsize14">
                    Pick-up Location
                  </p>

                  <p className="fsize16 font-medium textColor-light">Pune</p>
                </div>
                <div className="">
                  <Select
                    placeholder="Pick Up Date"
                    value={setPickup}
                    height="2rem"
                    border="0px"
                    className="fsize14 select clr-b8b8b8 cust-textColor"
                    onChange={handlePickup}
                  >
                    {PickupOptions.map(pickup => (
                      <option key={pickup.value} value={pickup.value}>
                        {pickup.label}
                      </option>
                    ))}
                  </Select>
                  <p className="fsize16 font-medium px-4 textColor-light">
                    {pickup}
                  </p>
                </div>
                <div className="">
                  <Select
                    placeholder="Pick Up Date"
                    value={setPickupTime}
                    height="2rem"
                    border="0px"
                    className="fsize14 select clr-b8b8b8 cust-textColor"
                    onChange={handlePickupTime}
                  >
                    {PickupTimeOptions.map(time => (
                      <option key={time.value} value={time.value}>
                        {time.label}
                      </option>
                    ))}
                  </Select>
                  <p className="fsize16 font-medium px-4 textColor-light">
                    {pickupTime}
                  </p>
                </div>
              </div>
              <div className="flex lg:justify-end justify-center lg:mt-0 md:mt-0 mt-6">
                <Button
                  id=""
                  colorScheme="primary"
                  className="dynamicStyle bg-primary cust-textColor theme-btn cust-bgColor border-1px sm-fsize14"
                >
                  <span onClick={() => handleClick('')}>
                    Search
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-secondary cust-bgColor lg:py-16 md:py-16 py-8 ">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-10 md:gap-8 gap-6 lg:px-0 md:px-0 px-5 lg:mt-6 mt-4 mt-3 lg:w-7/12 md:w-10/12 container mx-auto">
            <div className="herosection29 themeShadow rounded-lg lg:p-4 md:p-4 p-3">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/mountain.jpg"
                className="w-24 h-24 object-contain mx-auto"
                alt="herosection"
              />
              <p className="lg:mt-2 mt-1 fsize18 md-fsize16 fsize14 text-center">Buy Dog</p>
            </div>
            <div className="herosection29 themeShadow rounded-lg lg:p-4 md:p-4 p-3">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/mountain.jpg"
                className="w-24 h-24 object-contain mx-auto"
                alt="herosection"
              />
              <p className="lg:mt-2 mt-1 fsize18 md-fsize16 fsize14 text-center">Buy Dog</p>
            </div>
            <div className="herosection29 themeShadow rounded-lg lg:p-4 md:p-4 p-3">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/mountain.jpg"
                className="w-24 h-24 object-contain mx-auto"
                alt="herosection"
              />
              <p className="lg:mt-2 mt-1 fsize18 md-fsize16 fsize14 text-center">Buy Dog</p>
            </div>
            <div className="herosection29 themeShadow rounded-lg lg:p-4 md:p-4 p-3">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/image/mountain.jpg"
                className="w-24 h-24 object-contain mx-auto"
                alt="herosection"
              />
              <p className="lg:mt-2 mt-1 fsize18 md-fsize16 fsize14 text-center">Buy Dog</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection28;
