import React from 'react';

import HeroSection2 from '../components/HeroSection2';
import CardLayout1 from '../components/CardLayout1';
const Blog = () => {
  return (
    <div>
      <HeroSection2 />
      <CardLayout1 />
    </div>
  );
};

export default Blog;
