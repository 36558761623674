import API from './repositoryFactory';

const HELPER = 'helper';

export default {
  uploader(payload, headers) {
    return API.post(apiBaseRoute(HELPER), payload, headers);
  },
};

const apiBaseRoute = route => {
  return `upload/${route}`;
};
