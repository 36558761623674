import React, { useEffect, useRef, useState } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const BlogCategories1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-5 theme-bg">
      <div className="lg:flex md:flex gap-8 sm-reverse">
        <div className="lg:w-3/4 md:w-3/4 w-full">
          <div className=" pb-2">
            <p id="1864459522"
          className="dynamicStyle fsize40 md-fsize32 sm-fsize22 font-semibold text-primary">
              {data ? data['1864459522'] : 'Lorem ipsum dolor sit amet'}
            </p>
          </div>
          <div className="lg:pt-6 md:pt-6 pt-2">
            <div className="lg:flex md:flex w-full gap-6 items-center">
              <div className="lg:h-64 md:h-64 h-52 lg:w-1/3 md:w-1/3 w-full lg:mb-0 md:mb-0 mb-3">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="lg:h-64 md:h-64 h-52 w-full rounded-xl"
                />
              </div>
              <div className="lg:w-2/3 md:w-2/3 w-full">
                <p className="fsize28 sm-fsize18 font-semibold pb-2 cust-textColor">
                  Lorem Ipsum
                </p>
                <div className="flex gap-4 items-center pb-2">
                  <p className="bg-primary sm-fsize14 clr-fff rounded-full py-1 px-4">
                    2020
                  </p>
                  <p className="text-gray sm-fsize15 fsize18 cust-textColor">
                    Dashboard
                  </p>
                </div>
                <p className="textColor-light sm-fsize14">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Tempora quisquam dignissimos sit soluta doloremque earum,
                  praesentium blanditiis id odio maxime.
                </p>
              </div>
            </div>
            <hr className="bdr-bottom-d9d8d8 w-full my-6" />
          </div>
          <div className="lg:pt-6 md:pt-6 pt-2">
            <div className="lg:flex md:flex w-full gap-6 items-center">
              <div className="lg:h-64 md:h-64 h-52 lg:w-1/3 md:w-1/3 w-full lg:mb-0 md:mb-0 mb-3">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="lg:h-64 md:h-64 h-52 w-full rounded-xl"
                />
              </div>
              <div className="lg:w-2/3 md:w-2/3 w-full">
                <p className="fsize28 sm-fsize18 font-semibold pb-2 cust-textColor">
                  Lorem Ipsum
                </p>
                <div className="flex gap-4 items-center pb-2">
                  <p className="bg-primary sm-fsize14 clr-fff rounded-full py-1 px-4">
                    2020
                  </p>
                  <p className="text-gray sm-fsize15 fsize18 cust-textColor">
                    Dashboard
                  </p>
                </div>
                <p className="textColor-light sm-fsize14">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Tempora quisquam dignissimos sit soluta doloremque earum,
                  praesentium blanditiis id odio maxime.
                </p>
              </div>
            </div>
            <hr className="bdr-bottom-d9d8d8 w-full my-6" />
          </div>
          <div className="lg:pt-6 md:pt-6 pt-2">
            <div className="lg:flex md:flex w-full gap-6 items-center">
              <div className="lg:h-64 md:h-64 h-52 lg:w-1/3 md:w-1/3 w-full lg:mb-0 md:mb-0 mb-3">
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                  className="lg:h-64 md:h-64 h-52 w-full rounded-xl"
                />
              </div>
              <div className="lg:w-2/3 md:w-2/3 w-full">
                <p className="fsize28 sm-fsize18 font-semibold pb-2 cust-textColor">
                  Lorem Ipsum
                </p>
                <div className="flex gap-4 items-center pb-2">
                  <p className="bg-primary sm-fsize14 clr-fff rounded-full py-1 px-4">
                    2020
                  </p>
                  <p className="text-gray sm-fsize15 fsize18 cust-textColor">
                    Dashboard
                  </p>
                </div>
                <p className="textColor-light sm-fsize14">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Tempora quisquam dignissimos sit soluta doloremque earum,
                  praesentium blanditiis id odio maxime.
                </p>
              </div>
            </div>
            <hr className="bdr-bottom-d9d8d8 w-full my-6" />
          </div>
        </div>
        <div className="lg:w-1/4 md:w-1/4  w-full">
          <div>
            <p
              id="7382778855"
              className="dynamicStyle fsize24 font-semibold mt-4 mb-4 cust-textColor"
            >
              {data ? data['7382778855'] : 'Lorem ipsum dolor sit amet'}
            </p>
            <div className="bdr-d9d8d8-2px h-300px sm-h-150px rounded-lg themeShadow">
              <div className="flex flex-wrap gap-2 p-4 ">
                <p className="themeShadow-insert themeShadow cust-shadow px-4 py-2 rounded-full font-semibold text-gray-400 fsize12 textColor-light cust-bgColor">
                  Sports
                </p>

                <p className="themeShadow-insert themeShadow cust-shadow px-4 py-2 rounded-full font-semibold text-gray-400 fsize12 textColor-light cust-bgColor">
                  Sports Analyst
                </p>

                <p className="themeShadow-insert themeShadow cust-shadow px-4 py-2 rounded-full font-semibold text-gray-400 fsize12 textColor-light cust-bgColor">
                  Football
                </p>

                <p className="themeShadow-insert themeShadow cust-shadow px-4 py-2 rounded-full font-semibold text-gray-400 fsize12 textColor-light cust-bgColor">
                  Rugby
                </p>

                <p className="themeShadow-insert themeShadow cust-shadow px-4 py-2 rounded-full font-semibold text-gray-400 fsize12 textColor-light cust-bgColor">
                  Rug
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCategories1;
