const initialState = {
  gateway: {},
};
const gateways = (state = initialState, action) => {
  switch (action.type) {
    case 'setGateway':
      return { ...state, gateway: action.payload };

    default:
      return state;
  }
};

export default gateways;
