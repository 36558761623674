import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const FeatureCard27 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-8">
      <div className="lg:w-10/12 md:w-10/12 w-full text-center mx-auto lg:mb-8 md:mb-8 mb-6">
        <h3 id="1539466056"
              className="dynamicStyle fsize32 sm-fsize20 font-semibold  pb-2 cust-textColor">
          {data ? data['1539466056'] : 'Lorem Ipsum'}
        </h3>
      </div>
      <div  id="3984532065"
        className="dynamicStyle grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-12 gap-6">
          {data && data['3984532065']
          ? data['3984532065'].map(e => {
              return (
        <Card className="cursor-pointer">
          <div id="" className="relative feature26 ">
            <Image
               src={e ? getImage(e.field1) : ''}
               fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="lg:h-80 md:h-72 h-60 w-full rounded-lg"
            />

            <div className="flex justify-center feature27absolute">
              <div 
              className={
                colorMode === 'light'
                  ? 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 rounded-t-md theme-bg'
                  : 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 cust-bgColor rounded-t-md theme-bg'
              }
              >
                <p 
              className="fsize16 md-fsize13 sm-fsize13 text-gray textColor-light">
                 {e.field2}
                </p>
                <div className="">
                  <p
                  dangerouslySetInnerHTML={{
                    __html: e.field3,
                  }}
                    className="fsize14 md-fsize13 sm-fsize13 mt-1 lineClamp2 font-semibold cust-textColor"
                  >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
         );
        })
      : 'no-data'}
        {/* <Card className="cursor-pointer">
          <div id="3928771001" className="dynamicStyle2 relative feature26 ">
            <Image
              src={data ? getImage(data['3928771001']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="lg:h-80 md:h-72 h-60 w-full rounded-lg"
            />

            <div className="flex justify-center feature27absolute">
              <div 
              className={
                colorMode === 'light'
                  ? 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 rounded-t-md theme-bg'
                  : 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 cust-bgColor rounded-t-md theme-bg'
              }
              >
                <p id="7767208010"
              className="dynamicStyle fsize16 md-fsize13 sm-fsize13 text-gray textColor-light">
                  {data ? data['7767208010'] : 'Lorem Ipsum'}
                </p>
                <div className="">
                  <p
                    id="2501349323"
                    dangerouslySetInnerHTML={{
                      __html: data ? data['2501349323'] : 'Lorem ipsum generated',
                    }}
                    className="dynamicStyle fsize14 md-fsize13 sm-fsize13 mt-1 lineClamp2 font-semibold cust-textColor"
                  >
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="cursor-pointer">
          <div id="7955759854" className="dynamicStyle2 relative feature26 ">
            <Image
              src={data ? getImage(data['7955759854']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="lg:h-80 md:h-72 h-60 w-full rounded-lg"
            />

            <div className="flex justify-center feature27absolute">
              <div 
              className={
                colorMode === 'light'
                  ? 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 rounded-t-md theme-bg'
                  : 'bg-fff lg:w-10/12 lg:p-4 md:w-10/12 md:p-2 w-11/12 p-2 cust-bgColor rounded-t-md theme-bg'
              }
              >
                <p id="2378924034"
              className="dynamicStyle fsize16 md-fsize13 sm-fsize13 text-gray textColor-light">
                  {data ? data['2378924034'] : 'Lorem Ipsum'}
                </p>
                <div className="">
                  <p
                    id="9012281729"
                    dangerouslySetInnerHTML={{
                      __html: data ? data['9012281729'] : 'Lorem ipsum generated',
                    }}
                    className="dynamicStyle fsize14 md-fsize13 sm-fsize13 mt-1 lineClamp2 font-semibold cust-textColor"
                  >
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card> */}
      </div>
    </div>
  );
};

export default FeatureCard27;
