import { Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const CategoriesSection2 = ({ pageName }) => {
  const categories = [
    {
      id: 1,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 1',
    },
    {
      id: 2,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 2',
    },
    {
      id: 3,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 3',
    },
    {
      id: 4,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 4',
    },
    {
      id: 5,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 5',
    },
    {
      id: 6,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 6',
    },
    {
      id: 7,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 7',
    },
    {
      id: 8,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 8',
    },
    {
      id: 9,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 9',
    },
    {
      id: 10,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 10',
    },
    {
      id: 11,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 11',
    },
    {
      id: 12,
      imageUrl:
        'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Category 12',
    },
  ];

  const data = useSelector(state => state.cms.allSections[pageName]);

  // eslint-disable-next-line
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <section className={colorMode === 'light' ? 'theme-bg' : 'theme-bg'}>
      <div className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
        <div className="text-center wid-70 sm-wfull mx-auto lg:mb-12 md:mb-12 mb-6">
          <h2 id='4866160095' className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold text-primary cust-textColor">
          {data ? data['4866160095'] : 'Popular Category'}
          </h2>
        </div>
        <div className="w-full grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 lg:gap-8 md:gap-8 gap-4 lg:mt-12 md:mt-12 mt-6 lg:px-12 md:px-8">
          {categories.map((category, index) => {
            return (
              <div
                key={index}
                className="onHoverShadow themeShadow themeShadow-insert rounded-lg p-4"
              >
                <Image
                  src={category.imageUrl}
                  alt={category.imageUrl}
                  className="lg:w-24 lg:h-24 md:w-24 md:h-24 w-20 h-20 rounded-lg mx-auto"
                />
                <p className="fsize14 mt-3 text-center cust-textColor">
                  {category.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CategoriesSection2;
