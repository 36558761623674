import API from './repositoryFactory';

const get = 'get';

const LoginGatewayBase = route => {
  return `loginOptions/gateway/${route}`;
};

export default {
  getLoginGateway(payload) {
    return API.post(LoginGatewayBase(get), payload);
  },
};
