import React from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const AboutSection36 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image; 
    }
  }
  return (
    <div>
      <div className="container mx-auto lg:px-12 md:px-8 lg:py-16 md:py-16 px-5 py-10">
        <div>
          <p
            id="2102793610"
            className="dynamicStyle inline-block fsize24 sm-fsize20 font-light lheight50 sm-lh-32px text-secondary cust-textColor"
          >
            {data ? data['2102793610'] : 'ALL YOUR'}
          </p>
          <p
            id="4939906257"
            className="dynamicStyle font-semibold md-fsize36 fsize50 sm-fsize20 tracking-wide cust-textColor"
          >
            {data ? data['4939906257'] : ' BEAUTY FAVOURITES'}
          </p>
          <p
            id="1811562938"
            className="dynamicStyle fsize24 md-fsize18 sm-fsize14 mt-2 text-primary"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['1811562938']
                : '5000s+ of products, 100s+ of brands',
            }}
          ></p>
          <div className="lg:flex md:flex items-center container mx-auto lg:py-8 md:py-10 py-5">
            <div className="lg:w-1/2 md:w-1/2">
              <div
                id="8140952482"
                className="dynamicStyle grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 lg:gap-10 md:gap-10 gap-4"
              >
                {data && data['8140952482']
                  ? data['8140952482'].map(e => {
                      return (
                        <div className="flex items-center lg:gap-3 md:gap-3 gap-2">
                          <Image
                            src={e ? getImage(e.field1) : ''}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            className="lg:w-16 md:w-12 w-12 lg:h-16 md:h-12 h-12"
                          />
                          <p className="fsize18 md-fsize14 sm-fsize12 textColor-light">
                            {e.field2}
                          </p>
                        </div>
                      );
                    })
                  : 'no-data'}
              </div>
            </div>
            <div className="lg:w-1/2 md:w-1/2 lg:pl-12 md:pl-12 lg:mt-0 md:mt-0 mt-10">
              <div>
                <div id="5465481239" className="dynamicStyle2">
                  <Image
                    src={data ? getImage(data['5465481239']) : ''}
                    alt="aboutImage"
                    className="w-full sm-h250px minh350px object-cover rounded-lg"
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection36;
