import {
  Button,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

const NewsletterSection3 = ({ pageName }) => {
    const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="flex lg:static relative">
      <div className="lg:static  z-20 absolute top-0 left-0 lg:w-3/5 h-450px sm-h-250px md-h-350px lg:px-28 md:px-24 px-5  flex flex-col justify-center">
        <h2
          id="1517560982"
          className="dynamicStyle sm-clr-fff cust-textColor lg:text-left md:text-center text-center fsize48 md-fsize36 sm-fsize24 font-bold"
        >
          {data
            ? data['1517560982']
            : 'Get notified you can still join the waitist'}
        </h2>
        <div className="lg:mt-12 md:mt-10 mt-8">
          <InputGroup>
            <Input pr="8.5rem" className='cust-textColor sm-clr-fff' placeholder="Enter your email id" h="3.25rem" />
            <InputRightElement width="7.5rem" pt={2} h="2.75rem" right={1}>
              <Button
                id="1617086632"
                borderRadius={4}
                className={
                    colorMode === 'light'
                      ? 'dynamicStyle  bg-primary clr-fff sm-fsize14'
                      : 'dynamicStyle border-1px cust-bgColor clr-fff sm-fsize14'
                  }
              
              >
                {data ? data['1617086632'] : 'know more'}
              </Button>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>
      <div id='3957222184' className="dynamicStyle2 lg:w-2/5 mdLw-2/5 w-full">
        <Image
           src={data ? getImage(data['3957222184']) : ''}
           fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="newsletter"
          className="lg:opacity-100 md:opacity-100 opacity-90 h-450px sm-h-250px md-h-350px w-full"
        />
      </div>
    </div>
  );
};

export default NewsletterSection3;
