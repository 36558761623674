import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Image } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Header4 = () => {
  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  let menuData = useSelector(state => state.menuState.menu);
  console.log(menuData, 'Sachin');

  const [isFirstDropdownOpen, setIsFirstDropdownOpen] = useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);

  const handleFirstDropdownClick = () => {
    setIsFirstDropdownOpen(!isFirstDropdownOpen);
  };

  const handleSecondDropdownClick = () => {
    setIsSecondDropdownOpen(!isSecondDropdownOpen);
  };

  //primarylogo
  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }

  return (
    // <header>
    //   <nav>
    //     <div className="logo flex items-center">
    //       <NavLink to="">King</NavLink>
    //     </div>
    //     <label htmlFor="menudrop" className="bartoggle">
    //       <HamburgerIcon />
    //     </label>
    //     <input type="checkbox" id="menudrop" />
    //     <ul className="NavMenu">
    //       <li>
    //         <NavLink to="">Home</NavLink>
    //       </li>
    //       <li>
    //         <NavLink to="">About Us</NavLink>
    //       </li>
    //       <li>
    //         <NavLink to="#" onClick={handleFirstDropdownClick}>
    //           <label htmlFor="droplist1" className="toggle">
    //             Our Service <ChevronDownIcon />
    //           </label>
    //         </NavLink>
    //         <input
    //           type="checkbox"
    //           id="droplist1"
    //           checked={isFirstDropdownOpen}
    //           onChange={handleFirstDropdownClick}
    //           style={{ display: 'none' }}
    //         />
    //         {/* FirstDropDown */}
    //         {isFirstDropdownOpen && (
    //           <ul className="dropdown">
    //             <li>
    //               <NavLink to="">Service A</NavLink>
    //             </li>
    //             <li>
    //               <NavLink to="">Service B</NavLink>
    //             </li>
    //             <li>
    //               <NavLink to="#" onClick={handleSecondDropdownClick}>
    //                 <label htmlFor="droplist2" className="toggle">
    //                   Service C <ChevronDownIcon />
    //                 </label>
    //               </NavLink>
    //               <input
    //                 type="checkbox"
    //                 id="droplist2"
    //                 checked={isSecondDropdownOpen}
    //                 onChange={handleSecondDropdownClick}
    //                 style={{ display: 'none' }}
    //               />
    //               {/* Sub Drop Menu */}
    //               {isSecondDropdownOpen && (
    //                 <ul className="dropdown">
    //                   <li>
    //                     <NavLink to="">Sub Service 1</NavLink>
    //                   </li>
    //                   <li>
    //                     <NavLink to="">Sub Service 2</NavLink>
    //                   </li>
    //                   <li>
    //                     <NavLink to="">Sub Service 3</NavLink>
    //                   </li>
    //                 </ul>
    //               )}
    //             </li>
    //             <li>
    //               <NavLink to="">Service D</NavLink>
    //             </li>
    //           </ul>
    //         )}
    //       </li>
    //       <li>
    //         <NavLink to="">Gallery</NavLink>
    //       </li>
    //       <li>
    //         <NavLink to="">Contact</NavLink>
    //       </li>
    //       <li>
    //         <NavLink to="">Enquiry</NavLink>
    //       </li>
    //     </ul>
    //   </nav>
    // </header>
    <header>
      <nav>
        <div className="logo flex items-center">
          <NavLink to="/">
            <Image
              src={getPrimaryImage(brandProfile)}
              alt="logo"
              className="w-32 h-12 object-contain"
            />
          </NavLink>
        </div>
        <label htmlFor="menudrop" className="bartoggle">
          <HamburgerIcon />
        </label>
        <input type="checkbox" id="menudrop" />
        <ul className="NavMenu">
          {menuData
            ? menuData.map((e, i) => {
                return (
                  <li key={i}>
                    {e.menutype === 'page' && e.subMenu.length < 1 ? (
                      <NavLink to={`/${e.pageData[0].page}`}>
                        {e.menuName}
                      </NavLink>
                    ) : (
                      ''
                    )}

                    {e.menutype === 'page' && e.subMenu.length > 0 ? (
                      <>
                        <NavLink to="#" onClick={handleFirstDropdownClick}>
                          <label htmlFor="droplist1" className="toggle">
                            {e.menuName} <ChevronDownIcon />
                          </label>
                        </NavLink>
                        <input
                          type="checkbox"
                          id="droplist1"
                          checked={isFirstDropdownOpen}
                          onChange={handleFirstDropdownClick}
                          style={{ display: 'none' }}
                        />
                        {isFirstDropdownOpen && (
                          <ul>
                            {e.subMenu.map(sub => {
                              return (
                                <li>
                                  <NavLink to="">{sub.menuName}</NavLink>
                                </li>
                              );
                            })}

                            <li>
                              <NavLink
                                to="#"
                                onClick={handleSecondDropdownClick}
                              >
                                <label htmlFor="droplist2" className="toggle">
                                  Service C <ChevronDownIcon />
                                </label>
                              </NavLink>
                              <input
                                type="checkbox"
                                id="droplist2"
                                checked={isSecondDropdownOpen}
                                onChange={handleSecondDropdownClick}
                                style={{ display: 'none' }}
                              />
                              {/* Sub Drop Menu */}
                              {isSecondDropdownOpen && (
                                <ul className="dropdown">
                                  <li>
                                    <NavLink to="">Sub Service 1</NavLink>
                                  </li>
                                  <li>
                                    <NavLink to="">Sub Service 2</NavLink>
                                  </li>
                                  <li>
                                    <NavLink to="">Sub Service 3</NavLink>
                                  </li>
                                </ul>
                              )}
                            </li>
                          </ul>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </li>
                );
              })
            : ''}
        </ul>
      </nav>
    </header>
  );
};

export default Header4;
