import React, { useEffect, useRef, useState } from 'react';
import { Text, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection14 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section>
      <div className={colorMode === 'light' ? 'bg-secondary theme-bg' : ''}>
        <div className="lg:flex md:flex sm-reverse h-full items-center">
          <div ref={elementRef} className={`fade-left lg:px-20 lg:mt-0 md:mt-0 mt-4 md:px-12 px-5 lg:w-3/5 md:w-3/5 flex items-center banner-height lg:mb-0 md:mb-0 mb-4 ${isVisible ? 'visibleleft' : ''} `}>
            <div>
              <h2
                id="2154722687"
                className="dynamicStyle lg:text-4xl md:text-2xl  uppercase text-end text-primary cust-textColor text-xl font-semibold"
              >
                {data ? data['2154722687'] : ' 700+ Expert Coaches'}
              </h2>
              {/* <h2 className="lg:text-4xl md:text-2xl uppercase text-end text-white text-xl font-semibold">
                You Could Be One Of Them
              </h2> */}
              <p
                dangerouslySetInnerHTML={{
                  __html: data ? data['3619909429'] : 'no-data',
                }}
                id="3619909429"
                className="dynamicStyle clr-fff textColor-light theme-textColor fsize16 md-fsize16 sm-fsize14 lg:mt-4 md:mt-4 mt-2 lg:leading-7 md:leading-7 leading-6"
              ></p>
            </div>
          </div>

          <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
            <div id="6920255080" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['6920255080']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="heroImage"
                className=" object-cover w-full sm-h250px minh450px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection14;
