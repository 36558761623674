import React, { useEffect, useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Card, Image, Input, Select, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';
import { useSelector } from 'react-redux/es/exports';

const EventCardSection2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6">
      <div className="flex justify-between lg:pb-12 md:pb-12 pb-6">
        <div className="w-1/2 relative">
          {/* <input className="search-inp"></input> */}
          <Input className="cust-textColor" placeholder="Search" />
          <FeatherIcon
            className="cust-textColor search-abs"
            size={20}
            icon="search"
          />
        </div>
        <div className="sm-wid-30 cust-textColor">
          <Select className=" sm-fsize14" placeholder="Select">
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
        </div>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-12 md:gap-8 gap-6 ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer bg-white box-shadow-none themeShadow  themeShadow theme-bg overflow-hidden'
              : 'cursor-pointer themeShadow theme-bg  overflow-hidden'
          }
        >
          <div
            id="7211895100"
            className="dynamicStyle2 w-full overflow-hidden "
          >
            <Image
              src={data ? getImage(data['7211895100']) : ''}
              className="w-full h-52 custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>

          <div className="lg:p-5 md:p-4 p-3">
            <div className="flex items-center">
              <FeatherIcon
                icon="map-pin"
                className="text-primary cust-textColor"
                size={15}
              />
              <p
                className="text-dark cust-textColor fsize13 font-semibold ml-1 dynamicStyle"
                id="9404324487"
              >
                {data ? data['9404324487'] : 'Lorem Ipsum'}
              </p>
            </div>
            <h2
              className="text-primary cust-textColor fsize20 sm-fsize16 font-semibold dynamicStyle"
              id="6365584655"
            >
              {data ? data['6365584655'] : 'Lorem Ipsum'}
            </h2>
            <p
              className="text-gray cust-textColor fsize11 mt-2 dynamicStyle"
              id="1637595106"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['1637595106']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
            ></p>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer bg-white box-shadow-none themeShadow  themeShadow theme-bg overflow-hidden'
              : 'cursor-pointer themeShadow theme-bg  overflow-hidden'
          }
        >
          <div
            id="4386184349"
            className="dynamicStyle2 w-full overflow-hidden "
          >
            <Image
              src={data ? getImage(data['4386184349']) : ''}
              className="w-full h-52 custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="lg:p-5 md:p-4 p-3">
            <div className="flex items-center">
              <FeatherIcon
                icon="map-pin"
                className="cust-textColor text-primary"
                size={15}
              />
              <p
                className="text-dark fsize13 cust-textColor font-semibold ml-1 dynamicStyle"
                id="0369050241"
              >
                {data ? data['0369050241'] : 'Lorem Ipsum'}
              </p>
            </div>
            <h2
              className="text-primary fsize20 sm-fsize16 font-semibold dynamicStyle cust-textColor"
              id="4789212787"
            >
              {data ? data['4789212787'] : 'Lorem Ipsum'}
            </h2>
            <p
              className="text-gray cust-textColor fsize11 mt-2 dynamicStyle"
              id="8558828548"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['8558828548']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
            ></p>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light'
              ? 'cursor-pointer bg-white box-shadow-none themeShadow  themeShadow theme-bg overflow-hidden'
              : 'cursor-pointer themeShadow theme-bg  overflow-hidden'
          }
        >
          <div
            id="4293448999"
            className="dynamicStyle2 w-full overflow-hidden "
          >
            <Image
              src={data ? getImage(data['4293448999']) : ''}
              className="w-full h-52 custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="lg:p-5 md:p-4 p-3">
            <div className="flex items-center">
              <FeatherIcon
                icon="map-pin"
                className="cust-textColor text-primary"
                size={15}
              />
              <p
                className="text-dark fsize13 cust-textColor font-semibold ml-1 dynamicStyle"
                id="7038784785"
              >
                {data ? data['7038784785'] : 'Lorem Ipsum'}
              </p>
            </div>
            <h2
              className="text-primary fsize20 sm-fsize16 font-semibold dynamicStyle cust-textColor"
              id="4107097126"
            >
              {data ? data['4107097126'] : 'Lorem Ipsum'}
            </h2>
            <p
              className="text-gray cust-textColor fsize11 mt-2 dynamicStyle"
              id="6535371802"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['6535371802']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
            ></p>
          </div>
        </Card>
      </div>
    </section>
  );
};

export default EventCardSection2;
