import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux/es/exports';
import { Image, Card, useColorMode } from '@chakra-ui/react';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="relative falgship-h">
      <div
        id="9231512252"
        className="dynamicStyle2 w-full falgship-h cus-featuredynamic"
      >
        <Image
          src={data ? getImage(data['9231512252']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          className="w-full falgship-h"
          alt="falgship"
        />
      </div>
      <div className="absolute top-0 left-0 w-full">
        <div className="lg:px-12 md:px-8 lg:py-12 md:py-12 container mx-auto py-8 px-5">
          <h2
            id="8019995675"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 mb-2 font-semibold text-white text-center cust-textColor"
          >
            {data ? data['8019995675'] : 'Flagship Programs'}
          </h2>
          <p
            id="3779168579"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['3779168579']
                : 'This strategy aims at SBI Foundation having a focused implementation model and objectives against set targets and goals.',
            }}
            className="dynamicStyle fsize16 sm-fsize14 lg:w-7/12 mx-auto text-white text-center textColor-light"
          ></p>
          <div id="7197292489" className="dynamicStyle">
            <div
              ref={elementRef}
              className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
            >
              <Swiper
                spaceBetween={30}
                observer={true}
                observeParents={true}
                className="mySwiper mt-5 lg:mt-8 md:mt-8 mt-4"
                breakpoints={{
                  1536: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  991: {
                    slidesPerView: 2,
                    spaceBetween: 12,
                  },
                  820: {
                    slidesPerView: 2.3,
                    spaceBetween: 16,
                  },
                  425: {
                    slidesPerView: 2,
                    spaceBetween: 12,
                  },
                  325: {
                    slidesPerView: 2,
                    spaceBetween: 12,
                  },
                }}
              >
                {/* <div class="swiper-button image-swiper-button-prev bg-ffffffa8 rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div class="swiper-button image-swiper-button-next bg-ffffffa8 rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div> */}

                {data && data['7197292489']
                  ? data['7197292489'].map(e => {
                      return (
                        <SwiperSlide>
                          <Card
                            className={
                              colorMode === 'light'
                                ? 'lg:px-8 lg:py-10 px-4 py-4 rounded-lg flex justify-center items-center w-full theme-bg'
                                : 'lg:px-8 lg:py-10 px-4 py-4 cust-bgColor rounded-lg flex justify-center items-center w-full theme-bg'
                            }
                          >
                            <Image
                              src={e ? getImage(e.field1) : ''}
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              className="w-70per lg:h-40 md:h-40 h-32 object-contain"
                              alt="falgship"
                            />
                          </Card>
                        </SwiperSlide>
                      );
                    })
                  : 'no-data'}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard4;
