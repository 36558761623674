import { Button, Image } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux/es/exports';

import { useNavigate } from 'react-router-dom';

const AboutSection28 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);

  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="container mx-auto lg:px-12 md:px-8 lg:py-16 md:py-16 px-5 py-10">
      <div className="lg:w-2/4 md:w-3/4 w-full mx-auto">
        <h2
          id="4908910403"
          className="dynamicStyle text-primary fsize34 md-fsize24 sm-fsize20 sm font-semibold text-center cust-textColor"
        >
          {data ? data['4908910403'] : 'Take A Look At Our Awesome New Product'}
        </h2>
      </div>
      <div className="lg:flex md:flex lg:gap-10 md:gap-6 lg:mt-12 md:mt-12 mt-6">
        <div className="lg:w-1/3 md:w-1/3 w-full lg:pt-20 md:pt-6 lg:px-10">
          <p
            id="7652667624"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['7652667624']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle fsize18 md-fsize16 sm-fsize14 font-semibold cust-textColor"
          >
            {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum
            numquam architecto esse soluta explicabo itaque aspernatur odit
            molestiae, facere tempore. */}
          </p>
        </div>
        <div className="lg:w-1/3 md:w-1/3 w-full">
          <div
            id="0824525011"
            className="dynamicStyle2 h-600px md-h-450px sm-h-310px lg:my-0 md:my-0 my-6 rounded-4xl sm-rounded-3xl"
          >
            <Image
              className="h-600px md-h-450px sm-h-310px w-full sm-w-50 mx-auto rounded-4xl sm-rounded-3xl"
              src={data ? getImage(data['0824525011']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
        </div>
        <div className="lg:w-1/3 md:w-1/3 w-full lg:pt-20 md:pt-6 lg:px-10">
          <div className="lg:pb-10 pb-8">
            <p
              id="6487569018"
              className="dynamicStyle font-semibold pb-2 cust-textColor"
            >
              {data ? data['6487569018'] : 'Lorem Ipsum'}
            </p>
            <p
              id="9550541872"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['9550541872']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle text-gray fsize14 textColor-light"
            >
              {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque
              odit aliquid eaque repellendus, iure vero! Magni voluptatibus non
              cumque ipsa. */}
            </p>
          </div>
          <div className="lg:pb-10 pb-8">
            <p
              id="2297306265"
              className="dynamicStyle font-semibold pb-2 cust-textColor"
            >
              {data ? data['2297306265'] : 'Lorem Ipsum'}
            </p>
            <p
              id="1170123827"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['1170123827']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle text-gray fsize14 textColor-light"
            >
              {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque
              odit aliquid eaque repellendus, iure vero! Magni voluptatibus non
              cumque ipsa. */}
            </p>
          </div>
          <div className="">
            <p
              id="4770963524"
              className="dynamicStyle font-semibold pb-2 cust-textColor"
            >
              {data ? data['4770963524'] : 'Lorem Ipsum'}
            </p>
            <p
              id="0792480062"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['0792480062']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle text-gray fsize14 textColor-light"
            >
              {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque
              odit aliquid eaque repellendus, iure vero! Magni voluptatibus non
              cumque ipsa. */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection28;
