import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useDispatch } from 'react-redux';
import { getAllTeams } from '../../../redux/teams/teams-action';
import { handleScroll } from './fadeeffect/FadeEffect';

const Team = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  let allTeam = useSelector(state => state.Teams.Teams);
  useEffect(() => {
    dispatch(getAllTeams());
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto  lg:py-16 md:py-16 py-8 lg:px-12 md:px-8 px-5">
      <div className="lg:flex items-center gap-4 lg:mb-12 md:mb-12 mb-6 ">
        <div className="lg:w-1/2">
          <h1
            id="6748018147"
            className="fsize36 cust-textColor text-primary md-fsize24 font-semibold sm-fsize20 dynamicStyle lg:pr-24 md:pr-16"
          >
            {data ? data['6748018147'] : ' Meet Aur Professional teams'}
          </h1>
        </div>
        <div className="lg:w-1/2 lg:px-6">
          <p
            id="7139783053"
            dangerouslySetInnerHTML={{
              __html: data ? data['7139783053'] : 'Lorem ipsum dolor',
            }}
            className="fsize16 sm-fsize14 textColor-light dynamicStyle"
          >
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            cursus consectetur euismod aenean. */}
          </p>
        </div>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
      >
        <Swiper
          spaceBetween={30}
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className="mySwiper container relative overflow-x-hidden lg:px-0 md:px-0"
          autoplay={{
            delay: '1000',
          }}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
            991: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          {allTeam && allTeam.length > 0
            ? allTeam.map(e => {
                return (
                  <SwiperSlide>
                    <Card
                      className={
                        colorMode === 'light'
                          ? ' p-3 w-full rounded-lg cust-shadow themeShadow themeShadow-insert theme-bg'
                          : 'p-3 w-full themeShadow theme-bg rounded-lg'
                      }
                    >
                      <div className="overflow-hidden h-200px rounded-lg w-full  lg:mb-3">
                        <Image
                          src={`${process.env.REACT_APP_STORAGE_URL}${e.profilepicture}`}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="teams-card"
                          className="h-200px zoom w-full object-cover rounded-lg"
                        />
                      </div>

                      <p className=" cust-textColor fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2">
                        {e.name}
                      </p>
                      <p className=" textColor-light fsize14 text-center">
                        {e.designation}
                      </p>
                    </Card>
                  </SwiperSlide>
                );
              })
            : 'No Data'}
        </Swiper>
      </div>
    </section>
  );
};

export default Team;
