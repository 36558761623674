import React, { useEffect, useRef, useState } from 'react';
import { Button, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection16 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  }; 
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:flex container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-14 py-10 sm-custdirection md-custdirection items-center lg:gap-10 md:gap-8 gap-5">
      <div className="lg:w-2/5 md:w-full ">
        <h2
          id="3013252133"
          className="dynamicStyle fsize50 md-fsize40 sm-fsize24 font-semibold cust-textColor text-primary"
        >
          {data
            ? data['3013252133']
            : 'Lets Learn New Courses & Gain More Skills'}
        </h2>
        <p
          id="4866111632"
          className="dynamicStyle cust-textColor fsize16 sm-fsize14 textColor-light"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['4866111632']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
        ></p>

        <p id="0683311381" className="dynamicStyle ">
          {data
            ? data['0683311381']
            : 'Lets Learn New Courses & Gain More Skills'}
        </p>
        <div className=" lg:pt-8 md:pt-6 pt-4">
          <Button
            size="lg"
            className="dynamicStyle clr-fff theme-btn bg-primary cust-bgColor rounded-full sm-fsize14 buttonAnimate bdr-2px"
            id="6574138877"
          >
            <span onClick={() => handleClick('6574138877')}>
              {' '}
              {data ? data['6574138877'] : 'Get Course'}
            </span>{' '}
          </Button>
        </div>
        <div className="flex gap-5 pt-5">
          <div>
            <p
              id="0062380520"
              className="dynamicStyle cust-textColor fsize30 md-fsize20 sm-fsize20 font-semibold"
            >
              {data ? data['0062380520'] : '1000+'}
            </p>
            <p id="6277973982" className="dynamicStyle cust-textColor fsize14">
              {data ? data['6277973982'] : 'Reviews'}
            </p>
          </div>
          <div>
            <p
              id="0328230375"
              className="dynamicStyle cust-textColor fsize30 md-fsize20 sm-fsize20 font-semibold"
            >
              {data ? data['0328230375'] : '1500'}
            </p>
            <p id="0646053086" className="dynamicStyle cust-textColor fsize14">
              {data ? data['0646053086'] : 'Instructors'}
            </p>
          </div>
        </div>
      </div>
      <div
        ref={elementRef} className={`fade-right lg:w-3/5 md:w-full flex lg:gap-6 md:gap-5 gap-3 items-center px-4 ${isVisible ? 'visibleright' : ''} `}
      >
        <div id="3686308470" className="dynamicStyle2 w-full">
          <Image
            src={data ? getImage(data['3686308470']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            className="rounded-md h-300px sm-h-130px object-cover"
          />
        </div>
        <div className="lg:pt-20 md:pt-20 pt-12">
          <div id="1303924721" className="dynamicStyle2 w-full ">
            <Image
              src={data ? getImage(data['1303924721']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md h-300px sm-h-130px object-cover"
            />
          </div>
          <div id="6727535919" className="dynamicStyle2 w-full ">
            <Image
              src={data ? getImage(data['6727535919']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md mt-4 h-300px sm-h-130px object-cover"
            />
          </div>
        </div>
        <div>
          <div id="1367883275" className="dynamicStyle2 w-full">
            <Image
              src={data ? getImage(data['1367883275']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md h-300px sm-h-130px object-cover"
            />
          </div>
          <div id="4455136599" className="dynamicStyle2 w-full ">
            <Image
              src={data ? getImage(data['4455136599']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-md mt-4 h-300px sm-h-130px object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection16;
