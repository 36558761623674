import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
} from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';

const Mis = () => {
  return (
    <div>
      <div className="flex items-center mb-5 gap-2">
        <h2 className="fsize25 sm-fsize19 font-semibold text-primary" id="">
          MIS
        </h2>
        <p className="fsize14 text-gray">(4 files found)</p>
      </div>
      <TableContainer mt={5}>
        <Table variant="simple" style={{ overflow: 'auto' }}>
          <Thead>
            <Tr>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Sr No.
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                File Name
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Author
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Approver
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Leadership Team
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Status
              </Th>
              <Th className="bg-fa h-12 fsize15 sm-fsize13 border css-xumdn4">
                Action
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Employee
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">ABC</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4 text-success">
                Approved
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <Popover>
                  <PopoverTrigger>
                    <Button>
                      <FeatherIcon icon="more-vertical" className="text-gray" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <p className="text-gray fsize16 p-2">View</p>
                      <p className="text-gray fsize16 p-2">Comment</p>
                      <p className="text-gray fsize16 p-2">Download</p>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Employee
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">ABC</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4 text-success">
                Approved
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <Popover>
                  <PopoverTrigger>
                    <Button>
                      <FeatherIcon icon="more-vertical" className="text-gray" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <p className="text-gray fsize16 p-2">View</p>
                      <p className="text-gray fsize16 p-2">Comment</p>
                      <p className="text-gray fsize16 p-2">Download</p>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Employee
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">ABC</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4 text-success">
                Approved
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <Popover>
                  <PopoverTrigger>
                    <Button>
                      <FeatherIcon icon="more-vertical" className="text-gray" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <p className="text-gray fsize16 p-2">View</p>
                      <p className="text-gray fsize16 p-2">Comment</p>
                      <p className="text-gray fsize16 p-2">Download</p>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Employee
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">ABC</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4 text-success">
                Approved
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <Popover>
                  <PopoverTrigger>
                    <Button>
                      <FeatherIcon icon="more-vertical" className="text-gray" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <p className="text-gray fsize16 p-2">View</p>
                      <p className="text-gray fsize16 p-2">Comment</p>
                      <p className="text-gray fsize16 p-2">Download</p>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
            <Tr>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">01</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                Employee
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">ABC</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">EFG</Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4 text-success">
                Approved
              </Td>
              <Td className="h-12 fsize15 sm-fsize13 border css-xumdn4">
                <Popover>
                  <PopoverTrigger>
                    <Button>
                      <FeatherIcon icon="more-vertical" className="text-gray" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <p className="text-gray fsize16 p-2">View</p>
                      <p className="text-gray fsize16 p-2">Comment</p>
                      <p className="text-gray fsize16 p-2">Download</p>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Mis;
