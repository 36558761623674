import { Button } from '@chakra-ui/button';
import { useSelector, useDispatch } from 'react-redux/es/exports';
import { loadStripe } from '@stripe/stripe-js';
import { createCheckoutSession } from '../../../src/redux/payment/stripe-actions';

const Payment = ({ button, itemList, successUrl, cancelUrl }) => {
  let gateway = useSelector(state => state.gateways.gateway);
  const dispatch = useDispatch();

  async function handlePayment() {
    // Use the Stripe.js methods to handle the payment
    const stripe = await loadStripe(gateway.clientId);
    let payload = {
      items: itemList, //---------->   // [
      //              //   {
      //     name: "Test",
      //     quantity: 1,
      //     price: 1,
      //   },
      // ]

      currency: 'SGD',
      successUrl: `${process.env.REACT_APP_FRONTEND_URL}/${successUrl}`,
      cancelUrl: `${process.env.REACT_APP_FRONTEND_URL}/${cancelUrl}`,
    };
    let { data } = await dispatch(createCheckoutSession(payload));

    const result = stripe.redirectToCheckout({ sessionId: data.id });

    if (result.error) {
      console.log(result.error);
    }
  }

  return (
    <section>
      <Button colorScheme="primary" onClick={() => handlePayment()}>
        {button}
      </Button>
    </section>
  );
};

export default Payment;
