import apis from "../../apis/client/newsletter";
export const addNewsletter = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.addNewsletter(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      return error.response.data;
    }
    return record;
  };
};
