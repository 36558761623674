import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  Textarea,
} from "@chakra-ui/react";

const AddNewCoachPopUp = ({ isOpen, onClose }) => {
  return (
    <div>
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <div className="py-1">
              <h2 className="font-semibold fsize18 mb-1">
                Applicant for raise a request
              </h2>
              <p className="fsize11 clr-606060">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Dolores eius hic ab rerum eligendi libero blanditiis nulla
                perspiciatis tempora? Placeat.
              </p>
              <div className="pt-4 pb-6 border-b-2">
                <FormControl>
                  <FormLabel className="fsize12 font-semibold">
                    Service Name
                  </FormLabel>
                  <Input type="text" className="fsize12 pd-6px" />
                </FormControl>
                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 my-4">
                  <FormControl>
                    <FormLabel className="fsize12 font-semibold">
                      Start Date
                    </FormLabel>
                    <Input type="date" className="fsize12 pd-6px" />
                  </FormControl>
                  <FormControl>
                    <FormLabel className="fsize12 font-semibold">
                      End Date
                    </FormLabel>
                    <Input type="date" className="fsize12 pd-6px" />
                  </FormControl>

                  <FormControl>
                    <FormLabel className="fsize12 font-semibold">
                      Coach Type
                    </FormLabel>
                    <Select placeholder="Select" className="fsize12 pd-6px">
                      <option>Type 1</option>
                      <option>Type 2</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel className="fsize12 font-semibold">
                      Language
                    </FormLabel>
                    <Select placeholder="Select" className="fsize12 pd-6px">
                      <option>English</option>
                      <option>German</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel className="fsize12 font-semibold">
                      Where are you looking train
                    </FormLabel>
                    <Select placeholder="Select" className="fsize12 pd-6px">
                      <option>My Gym</option>
                      <option>My Gym 2</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel className="fsize12 font-semibold">
                      Training type
                    </FormLabel>
                    <Select placeholder="Online" className="fsize12 pd-6px">
                      <option>Online</option>
                      <option>Offline</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel className="fsize12 font-semibold">
                      Experience
                    </FormLabel>
                    <Select placeholder="1 years" className="fsize12 pd-6px">
                      <option>1 years</option>
                      <option>2 years</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel className="fsize12 font-semibold">
                      Loaction
                    </FormLabel>
                    <Select placeholder="Singapore" className="fsize12 pd-6px">
                      <option>Singapore</option>
                      <option>India</option>
                    </Select>
                  </FormControl>
                </div>
                <FormControl>
                  <FormLabel className="fsize12 font-semibold">
                    Describe your current physical condition
                  </FormLabel>
                  <Textarea
                    placeholder="Type here"
                    className="fsize12 pd-6px"
                  />
                </FormControl>
              </div>
              <div className="py-4">
                <div className="flex justify-between items-center mb-2">
                  <h2 className="fsize16 font-semibold ">
                    Would you like the coach to contact you directly
                  </h2>
                  <Stack align="center" direction="row">
                    <Switch size="sm" />
                  </Stack>
                </div>
                <FormControl>
                  <FormLabel className="fsize12 font-semibold">
                    Select Medium
                  </FormLabel>
                  <Select placeholder="Select" className="fsize12 pd-6px">
                    <option>Singapore</option>
                    <option>India</option>
                  </Select>
                </FormControl>
                <div className="flex gap-4 mt-4">
                  <Button colorScheme="tertiary" height="8" className="fsize10">
                    Gym Trainer
                  </Button>
                  <Button colorScheme="tertiary" height="8" className="fsize10">
                    Gym Trainer
                  </Button>
                  <Button colorScheme="tertiary" height="8" className="fsize10">
                    Gym Trainer
                  </Button>
                  <Button colorScheme="tertiary" height="8" className="fsize10">
                    2 years experience
                  </Button>
                </div>
              </div>
              <div className="flex my-2 w-full">
                <Button colorScheme="tertiary" className="w-100per" mr={3}>
                  Submit
                </Button>
                <Button
                  colorScheme="tertiary"
                  className="w-100per"
                  variant="outline"
                  onClick={onClose}
                >
                  Close
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddNewCoachPopUp;
