import { useState } from 'react';

function UserEffect() {
  const [transform, setTransform] = useState('');
  const handleMouseMove = e => {
    let rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    let dx = (x - rect.width / 1) / (rect.width / 1);
    let dy = (y - rect.height / 1) / (rect.height / 1);
    setTransform(
      `perspective(500px) rotateY(${dx * 3}deg) rotateX(${-dy * 3}deg)`
    );
  };

  const handleMouseLeave = () => {
    setTransform('');
  };

  return { transform, handleMouseMove, handleMouseLeave };
}

export default UserEffect;
