import { Button, Image, Select } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const HeroSection23 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      Navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const TripOptions = [
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Goa', label: 'Goa' },
  ];

  const PickupOptions = [
    { value: 'Feb18, 2023', label: 'Feb18, 2023' },
    { value: 'Feb22, 2023', label: 'Feb22, 2023' },
    { value: 'March24, 2023', label: 'March24, 2023' },
  ];

  const PickupTimeOptions = [
    { value: '3:00 PM', label: '3:00 PM' },
    { value: '2:10 AM', label: '2:10 AM' },
    { value: '5:00 PM', label: '5:00 PM' },
  ];

  const [trip, setTrip] = useState(TripOptions[0].value);
  const [pickup, setPickup] = useState(PickupOptions[0].value);
  const [pickupTime, setPickupTime] = useState(PickupTimeOptions[0].value);

  const handleTrip = e => {
    setTrip(e.target.value);
  };

  const handlePickup = e => {
    setPickup(e.target.value);
  };

  const handlePickupTime = e => {
    setPickupTime(e.target.value);
  };

  return (
    <>
      <div className="relative w-full h-700px md-h-600px sm-h-500px w-full">
        <div id="3360789794" className="dynamicStyle2">
          <Image
            src={data ? getImage(data['3360789794']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            className="w-full h-700px md-h-600px sm-h-500px"
          />
        </div>
        <div className="h-700px md-h-600px sm-h-500px absolute top-0 left-0 w-full flex justify-center items-center">
          <div className="container mx-auto lg:px-0 px-5">
            <div className="lg:w-1/2 md:w-2/3  mx-auto text-center">
              <h2
                id="1856344805"
                className="dynamicStyle cust-textColor fsize86 md-cust-fsize40 sm-fsize28 sm-lh-46 text-primary font-semibold"
              >
                {data ? data['1856344805'] : 'Travel around the World'}
              </h2>
              <p
                id="8962090671"
                className="dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14 lg:mt-0 md:mt-0 mt-2"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['8962090671']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
              ></p>
            </div>
            <div className="cust-bgColor lg:mt-8 md:mt-8 mt-6 lg:w-2/3 mx-auto bg-white p-4 lg:flex gap-6 justify-between items-center themeShadow rounded-lg">
              <div className="lg:gap-10 md:gap-12 gap-6 grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2">
                <div className="">
                  <Select
                    placeholder="Trip Type"
                    value={setTrip}
                    height="2rem"
                    border="0px"
                    className="fsize14 select clr-b8b8b8 cust-textColor"
                    onChange={handleTrip}
                  >
                    {TripOptions.map(trip => (
                      <option key={trip.value} value={trip.value}>
                        {trip.label}
                      </option>
                    ))}
                  </Select>
                  <p className="fsize16 font-medium px-4 textColor-light">{trip}</p>
                </div>
                <div className="lg:pl-0 md:pl-0 pl-4">
                  <p className="h-2rem clr-b8b8b8 cust-textColor flex items-center fsize14">
                    Pick-up Location
                  </p>

                  <p className="fsize16 font-medium textColor-light">Pune</p>
                </div>
                <div className="">
                  <Select
                    placeholder="Pick Up Date"
                    value={setPickup}
                    height="2rem"
                    border="0px"
                    className="fsize14 select clr-b8b8b8 cust-textColor"
                    onChange={handlePickup}
                  >
                    {PickupOptions.map(pickup => (
                      <option key={pickup.value} value={pickup.value}>
                        {pickup.label}
                      </option>
                    ))}
                  </Select>
                  <p className="fsize16 font-medium px-4 textColor-light">{pickup}</p>
                </div>
                <div className="">
                  <Select
                    placeholder="Pick Up Date"
                    value={setPickupTime}
                    height="2rem"
                    border="0px"
                    className="fsize14 select clr-b8b8b8 cust-textColor"
                    onChange={handlePickupTime}
                  >
                    {PickupTimeOptions.map(time => (
                      <option key={time.value} value={time.value}>
                        {time.label}
                      </option>
                    ))}
                  </Select>
                  <p className="fsize16 font-medium px-4 textColor-light">{pickupTime}</p>
                </div>
              </div>
              <div className="flex lg:justify-end justify-center lg:mt-0 mt-6">
                <Button
                  id="6167081565"
                  colorScheme="primary"
                  className="dynamicStyle bg-primary cust-textColor theme-btn cust-bgColor border-1px sm-fsize14"
                >
                  <span onClick={() => handleClick('6167081565')}>
                    {data ? data['6167081565'] : 'Search'}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection23;
