import React, { useEffect, useRef, useState } from 'react';
import { Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();

  const handleErorrImage = e => {
    e.target.src =
      'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg';
  };
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section
      className={colorMode === 'light' ? 'bg-secondary theme-bg' : ''}
    >
      {' '}
      <div className="lg:flex md:flex sm-reverse h-full items-center">
        <div
          ref={elementRef} className={`fade-left lg:w-3/5 md:w-3/5 lg:px-20 md:px-12 px-5 lg:py-0 md:py-8 py-6 ${isVisible ? 'visibleleft' : ''} `}
        >
          <div className="flex items-center gap-4">
            <p id="8588175253" className="text-primary fsize16 sm-fsize14 dynamicStyle">
              {data ? data['8588175253'] : 'EDUCATE'}
            </p>
            <span className="block h-2 w-2 bg-primary cust-bgColor rounded-full"> </span>
            <p id="1659103740" className="text-primary fsize16 sm-fsize14 dynamicStyle">
              {data ? data['1659103740'] : 'CONNECT'}
            </p>
            <span className="block h-2 w-2 bg-primary cust-bgColor rounded-full"> </span>
            <p id="2552274841" className="text-primary fsize16 sm-fsize14 dynamicStyle">
              {data ? data['2552274841'] : 'MOTIVATE'}
            </p>
          </div>

          <div className="lg:mt-6 md:mt-4 mt-2">
            <h2
              id="5298456299"
              className="lg:text-4xl md:text-2xl text-xl text-end theme-textColor text-white font-semibold dynamicStyle "
            >
              {' '}
              {data
                ? data['5298456299']
                : 'STAY INFORMED WITH OUR LATEST ARTICLES'}
            </h2>
            <p
              id="3430366058"
              dangerouslySetInnerHTML={{
                __html: data ? data['3430366058'] : 'no-data',
              }}
              className="clr-fff textColor-light opacity-70 theme-textColor lg:mt-6 md:mt-4 mt-2 sm-fsize14 dynamicStyle lg:leading-7 md:leading-7 leading-6"
            ></p>
          </div>
        </div>
        <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
          <div id="7473290310" className="dynamicStyle2 w-full">
            {' '}
            <Image
              src={data ? getImage(data['7473290310']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              className="object-cover w-full sm-h250px minh450px"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection2;
