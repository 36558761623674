import { ChevronRightIcon } from "@chakra-ui/icons";
import { Button, Image, useColorMode } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';


const FeatureCard31 = ({ pageName }) => {
    const [check, setcheck] = useState(1);
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
    const data = useSelector(state => state.cms.allSections[pageName]);
    const CTALink = useSelector(state => state.cms.links[pageName]);
    let selectedStyle = useSelector(
      state => state.brandDetails.brandProfile.businessThemeVariation
    );
    console.log(selectedStyle, 'ss');
  
    const { colorMode, toggleColorMode } = useColorMode();
    const navigate = useNavigate();
    const link = 'https://example.com';
  
    const handleClick = id => {
      const isHttpsLink =
        CTALink[id].includes('https') || CTALink[id].includes('www');
      if (isHttpsLink) {
        window.open(`${CTALink[id]}`, '_blank');
  
        console.log(link, 'cmsdatacheck');
      } else {
        navigate(`/${CTALink[id]}`);
      }
    };
  
    function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
    useEffect(() => {
      const cleanp1 = handleScroll(elementRef, setIsVisible);
  
      return () => {
        cleanp1();
      };
    }, []);
  return (
    <div id="3490849669"
            className="dynamicStyle2 h-500px sm-h-600px relative">
      <Image
       src={data ? getImage(data['3490849669']) : ''}
       fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
        alt="img"
        className="h-500px sm-h-600px w-full object-cover"
      />
      <div className="absolute bottom-0 left-0 h-500px sm-h-600px w-full">
        <h2 id="1027762902"
            className="dynamicStyle fsize34 md-fsize28 sm-fsize20 font-semibold text-center w-9/12 mx-auto lg-pt-14 md:pt-14 pt-10 pb-8 clr-fff">
          {data ? data['1027762902'] : 'Lorem ipsum'}
        </h2>
        <div className="lg:flex md:flex bdr-top-fff absolute bottom-0 left-0 w-full">
          <div className="lg:w-1/2 md:w-1/2 lg:px-12 md:px-10 px-4 lg:py-20 py-6 bdr-right-fff sm-bdr-right-0px flex justify-center items-center">
           <div>
           <h1 id="7601861322"
            className="dynamicStyle fsize34 md-fsize28 sm-fsize20 font-semibold clr-fff pb-2">{data ? data['7601861322'] : 'Lorem ipsum'}</h1>
            <p id="5277287946" dangerouslySetInnerHTML={{
              __html: data
                ? data['5277287946']
                : ' publishing and graphic design, Lorem ipsum is a placeholder text',
            }}
            className="dynamicStyle clr-fff">
             
            </p>
            <Button
              variant='transparent'
              className="dynamicStyle text-primary mt-4 clr-fff"
              id="8075642535"
              px={0}
            >
              <span onClick={() => handleClick("8075642535")}>
                {data ? data["8075642535"] : "Find Coach"}
              </span>
              <ChevronRightIcon className="ml-2 clr-fff" />
            </Button>
           </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 lg:px-12 md:px-10 px-4 lg:py-20 py-6  flex justify-center items-center">
           <div>
           <h1  id="9496674352"
            className="dynamicStyle fsize34 md-fsize28 sm-fsize20 font-semibold clr-fff pb-2"> {data ? data['9496674352'] : 'Lorem ipsum'}</h1>
            <p id="8107896029"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['8107896029']
                : ' publishing and graphic design, Lorem ipsum is a placeholder text',
            }}
            className="dynamicStyle clr-fff">
             
            </p>
            <Button
              variant='transparent'
              className="dynamicStyle text-primary mt-4 clr-fff"
              id="1275617131"
              px={0}
            >
              <span onClick={() => handleClick("1275617131")}>
                {data ? data["1275617131"] : "Find Coach"}
              </span>
              <ChevronRightIcon className="ml-2 clr-fff"  />
            </Button>
           </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default FeatureCard31;
