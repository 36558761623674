const initialState = {
  //   project: {},
  Blog: [],
  Blogs: {},
  BlogsLoadMore: {},
  RelevantBlogs: {},
  Category: [],
  addSingleBlog: {},
  selectedBlog: {},
};
const BlogDetails = (state = initialState, action) => {
  switch (action.type) {
    case "setBlog":
      return { ...state, Blog: action.payload };
    case "setBlogs":
      return { ...state, Blogs: action.payload};
    case "setBlogsLoadMore":
      return { ...state, BlogsLoadMore: action.payload};
      // return { ...state, BlogsLoadMore: {...action.payload, result: [...state.BlogsLoadMore.result,action.payload.result] }};
    case "setCategory":
      return { ...state, Category: action.payload };
    case "setSelectedBlog":
      return { ...state, selectedBlog: action.payload };
    case "setRelevantBlogs":
      return { ...state, RelevantBlogs: action.payload };

    case "ADD_SINGLE_BLOG":
      return {
        ...state,
        addSingleBlog: action.payload,
      };
    default:
      return state;
  }
};

export default BlogDetails;
