import React from 'react';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { TiSocialFacebookCircular } from 'react-icons/ti';
import { FiYoutube } from 'react-icons/fi';
import { FiPhoneCall } from 'react-icons/fi';
import { AiOutlineMail } from 'react-icons/ai';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';

const Footer7 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
 
  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }
  return (
    <section className='theme-bg'>
      <div className="lg:flex md:flex w-full ">
        <div className="border lg:w-1/2 md:w-1/2 lg:pl-28 lg:pr-12 md:pl-12 md:pr-12 px-4 lg:py-10 py-6">
          <div className="">
            <a href="/">
              <Image
                src={getSecondaryImage(brandProfile)}
                alt="footer-logo"
                className="w-10rem sm-mx-auto lg:pb-4 md:pb-4 pb-2"
              />
            </a>
            <div className="my-4">
              <div id="4996382437" className="dynamicStyle pb-4">
                <p
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data['4996382437']
                      : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                  }}
                  className=" fsize14 clr-5a5a5a lineClamp3 textColor-light"
                ></p>
              </div>
            </div>

            <div className="lg:flex md:block gap-8">
              <a href="tel:123-456-7890">
                <div className="flex items-center gap-4 my-2">
                  <div className="">
                    <FiPhoneCall className="lg:w-8 md:w-6 w-6 lg:h-8 md:h-6 h-6 clr-5a5a5a textColor-light" />
                  </div>

                  <div className="">
                    <p
                      id="7354039539"
                      className="dynamicStyle  fsize12 clr-5a5a5a textColor-light"
                    >
                      {data ? data['7354039539'] : 'Lorem ipsum'}
                    </p>

                    <span
                      id="6287634949"
                      className="dynamicStyle clr-5a5a5a fsize16 sm-fsize14 textColor-light"
                    >
                      {data ? data['6287634949'] : 'Lorem ipsum'}
                    </span>
                  </div>
                </div>
              </a>
              <a href="mailto:unreal@outlook.com">
                <div className="flex items-center gap-4 my-2">
                  <div className="flex ">
                    <AiOutlineMail className="lg:w-8 md:w-6 w-6 lg:h-8 md:h-6 h-6 clr-5a5a5a textColor-light" />
                  </div>

                  <div className="">
                    <p
                      id="9691819056"
                      className="dynamicStyle fsize12 clr-5a5a5a textColor-light"
                    >
                      {data ? data['9691819056'] : 'Lorem ipsum'}
                    </p>

                    <span
                      id="3898609804"
                      className="dynamicStyle fsize16 5a5a5a sm-fsize14 textColor-light"
                    >
                      {data ? data['3898609804'] : 'Lorem ipsum'}
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="border lg:w-1/2 md:w-1/2 lg:pr-28 md:pr-12 px-4 lg:pl-12 md:pl-12 px-4 lg:py-10 py-6">
          <div className="">
            <p id="2175344068" className="dynamicStyle fsize18 textColor-light font-semibold">
              {data ? data['2175344068'] : 'Lorem ipsum'}
            </p>
            <div className="my-4">
              <div id="2625822458" className="dynamicStyle pb-4">
                <p
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data['2625822458']
                      : ' publishing and graphic design, Lorem ipsum is a placeholder text',
                  }}
                  className="fsize14 clr-5a5a5a textColor-light lineClamp3"
                ></p>
              </div>
            </div>

            <div className="relative">
              <input
                type="text"
                placeholder="Enter your email"
                className="input-email tb-fsize14"
              />
              <div className=" absolute right-0 top-0 m-1">
                <button
                  id="5640825632"
                  className="dynamicStyle bg-d9d9d9 submit-email fsize12"
                >
                  {data ? data['5640825632'] : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:px-28 md:px-12  px-4 lg:py-10 py-8">
        <div className="lg:flex cust-grid4 justify-between items-center">
          <div id="" className="">
            <a href="/">
              <p className="fsize14 font-semibold textColor-light ">About us</p>
            </a>
          </div>
          <div id="" className=" ">
            <a href="/">
              <p className="fsize14 font-semibold textColor-light">Jobs</p>
            </a>
          </div>
          <div id="" className="">
            <a href="/">
              <p className="fsize14 font-semibold textColor-light">Blog</p>
            </a>
          </div>
          <div id="" className="">
            <a href="/">
              <p className="fsize14 font-semibold textColor-light">Press</p>
            </a>
          </div>
          <div id="" className="">
            <a href="/">
              <p className="fsize14 font-semibold textColor-light">FAQ</p>
            </a>
          </div>
          <div id="" className="">
            <a href="/">
              <p className="fsize14 font-semibold textColor-light">Careers</p>
            </a>
          </div>
          <div id="" className="">
            <a href="/">
              <p className="fsize14 font-semibold textColor-light">Contact</p>
            </a>
          </div>
          <div id="" className="">
            <a href="/">
              <p className="fsize14 font-semibold textColor-light">Privacy Policy</p>
            </a>
          </div>
          <div id="" className="">
            <a href="/">
              <p className="fsize14 font-semibold textColor-light">Sitemap</p>
            </a>
          </div>
          <div id="" className="">
            <a href="/">
              <p className="fsize14 font-semibold textColor-light">Term of Use</p>
            </a>
          </div>
        </div>

        <div className="lg:flex md:flex justify-between items-center lg:mt-4 md:mt-4 mt-8">
          <div className="icons flex gap-4 my-4 sm-center">
            <a href="/">
              <FaWhatsapp className="w-6 h-6 clr-5a5a5a" />
            </a>
            <a href="/">
              <FaInstagram className="w-6 h-6 clr-5a5a5a" />
            </a>
            <a href="/">
              <TiSocialFacebookCircular className="w-6 h-6 clr-5a5a5a" />
            </a>
            <a href="/">
              <FiYoutube className="w-6 h-6 clr-5a5a5a" />
            </a>
          </div>
          <span
            id="5413749148"
            className="dynamicStyle clr-d9d9d9 fsize14 sm-center"
          >
            {data ? data['5413749148'] : 'Lorem ipsum'}
          </span>
        </div>
      </div>
    </section>
  );
};

export default Footer7;
