import { ChevronRightIcon } from "@chakra-ui/icons";
import { Button, Image, useColorMode } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';

const FeatureCard32 = ({ pageName }) => {
    const [check, setcheck] = useState(1);
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
    const data = useSelector(state => state.cms.allSections[pageName]);
    const CTALink = useSelector(state => state.cms.links[pageName]);
    let selectedStyle = useSelector(
      state => state.brandDetails.brandProfile.businessThemeVariation
    );
    console.log(selectedStyle, 'ss');
  
    const { colorMode, toggleColorMode } = useColorMode();
    const navigate = useNavigate();
    const link = 'https://example.com';
  
    const handleClick = id => {
      const isHttpsLink =
        CTALink[id].includes('https') || CTALink[id].includes('www');
      if (isHttpsLink) {
        window.open(`${CTALink[id]}`, '_blank');
  
        console.log(link, 'cmsdatacheck');
      } else {
        navigate(`/${CTALink[id]}`);
      }
    };
  
    function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
    useEffect(() => {
      const cleanp1 = handleScroll(elementRef, setIsVisible);
  
      return () => {
        cleanp1();
      };
    }, []);

  return (
    <div className="lg:py-10 md:py-10 py-6 h-500px sm-h-600px z-50 relative">
      <div className="lg:block md:block hidden lg:w-7/12 md:w-7/12 w-full mx-auto text-center px-5">
        <h1
          id="5338554480"
          className="dynamicStyle fsize34 md-fsize28 sm-fsize20 font-semibold text-secondary"
        >
          {data ? data["5338554480"] : "Lorem ipsum"}
        </h1>
        <p
          id="4744958819"
          dangerouslySetInnerHTML={{
            __html: data
              ? data["4744958819"]
              : " publishing and graphic design, Lorem ipsum is a placeholder text",
          }}
          className="dynamicStyle  sm-fsize14 text-gray-400"
        ></p>
        <Button
          px={6}
          className={
            colorMode === 'light'
              ? 'dynamicStyle bg-secondary clr-fff theme-btn bdr-0px mt-6 sm-fsize14'
              : 'dynamicStyle theme-btn cust-bgColor bdr-0px mt-6 cust-textColor sm-fsize14 '
          }
          id="7960944207"
        >
        
            {data ? data["7960944207"] : "Find Coach"}
         
        </Button>
      </div>
      <div className="lg:flex md:flex justify-between absolute left-0 bottom-0 w-full z-50">
        <div id="2248580397" className="dynamicStyle2 h-300px sm-h-200px lg:w-2/6">
          <Image
            src={data ? getImage(data["2248580397"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            className="h-300px sm-h-200px w-full object-right object-cover sm-pr-125px"
          />
        </div>
        <div className="py-6 lg:hidden md:hidden block text-center">
        <h1
          id="5338554480"
          className="dynamicStyle fsize34 md-fsize28 sm-fsize20 font-semibold text-secondary"
        >
          {data ? data["5338554480"] : "Lorem ipsum"}
        </h1>
        <p
          id="4744958819"
          dangerouslySetInnerHTML={{
            __html: data
              ? data["4744958819"]
              : " publishing and graphic design, Lorem ipsum is a placeholder text",
          }}
          className="dynamicStyle  sm-fsize14 text-gray-400"
        ></p>
        <Button
          px={6}
          className={
            colorMode === 'light'
              ? 'dynamicStyle bg-secondary clr-fff theme-btn bdr-0px mt-6 sm-fsize14'
              : 'dynamicStyle theme-btn cust-bgColor bdr-0px mt-6 cust-textColor sm-fsize14 '
          }
          id="7960944207"
        >
         
            {data ? data["7960944207"] : "Find Coach"}
       
        </Button>
        </div>
        <div id="3921298872" className="dynamicStyle2 h-300px sm-h-200px lg:w-2/6">
          <Image
            src={data ? getImage(data["3921298872"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
            className="h-300px sm-h-200px sm-pl-100px w-full object-left object-cover"
          />
        </div>
      </div>
      <div className="car-round1 bg-secondary absolute top-0 right-0 z-10 mob-hidden"></div>
    </div>
  );
};

export default FeatureCard32;
