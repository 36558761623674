import React, { useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';

const SurveyCards = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {}, []);
  return (
    <section className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <div>
        <p
          id="6112603335"
          className="dynamicStyle fsize36 sm-fsize24 font-semibold lg:pb-6 md:pb-6 pb-4 cust-textColor"
        >
          {data ? data['6112603335'] : 'Lorem ipsum'}
        </p>
        <div className="w-full grid lg:grid-cols-4 md:grid-cols-3 gap-8">
          <div>
            <div className="overflow-hidden">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="h-250px w-full custimghover"
              />
            </div>
            <p className="mt-2 textColor-light text-dark">Lorem Ipsum</p>
          </div>
          <div className="w-full">
            <div className="overflow-hidden">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="h-250px w-full custimghover"
              />
            </div>
            <p className="mt-2 textColor-light text-dark">Lorem Ipsum</p>
          </div>
          <div className="w-full">
            <div className="overflow-hidden">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="h-250px w-full custimghover"
              />
            </div>
            <p className="mt-2 textColor-light text-dark">Lorem Ipsum</p>
          </div>
          <div className="w-full">
            <div className="overflow-hidden">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="h-250px w-full custimghover"
              />
            </div>
            <p className="mt-2 textColor-light text-dark">Lorem Ipsum</p>
          </div>
          <div className="w-full">
            <div className="overflow-hidden">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="h-250px w-full custimghover"
              />
            </div>
            <p className="mt-2 textColor-light text-dark">Lorem Ipsum</p>
          </div>
          <div className="w-full">
            <div className="overflow-hidden">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="h-250px w-full custimghover"
              />
            </div>
            <p className="mt-2 textColor-light text-dark">Lorem Ipsum</p>
          </div>
          <div className="w-full">
            <div className="overflow-hidden">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="h-250px w-full custimghover"
              />
            </div>
            <p className="mt-2 textColor-light text-dark">Lorem Ipsum</p>
          </div>
          <div className="w-full">
            <div className="overflow-hidden">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                className="h-250px w-full custimghover"
              />
            </div>
            <p className="mt-2 textColor-light text-dark">Lorem Ipsum</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SurveyCards;
