import React from 'react';
import Banner from './components/Banner';
import Approvaltab from './components/Approvaltab';

const ApprovalTabs = () => {
  return (
    <div>
      <Banner />
      <Approvaltab />
    </div>
  );
};

export default ApprovalTabs;
