import { Image } from '@chakra-ui/react';
import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaPinterest,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { useSelector } from 'react-redux/es/exports';

const Footer11 = () => {
  let brandProfile = useSelector(state => state.brandDetails.brandProfile);

  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }
  return (
    <div className="bg-dark">
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-10 py-8">
        <div className="lg:flex md:flex w-full">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <a href="/">
              <Image
                src={getSecondaryImage(brandProfile)}
                alt="footer-logo"
                className="h-20 w-52 object-contain"
              />
            </a>
            <p className="lg:my-4 md:my-4 my-3 text-white textColor-light fsize14 lg:w-1/2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis
              odit sunt nihil?
            </p>

            <div className="flex gap-2 my-4">
              <div className="">
                <FaWhatsapp className="text-primary w-12 h-12" />
              </div>
              <div className="">
                <p className="text-primary tracking-widest">Whatsapp</p>
                <a href="tel:1234567890">
                  <span className="clr-fff fsize20">+123 456 789</span>
                </a>
              </div>
            </div>

            <div className="lg:pt-4 md:pt-4 flex gap-4">
              <div className="footer-icon lg:w-16 lg:h-16 md:h-12 md:w-12 w-12 h-12 rounded-full ">
                <FaFacebookF className="clr-fff" />
              </div>
              <div className="footer-icon lg:w-16 lg:h-16 md:h-12 md:w-12 w-12 h-12 rounded-full ">
                <FaInstagram className="clr-fff" />
              </div>
              <div className="footer-icon lg:w-16 lg:h-16 md:h-12 md:w-12 w-12 h-12 rounded-full ">
                <FaYoutube className="clr-fff" />
              </div>
              <div className="footer-icon lg:w-16 lg:h-16 md:h-12 md:w-12 w-12 h-12 rounded-full ">
                <FaPinterest className="clr-fff" />
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-8">
            <div className="w-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4 lg:pb-20 md:pb-10 pb-6">
              <div className="">
                <p className="font-semibold mb-6 fsize18 font-semibold mb-3 fsize18 text-primary cust-textColor">
                  Supports
                </p>
                <ul className="list-none">
                  <a href="/" className="">
                    <li className="fsize14 mb-1 textColor-light clr-fff pb-2 anchor-li ">
                      Link
                    </li>
                  </a>
                  <a href="/" className="">
                    <li className="fsize14 mb-1 textColor-light clr-fff pb-2 anchor-li">
                      Link
                    </li>
                  </a>
                  <a href="/" className="">
                    <li className="fsize14 mb-1 textColor-light clr-fff pb-2 anchor-li">
                      Link
                    </li>
                  </a>
                  <a href="/" className="">
                    <li className="fsize14 mb-1 textColor-light clr-fff pb-2 anchor-li">
                      Link
                    </li>
                  </a>
                </ul>
              </div>
              <div className="">
                <p className="font-semibold mb-6 fsize18 font-semibold mb-3 fsize18 text-primary cust-textColor">
                  Supports
                </p>
                <ul className="list-none">
                  <a href="/" className="">
                    <li className="fsize14 mb-1 textColor-light clr-fff pb-2 anchor-li ">
                      Link
                    </li>
                  </a>
                  <a href="/" className="">
                    <li className="fsize14 mb-1 textColor-light clr-fff pb-2 anchor-li">
                      Link
                    </li>
                  </a>
                  <a href="/" className="">
                    <li className="fsize14 mb-1 textColor-light clr-fff pb-2 anchor-li">
                      Link
                    </li>
                  </a>
                  <a href="/" className="">
                    <li className="fsize14 mb-1 textColor-light clr-fff pb-2 anchor-li">
                      Link
                    </li>
                  </a>
                </ul>
              </div>
              <div className="">
                <p className="font-semibold mb-6 fsize18 font-semibold mb-3 fsize18 text-primary cust-textColor">
                  Supports
                </p>
                <ul className="list-none">
                  <a href="/" className="">
                    <li className="fsize14 textColor-light clr-fff pb-2 anchor-li ">
                      Link
                    </li>
                  </a>
                  <a href="/" className="">
                    <li className="fsize14 textColor-light clr-fff pb-2 anchor-li">
                      Link
                    </li>
                  </a>
                  <a href="/" className="">
                    <li className="fsize14 textColor-light clr-fff pb-2 anchor-li">
                      Link
                    </li>
                  </a>
                  <a href="/" className="">
                    <li className="fsize14 textColor-light clr-fff pb-2 anchor-li">
                      Link
                    </li>
                  </a>
                </ul>
              </div>
            </div>
            <div className="flex items-center flex-wrap lg:gap-8 md:gap-4 gap-4">
              <a href="/">
                <span className="clr-fff fsize14">Terms & Conditions</span>
              </a>
              <a href="/">
                <span className="clr-fff fsize14">Privacy Policy</span>
              </a>
              <p className="clr-fff fsize14">
                Copyrights 2024 - 2024 World Unite!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer11;
