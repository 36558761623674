import React from 'react';
import { Image, useColorMode } from '@chakra-ui/react';
import { Mail } from 'react-feather';
import { useSelector } from 'react-redux/es/exports';

const AboutSection34 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div
      id="3050828218"
      className="dynamicStyle2 relative h-800px md-h-700px sm-h550px"
    >
      <Image
        src={data ? getImage(data['3050828218']) : ''}
        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
        alt="img"
        className="h-800px md-h-700px sm-h550px w-full object-cover"
      />
      <div className="h-800px md-h-700px sm-h550px absolute top-0 left-0 w-full flex items-center">
        <div className="lg:w-1/2 md:w-3/5 lg:pl-20 md:pl-12 md:px-0 px-5 lg:py-20 md:py-16 py-8">
          <h2
            id="8526373002"
            className="dynamicStyle fsize48 md-fsize36 sm-fsize24 lheight48  sm-lh-24 font-light"
          >
            {data ? data['8526373002'] : 'Lorem ipsum'}
          </h2>
          <h2
            id="5882479251"
            className="dynamicStyle fsize64 md-fsize48 md-lh-48 sm-fsize32 sm-lh-46 font-semibold font-semibold"
          >
            {data ? data['5882479251'] : 'Lorem ipsum dolor sit amet'}
          </h2>
          <p
            id="5825557903"
            dangerouslySetInnerHTML={{
              __html: data ? data['5825557903'] : 'Lorem ipsum generated',
            }}
            className="dynamicStyle fsize24 md-fsize20 fsize16 font-light text-primary lg:mt-4 md:mt-3 mt-2 mb-3"
          ></p>

          <div
            id="7300963272"
            className="dynamicStyle grid grid-cols-2 lg:gap-12 md:gap-8 gap-6 lg:mt-12 md:mt-10 mt-6"
          >
            {data && data['7300963272']
              ? data['7300963272'].map((e, index) => {
                  return (
                    <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
                      <Image
                        src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="skin"
                        className="lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover"
                      />
                      <p className="fsize16 sm-fsize14">{e.field2}</p>
                    </div>
                  );
                })
              : 'no-card'}
            {/* <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpgs"
                alt="skin"
                className="lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover"
              />
              <p className="fsize16 sm-fsize14">Lorem Ispum</p>
            </div>
            <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpgs"
                alt="skin"
                className="lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover"
              />
              <p className="fsize16 sm-fsize14">Lorem Ispum</p>
            </div>
            <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpgs"
                alt="skin"
                className="lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover"
              />
              <p className="fsize16 sm-fsize14">Lorem Ispum</p>
            </div>
            <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpgs"
                alt="skin"
                className="lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover"
              />
              <p className="fsize16 sm-fsize14">Lorem Ispum</p>
            </div>
            <div className="flex items-center lg:gap-4 md:gap-3 gap-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpgs"
                alt="skin"
                className="lg:w-16 lg:h-16 md:w-12 md:h-12 w-10 h-10 bg-primary object-cover"
              />
              <p className="fsize16 sm-fsize14">Lorem Ispum</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection34;
