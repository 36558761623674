import React, { useEffect, useState } from 'react';
import {
  SpeechRecognizer,
  SpeechSynthesizer,
  AudioConfig,
  SpeechConfig,
  ResultReason,
} from 'microsoft-cognitiveservices-speech-sdk';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { OpenAIClient, AzureKeyCredential } from '@azure/openai';
import { debounce } from 'lodash';
import { openAiClient } from './OpenAiClient';
import {
  setConversationHistory,
  setRecognizedText,
  setuseGpt,
  setRecognizer,
} from '../../../../../redux/Aiassist/Aiassist-actions';
import getCurrentTime from './getCurrentTime';

const Recognizer = async (dispatch, targetLanguage, setpause) => {
  const speechConfig = SpeechConfig.fromSubscription(
    // (await handler()).speechKey,
    process.env.REACT_APP_SPEECH_KEY,
    process.env.REACT_APP_SPEECH_REGION
  );
  speechConfig.speechRecognitionLanguage = targetLanguage;
  const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
  let recognizer = new SpeechRecognizer(speechConfig, audioConfig);

  let timeout;
  recognizer.recognized = (s, e) => {
    if (e.result.reason === ResultReason.RecognizedSpeech) {
      dispatch(setRecognizedText(e.result.text));
      dispatch(
        setConversationHistory({
          role: 'user',
          content: e.result.text,
          timestamp: getCurrentTime(),
        })
      );
      dispatch(setuseGpt(true));
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        recognizer.stopContinuousRecognitionAsync();
        setpause(true);
      }, 500);
    } else {
    }
  };
  recognizer.canceled = (s, e) => {
    console.error('Speech recognition canceled:', e.errorDetails);
    if (e.errorDetails === 'Error: No available microphone is found ') {
      Recognizer();
    }
  };

  dispatch(setRecognizer(recognizer));
};
export default Recognizer;
