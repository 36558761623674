const initialState = {
  LoginGateway: {},
};
const LoginGateway = (state = initialState, action) => {
  switch (action.type) {
    case 'setLoginGateway':
      return { ...state, LoginGateway: action.payload };

    default:
      return state;
  }
};

export default LoginGateway;
