import React from 'react';
import Banner from './components/Banner';
import BreadCumb from './components/BreadCumb';
import Folder from './components/Folder';

const ReviewAssets = () => {
  return (
    <div>
      <Banner />
      <BreadCumb />
      <Folder />
    </div>
  );
};

export default ReviewAssets;
