import React, { useEffect, useRef, useState } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux/es/exports';
import { getAllCourses } from '../../../../redux/elearningProduct/products-action';
import { useDispatch } from 'react-redux';
import { handleScroll } from '../../components/fadeeffect/FadeEffect';

const AllCourses = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  // function getImage(image) {
  //   if (image && image.includes('blob:')) {
  //     return image;
  //   } else {
  //     return process.env.REACT_APP_STORAGE_URL + image;
  //   }
  // }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCourses());
  }, []);
  let allCourse = useSelector(state => state.elearningProduct.Course);
  console.log(allCourse);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <>
      <div className="container m-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
        <div className="">
          <h2 id='1667918791' className="dynamicStyle inline-block fsize28 md-fsize24 sm-fsize20 font-medium cust-textColor ">
            {data ? data['1667918791'] : 'Latest Search'}
          </h2>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 lg:pt-12 md:pt-10 pt-8 lg:pb-18 md:pb-16 pb-12">
            {allCourse && allCourse.result && allCourse.result.length > 0
              ? allCourse.result.map((course, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        (colorMode === 'light'
                          ? 'rounded-lg cursor-pointer  themeShadow'
                          : 'cust-bgColor rounded-lg  cursor-pointer') +
                        ' ' +
                        (selectedStyle === 'Default' && colorMode !== 'dark'
                          ? 'cust-shadow'
                          : '')
                      }
                    >
                      <div className="relative image-product overflow-hidden bradius-card-top">
                        <Image
                          className="img-h-220 w-full opct"
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                          alt="img"
                        />
                        <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                          <div className="">
                            <p className="fsize14 textColor-light">4.5/5</p>
                          </div>
                          <div>
                            <FeatherIcon
                              className="filled-blk "
                              icon="star"
                              size={18}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="p-4">
                        <div className="flex justify-between  text-b2b2b2">
                          <p className="fsize14 font-medium">12 Lessons</p>
                          <p className=" fsize14  font-medium">
                            {course.totalhours} hours
                          </p>
                        </div>
                        <div className="py-3">
                          <p className="fsize16 sm-fsize16 font-semibold textColor-light lineClamp1">
                            {course['course name']}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                            ₹ {course['actual rate']}/-
                          </p>
                          <div className="flex gap-4">
                            <FeatherIcon
                              className=" cust-textColor"
                              icon="heart"
                              size={20}
                            />
                            <FeatherIcon
                              className="cust-textColor "
                              icon="shopping-cart"
                              size={20}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : 'no-data'}
          </div>
        </div>
        <div className="">
          <h2 id='3061999518' className="dynamicStyle inline-block fsize28 md-fsize24 sm-fsize20 font-medium cust-textColor ">
            {data ? data['3061999518'] : 'Popular Course'}
          </h2>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 lg:pt-12 md:pt-10 pt-8 lg:pb-18 md:pb-16 pb-12">
            {allCourse && allCourse.result && allCourse.result.length > 0
              ? allCourse.result.map((course, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        (colorMode === 'light'
                          ? 'rounded-lg cursor-pointer  themeShadow'
                          : 'cust-bgColor rounded-lg  cursor-pointer') +
                        ' ' +
                        (selectedStyle === 'Default' && colorMode !== 'dark'
                          ? 'cust-shadow'
                          : '')
                      }
                    >
                      <div className="relative image-product overflow-hidden bradius-card-top">
                        <Image
                          className="img-h-220 w-full opct"
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                          alt="img"
                        />
                        <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                          <div className="">
                            <p className="fsize14 textColor-light">4.5/5</p>
                          </div>
                          <div>
                            <FeatherIcon
                              className="filled-blk "
                              icon="star"
                              size={18}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="p-4">
                        <div className="flex justify-between  text-b2b2b2">
                          <p className="fsize14 font-medium">12 Lessons</p>
                          <p className=" fsize14  font-medium">
                            {course.totalhours} hours
                          </p>
                        </div>
                        <div className="py-3">
                          <p className="fsize16 sm-fsize16 font-semibold textColor-light lineClamp1">
                            {course['course name']}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                            ₹ {course['actual rate']}/-
                          </p>
                          <div className="flex gap-4">
                            <FeatherIcon
                              className=" cust-textColor"
                              icon="heart"
                              size={20}
                            />
                            <FeatherIcon
                              className="cust-textColor "
                              icon="shopping-cart"
                              size={20}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : 'no-data'}
          </div>
        </div>
        <div className="">
          <h2 id='8936992385' className="dynamicStyle inline-block fsize28 md-fsize24 sm-fsize20 font-medium cust-textColor ">
            {data ? data['8936992385'] : 'All Course'}
          </h2>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 lg:pt-12 md:pt-10 pt-8 lg:pb-18 md:pb-16 pb-12">
            {allCourse && allCourse.result && allCourse.result.length > 0
              ? allCourse.result.map((course, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        (colorMode === 'light'
                          ? 'rounded-lg cursor-pointer  themeShadow'
                          : 'cust-bgColor rounded-lg  cursor-pointer') +
                        ' ' +
                        (selectedStyle === 'Default' && colorMode !== 'dark'
                          ? 'cust-shadow'
                          : '')
                      }
                    >
                      <div className="relative image-product overflow-hidden bradius-card-top">
                        <Image
                          className="img-h-220 w-full opct"
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                          alt="img"
                        />
                        <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                          <div className="">
                            <p className="fsize14 textColor-light">4.5/5</p>
                          </div>
                          <div>
                            <FeatherIcon
                              className="filled-blk "
                              icon="star"
                              size={18}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="p-4">
                        <div className="flex justify-between  text-b2b2b2">
                          <p className="fsize14 font-medium">12 Lessons</p>
                          <p className=" fsize14  font-medium">
                            {course.totalhours} hours
                          </p>
                        </div>
                        <div className="py-3">
                          <p className="fsize16 sm-fsize16 font-semibold textColor-light lineClamp1">
                            {course['course name']}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                            ₹ {course['actual rate']}/-
                          </p>
                          <div className="flex gap-4">
                            <FeatherIcon
                              className=" cust-textColor"
                              icon="heart"
                              size={20}
                            />
                            <FeatherIcon
                              className="cust-textColor "
                              icon="shopping-cart"
                              size={20}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : 'no-data'}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllCourses;
