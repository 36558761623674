import React, { useState } from 'react';
import { Button, Image, Input, Select } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorMode,
} from '@chakra-ui/react';
import TimezoneSelect from 'react-timezone-select';

const BookingPortal2 = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTimezone, setselectedTimezone] = useState('');
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className=" w-full bg-f4f5f6 cust-bgColor lg:px-12 md:px-8 px-5">
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className='bg-primary cust-bgColor'>
            <h5 className="text-white fsize17">Please Enter your details</h5>
          </ModalHeader>
          <ModalCloseButton mt={1} color="white" />
          <ModalBody>
            <div className="lg:py-3 py-2">
              <div className="">
                <Input placeholder="Name" className="mt-2 textColor-light" h={12} />
                <Input placeholder="Email Id" className="mt-2 textColor-light" h={12} />
                <Input placeholder="Phone Number" className="mt-2 textColor-light" h={12} />
                <Input
                  placeholder="Time Zone"
                  type="date"
                  className="mt-2 textColor-light"
                  h={12}
                />
                <Input
                  placeholder="Time Zone"
                  type="time"
                  className="mt-2 textColor-light"
                  h={12}
                />
              </div>
              <div className="mt-5">
                <Button
                  colorScheme="primary"
                  className={colorMode === 'light'
                  ? 'w-full sm-fsize13 lg:py-6'
                  : 'w-full sm-fsize13 lg:py-6 bg-glass cust-textColor'
              }
                >
                  Shedule Appointment
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="container mx-auto">
        <div className="lg:flex md:flex items-center h-100vh lg:py-0 md:py-0 py-12">
          <div className="lg:w-7/12 md:w-1/2 w-full">
            <div className="text-left lg:w-9/12">
              <h4 className="fsize32 cust-textColor md-fsize28 sm-fsize23 font-semibold">
                Launch an MVP website in just 60 mins
              </h4>
              <p className="fsize17 textColor-light sm-fsize13 mt-2 text-gray">
                Discover a new way of launching your ambitious ideas online with
                a Virtual Teach Team.
              </p>
            </div>
          </div>
          <div className="lg:w-5/12 md:w-1/2 w-full lg:py-0 md:py-0 py-5 lg:pl-0 md:pl-4 pl-0">
            <div className={colorMode === 'light'
                  ? 'lg:p-8 md:p-6 p-4 lg:mt-3 mt-2 bg-white '
                  : 'lg:p-8 md:p-6 p-4 lg:mt-3 mt-2 bg-glass'
              }>
              <div className="grid grid-cols-1 gap-4">
                <div className="">
                  <Select placeholder="Select option" h={16} className="textColor-light">
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </Select>
                </div>
                <div className="">
                  <Input placeholder="Enter" type="date" h={16} className="textColor-light" />
                </div>
                <div className="clsss">
                  <TimezoneSelect
                    styles={{
                      backgroundColor: '#000',
                    }}
                    placeholder="Time Zone"
                    value={selectedTimezone}
                    onChange={setselectedTimezone}
                  />
                </div>
                <div className="">
                  <Input placeholder="Enter" type="time" h={16} className="textColor-light" />
                </div>
              </div>
              <div className="lg:mt-28 md:mt-16 mt-5">
                <Button
                  colorScheme="primary"
                  className="w-full lg:py-8 py-6 sm-fsize13 cust-bgColor cust-textColor"
                  onClick={onOpen}
                >
                  Book Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPortal2;
