import React from 'react';

export default function () {
  return (
    <div className="container mx-auto lg:px-12 md:px-8 lg:py-16 md:py-14 px-5 py-10">
      <div class="text-center">
        <span class="fsize16 md-fsize16 sm-fsize14 text-primary cust-textColor theme-textColor font-medium">Have doubts ? </span>
        <h2 class="fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor theme-textColor mt-2 ">Frequently Asked Questions</h2>
      </div>
      <div class="lg:mt-12 md:mt-12 mt-6 theme-textColor textColor-light">
        <el-collapse class="cust-border-bottom">
          <el-collapse-item class="collapse_bgsecondary py-3" name="1">
            <template slot="title">
              <h2 class="fsize18 font-semibold sm-fsize16">
                Who can be an investor on Bizdateup?
              </h2>
            </template>
            <p class="fsize16 sm-fsize14 textgrey mt-4 para-lineheight">
              Indian citizens who are at least 21 years old and have a solid
              grasp of investing are eligible to become an investor on
              Bizdateup.
            </p>
          </el-collapse-item>

          <el-collapse-item class="collapse_bgsecondary py-3" name="2">
            <template slot="title">
              <h2 class="fsize18 font-semibold sm-fsize16">
                What's the minimum amount of investment one can start with on
                Bizdateup?
              </h2>
            </template>
            <p class="fsize16 sm-fsize14 textgrey mt-4 para-lineheight">
              The minimum amount one can invest through Bizdateup is ₹20,000/-.
            </p>
          </el-collapse-item>

          <el-collapse-item class="collapse_bgsecondary py-3" name="3">
            <template slot="title">
              <h2 class="fsize18 font-semibold sm-fsize16">
                How does the entire process of Startup investing work on
                Bizdateup?
              </h2>
            </template>
            <p class="fsize16 sm-fsize14 textgrey mt-4 para-lineheight">
              Create a Profile: Begin your angel investing journey by creating a
              Bizdateup profile. KYC Verification: Once the investor's KYC has
              been successfully verified, they will be added to Bizdateup and
              given access to curated startups to invest in. Invest in Startups:
              Once an investor finds a startup that matches their vision and
              interests on the "Invest" page, they can invest with a single
              click.
            </p>
          </el-collapse-item>

          <el-collapse-item class="collapse_bgsecondary py-3" name="4">
            <template slot="title">
              <h2 class="fsize18 font-semibold sm-fsize16">
                How much does Bizdateup charge for each investment you make
                through the platform?
              </h2>
            </template>
            <p class="fsize16 sm-fsize14 textgrey mt-4 para-lineheight">
              Bizdateup currently charges no commission to investors on
              investments made through the platform. The primary goal is to
              establish a dependable and transparent ecosystem for investors in
              promising Indian startups.
            </p>
          </el-collapse-item>
        </el-collapse>
        <p class="fsize18 sm-fsize14 font-medium textgrey mt-12">
          Can’t find what you’re looking for? - 
          <a class="textunderline text-primary font-semibold cust-textColor cursor-pointer">
            {" "}Check Here
          </a>
        </p>
      </div>
    </div>
  );
}
