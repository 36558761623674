import { Card, Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

const CardImage6 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-8 py-5">
      <div className="lg:w-2/3 md:w-3/4 w-full mx-auto">
        <p
          id="9626872199"
          className="dynamicStyle text-primary cust-textColor fsize16 md-fsize16 sm-fsize14 font-medium text-center"
        >
          {data ? data['9626872199'] : 'Lorem Ipsum'}
        </p>
        <h2
          id="1087985855"
          className="dynamicStyle cust-textColor fsize36 md-fsize24 sm-fsize20 font-semibold text-center"
        >
          {data ? data['1087985855'] : 'Lorem Ipsum'}
        </h2>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-8 gap-4 w-full lg:mt-12 md:mt-12 mt-6 ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <Card
          className={
            colorMode === 'light' ? 'px-1 py-4 box-shadow-none themeShadow theme-bg themeShadow-insert' : 'px-1 py-4 themeShadow theme-bg '
          }
        >
          <div id="9817888732" className="dynamicStyle2 w-full mb-2">
            <Image
              src={data ? getImage(data['9817888732']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="card-image"
              id=""
              className="h-20 w-20 mx-auto object-cover"
            />
          </div>
          <div className="w-full">
            <h2
              id="4356728801"
              className="dynamicStyle lg:w-2/3 mx-auto mb-3 text-center cust-textColor text-primary font-semibold fsize20 ms:fsize18 sm-fsize16 leading-6"
            >
              {data ? data['4356728801'] : 'Lorem Ipsum'}
            </h2>
            <p
              id="1969993537"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['1969993537']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle text-secondary cust-textColor text-center fsize16 sm-fsize14"
            >
              {/* As Marine Engine Specialist we service and repair a wide range of
              engines and related equipment. */}
            </p>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light' ? 'px-1 py-4 box-shadow-none themeShadow theme-bg themeShadow-insert' : 'px-1 py-4 themeShadow theme-bg '
          }
        >
          <div id="7718335135" className="dynamicStyle2 w-full mb-2">
            <Image
              src={data ? getImage(data['7718335135']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="card-image"
              id=""
              className="h-20 w-20 mx-auto object-cover"
            />
          </div>
          <div className="w-full">
            <h2
              id="5182106385"
              className="dynamicStyle lg:w-2/3 mx-auto mb-3 text-center cust-textColor text-primary font-semibold fsize20 ms:fsize18 sm-fsize16 leading-6"
            >
              {data ? data['5182106385'] : 'Lorem Ipsum'}
            </h2>
            <p
              id="2904428767"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['2904428767']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle text-secondary cust-textColor text-center fsize16 sm-fsize14"
            >
              {/* As Marine Engine Specialist we service and repair a wide range of
              engines and related equipment. */}
            </p>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light' ? 'px-1 py-4 box-shadow-none themeShadow theme-bg themeShadow-insert' : 'px-1 py-4 themeShadow theme-bg '
          }
        >
          <div id="4953077910" className="dynamicStyle2 w-full mb-2">
            <Image
              src={data ? getImage(data['4953077910']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="card-image"
              id=""
              className="h-20 w-20 mx-auto object-cover"
            />
          </div>
          <div className="w-full">
            <h2
              id="5317807836"
              className="dynamicStyle lg:w-2/3 mx-auto mb-3 text-center cust-textColor text-primary font-semibold fsize20 ms:fsize18 sm-fsize16 leading-6"
            >
              {data ? data['5317807836'] : 'Lorem Ipsum'}
            </h2>
            <p
              id="5675908545"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['5675908545']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle text-secondary cust-textColor text-center fsize16 sm-fsize14"
            >
              {/* As Marine Engine Specialist we service and repair a wide range of
              engines and related equipment. */}
            </p>
          </div>
        </Card>
        <Card
          className={
            colorMode === 'light' ? 'px-1 py-4 box-shadow-none themeShadow theme-bg themeShadow-insert' : 'px-1 py-4 themeShadow theme-bg '
          }
        >
          <div id="5048100995" className="dynamicStyle2 w-full mb-2">
            <Image
              src={data ? getImage(data['5048100995']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="card-image"
              id=""
              className="h-20 w-20 mx-auto object-cover"
            />
          </div>
          <div className="w-full">
            <h2
              id="6301805196"
              className="dynamicStyle lg:w-2/3 mx-auto mb-3 text-center cust-textColor text-primary font-semibold fsize20 ms:fsize18 sm-fsize16 leading-6"
            >
              {data ? data['6301805196'] : 'Lorem Ipsum'}
            </h2>
            <p
              id="2494036995"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['2494036995']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle text-secondary cust-textColor text-center fsize16 sm-fsize14"
            >
              {/* As Marine Engine Specialist we service and repair a wide range of
              engines and related equipment. */}
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CardImage6;
