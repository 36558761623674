import React from 'react';
import Banner from './components/Banner';
import ApprovedTab from './components/ApprovedTab';

const ApproveFile = () => {
  return (
    <div>
      <Banner />
      <ApprovedTab />
    </div>
  );
};

export default ApproveFile;
