import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import FeatherIcon from 'feather-icons-react';

const Swipercard = () => {
  return (
    <div className="lg:py-16 md:py-10 py-10 lg:px-12 md:px-8 px-5 container mx-auto">
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        observer={true}
        observeParents={true}
        className="mySwiper icicdirection"
        breakpoints={{
          1536: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
        }}
      >
        <SwiperSlide>
          <div className="relative ">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="home-banner"
              className="h-swiper rounded-lg filter-b4 w-full"
            />
            <div className="w-full lg:px-12 md:px-8 px-5 absolute top-0 left-0 h-swiper flex items-end">
              <div className="">
                <div className="text-left mb-8">
                  <p className="sm-fsize14 text-white">What's new</p>
                  <h3 className="fsize36 sm-fsize19 font-semibold text-white">
                    New Document uploaded on Bankruptcy
                  </h3>
                  <p className="fsize21 sm-fsize16 text-white">
                    New Document uploaded on bankruptcy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative ">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="home-banner"
              className="h-swiper rounded-lg filter-b4 w-full"
            />
            <div className="w-full lg:px-12 md:px-8 px-5 absolute top-0 left-0 h-swiper flex items-end">
              <div className="">
                <div className="text-left mb-8">
                  <p className="sm-fsize14 text-white">What's new</p>
                  <h3 className="fsize36 sm-fsize19 font-semibold text-white">
                    New Document uploaded on Bankruptcy
                  </h3>
                  <p className="fsize21 sm-fsize16 text-white">
                    New Document uploaded on bankruptcy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        observer={true}
        observeParents={true}
        className="mySwiper mt-4 icicdirection2"
        breakpoints={{
          1536: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
        }}
      >
        <SwiperSlide>
          <div className="lg:p-6 md:p-6 p-6 bg-ffeaa1 rounded-lg">
            <div className="text-left">
              <div className="flex items-center gap-2">
                <FeatherIcon icon="info" size={18} />
                <h3 className="fsize20 sm-fsize17 font-semibold clr-232323">
                  Latest update
                </h3>
              </div>

              <p className="fsize15 sm-fsize13 text-gray">
                New Document added in
                <span className="text-dark mx-1">
                  Knowledge Management Portal > Rulebooks
                </span>
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="p-6 bg-ffeaa1 rounded-lg">
            <div className="text-left">
              <div className="flex items-center gap-2">
                <FeatherIcon icon="info" size={18} />
                <h3 className="fsize20 sm-fsize18 font-semibold clr-232323">
                  Latest update
                </h3>
              </div>

              <p className="fsize15 text-gray">
                New Document added in
                <span className="text-dark">
                  Knowledge Management Portal > Rulebooks
                </span>
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Swipercard;
