import React from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const UXP = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className="lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6 ">
      <div className="lg:flex md:flex block items-center">
        <div className="lg:w-5/12 md:w-4/12 w-full">
          <h4
            id="9547017465"
            className="dynamicStyle cust-textColor fsize32 md-fsize25 font-semibold text-left sm-fsize20 "
          >
            {data ? data['9547017465'] : 'Welcome to My World'}
          </h4>
        </div>
        <div className="lg:w-7/12 md:w-8/12 w-full lg:mt-0 md:mt-0 mt-3">
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            className="mySwiper p-2"
            breakpoints={{
              1536: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              820: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div
                className={
                  colorMode === 'light'
                    ? 'flex items-center cust-shadow bg-white rounded-lg overflow-hidden lg:ml-0 md:ml-0 ml-3'
                    : 'flex items-center cust-shadow rounded-lg overflow-hidden lg:ml-0 md:ml-0 ml-3 cust-bgColor'
                }
              >
                <div className="w-4/12">
                  <Image
                    src={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="image"
                    className="discount-img"
                  />
                </div>
                <div className="w-8/12 pl-2">
                  {' '}
                  <h2 className="fsize16 font-semibold cust-textColor">
                    Lorem Ipsum
                  </h2>
                  <p className="text-gray fsize13 textColor-light">20%</p>
                  <p className="text-primary font-semibold fsize14 textColor-light mt-2">
                    Shop Now
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className={
                  colorMode === 'light'
                    ? 'flex items-center cust-shadow bg-white rounded-lg overflow-hidden lg:ml-0 md:ml-0 ml-3'
                    : 'flex items-center cust-shadow rounded-lg overflow-hidden lg:ml-0 md:ml-0 ml-3 cust-bgColor'
                }
              >
                <div className="w-4/12">
                  <Image
                    src={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="image"
                    className="discount-img"
                  />
                </div>
                <div className="w-8/12 pl-2">
                  {' '}
                  <h2 className="fsize16 font-semibold cust-textColor">
                    Lorem Ipsum
                  </h2>
                  <p className="text-gray fsize13 textColor-light">20%</p>
                  <p className="text-primary font-semibold fsize14 textColor-light mt-2">
                    Shop Now
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className={
                  colorMode === 'light'
                    ? 'flex items-center cust-shadow bg-white rounded-lg overflow-hidden lg:ml-0 md:ml-0 ml-3'
                    : 'flex items-center cust-shadow rounded-lg overflow-hidden lg:ml-0 md:ml-0 ml-3 cust-bgColor'
                }
              >
                <div className="w-4/12">
                  <Image
                    src={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="image"
                    className="discount-img"
                  />
                </div>
                <div className="w-8/12 pl-2">
                  {' '}
                  <h2 className="fsize16 font-semibold cust-textColor">
                    Lorem Ipsum
                  </h2>
                  <p className="text-gray fsize13 textColor-light">20%</p>
                  <p className="text-primary font-semibold fsize14 textColor-light mt-2">
                    Shop Now
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default UXP;
