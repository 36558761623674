import { Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Image } from '@chakra-ui/react';

const LeftTextRightImage1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="container mx-auto md:px-6 lg:px-12 px-5 lg:py-16 md:py-16 py-8">
      <div className="w-full lg:flex md:flex block items-center">
        <div
          className="lg:w-1/2 md:w-1/2 w-full  lg:pr-12 "
          data-aos="fade-up"
          data-aos-once="true"
        >
          <p
            id="3719579826"
            className="dynamicStyle fsize24 sm-fsize16 letter-spacing4px font-semibold clr-ddd9d8"
          >
            {data ? data['3719579826'] : 'Lorem Ipsum'}
          </p>
          <h1
            id="3080051043"
            className="dynamicStyle fsize40 sm-fsize24  font-semibold"
          >
            {data ? data['3080051043'] : 'Lorem Ipsum'}
          </h1>
          <p
            id="2754194557"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['2754194557']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle fsize18 sm-fsize14 mb-4"
          ></p>
          <Button
            className="dynamicStyle smcust-btn"
            width="180px"
            height="50px"
            colorScheme="primary"
            id="9055503124"
          >
            {data ? data['9055503124'] : 'Enquire Now'}
          </Button>
        </div>
        <div className="relative lg:w-1/2 md:w-1/2 w-full flex justify-end items-center lg:mt-0 md:mt-0 mt-5">
          <div
            id="9433840050"
            className="dynamicStyle2 absolute front-img-div w-full"
          >
            <Image
              src={data ? getImage(data['']) : '9433840050'}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="front-img"
              className="front-img object-cover"
            />
          </div>
          <div className="back-img-div overflow-hidden flex justify-center items-center lg:ml-30 md:ml-12 ml-16">
            <div id="9960594296" className="dynamicStyle2 back-img w-full">
              <Image
                src={data ? getImage(data['']) : '9960594296'}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="back-img"
                className="back-img object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeftTextRightImage1;
