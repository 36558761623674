import React, { useEffect, useRef, useState } from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection12 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="">
      <div className="container mx-auto lg:py-16 py-8 lg:px-12 md:px-8 px-5">
        <div className="lg:flex items-center">
          <div className="lg:w-6/12 lg:pr-10 lg:mt-0 mt-4 lg:mb-0 mb-4">
            <p
              className="fsize16 md-fsize16 sm-fsize14 text-primary cust-textColor font-semibold dynamicStyle"
              id="5243023323"
            >
              {data ? data['5243023323'] : 'Lorem Ipsum'}
            </p>
            <h2
              className="fsize34 md-fsize24 sm-fsize20 font-semibold  cust-textColor dynamicStyle"
              id="4772448594"
            >
              {data ? data['4772448594'] : 'Lorem Ipsum'}
            </h2>
            <p
              className="fsize16 sm-fsize14 textColor-light lg:mt-2 md:mt-2 mt-1  dynamicStyle"
              id="9823441385"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['9823441385']
                  : ' publishing and graphic design, Lorem ipsum is a placeholder text',
              }}
            ></p>
          </div>
          <div
            ref={elementRef}
            className={`fade-right lg:w-6/12 lg:pl-10 ${
              isVisible ? 'visibleright' : ''
            } `}
          >
            <div id="8824589259" className="dynamicStyle2 cus-hbanner w-full">
              <Image
                src={data ? getImage(data['8824589259']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="ilm-img rounded-lg"
                alt="ilm"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection12;
