import { Button, useColorMode } from '@chakra-ui/react';
import React from 'react';

const ContactUs = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <>
      <div className="lg:px-0 md:px-0 px-5 lg:pt-0 md:pt-0 pt-8">
        <div className="flex">
          <h2 className="fsize32 sm-fsize24 font-semibold cust-textColor ">Contact</h2>
        </div>
        <div
        className={
          colorMode === 'light'
            ? 'contact-area bg-f8fbfd mt-6'
            : 'contact-area border mt-6'
        }
        >
          <h5 className="fsize25 sm-fsize16 mb-3 cust-textColor">
            I'm Always Open To Discussing Product
          </h5>
          <h5 className="fsize25 sm-fsize16 font-semibold mb-10 cust-textColor">
            design work or partnerships.
          </h5>
          <div className="">
            <div className="lg:mb-10 md:mb-8 mb-6">
              <p className="leading-none fsize16 sm-fsize14 cust-textColor">Name *</p>
              <input className="input-box" />
            </div>
            <div className="lg:mb-10 md:mb-8 mb-6">
              <p className="leading-none fsize16 sm-fsize14 cust-textColor">Email *</p>
              <input className="input-box" />
            </div>
            <div className="lg:mb-10 md:mb-8 mb-6">
              <p className="leading-none fsize16 sm-fsize14 cust-textColor">Message *</p>
              <input type="textarea" className="input-box" />
            </div>
            <Button className="form-btn">Submit</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
