import React, { useEffect } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import { Globe, Star } from 'react-feather';
import { PlayCircle } from 'react-feather';
import { Folder, Monitor, File, Link, Smartphone, Award } from 'react-feather';
import { useSelector } from 'react-redux/es/exports';
import { useDispatch } from 'react-redux';
import { getAllCourses } from '../../../../redux/elearningProduct/products-action';
import { useNavigate } from 'react-router-dom';

const ProductDetails1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
    // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  // const [searchParams] = useSearchParams();

  let selectCourse = useSelector(
    state => state.elearningProduct.selectedCourse
  );
  console.log(selectCourse, 'selectCourse');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCourses());
    // getQueryData();
  }, [dispatch]);
  // const getQueryData = async () => {
  //   await dispatch(getAllCourses());
  //   let courseId = searchParams.get('ci');
  //   console.log(courseId, 'courseId');

  //   if (
  //     courseId &&
  //     allCourse &&
  //     allCourse.result &&
  //     allCourse.result.length > 0
  //   ) {
  //     let filterCourseData = allCourse.result.filter(
  //       course => course._id === courseId
  //     );
  //     if (filterCourseData && filterCourseData.length > 0) {
  //       setSelectedCourse(filterCourseData[0]);
  //       console.log(filterCourseData, 'filterBlog');
  //     }
  //   }

  // };
  return (
    <>
      <div className={colorMode === 'light' ? 'bg-f8f8f8' : ''}>
        <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-12 py-8">
          <div className="lg:flex md:flex lg:gap-16 md:gap-12 sm-custdirection ">
            <div className="lg:w-3/5 md:w-3/5 w-full lg:pt-0 md:pt-0 pt-6">
              <div>
                <h2 className="fsize34 md-fsize24 sm-fsize20 mb-3 cust-textColor text-primary font-semibold">
                  {selectCourse && selectCourse['course name']
                    ? selectCourse['course name']
                    : ''}
                </h2>
                <p className="fsize16 sm-fsize14 lg:leading-7 md:leading-7 leading-6 textColor-light">
                  {selectCourse && selectCourse.coursecontent
                    ? selectCourse.coursecontent
                    : ''}
                </p>

                <div className="flex gap-2 py-3 textColor-light">
                  <Star className="w-4 h-4 " />
                  <Star className="w-4 h-4 " />
                  <Star className="w-4 h-4 " />
                  <Star className="w-4 h-4 " />
                  <Star className="w-4 h-4 " />
                  <p className="fsize12 cust-textColor">
                    {' '}
                    (4,129 rating) 5,000 Student
                  </p>
                </div>
                <p className="fsize14 lg:py-2 md:py-2 textColor-light">
                  Created by{' '}
                  <span className="font-semibold cust-textColor">
                    {' '}
                    {selectCourse && selectCourse.createdBy
                      ? selectCourse.createdBy.name
                      : ''}
                  </span>
                </p>
                <div className="flex gap-4 py-2 textColor-light">
                  <div className="flex items-center gap-1">
                    <Globe className=" w-4 h-4" />
                    <p className="sm-fsize14 ">English</p>
                  </div>
                  <div className="flex items-center gap-1 ">
                    <Globe className=" w-4 h-4" />
                    <p className="sm-fsize14">English and Hindi</p>
                  </div>
                </div>
                <p className="fsize16 md-fsize16 sm-fsize14">
                  ₹ {selectCourse['actual rate']}/-
                </p>
                <div className="lg:pt-6 md:pt-6 pt-4">
                  <Button
                    size="lg"
                    className="dynamicStyle theme-btn2 lg:py-4 md:py-3 py-2 bg-primary cust-bgColor clr-fff rounded-full  text-white px-8"
                    id="9602253853"
                  >
                    <span onClick={() => handleClick('9602253853')}>
                      {data ? data['9602253853'] : 'Enroll Now'}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="lg:w-2/5 md:w-2/5 w-full justify-center flex flex-col">
              <Image
                src={`${process.env.REACT_APP_STORAGE_URL}${
                  selectCourse && selectCourse.image ? selectCourse.image : ''
                }`}
                alt="img"
                className="h-300px rounded-lg w-full md-h-250px sm-h-250px"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto lg:px-12 md:px-8 px-5">
      <div className="lg:flex md:flex gap-16 justify-between lg:py-16 md:py-12 py-10 ">
        <div className="lg:w-3/5 md:w-1/2">
          <h2
            id="1060033374"
            className="dynamicStyle cust-textColor fsize24 sm-fsize20  font-semibold pb-2"
          >
            {data ? data['1060033374'] : 'Description'}
          </h2>
          <p className='fsize18 sm-fsize16 mb-3'>What you'all learn</p>
          <p className="sm-fsize14 textColor-light">
            {selectCourse && selectCourse.coursecontent
              ? selectCourse.coursecontent
              : ''}
          </p>
        </div>
        <div className="lg:w-2/5 md:w-1/2 lg:pt-0 md:pt-0 pt-5">
          <div className="lg:flex  items-center justify-between">
            <h2
              id="9684038619"
              className="dynamicStyle cust-textColor fsize24 sm-fsize20  font-semibold lg:pb-2"
            >
              {data ? data['9684038619'] : 'Lessons in This Class'}
            </h2>

            <p className="sm-fsize14 textColor-light">10 Lessons (30 M)</p>
          </div>

          <div className="flex justify-between pt-5 pb-3 cust-textColor">
            <div className="flex items-center gap-4">
              <PlayCircle className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-4 h-4" />

              <p className="fsize20 sm-fsize16">Introduction</p>
            </div>
            <p className="fsize18 sm-fsize14">2:02</p>
          </div>
          <hr />
          <div className="flex justify-between pt-5 pb-3 cust-textColor">
            <div className="flex items-center gap-4">
              <PlayCircle className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-4 h-4" />

              <p className="fsize20 sm-fsize16">Chapter 1</p>
            </div>
            <p className="fsize18 sm-fsize14">2:02</p>
          </div>
          <hr />
          <div className="flex justify-between pt-5 pb-3 cust-textColor">
            <div className="flex items-center gap-4">
              <PlayCircle className="lg:w-6 lg:h-6 md:w-6 md:h-6 w-4 h-4" />

              <p className="fsize20 sm-fsize16">Chapter 2</p>
            </div>
            <p className="fsize18 sm-fsize14">2:02</p>
          </div>
          <hr />
          <div className="flex justify-end pt-5">
            <p
              colorScheme="primary"
              id="8680046391"
              className="dynamicStyle cursor-pointer cust-textColor rounded-full font-medium "
            >
              {data ? data['8680046391'] : 'View All'}
            </p>
          </div>
        </div>
      </div>
      <div className="pb-10">
        <div>
          <h2
            id="7813381194"
            className="dynamicStyle fsize24 sm-fsize20 font-medium pb-5 cust-textColor"
          >
            {data ? data['7813381194'] : 'This Course includes'}
          </h2>
          <div className="cust-textColor">
            <div className="flex items-center gap-3 pb-3">
              <Monitor className="lg:w-5 lg:h-5 md:w-5 md:h-5 w-4 h-4" />
              <p className="fsize16 sm-fsize14">
                19.5 total hours on-demand video
              </p>
            </div>
            <div className="flex items-center gap-3 pb-3">
              <Folder className="lg:w-5 lg:h-5 md:w-5 md:h-5 w-4 h-4" />
              <p className="fsize16 sm-fsize14">Support files</p>
            </div>
            <div className="flex items-center gap-3 pb-3">
              <File className="lg:w-5 lg:h-5 md:w-5 md:h-5 w-4 h-4" />
              <p className="fsize16 sm-fsize14">20 Articels</p>
            </div>
            <div className="flex items-center gap-3 pb-3">
              <Link className="lg:w-5 lg:h-5 md:w-5 md:h-5 w-4 h-4" />
              <p className="fsize16 sm-fsize14">Full Lifetime accesss</p>
            </div>
            <div className="flex items-center gap-3 pb-3">
              <Smartphone className="lg:w-5 lg:h-5 md:w-5 md:h-5 w-4 h-4" />
              <p className="fsize16 sm-fsize14">
                Access on mobile, desktop, and TV
              </p>
            </div>
            <div className="flex items-center gap-3 pb-3">
              <Award className="lg:w-5 lg:h-5 md:w-5 md:h-5 w-4 h-4" />
              <p className="fsize16 sm-fsize14">Certificate of Completion</p>
            </div>
          </div>
          <h2
            id="2265162603"
            className="dynamicStyle fsize24 sm-fsize20 font-medium py-6 cust-textColor "
          >
            {data ? data['2265162603'] : 'Instructor'}
          </h2>
          <div
            className={
              colorMode === 'light'
                ? 'inborder lg:px-5 md:px-4 px-2 py-4 rounded-lg theme-bg themeShadow theme-border-0'
                : 'border-1px lg:px-5 md:px-4 px-2 py-4 rounded-lg theme-bg themeShadow'
            }
          >
            <div className="flex gap-5 items-center">
              <div>
                <Image
                  className="w-14 h-14 rounded-full"
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                />
              </div>
              <div className="">
                <p className="font-semibold fsize20 sm-fsize18 cust-textColor">
                  Sunny Shetty
                </p>
                <p className=" fsize18 sm-fsize16 textColor-light">
                  Founder of Critical Thinking
                </p>
              </div>
            </div>
            <div className="cust-textColor grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-x-8 gap-y-2 pt-3">
              <div className="flex gap-3 items-center">
                <span className="w-2 h-2 rounded-full bg-red-500 cust-bgColor"></span>
                <p className="sm-fsize14">4.5 Instructor rating</p>
              </div>
              <div className="flex gap-3 items-center">
                <span className="w-2 h-2 rounded-full bg-red-500 cust-bgColor"></span>
                <p className="sm-fsize14">1000 Reviews</p>
              </div>
              <div className="flex gap-3 items-center">
                <span className="w-2 h-2 rounded-full bg-red-500 cust-bgColor"></span>
                <p className="sm-fsize14">1500 Students</p>
              </div>
              <div className="flex gap-3 items-center">
                <span className="w-2 h-2 rounded-full bg-red-500 cust-bgColor"></span>
                <p className="sm-fsize14">4 Courses</p>
              </div>
            </div>
            <p className="pt-3  sm-fsize14 sm-lineclamp-5 textColor-light ">
              Typography animation is a dynamic form of visual storytelling that
              brings written text to life through movement, transformation, and
              creative manipulation of letterforms. It involves animating
              individual characters, words, or sentences in a way that enhances
              their meaning, evokes emotions, and engages the audience.
              Typography animation can be used in various contexts, such as
              video intros, commercials, presentations, and social media
              content, to create eye-catching and memorable visual experiences.
            </p>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default ProductDetails1;
