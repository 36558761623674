import { BellIcon } from '@chakra-ui/icons';
import {
  Avatar,
  WrapItem,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { FaFileMedical, FaQuestion } from 'react-icons/fa';
import { FiBell, FiLogOut, FiUser } from 'react-icons/fi';
import FeatherIcon from 'feather-icons-react';
import { NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialFocusRef = useRef();
  const btnRef = React.useRef();
  return (
    <div className="bg-053c6d ">
      {/* <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent maxWidth="25rem">
          <DrawerCloseButton textColor="White" />
          <DrawerHeader className="bg-053c6d ">
            {' '}
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />

              <Box>
                <Heading size="sm" textColor="White">
                  Hello, User
                </Heading>
                <Text className="fsize12" textColor="White">
                  Welcome Back
                </Text>
              </Box>
            </Flex>
          </DrawerHeader>

          <DrawerBody className="bg-fa" py="1rem">
            <div className="bg-fff px-2 py-2 mb-4">
              <Flex flex="1" gap="3" alignItems="center" flexWrap="wrap">
                <FiUser className="w-8 h-8 borderall rounded-full p-1 text-primary" />
                <Box>
                  <Text className="fsize14 font-medium text-primary">
                    Profile Setting
                  </Text>
                </Box>
              </Flex>
            </div>
            <div className="bg-fff px-2 py-2 mb-4">
              <Flex flex="1" gap="3" alignItems="center" flexWrap="wrap">
                <FaQuestion className="w-8 h-8 borderall rounded-full p-1 text-primary" />
                <Box>
                  <Text className="fsize14 font-medium text-primary">
                    Help & Support
                  </Text>
                </Box>
              </Flex>
            </div>
            <div className="bg-fff px-2 py-2 mb-4">
              <Flex flex="1" gap="3" alignItems="center" flexWrap="wrap">
                <FaFileMedical className="w-8 h-8 borderall rounded-full p-1 text-primary" />
                <Box>
                  <Text className="fsize14 font-medium text-primary">
                    New Document Request
                  </Text>
                </Box>
              </Flex>
            </div>
            <div className="bg-fff px-2 py-2 mb-4">
              <Flex flex="1" gap="3" alignItems="center" flexWrap="wrap">
                <FaFileMedical className="w-8 h-8 borderall rounded-full p-1 text-primary" />
                <Box>
                  <Text className="fsize14 font-medium text-primary">
                    Platform Queries
                  </Text>
                </Box>
              </Flex>
            </div>
            <div className="bg-fff px-2 py-2 mb-4">
              <Flex flex="1" gap="3" alignItems="center" flexWrap="wrap">
                <FiLogOut className="w-8 h-8 borderall rounded-full p-1 text-primary" />
                <Box>
                  <Text className="fsize14 font-medium text-primary">
                    Logout
                  </Text>
                </Box>
              </Flex>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer> */}
      <div className="container mx-auto flex justify-between w-full py-3 lg:px-12 md:px-7 px-5">
        <div className="">
          <a href="/icici">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="logo"
              className="header-logo cursor-pointer object-cover"
            />
          </a>
        </div>
        <div className="w-full flex justify-end items-center lg:gap-4 md:gap-4 gap-2">
          <div className="w-10 h-10 lg:flex justify-center items-center rounded-full bdr md:hidden hidden">
            <FiUser className="textwhite cursor-pointer" />
          </div>
          <div className="w-10 h-10 lg:flex justify-center items-center rounded-full bdr md:hidden hidden">
            <FiUser className="textwhite cursor-pointer" />
          </div>
          <div className="w-10 h-10 lg:flex justify-center items-center rounded-full bdr md:hidden hidden">
            <Popover initialFocusRef={initialFocusRef} placement="bottom">
              <PopoverTrigger>
                <Button p="0" width="0" height="0" colorScheme="transparent">
                  <FiUser className="textwhite cursor-pointer p-0" />
                </Button>
              </PopoverTrigger>
              <PopoverContent mt={4}>
                <PopoverArrow />

                <PopoverBody
                  p="0"
                  className="popwidth flex rounded-lg"
                  overflow="hidden"
                >
                  <div className="w-full">
                    <div className="flex justify-center px-3 pt-5">
                      <WrapItem>
                        <Avatar
                          name="Abhishek Tandel"
                          size="xl"
                          src="https://bit.ly/sage-adebayo"
                        />
                      </WrapItem>
                    </div>
                    <div className="mt-3 px-3">
                      <h4 className="fsize18 text-center text-primary font-medium">
                        Abhishek Tandel
                      </h4>
                      <p className="text-gray text-center fsize14">
                        ICICI Employee
                      </p>
                      <div className="flex justify-center mt-3">
                        <div className="bg-light-sucess">Business User</div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center gap-1 bordh mt-10 py-3">
                      <FeatherIcon
                        icon="log-out"
                        size="16"
                        className="text-primary"
                      />
                      <p className="fsize15 m-0 text-primary">Logout</p>
                    </div>
                  </div>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </div>
          <div className="w-10 h-10 lg:flex justify-center items-center rounded-full bdr md:hidden hidden">
            <FiBell className="textwhite cursor-pointer" />
          </div>
          <div
            className="w-10 h-10 lg:flex justify-center items-center rounded-full bdr md:hidden hidden"
            onClick={onOpen}
          >
            <FiUser className="textwhite cursor-pointer" />
          </div>
          <div className="lg:hidden md:flex flex">
            <Button ref={btnRef} onClick={onOpen}>
              <FaBars />
            </Button>
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                  <a href="/">
                    <Image
                      src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="logo"
                      className="w-12 h-8 object-contain"
                    />
                  </a>
                </DrawerHeader>

                <DrawerBody>
                  <div className=" relative h-100vh">
                    <div className="pt-4">
                      <div className="mt-4 grid grid-cols-1 gap-4">
                        <NavLink to="/">Profile</NavLink>
                        <NavLink to="/">Birthday</NavLink>
                        <NavLink to="/">Questions</NavLink>
                      </div>
                    </div>
                  </div>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
