import React from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';


const GallerySection9 = ({ pageName }) => {
    const data = useSelector(state => state.cms.allSections[pageName]);
    function getImage(image) {
        if (image && image.includes('blob:')) {
          return image;
        } else {
          return process.env.REACT_APP_STORAGE_URL + image;
        }
      }

  return (
    <div className="container mx-auto lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5">
      <div className="lg:grid md:grid lg:grid-cols-3 md:grid-cols-3 sm-rev-col lg:gap-8 md:gap-6">
        <div className="lg:mb-0 md:mb-0 mb-4">
          <div id="0104611642"
            className="dynamicStyle2 lg:h-60 md:h-52 h-48 w-full lg:mb-6 md:mb-6 mb-4">
            <Image
              className="lg:h-60 md:h-52 h-48 w-full rad-ltop-4rem sm-bdr-0px"
              src={data ? getImage(data['0104611642']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <div id="6319917685"
            className="dynamicStyle2 h-480px md-h-400px sm-h200px w-full">
            <Image
              className="h-480px md-h-400px sm-h200px w-full rad-lbot-4rem sm-bdr-0px"
              src={data ? getImage(data['6319917685']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
        </div>
        <div className="lg:mb-0 md:mb-0 mb-4">
          <div id="0315849638"
            className="dynamicStyle2 h-480px md-h-400px sm-h200px w-full lg:mb-6 md:mb-6 mb-4">
            <Image
              className="h-480px md-h-400px sm-h200px w-full rad-rtop-4rem sm-bdr-0px"
              src={data ? getImage(data['0315849638']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <div id="7660051455"
            className="dynamicStyle2 lg:h-60 md:h-52 h-48 w-full">
            <Image
              className="lg:h-60 md:h-52 h-48 w-full rad-rbot-4rem sm-bdr-0px"
              src={data ? getImage(data['7660051455']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
        </div>
        <div className="lg:mb-0 md:mb-0 mb-4">
          <div className="mb-6 px-4 lg:pt-10 md:pt-6 pt-4">
            <p id="4369155915"
              className="dynamicStyle fsize34 md-fsize24 sm-fsize24 font-semibold text-primary text-center lg:px-10 md:px-0 px-2 lg:pb-6 md:pb-6 pb-2 cust-textColor">
              {data ? data['4369155915'] : 'Gallery'}
            </p>
            <p id="9180822815"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['9180822815']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle fsize16 sm-fsize14 lg:px-10 md:px-0 px-2 pb-2 textColor-light text-center">
              {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Impedit,
              earum? */}
            </p>
          </div>
          <div id="3409791451"
            className="dynamicStyle2 h-480px md-h-400px sm-h200px w-full">
            <Image
              className="h-480px md-h-400px sm-h200px w-full rad-rbot-4rem sm-bdr-0px"
              src={data ? getImage(data['3409791451']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection9;
