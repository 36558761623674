import { useContext, useEffect, useRef, useState } from 'react';
import UploaderBox from '../../../../../components/UploaderBox';
import FeatherIcon from 'feather-icons-react';
import { Image } from '@chakra-ui/react';

const UploadBox = ({
  closeUploader,
  selectedFile,
  selectedfilePreview,
  setChatFile,
  setChatFilePreview,
}) => {
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  return (
    <>
      <div className="flex items-center h-full">
        {!filePreview && (
          <FeatherIcon
            icon="x"
            className="cursor-pointer absolute top-4 right-4"
            onClick={() => {
              setFile(null);
              setFilePreview(null);
              setChatFile(null);
              setChatFilePreview(null);
              closeUploader(false);
            }}
          />
        )}
        <div className="relative w-full ">
          {!filePreview ? (
            <div className="">
              <UploaderBox
                accept="image/*"
                onFileSelect={async file => {
                  selectedFile(file);
                  setFile(file);
                }}
                onPreview={async prev => {
                  selectedfilePreview(prev);
                  setFilePreview(prev);
                }}
              />
            </div>
          ) : (
            <>
              <div className="relative lg:w-8/12 mx-auto">
                <Image
                  src={filePreview}
                  className="w-full h-80 object-contain bg-ec"
                />
                <div className="absolute right-0 top-0 m-4">
                  <FeatherIcon
                    icon="x"
                    className="cursor-pointer"
                    onClick={() => {
                      setFile(null);
                      setFilePreview(null);
                      setChatFile(null);
                      setChatFilePreview(null);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadBox;
