import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const Boardofdirectors1 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 lg:py-16 py-8 px-5">
        <h2
          id="5301296068"
          className="dynamicStyle lg:w-2/3 md:w-3/4 w-full mx-auto cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold text-center text-primary"
        >
          {data ? data['5301296068'] : 'board of directors'}
        </h2>

        <div  ref={elementRef} className={`fade-bottom mt-6 lg:mt-12 md:mt-12 lg:grid-cols-4 md:grid-cols-3 grid-cols-1 grid gap-8 ${isVisible ? 'visiblebottom' : ''} `} >
          <Card
            className={
              colorMode === 'light' ? 'mx-auto box-shadow-none themeShadow theme-bg' : 'mx-auto themeShadow theme-bg'
            }
            
          >
            <div
              id="6646800574"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['6646800574']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className=" lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="6618227786"
              className="dynamicStyle text-dark cust-textColor mt-3 text-center font-semibold fsize18 sm-fsize16"
            >
              {data ? data['6618227786'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="0106369722"
              className="dynamicStyle col-79 text-center fsize14 pb-2 textColor-light"
            >
              {data
                ? data['0106369722']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card
            className={
              colorMode === 'light' ? 'mx-auto box-shadow-none themeShadow theme-bg' : 'mx-auto themeShadow theme-bg'
            }
            
          >
            <div
              id="5373088643"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['5373088643']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="3184116159"
              className="dynamicStyle text-dark cust-textColor mt-3 text-center font-semibold fsize18 sm-fsize16"
            >
              {data ? data['3184116159'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="2722677220"
              className="dynamicStyle col-79 text-center fsize14 pb-2 textColor-light"
            >
              {data
                ? data['2722677220']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card
            className={
              colorMode === 'light' ? 'mx-auto box-shadow-none themeShadow theme-bg' : 'mx-auto themeShadow theme-bg'
            }
            
          >
            <div
              id="1472962995"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['1472962995']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="6632170342"
              className="dynamicStyle text-dark cust-textColor mt-3 text-center font-semibold fsize18 sm-fsize16"
            >
              {data ? data['6632170342'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="0629784039"
              className="dynamicStyle col-79 text-center fsize14 pb-2 textColor-light"
            >
              {data
                ? data['0629784039']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card
            className={
              colorMode === 'light' ? 'mx-auto box-shadow-none themeShadow theme-bg' : 'mx-auto themeShadow theme-bg'
            }
            
          >
            <div
              id="4785675785"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['4785675785']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="1916580931"
              className="dynamicStyle text-dark cust-textColor mt-3 text-center font-semibold fsize18 sm-fsize16"
            >
              {data ? data['1916580931'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="1561618838"
              className="dynamicStyle col-79 text-center fsize14 pb-2 textColor-light"
            >
              {data
                ? data['1561618838']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card
            className={
              colorMode === 'light' ? 'mx-auto box-shadow-none themeShadow theme-bg' : 'mx-auto themeShadow theme-bg'
            }
            
          >
            <div
              id="5588355484"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['5588355484']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="5191643203"
              className="dynamicStyle text-dark cust-textColor mt-3 text-center font-semibold fsize18 sm-fsize16"
            >
              {data ? data['5191643203'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="7719084108"
              className="dynamicStyle col-79 text-center fsize14 pb-2 textColor-light"
            >
              {data
                ? data['7719084108']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card
            className={
              colorMode === 'light' ? 'mx-auto box-shadow-none themeShadow theme-bg' : 'mx-auto themeShadow theme-bg'
            }
            
          >
            <div
              id="0100818139"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['0100818139']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="9990358302"
              className="dynamicStyle text-dark cust-textColor mt-3 text-center font-semibold fsize18 sm-fsize16"
            >
              {data ? data['9990358302'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="5308582803"
              className="dynamicStyle col-79 text-center fsize14 pb-2 textColor-light"
            >
              {data
                ? data['5308582803']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card
            className={
              colorMode === 'light' ? 'mx-auto box-shadow-none themeShadow theme-bg' : 'mx-auto themeShadow theme-bg'
            }
            
          >
            <div
              id="8384277288"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['8384277288']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="9675401558"
              className="dynamicStyle text-dark cust-textColor mt-3 text-center font-semibold fsize18 sm-fsize16"
            >
              {data ? data['9675401558'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="2084962983"
              className="dynamicStyle col-79 text-center fsize14 pb-2 textColor-light"
            >
              {data
                ? data['2084962983']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
          <Card
            className={
              colorMode === 'light' ? 'mx-auto box-shadow-none themeShadow theme-bg' : 'mx-auto themeShadow theme-bg'
            }
            
          >
            <div
              id="1991125682"
              className="dynamicStyle2 lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
            >
              <Image
                src={data ? getImage(data['1991125682']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="lg:w-full mx-auto w-full lg:h-52 h-40 object-contain"
                alt="board"
              />
            </div>
            <h6
              id="4057918763"
              className="dynamicStyle text-dark cust-textColor mt-3 text-center font-semibold fsize18 sm-fsize16"
            >
              {data ? data['4057918763'] : 'Mr Dinesh Khara'}
            </h6>
            <p
              id="8203854310"
              className="dynamicStyle col-79 text-center fsize14 pb-2 textColor-light"
            >
              {data
                ? data['8203854310']
                : 'Nominee Director & Chairman of the Board (Chairman SBI)'}
            </p>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Boardofdirectors1;
