import { Image } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection17 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-10 py-8 lg:flex gap-12">
      <div
       
       ref={elementRef} className={`fade-left overflow-hidden w-full h-400px sm-h250px ${isVisible ? 'visibleleft' : ''} `} 
      >
        <div  id="3825243837" className='dynamicStyle2'>
        <Image
          src={data ? getImage(data['3825243837']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="section-image"
          style={{ transform: transform }}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          className="w-full h-400px md-h-300px rounded-lg sm-h250px"
        />
        </div>
      </div>
      <div className="w-full lg:mt-0 md:mt-6 mt-6">
        <h1
          id="4120609481"
          className="dynamicStyle mb-2 uppercase fsize36 font-semibold md-fsize28 sm-fsize20 text-primary cust-textColor"
        >
          {data ? data['4120609481'] : 'Lorem ipsum'}
        </h1>
        <p
          id="8827429270"
          dangerouslySetInnerHTML={{
            __html: data ? data['8827429270'] : 'no-data',
          }}
          className="dynamicStyle fsize14 textColor-light"
        >
          {/* Our Marine Engineering division is dedicated to serving the maritime
          industry with cutting-edge solutions. We offer a wide range of
          services. */}
        </p>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-4 gap-3 mt-6">
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="8189757965" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['8189757965'] : 'Lorem ipsum'}
            </p>
          </div>
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="2512327359" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['2512327359'] : 'Lorem ipsum'}
            </p>
          </div>
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="3971120611" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['3971120611'] : 'Lorem ipsum'}
            </p>
          </div>
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="9487186103" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['9487186103'] : 'Lorem ipsum'}
            </p>
          </div>
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="9067442285" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['9067442285'] : 'Lorem ipsum'}
            </p>
          </div>
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="2510214038" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['2510214038'] : 'Lorem ipsum'}
            </p>
          </div>
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="7335828967" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['7335828967'] : 'Lorem ipsum'}
            </p>
          </div>
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="7033624005" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['7033624005'] : 'Lorem ipsum'}
            </p>
          </div>
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="7583333711" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['7583333711'] : 'Lorem ipsum'}
            </p>
          </div>
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="0662837064" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['0662837064'] : 'Lorem ipsum'}
            </p>
          </div>
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="7627721866" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['7627721866'] : 'Lorem ipsum'}
            </p>
          </div>
          <div className="flex items-center lg:gap-2 md:gap-2 gap-3">
            <div className="dot cust-bgColor"></div>
            <p id="5165770746" className="dynamicStyle fsize14 textdark textColor-light">
              {data ? data['5165770746'] : 'Lorem ipsum'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection17;
