import API from './ceraRepositoryfactory';

const createOtp = 'createOtp';
const verifyOtp = "verifyOtp"
const verifySession = "verifySession"
const closeSession = "closeSession"

const cmsRoute = route => {
  return `cms/${route}`;
};

export default {
  createOtp(payload,headers) {
    return API.post(cmsRoute(createOtp), payload,headers);
  },
    verifyOtp(payload,headers) {
    return API.post(cmsRoute(verifyOtp), payload,headers);
  },
    
     verifySession(payload,headers) {
    return API.post(cmsRoute(verifySession), payload,headers);
  },
         closeSession(payload,headers) {
    return API.post(cmsRoute(closeSession), payload,headers);
  },
};
