import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Image,
} from '@chakra-ui/react';

import { useSelector, useDispatch } from 'react-redux/es/exports';
const FAQsection = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const faqData = [
    {
      title: 'FREQUENTLY ASK QUESTIONS 1',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      title: 'FREQUENTLY ASK QUESTIONS 2',
      content:
        'To get started, simply follow the instructions in the documentation. You can find detailed information on our website.',
    },
    {
      title: 'FREQUENTLY ASK QUESTIONS 3',
      content:
        'Yes, we offer a free trial period for new users. You can sign up on our website and explore the features for free during the trial period.',
    },
    {
      title: 'FREQUENTLY ASK QUESTIONS 4',
      content:
        "If you need assistance or have any questions, you can contact our support team through the help center on our website. We're here to help!",
    },
  ];
  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-8">
        <div className="lg:w-1/2 md:w-1/2 w-full mx-auto">
          <h1
            className="dynamicStyle uppercase font-bold fsize36 sm-fsize30 text-center"
            id="3650987430"
          >
            {data ? data['3650987430'] : "FAQ's"}
          </h1>
          <p id="6203622222" className="dynamicStyle fsize14 text-center">
            {data
              ? data['6203622222']
              : 'Frequently Asked Questions will help you if you stuck somewhere'}
          </p>
        </div>
        <div className="w-full mt-8">
          <Accordion allowToggle>
            {faqData.map((faq, index) => (
              <div key={index} className="py-1 mb-2">
                <AccordionItem className="border rounded-md">
                  <h2>
                    <AccordionButton py="3">
                      <Box as="span" flex="1" textAlign="left">
                        <h2 className="font-semibold">{faq.title}</h2>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <p className="fsize14">{faq.content}</p>
                  </AccordionPanel>
                </AccordionItem>
              </div>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FAQsection;
