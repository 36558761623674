import API from './ceraRepositoryfactory';

const getPages = 'getPages';

const pageRoute = route => {
  return `uibuilder/${route}`;
};

export default {
  getAllPages(payload) {
    return API.post(pageRoute(getPages), payload);
  },
};
