import React from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const GallerySection11 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="lg:py-12 md:py-10 py-8">
      <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1">
        <div className="relative">
          <div id="9377051092" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['9377051092']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 id="5470467171"
              className="dynamicStyle fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
                {data ? data['5470467171'] : 'Lorem Ipsum'}
              </h3>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-2 md:col-span-2 col-span-1">
          <div id="8563568914" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['8563568914']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 id="0086419568"
              className="dynamicStyle fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
                {data ? data['0086419568'] : 'Lorem Ipsum'}
              </h3>
            </div>
          </div>
        </div>
        <div className="relative">
          <div id="1357457929" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['1357457929']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 id="9296387107"
              className="dynamicStyle fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
              {data ? data['9296387107'] : 'Lorem Ipsum'}
              </h3>
            </div>
          </div>
        </div>
        <div className="relative">
          <div  id="7885148815" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['7885148815']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 id="1927896925"
              className="dynamicStyle fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
                {data ? data['1927896925'] : 'Lorem Ipsum'}
              </h3>
            </div>
          </div>
        </div>
        <div className="relative">
          <div id="3109756882" className="dynamicStyle2">
            <Image
              src={data ? getImage(data['3109756882']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 id="1599860975"
              className="dynamicStyle fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
                {data ? data['1599860975'] : 'Lorem Ipsum'}
              </h3>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-2 md:col-span-2 col-span-1">
          <div id="5525702970" className="dynamicStyle2">
            <Image
               src={data ? getImage(data['5525702970']) : ''}
               fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
               alt="img"
              className="lg:h-72 md:h-52 h-52 w-full"
            />
          </div>
          <div className="absolute top-0 left-0 lg:h-72 md:h-52 h-52 w-full gallery11-text flex items-center justify-center">
            <div className="bordb lg:w-max md:w-max md-max-w sm-max-w px-4 py-2 lg:px-6 lg:py-2 md:px-5 md:py-2">
              <h3 id="5138624571"
              className="dynamicStyle fsize20 md-fsize16 sm-fsize16 text-center text-white font-semibold">
                {data ? data['5138624571'] : 'Lorem Ipsum'}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection11;
