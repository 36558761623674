import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const GallerySection3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 py-8 px-5 lg:py-16">
        <div className="lg:w-1/2 md:w-1/2 w-full mx-auto">
          <p
            id="1934690016"
            className="dynamicStyle font-medium text-primary cust-textColor text-center fsize16 md-fsize16 sm-fsize14"
          >
            {data ? data['1934690016'] : 'Our Work'}
          </p>
          <h2
            id="3170971433"
            className="dynamicStyle cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold text-center"
          >
            {data ? data['3170971433'] : 'SBIF Jivanam Initiatives'}
          </h2>
        </div>

        <div
          ref={elementRef}
          className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
        >
          <div
            id="9117272114"
            className="dynamicStyle lg:mt-12 md:mt-12 mt-6 grid lg:grid-cols-6 md:grid-cols-3 grid-cols-1 w-full lg:gap-6 md:gap-6 gap-3"
          >
            {data && data['9117272114']
              ? data['9117272114'].map(e => {
                  return (
                    <Card
                      className={
                        colorMode === 'light'
                          ? 'w-full py-2 box-shadow-none themeShadow'
                          : 'w-full py-2 themeShadow theme-bg'
                      }
                    >
                      <div className="cus-hbanner w-full">
                        <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          className="lg:w-11/12 w-full mx-auto h-40"
                          alt="board"
                        />
                      </div>

                      <p className="text-secondary cust-textColor text-center fsize16 mt-3">
                        {e.field2}
                      </p>
                    </Card>
                  );
                })
              : 'no-data'}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySection3;
