import React from 'react';

function QueuingSystem1() {
  return (
    <section className="container mx-auto mx-auto md:px-6 lg:px-12 px-5 lg:py-16 md:py-16 py-8">
      <div className="grid lg:grid-cols-3 md:grid-cols-3 ">
        <div>
          <h2 className="bg-primary text-white px-8 py-6 fsize18 font-semibold  borderright">
            Scheduled{' '}
          </h2>
          <div className="bg-fafafa p-4">
            <p>Now</p>
            <h2 className="text-6xl text-primary font-semibold">1001</h2>
          </div>

          <div className="">
            <div className="bg-f4f5f6 px-6 py-4">
              <h2 className="font-medium">Next in Queue</h2>
            </div>

            <div className="mt-4 px-6">
              <h2 className="font-semibold fsize20 mb-2">1002</h2>

              <h2 className="font-semibold fsize20 mb-2">1002</h2>

              <h2 className="font-semibold fsize20 mb-2">1002</h2>
              <h2 className="font-semibold fsize20 mb-2">1002</h2>
              <h2 className="font-semibold fsize20 mb-2">1002</h2>
            </div>
          </div>
        </div>

        <div>
          <h2 className="bg-primary text-white px-8 py-6 fsize18 font-semibold  borderright">
            Walk-in{' '}
          </h2>
          <div className="bg-fafafa p-4">
            <p>Now</p>
            <h2 className="text-6xl text-primary font-semibold">2001</h2>
          </div>

          <div className="">
            <div className="bg-f4f5f6 px-6 py-4">
              <h2 className="font-medium">Next in Queue</h2>
            </div>

            <div className="mt-4 px-6">
              <h2 className="font-semibold fsize20 mb-2">1002</h2>
              <h2 className="font-semibold fsize20 mb-2">1002</h2>

              <h2 className="font-semibold fsize20 mb-2">1002</h2>
              <h2 className="font-semibold fsize20 mb-2">1002</h2>
              <h2 className="font-semibold fsize20 mb-2">1002</h2>
            </div>
          </div>
        </div>

        <div>
          <h2 className="bg-primary text-white px-8 py-6 fsize18 font-semibold  borderright">
            Pickup{' '}
          </h2>
          <div className="bg-fafafa p-4">
            <p>Now</p>
            <h2 className="text-6xl text-primary font-semibold">3001</h2>
          </div>

          <div className="">
            <div className="bg-f4f5f6 px-6 py-4">
              <h2 className="font-medium">Next in Queue</h2>
            </div>

            <div className="mt-4 px-6">
              <h2 className="font-semibold fsize20 mb-2">1002</h2>

              <h2 className="font-semibold fsize20 mb-2">1002</h2>

              <h2 className="font-semibold fsize20 mb-2">1002</h2>
              <h2 className="font-semibold fsize20 mb-2">1002</h2>
              <h2 className="font-semibold fsize20 mb-2">1002</h2>
            </div>
          </div>
        </div>
      </div>
      <p className="px-8 mt-16 py-2 text-primary fsize14 bg-f4f5f6 font-medium">
        Watch here for you number
      </p>
    </section>
  );
}

export default QueuingSystem1;
