import API from '../repositoryFactory';

const getsubCategory = 'get/ecom_sub_category';

const getsubCategorys = route => {
  return `forms/${route}`;
};

export default {
  getAllSubCategorys(payload) {
    return API.post(getsubCategorys(getsubCategory), payload);
  },
};
