import { Button, Image } from "@chakra-ui/react";
import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const HeroSection30 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const CTALink = useSelector((state) => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = "https://example.com";

  const handleClick = (id) => {
    const isHttpsLink =
      CTALink[id].includes("https") || CTALink[id].includes("www");
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, "_blank");

      console.log(link, "cmsdatacheck");
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section>
      <div className="relative w-full h-500px">
        <div className="dynamicStyle2" id="1546004139">
          <Image
            src={data ? getImage(data["1546004139"]) : ""}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            className="w-full h-500px object-cover"
          />
        </div>
        <div className="h-500px flex flex-col justify-center lg:pl-20 md:pl-12 px-5 absolute top-0 left-0 lg:w-1/2 md:w-1/2 w-full">
          <p id="7375843169" className="dynamicStyle hero-header clr-fff">
            {data
              ? data["1192846446"]
              : "Experience the future of comfort and convenience at your fingertips."}
          </p>
          <h1
            className="fsize34 md-fsize28 sm-fsize20 mt-2 clr-fff dynamicStyle"
            id="2742587260"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["2742587260"]
                : "Experience top-notch detailing services for a pristine shine.",
            }}
          ></h1>
          <div className="mt-8">
            <Button
              borderRadius={0}
              colorScheme="primary"
              variant="outline"
              fontWeight={400}
              className="dynamicStyle border-primary text-primary"
              id="5993517895"
            >
              <span onClick={() => handleClick("5993517895")}>
                {data ? data["5993517895"] : "Find Coach"}
              </span>
              <FaLongArrowAltRight className="ml-2" />
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-7/12 bg-9c9c9c lg:py-6 lg:px-16 md:py-4 md:px-8 flex">
          <div className="w-1/3 flex flex-col justify-center p-2">
            <p
              id="5911842676"
              className="dynamicStyle fsize24 sm-fsize18 font-semibold clr-fff "
            >
              {data ? data["5911842676"] : "no"}
            </p>
            <p
              id="6502104771"
              dangerouslySetInnerHTML={{
                __html: data ? data["6502104771"] : "no-data",
              }}
              className="dynamicStyle size16 sm-fsize14 clr-fff"
            ></p>
          </div>
          <div className="verticalLine"></div>
          <div className="w-1/3 flex flex-col justify-center p-2">
            <p
              id="3548764222"
              className="dynamicStyle fsize24 sm-fsize18 font-semibold clr-fff"
            >
              {data ? data["3548764222"] : "no"}
            </p>
            <p
              id="2220244549"
              dangerouslySetInnerHTML={{
                __html: data ? data["2220244549"] : "no-data",
              }}
              className="dynamicStyle fsize16 sm-fsize14 clr-fff"
            ></p>
          </div>
          <div className="verticalLine"></div>
          <div className="w-1/3 flex flex-col justify-center p-2">
            <p
              id="2626292616"
              className="dynamicStyle fsize24 sm-fsize18 font-semibold clr-fff"
            >
              {data ? data["2626292616"] : "no"}
            </p>
            <p
              id="5022318566"
              dangerouslySetInnerHTML={{
                __html: data ? data["5022318566"] : "no-data",
              }}
              className="dynamicStyle fsize16 sm-fsize14 clr-fff"
            ></p>
          </div>
        </div>
        <div className="w-5/12 bg-secondary lg:py-6 lg:px-16 md:py-4 md:px-8">
          <div className="p-2">
            <p
              id="8771321010"
              className="dynamicStyle fsize24 sm-fsize18 font-semibold clr-fff"
            >
              {data ? data["8771321010"] : "Lorem Ipsum"}
            </p>
            <p
              id="3121954239"
              className="dynamicStyle text-primary fsize16 sm-fsize14 flex items-center"
            >
              <span onClick={() => handleClick("3121954239")}>
                {data ? data["3121954239"] : "Find Coach"}
              </span>
              <FaLongArrowAltRight className="ml-2" />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection30;
