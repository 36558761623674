import API from '../repositoryFactory';

const get = 'get/ecom_products';

const getProducts = route => {
  return `forms/${route}`;
};

export default {
  getAllProducts(payload) {
    return API.post(getProducts(get), payload);
  },
};
