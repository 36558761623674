import React, { useEffect, useRef, useState } from 'react';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from '@chakra-ui/react';
// import 'swiper/css';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import Slider from 'react-slick';

const Clients7 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const Client = [
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 4.5,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          speed: 4000,
          autoplaySpeed: 4000,
          cssEase: 'linear',
        },
      },
    ],
  };

  return (
    <div className="container mx-auto lg:py-16 md:py-16 py-12 lg:px-12 md:px-8 px-5">
      <div className="lg:w-2/3 md:w-3/4 w-full mx-auto">
        <h2
          id="5304341642"
          className="dynamicStyle pb-2 fsize34 md-fsize24 sm-fsize24 text-center font-semibold text-primary cust-textColor"
        >
          {data ? data['5304341642'] : ' Our Clients'}
        </h2>
        <p
          id="1670160342"
          className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-center textColor-light"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['1670160342']
              : 'We are united by our passion for innovation and our commitment',
          }}
        ></p>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}
      >
        <div id="1182618352" className="dynamicStyle lg:mt-12 md:mt-12 mt-8">
          <div className='grid grid-cols-5 gap-6'>
            {data && data['1182618352']
              ? data['1182618352'].map(e => {
                  return (
                    <div>
                      <Image
                        src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="img"
                        className="rounded-md img-ratio lg:h-32 h-24"
                      />
                    </div>
                  );
                })
              : 'no-data'}
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients7;
