import React, { useEffect, useRef, useState } from 'react';
import { Star } from 'react-feather';
import { Image } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Rating from 'react-rating';
import FeatherIcon from 'feather-icons-react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Autoplay, Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';

const Testimonial6 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const dispatch = useDispatch();
  const Testimonial = useSelector(state => state.testimonial.Testimonial);

  useEffect(() => {
    dispatch(getTestimonal());
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <>
      {Testimonial ? (
        <section className="lg:py-20 md:py-10 lg:px-12 md:px-8 py-12 px-5 bg-secondary">
          <div className="text-center lg:mb-8 md:mb-4 mb-6">
            <p
              id="8854398588"
              className="dynamicStyle text-primary fsize16 sm-fsize14 font-medium  cust-textColor"
            >
              {data ? data['8854398588'] : 'Testimonial'}
            </p>
            <p
              id="8799852098"
              className="dynamicStyle fsize34 md-fsize24 sm-fsize20 clr-fff font-semibold cust-textColor"
            >
              {data ? data['8799852098'] : 'Reviews from Customers'}
            </p>
          </div>
          <div
            ref={elementRef}
            className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
          >
            {/* <div className="swiper-button custswiper image-swiper-button-next">
          <IoIosArrowForward />
        </div>
        <div className="swiper-button custswiper image-swiper-button-prev">
          <IoIosArrowBack />
        </div> */}
            <Swiper
              className="p-4"
              spaceBetween={30}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 12,
                },
                820: {
                  slidesPerView: 2,
                  spaceBetween: 12,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 12,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 12,
                },
                425: {
                  slidesPerView: 1.2,
                  spaceBetween: 12,
                },
                325: {
                  slidesPerView: 1.2,
                  spaceBetween: 12,
                },
              }}
            >
              {Testimonial &&
              Testimonial.data &&
              Testimonial.data.result &&
              Testimonial.data.result.length > 0
                ? Testimonial.data.result.map(item => (
                    <SwiperSlide>
                      <div className="border-1px p-4 bg-fff themeShadow theme-radius">
                        <div className="cmnt-h lg:pb-4 md:pb-4 pb-2">
                          <p className="fsize16 sm-fsize14 cust-textColor lineClamp3 textColor-light">
                            “{item.testimonial}”
                          </p>
                        </div>
                        <div className="flex gap-4 ">
                          <div>
                            <Image
                              className="cust-hw rounded-full"
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                              alt="testimonial-image"
                            />
                          </div>
                          <div className="cust-textColor">
                            {/* <p className="font-semibold pt-1">{`${
                              item.firstName.charAt(0).toUpperCase() +
                              item.firstName.slice(1)
                            } ${item.lastName}`}</p> */}
                            <p className="font-semibold pt-1">
                              {item.name.charAt(0).toUpperCase() +
                                item.name.slice(1)}{' '}
                            </p>
                            <Rating
                              initialRating={4}
                              className="flex_rating"
                              readonly={true}
                              emptySymbol={
                                <FeatherIcon
                                  icon="star"
                                  className="mr-1 textColor-light"
                                  size={18}
                                />
                              }
                              fullSymbol={
                                <FeatherIcon
                                  icon="star"
                                  className="mr-1 fillstroke"
                                  size={18}
                                />
                              }
                              fractions={2}
                            />
                            {/* <p className="fsize14 sm-fsize12">CEO & Founder</p> */}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                : 'no-data'}
            </Swiper>
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default Testimonial6;
