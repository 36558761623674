const initialState = {
  Tags: [],
};

const ecom_Tags = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_TAGS':
      return {
        ...state,
        Tags: action.payload,
      };

    default:
      return state;
  }
};

export default ecom_Tags;
