// import axios from "axios";
import apis from '../../apis/client/auth';
import { setWS } from '../chat/chat-actions';
import { ws, connectToWS, disconnectToWS } from '../chat/chat-websocket';
import Helper from '../../helper';

export const signupWithPassword = payload => {
  return async dispatch => {
    try {
      let register = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.signupWithPassword(register);

      if (data) {
        dispatch(setDataSignup(data.data));
        await connectToWS();
        dispatch(setWS(ws));
      }
      return data;
    } catch (error) {
      return error.response.data;
    }
  };
};

export const CreateNewPassword = payload => {
  return async dispatch => {
    try {
      let NewpassWordPayload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.createPassword(NewpassWordPayload);

      // if (data) dispatch(setDataSignup(data.data));
      return data;
    } catch (error) {}
  };
};

export const ConfirmPassword = payload => {
  return async dispatch => {
    try {
      let NewpassWordPayload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.CheckPassword(NewpassWordPayload);

      // if (data) dispatch(setDataSignup(data.data));
      return data;
    } catch (error) {}
  };
};

//DeactivateAccount
export const DeactivateAccountApi = payload => {
  return async dispatch => {
    try {
      let NewpassWordPayload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.DeactivateAccount(NewpassWordPayload);

      // if (data) dispatch(setDataSignup(data.data));
      return data;
    } catch (error) {}
  };
};

export const loginwithPassword = payload => {
  return async dispatch => {
    try {
      let login = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      // console.log(payload, login);
      let { data } = await apis.loginwithPassword(login);

      if (data.data && Helper.Object.isEmpty(data.data.errmsg)) {
        dispatch(setDataLogin(data.data.userAuth));
        connectToWS();
        dispatch(setWS(ws));
      }

      return data;
    } catch (error) {
      // console.log();
      return error.response.data;
      // console.log(error);
    }
  };
};

export const generateOTP = payload => {
  return async dispatch => {
    try {
      let generateotp = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.generateOTP(generateotp);

      if (data) dispatch(setGenerateOTP(data.data));
      return data;
    } catch (error) {
      return error.response.data;
    }
  };
};

export const verifyOTP = payload => {
  return async dispatch => {
    try {
      let verifyotp = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.verifyOTP(verifyotp);

      if (data) dispatch(setVerifyOTP(data.data.auth));
    } catch (error) {
      return error.response.data;
    }
  };
};

export const logout = payload => {
  return async dispatch => {
    try {
      // let logoutUser = {
      //   projectName: process.env.REACT_APP_COMMUNITY_NAME,
      //   ...payload,
      // };
      // console.log(payload, "payload logout");
      // dispatch(userLogout());
      let { data } = await apis.logout(payload);

      window.location.href = '/';
      if (data) {
        dispatch(userLogout());
        disconnectToWS();
      }
      return data;
    } catch (error) {
      return error.response;
    }
  };
};

export const forgetPassword = payload => {
  return async dispatch => {
    try {
      let forgetpass = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.forgetPassword(forgetpass);

      if (data) dispatch(userForgetPassword(data.data));
      return data;
    } catch (error) {
      return error.response;
    }
  };
};
export const SaveBasicProfileDetails = payload => {
  return async dispatch => {
    try {
      // let basicProfilePayload = {
      //   projectName: process.env.REACT_APP_COMMUNITY_NAME,
      //   ...payload,
      // };

      let { data } = await apis.SaveBasicProfile(payload);

      // if (data) dispatch(setGenerateOTP(data.data));
      return data;
    } catch (error) {}
  };
};

export const GetUsersProfileInfo = payload => {
  return async dispatch => {
    try {
      let { data } = await apis.GetUserInfo();

      // console.log(data, "OTP");
      if (data) {
        dispatch(setProfileUserDetails(data.data));
        return data.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getUserByToken = payload => {
  let record;
  return async dispatch => {
    try {
      let { data } = await apis.getUserByToken(payload);

      if (data) {
        dispatch(setDataLogin(data.data));
      }

      record = data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const createMFA = payload => {
  let record;
  return async dispatch => {
    try {
      let { data } = await apis.createMFA(payload);
      record = data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const verifyMFA = payload => {
  let record;
  return async dispatch => {
    try {
      let { data } = await apis.verifyMFA(payload);
      record = data.data;
      if (record.token) {
        dispatch(setDataLogin(data.data));
      }
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const setDataSignup = data => {
  return {
    type: 'SET_BASIC_PROFILE',
    payload: data,
  };
};

export const setProfileUserDetails = data => {
  return {
    type: 'SET_DATA_SIGNUP',
    payload: data,
  };
};

export const setGenerateOTP = data => {
  return {
    type: 'SET_GENERATE_OTP',
    payload: data,
  };
};

export const setVerifyOTP = data => {
  return {
    type: 'SET_VERIFY_OTP',
    payload: data,
  };
};

export const setDataLogin = data => {
  return {
    type: 'SetLogin',
    payload: data,
  };
};
export const userLogout = data => {
  return {
    type: 'LOGOUT',
    // payload: data,
  };
};

export const userForgetPassword = data => {
  return {
    type: 'UserForgetPassword',
    payload: data,
  };
};

export const otherLogin = data => {
  return {
    type: 'SET_OTHER_LOGIN',
    payload: data,
  };
};
export const setIsEditUserProfile = data => {
  return {
    type: 'SET_ISEDITUSERPROFILE',
    payload: data,
  };
};
