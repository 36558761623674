import { Badge, Card, Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { ArrowRight } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getAllBlogs } from '../../../redux/blogs/blogs-actions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { handleScroll } from './fadeeffect/FadeEffect';

const Blogs = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);

  let allBlog = useSelector(state => state.BlogDetails.Blog);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, []);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-14 md:py-14 py-8">
      <div className="flex justify-center lg:pb-8 md:pb-8 pb-4">
        <div className="w-70">
          <p
            id="4980097514"
            className="dynamicStyle cust-textColor fsize34 md-fsize24 sm-fsize20 mb-2 text-primary text-center font-semibold"
          >
            {data ? data['4980097514'] : 'Lorem ipsum'}
          </p>
          <p
            id="4718020350"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['4718020350']
                : ' publishing and graphic design, Lorem ipsum is a placeholder text',
            }}
            className="dynamicStyle textColor-light text-center fsize16 sm-fsize14"
          >
            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis
            dolor quo atque eum, fugit voluptatem tempora pariatur asperiores
            alias voluptatum! */}
          </p>
          {/* <hr className="hr-blg" /> */}
        </div>
      </div>
      <Swiper
        spaceBetween={25}
        slidesPerView={3.2}
        observer={true}
        observeParents={true}
        modules={[Navigation]}
        className="mySwiper p-2"
        autoplay={{
          delay: '1000',
        }}
        loop
        navigation={{
          nextEl: '.image-swiper-button-next',
          prevEl: '.image-swiper-button-prev',
        }}
        breakpoints={{
          1536: {
            slidesPerView: 3.2,
            spaceBetween: 25,
          },
          1280: {
            slidesPerView: 3.2,
            spaceBetween: 25,
          },
          1024: {
            slidesPerView: 3.1,
            spaceBetween: 25,
          },
          991: {
            slidesPerView: 2.8,
            spaceBetween: 25,
          },
          767: {
            slidesPerView: 2,
            spaceBetween: 25,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
        }}
      >
        {allBlog
          ? allBlog.map(item => (
              <SwiperSlide
                ref={elementRef}
                className={`fade-bottom   ${
                  isVisible ? 'visiblebottom' : ''
                } `}
              >
                <Card
                  className={
                    colorMode === 'light'
                      ? 'cursor-pointer cust-shadow  themeShadow theme-bg'
                      : 'cursor-pointer cust-shadow  themeShadow theme-bg'
                  }
                >
                  <div id="" className="w-full ">
                    <div className="overflow-hidden bradius-card-top">
                      <Image
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        className="w-full h-56  custimghover"
                        alt="image"
                      />
                    </div>
                    <div className="blog-abs text-center blg-hov">
                      <p className="fsize14 font-semibold">
                        <p className="">
                          {new Date(item.createdAt).toLocaleDateString(
                            'en-US',
                            {
                              month: 'short',
                              day: 'numeric',
                            }
                          )}
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="flex justify-between items-center">
                      <p className="fsize14 cust-textColor">
                        {new Date(item.createdAt).toLocaleTimeString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </p>

                      <div className="">
                        <Badge
                          borderRadius="full"
                          px="3"
                          py="0.5"
                          colorScheme="green"
                          className="card-badge-gren cust-textColor"
                        >
                          {item.categoryID[0].categoryName}
                        </Badge>
                      </div>
                    </div>
                    <div className="py-2">
                      <p className="fsize20 sm-fsize18 font-semibold pb-2 cust-textColor">
                        {item.title}
                      </p>
                      <p
                        className="fsize14 lineClamp3 textColor-light"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></p>
                    </div>
                    <div className="flex  cursor-pointer">
                      <p className="text-primary cust-textColor fsize14">
                        Read More
                      </p>
                      <ArrowRight className="text-primary cust-textColor ml-2 arrow-r" />
                    </div>
                  </div>
                </Card>
              </SwiperSlide>
            ))
          : 'no-data'}
      </Swiper>
    </div>
  );
};

export default Blogs;
