const initialState = {
  Categorys: [],
};

const ecom_Category = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_CATEGORY':
      return {
        ...state,
        Categorys: action.payload,
      };

    default:
      return state;
  }
};

export default ecom_Category;
