import {
  Button,
  Input,
  Textarea,
  FormErrorMessage,
  FormControl,
  Image,
  useColorMode,
} from "@chakra-ui/react";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useRef, useState } from "react";
import { createContact } from "../../../redux/marcom/marcom-action";
import { useDispatch, useSelector } from "react-redux/es/exports";
import "sweetalert2/src/sweetalert2.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { handleScroll } from "./fadeeffect/FadeEffect";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const ContactUs = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();

  const data = useSelector((state) => state.cms.allSections[pageName]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname === "/contact%20us") {
      const contactUsSection = document.getElementById("contact-form");
      if (contactUsSection) {
        contactUsSection.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo(0, 0);
      }
    }
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    description: "",
    countryCode: "",
  };

  const validationRules = Yup.object({
    name: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .max(25, "Name must be at most 25 characters")
      .required("Name is required")
      .matches(/^[a-zA-Z ]*$/, "Only alphabets is allowed"),
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 50 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
    mobile: Yup.string()
      // .min(10, 'Mobile number must be 10 digits')
      // .max(10, 'Mobile number must be 10 digits')
      .required("Mobile number is required"),
    // .matches(/^\d{10}$/, 'Mobile number must be digits')
    // .matches(/^[6789]/, 'Mobile number is invalid')
    // .matches(/^[0-9]*$/, 'Only numeric value is allowed'),

    description: Yup.string()
      .trim()
      .min(3, "Message must be at least 3 characters")
      .max(100, "Message must be at most 100 characters")
      .required("Message is required"),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      name: values.name,
      email: values.email.toLowerCase(),
      mobile: values.mobile,
      description: values.description,
      countryCode: values.countryCode,
    };
    try {
      let data = await dispatch(createContact(payload));

      if (data && data.code == 200) {
        Swal.fire({
          title: "Query submitted",
          text: `We will get back to you shortly`,
          icon: "success",
          timer: "2500",
        });
        // toast.success(`Will get back to you shortly`);
        formik.handleReset();
      } else {
        if (data.message) {
          Swal.fire({
            title: "Error",
            text: data.message,
            icon: "error",
          });
          // toast.error(data.message);
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "error",
      });
      // toast.error("Something went wrong!");
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  const handlePhoneChange = async (value, country, e, formattedValue) => {
    const dialCountryCode = country.dialCode;
    const phoneNumber = value.replace(`${dialCountryCode}`, "");
    await formik.setFieldValue("mobile", phoneNumber);
    await formik.setFieldValue("countryCode", dialCountryCode);
  };

  return (
    <section id="contact-form">
      <form onSubmit={formik.handleSubmit}>
        <div className="container relative zindex9 mx-auto md:px-12 lg:px-12 px-5 lg:py-16 py-8">
          <div className="lg:flex">
            <div
              ref={elementRef}
              className={`fade-left lg:w-6/12 md:w-3/4 ${
                isVisible ? "visibleleft" : ""
              } `}
            >
              <h3
                id="8947028329"
                className="dynamicStyle cust-textColor fsize34 md-fsize24 sm-fsize20 text font-semibold text-dark"
              >
                {data ? data["8947028329"] : "WE CARE ABOUT YOU"}
              </h3>
              <p
                id="9620338707"
                dangerouslySetInnerHTML={{
                  __html: data ? data["9620338707"] : "no-data",
                }}
                className="dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14 text8787 font-normal mt-2 sm-fsize14 "
              ></p>

              {/* <div className="flex mt-8 items-start">
                  <div>
                

                    <FeatherIcon icon="map-pin" className="cust-textColor mt-1 text-primary" />
                  </div>
                  <div className="lg:ml-4 md:ml-4 ml-4 lg:mt-0 mt-0">
                    <h5
                      id="3518121002"
                      className="dynamicStyle cust-textColor fsize20 sm-fsize16 font-semibold text-primary "
                    >
                      {data ? data['3518121002'] : 'Address'}
                    </h5>
                    <p
                      id="7817684324"
                      dangerouslySetInnerHTML={{
                        __html: data ? data['7817684324'] : 'no-data',
                      }}
                      className="dynamicStyle textColor-light fsize15 sm-fsize14 col-79 mt-1 sm-line-clamp3"
                    ></p>
                  </div>
                </div> */}
              <div className="flex items-start lg:mt-10 md:mt-10  mt-6">
                <div>
                  {" "}
                  {/* <img
                    src={data ? getImage(data["2742587937"]) : ""}
                    className="w-10 h-10 shadow object-contain"
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                    alt="map"
                  /> */}
                  <FeatherIcon
                    icon="mail"
                    className="cust-textColor mt-1 text-primary"
                  />
                </div>
                <div className=" lg:ml-4 md:ml-4 ml-4 lg:mt-0 mt-0">
                  <p
                    id="6880022362"
                    className="dynamicStyle cust-textColor fsize20 sm-fsize16 font-semibold text-primary "
                  >
                    {data ? data["6880022362"] : "Email"}
                  </p>
                  <p
                    id="5382831938"
                    className="dynamicStyle textColor-light fsize15 sm-fsize14 col-79 mt-1"
                  >
                    <a
                      // href={`mailto:${
                      //   data ? data["5382831938"] : "fit2goasia@gmail.com"
                      // }`}
                      href={`fit2goasia@gmail.com`}
                      dangerouslySetInnerHTML={{
                        __html: data
                          ? data["5382831938"]
                          : "fit2goasia@gmail.com",
                      }}
                    ></a>
                  </p>
                </div>
              </div>
              {/* <div className="flex items-start lg:mt-10 md:mt-10  mt-6">
                  <div>
                    {' '}
                   
                    <FeatherIcon icon="phone" className="cust-textColor mt-1 text-primary" />
                  </div>
                  <div className=" lg:ml-4 md:ml-4 ml-4 lg:mt-0 mt-0">
                    <h5
                      id="1347737073"
                      className="dynamicStyle cust-textColor fsize20 sm-fsize16 font-semibold text-primary "
                    >
                      {data ? data['1347737073'] : 'Contact Us'}
                    </h5>
                    <p
                      id="5799582602"
                      className="dynamicStyle textColor-light fsize15 sm-fsize14 col-79 mt-1"
                    >
                      <a
                        href="tel:+91 9807654321"
                        dangerouslySetInnerHTML={{
                          __html: data ? data['5799582602'] : 'no-data',
                        }}
                      ></a>
                    </p>
                  </div>
                </div> */}
            </div>

            <div className="lg:w-6/12 md:w-full lg:mt-0 mt-4 lg:pl-20 md:pl-0">
              <FormControl
                isInvalid={!!formik.errors.name && formik.touched.name}
              >
                <Input
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  placeholder="Enter Name"
                  className="lg:text-base md:text-base text-sm cust-textColor theme-bg themeShadow"
                />
                {formik.touched.name && formik.errors.name && (
                  <FormErrorMessage className="font-secondary">
                    {formik.errors.name}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                isInvalid={!!formik.errors.email && formik.touched.email}
              >
                <Input
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  placeholder="Email Address"
                  className="mt-4 lg:text-base md:text-base text-sm cust-textColor theme-bg themeShadow"
                />
                {formik.touched.email && formik.errors.email && (
                  <FormErrorMessage className="font-secondary">
                    {formik.errors.email}
                  </FormErrorMessage>
                )}
              </FormControl>

              {/* <FormControl
                isInvalid={!!formik.errors.mobile && formik.touched.mobile}
              >
                <Input
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  placeholder="Mobile No."
                  className="mt-4 lg:text-base md:text-base text-sm"
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <FormErrorMessage className="font-secondary">{formik.errors.mobile}</FormErrorMessage>
                )}
              </FormControl> */}
              <FormControl
                isInvalid={!!formik.errors.mobile && formik.touched.mobile}
              >
                <PhoneInput
                  name="mobile"
                  country={"in"}
                  enableSearch={true}
                  className={
                    colorMode === "light"
                      ? "mt-4 phoneinput w-full mt-2 themefamily theme-bg themeShadow"
                      : "mt-4 phoneinputDark w-full mt-2 themefamily theme-bg themeShadow"
                  }
                  onChange={handlePhoneChange}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <FormErrorMessage className="font-secondary">
                    {formik.errors.mobile}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                isInvalid={
                  !!formik.errors.description && formik.touched.description
                }
              >
                <Textarea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  placeholder="Message"
                  className="font-secondary mt-4 lg:text-base md:text-base text-sm cust-textColor theme-bg themeShadow"
                />
                {formik.touched.description && formik.errors.description && (
                  <FormErrorMessage className="font-secondary">
                    {formik.errors.description}
                  </FormErrorMessage>
                )}
              </FormControl>

              <div id="3361556184" className="dynamicStyle">
                {" "}
                <Button
                  type="submit"
                  size="lg"
                  className={
                    colorMode === "light"
                      ? "w-full clr-fff mt-4 bg-primary theme-btn sm-fsize16px buttonAnimate bdr-2px"
                      : "w-full mt-4 theme-btn cust-bgColor cust-textColor sm-fsize16px bdr-2px"
                  }
                >
                  {data ? data["3361556184"] : "Send"}
                </Button>
              </div>
            </div>
          </div>

          {/* <iframe
            className="sm-h250px tb-h280px w-full h-96 mt-10"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7709023962652!2d103.90097507499006!3d1.3128967616934797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da180e0fb5888d%3A0xfffd54b242c84074!2s47%20Joo%20Chiat%20Ln%2C%20Singapore%20428106!5e0!3m2!1sen!2sin!4v1705597718184!5m2!1sen!2sin"
            width="100%"
            allowFullScreen=""
            loading="lazy"
            title='map'
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe> */}
        </div>
      </form>
    </section>
  );
};

export default ContactUs;
