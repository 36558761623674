import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Card, HStack, Image, Tag } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { useSelector } from 'react-redux';

const TourSection1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const category = [{name:'category 1'}, {name:'category 2'}, {name:'category 3'}, {name:'category 4'}];
  console.log(category, 'category')
  return (
    <div className="lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8 container mx-auto">
      <h2
        id="3991010524"
        className="dynamicStyle font-semibold cust-textColor header2 fsize34 md-fsize24 sm-fsize20  relative inline-block"
      >
        {data ? data['3991010524'] : 'Lorem Ipsum'}
      </h2>
      <div className="lg:mt-8 md:mt-6 mt-6">
        
        <div className="flex flex-wrap gap-2 mb-6">
          {
            category.map((cat, index)=>(
                <p key={index} className=" py-2 px-4 bg-primary text-white fsize12 cust-bgColor rounded-full">{cat.name}</p>
            ))
          }
        </div>
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper py-2"
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            820: {
              slidesPerView: 2.3,
              spaceBetween: 16,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <Card className="cursor-pointer cust-shadow rounded">
              <div className="relative image-product overflow-hidden rounded-t">
                <Image
                  className="img-h-220 w-full opct"
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                />
                <div className="absolute right-0 top-0 flex gap-2 p-2 bg-fff">
                  <div className="">
                    <p className="fsize14 textColor-light">4.5/5</p>
                  </div>
                  <div>
                    <FeatherIcon
                      className="filled-blk "
                      icon="star"
                      size={18}
                    />
                  </div>
                </div>
              </div>

              <div className="p-4">
                <div className="">
                  <p className="fsize18 sm-fsize16 font-semibold text-black cust-textColor lineClamp1">
                    Motion Graphics: Create a Nice Typography Animations
                  </p>
                </div>
                <div className="">
                  <div className="flex items-center gap-2">
                    <FeatherIcon
                      className="clr-b8b8b8 textColor-light"
                      icon="map-pin"
                      size={16}
                    />
                    <p className="fsize16 sm-fsize14 font-medium clr-b8b8b8 textColor-light">
                      Lorem, ipsum dolor.
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className="cursor-pointer cust-shadow rounded">
              <div className="relative image-product overflow-hidden rounded-t">
                <Image
                  className="img-h-220 w-full opct"
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                />
                <div className="absolute right-0 top-0 flex gap-2 p-2 bg-fff">
                  <div className="">
                    <p className="fsize14 textColor-light">4.5/5</p>
                  </div>
                  <div>
                    <FeatherIcon
                      className="filled-blk "
                      icon="star"
                      size={18}
                    />
                  </div>
                </div>
              </div>

              <div className="p-4">
                <div className="">
                  <p className="fsize18 sm-fsize16 font-semibold text-black cust-textColor lineClamp1">
                    Motion Graphics: Create a Nice Typography Animations
                  </p>
                </div>
                <div className="">
                  <div className="flex items-center gap-2">
                    <FeatherIcon
                      className="clr-b8b8b8 textColor-light"
                      icon="map-pin"
                      size={16}
                    />
                    <p className="fsize16 sm-fsize14 font-medium clr-b8b8b8 textColor-light">
                      Lorem, ipsum dolor.
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className="cursor-pointer cust-shadow rounded">
              <div className="relative image-product overflow-hidden rounded-t">
                <Image
                  className="img-h-220 w-full opct"
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                />
                <div className="absolute right-0 top-0 flex gap-2 p-2 bg-fff">
                  <div className="">
                    <p className="fsize14 textColor-light">4.5/5</p>
                  </div>
                  <div>
                    <FeatherIcon
                      className="filled-blk "
                      icon="star"
                      size={18}
                    />
                  </div>
                </div>
              </div>

              <div className="p-4">
                <div className="">
                  <p className="fsize18 sm-fsize16 font-semibold text-black cust-textColor lineClamp1">
                    Motion Graphics: Create a Nice Typography Animations
                  </p>
                </div>
                <div className="">
                  <div className="flex items-center gap-2">
                    <FeatherIcon
                      className="clr-b8b8b8 textColor-light"
                      icon="map-pin"
                      size={16}
                    />
                    <p className="fsize16 sm-fsize14 font-medium clr-b8b8b8 textColor-light">
                      Lorem, ipsum dolor.
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card className="cursor-pointer cust-shadow rounded">
              <div className="relative image-product overflow-hidden rounded-t">
                <Image
                  className="img-h-220 w-full opct"
                  src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="img"
                />
                <div className="absolute right-0 top-0 flex gap-2 p-2 bg-fff">
                  <div className="">
                    <p className="fsize14 textColor-light">4.5/5</p>
                  </div>
                  <div>
                    <FeatherIcon
                      className="filled-blk "
                      icon="star"
                      size={18}
                    />
                  </div>
                </div>
              </div>

              <div className="p-4">
                <div className="">
                  <p className="fsize18 sm-fsize16 font-semibold text-black cust-textColor lineClamp1">
                    Motion Graphics: Create a Nice Typography Animations
                  </p>
                </div>
                <div className="">
                  <div className="flex items-center gap-2">
                    <FeatherIcon
                      className="clr-b8b8b8 textColor-light"
                      icon="map-pin"
                      size={16}
                    />
                    <p className="fsize16 sm-fsize14 font-medium clr-b8b8b8 textColor-light">
                      Lorem, ipsum dolor.
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default TourSection1;
