
import apis from "../../apis/client/teams";

export const getAllTeams = (payload) => {
    let record;
    return async (dispatch) => {
      try {
        let payload2 = {
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
          ...payload,
        };
        let { data } = await apis.getAllTeam(payload2)
        if (data) {
          await dispatch(setTeam(data.data.result));
        
        }
      } catch (error) {
        console.log(error);
      }
      return record;
    };
  };


  export const setTeam = (data) => {
    return {
      type: "setTeam",
      payload: data,
    };
  };