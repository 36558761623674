import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { ChevronRightIcon } from '@chakra-ui/icons';
import React from 'react';

const BreadCumb = () => {
  return (
    <div className="bg-primary lg:px-28 md:px-8 px-5 lg:py-8 md:py-6 py-5">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="#" className="textwhite sm-fsize13">
            CLG Use Directory
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="#" className="textwhite sm-fsize13">
            Retail Banking Group
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};

export default BreadCumb;
