import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';
import Rating from 'react-rating';
import FeatherIcon from 'feather-icons-react';
import { handleScroll } from './fadeeffect/FadeEffect';



const Testimonial2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();

  const data = useSelector(state => state.cms.allSections[pageName]);
  
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  console.log(getAllTestimonial, 'getAllTestimonial');
  useEffect(() => {
   
    dispatch(getTestimonal());
    
    
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

   
    return (
      <>
        {getAllTestimonial ? (
          <section className="py-5 container mx-auto lg:px-12 md:px-8 px-5">
            <div className="lg:w-3/5 md:w-3/4 mx-auto text-center w-full flex flex-col justify-center lg:pt-8 md:pt-8  lg:mb-12 md:mb-12 mb-8 ">
              <h3
                id="3637674290"
                className="dynamicStyle fsize16 md-fsize14 sm-fsize16 font-medium text-primary cust-textColor"
              >
                {data ? data['3637674290'] : 'Lorem Ipsum'}
              </h3>
              
              <h1
                id="2333017546"
                className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor mb-2"
              >
                {data ? data['2333017546'] : 'Lorem Ipsum'}
              </h1>
              <div className="">
                <p
                  id="6834492840"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data['6834492840']
                      : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                  }}
                  className="dynamicStyle textColor-light fsize16 md-fsize14 sm-fsize14 text-center "
                ></p>
              </div>
            </div>
  
            <div className="w-full grid lg:grid-cols-2 md:grid-cols-2 w-full lg:gap-4 md:gap-4 gap-3 ">
              {getAllTestimonial.data &&
              getAllTestimonial.data.result &&
              getAllTestimonial.data.result.length > 0
                ? getAllTestimonial.data.result.map(testimonial => {
                    return (
                      <div
                        ref={elementRef}
                        className={`fade-bottom  ${
                          isVisible ? 'visiblebottom' : ''
                        } `}
                      >
                        <div
                          className={
                            colorMode === 'light'
                              ? 'testimonial-card themeShadow-insert theme-radius border-1px rounded-lg themeShadow'
                              : 'testimonial-card theme-radius border-1px rounded-lg themeShadow'
                          }
                        >
                          <div className="w-full lg:text-left md:text-left text-center flex ">
                            <div className="testimonial-div1 ">
                              <Image
                                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                                alt="testimonial2"
                                className="testimonial-img rounded-full object-cover"
                              />
                            </div>
                            <div className="w-full text-left lg:mt-0 md:mt-0 mt-2">
                              {/* <h2 className="fsize24 sm-fsize16 font-semibold ">
                              {`${
                                testimonial.firstName.charAt(0).toUpperCase() +
                                testimonial.firstName.slice(1)
                              }` +
                                "\n" +
                                `${testimonial.lastName}`}
                            </h2> */}
                              <h2 className="fsize20 sm-fsize16 font-semibold cust-textColor">
                                {testimonial.name.charAt(0).toUpperCase() +
                                  testimonial.name.slice(1)}
                              </h2>
                              <div className="flex justify-between items-center ">
                                {/* <p className="fsize18 sm-fsize14">
                                CEO & Co Founder
                              </p> */}
                                <Rating
                                  initialRating={4}
                                  readonly={true}
                                  emptySymbol={
                                    <FeatherIcon
                                      icon="star"
                                      className="mr-1 textColor-light"
                                      size={22}
                                    />
                                  }
                                  fullSymbol={
                                    <FeatherIcon
                                      icon="star"
                                      className="mr-1 fillstroke"
                                      size={22}
                                    />
                                  }
                                  fractions={2}
                                />
                              </div>
                            </div>
                          </div>
                          <p className="lg:text-left md:text-justify text-center fsize16 sm-fsize14 lg:pt-4 md:pt-4 pt-2 lg:pb-4 md:pb-4 textColor-light">
                            {testimonial.testimonial}
                          </p>
                        </div>
                      </div>
                    );
                  })
                : 'No Data'}
            </div>
          </section>
        ) : (
          ''
        )}
      </>
    );
  
 
  
};

export default Testimonial2;
