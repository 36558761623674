import { Card, Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import VanillaTilt from 'vanilla-tilt';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard13 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll('.shake'), {
      max: 25,
      speed: 400,
      glare: true,
      'max-glare': 0.5,
    });

    VanillaTilt.init(document.querySelectorAll('.shakeDark'), {
      max: 25,
      speed: 400,
      glare: true,
      'max-glare': 0.5,
    });
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div
      className={
        colorMode === 'light' ? 'bg-f4f5f6 theme-bg' : 'textColor-light'
      }
    >
      <div
        className={
          colorMode === 'light'
            ? 'container mx-auto bg-f4f5f6 lg:px-12 md:px-8 px-5 lg:py-24 md:py-16 py-12 theme-bg'
            : 'container mx-auto textColor-light lg:px-12 md:px-8 px-5 lg:py-24 md:py-16 py-12 theme-bg'
        }
      >
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center lg:mb-12 md:mb-12 mb-6">
          <h2
            id="0927925577"
            className="dynamicStyle font-semibold cust-textColor fsize34 md-fsize24 sm-fsize20 mb-2 text-primary relative inline-block"
          >
            {data ? data['0927925577'] : 'Welcome to my world'}
          </h2>

          <p
            id="8390909776"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 textColor-light"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['8390909776']
                : 'I m a young tech enthasist and entrepreneur who love to take risk.',
            }}
          ></p>
        </div>
        <div
          id="7628062516"
          className="dynamicStyle w-full items-start grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-x-8 md:gap-x-8 lg:gap-y-0 md:gap-y-0 gap-y-6"
        >
          {data && data['7628062516']
            ? data['7628062516'].map((e, index) => {
                return (
                  <Card
                    className={
                      colorMode === 'light'
                        ? `${
                            index % 2 === 0
                              ? 'themeShadow theme-bg lg:px-12 md:px-8 px-5 lg:py-12 md:py-8 py-5 bg-white rounded-xl border-e0e0e0 shake lg:mb-12 md:mb-8'
                              : 'theme_insertShadow theme-bg lg:px-12 md:px-8 px-5 lg:py-12 md:py-8 py-5 bg-white rounded-xl border-e0e0e0 shake lg:mt-12 md:mt-8'
                          }`
                        : `${
                            index % 2 === 0
                              ? 'themeShadow theme-bg lg:px-12 md:px-8 px-5 lg:py-12 md:py-8 py-5 bg-white rounded-xl shake lg:mb-12 md:mb-8'
                              : 'theme_insertShadow theme-bg lg:px-12 md:px-8 px-5 lg:py-12 md:py-8 py-5 bg-white rounded-xl shake lg:mt-12 md:mt-8'
                          }`
                    }
                    data-tilt
                  >
                    <div className={``}>
                      <Image
                        src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="w-12 h-12 bg-gray"
                      />
                    </div>

                    <div className="">
                      <h3 className="fsize24 md-fsize20 sm-fsize18 mt-2 lg:mb-4 md:mb-3 mb-2 font-medium cust-textColor">
                        {e.field2}
                      </h3>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: e.field3,
                        }}
                        className="fsize16 sm-fsize14 textColor-light"
                      ></p>
                    </div>
                  </Card>
                );
              })
            : 'no-card'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard13;
