import { Button, Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HeroSection24 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const { colorMode, toggleColorMode } = useColorMode();

  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else if (image) {
      return process.env.REACT_APP_STORAGE_URL + image;
    } else {
      return `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
    }
  }

  return (
    <>
      <div className="relative dynamicStyle2" id="3185593645">
        <Image
          src={data ? getImage(data['3185593645']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="elearning-banner"
          className="h-400px sm-h300px w-full"
        />
        <div className="absolute top-0 left-0 h-400px sm-h300px w-full flex justify-start items-center">
          <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
            <h2
              className="fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor text-primary dynamicStyle"
              id="0746790063"
            >
              {data ? data['0746790063'] : 'Ui/UX Design'}
            </h2>
            <p
              id="8106334016"
              className="dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium mt-2 textColor-light"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['8106334016']
                  : 'Free Session on Basic Ux/Ui Concepts',
              }}
            ></p>
            <div className="lg:mt-8 md:mt-8 mt-6">
              <Button
                px={8}
                py={6}
                className={
                  colorMode === 'light'
                    ? 'bg-primary clr-fff rounded-100px fsize14 theme-btn dynamicStyle'
                    : ' rounded-100px fsize14 dynamicStyle '
                }
                
                id="4156727709"
              >
                <span onClick={() => handleClick('4156727709')}>
                  {data ? data['4156727709'] : 'Register to Join'}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection24;
