import { Input, Textarea } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FaPhone, FaPhoneAlt } from 'react-icons/fa';
import { FiMail, FiMapPin } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { handleScroll } from './fadeeffect/FadeEffect';

const ContactDetails4 = ({pageName}) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8">
      <div className="container mx-auto">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center lg:mb-12 md:mb-12 mb-6">
          <h2 id='9692829694' className="dynamicStyle font-semibold header2 cust-textColor fsize30 sm-fsize24 mb-2 relative inline-block">
          {data ? data['9692829694'] : 'Get in touch'}
          </h2>

          <p className="fsize16 sm-fsize14 dynamicStyle textColor-light"
          id="2664572945"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['2664572945']
              : 'I m a young tech enthasist and entrepreneur',
          }}>
            
          </p>
        </div>

        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 lg:px-20 md:px-12 pr-4">
          <div className="flex">
            <div className="bg-primary icon-box cust-bgColor flex items-center justify-center">
              <FiMapPin />
            </div>

            <div className="">
              <h4 id='8870648199' className="dynamicStyle font-bold fsize18 sm-fsize16 cust-textColor">
              {data ? data['8178921044'] : 'Lorem Ipsum'}
              </h4>

              <p id='1333994894' className="dynamicStyle fsize16 sm-fsize14 textColor-light">
              {data ? data['8178921044'] : 'Lorem Ipsum'}
              </p>
            </div>
          </div>

          <div className="flex">
            <div className="bg-primary icon-box cust-bgColor flex items-center justify-center">
              <FaPhoneAlt className="" />
            </div>

            <div className="">
              <h4 id='2188533937' className="dynamicStyle font-bold fsize18 sm-fsize16 cust-textColor">
              {data ? data['2188533937'] : 'Lorem Ipsum'}
              </h4>

              <a href="tel:123 456 7890">
                <p  className="fsize16 sm-fsize14 textColor-light">
                123 456 7890
                </p>
              </a>
            </div>
          </div>

          <div className="flex">
            <div className="bg-primary icon-box cust-bgColor flex items-center justify-center">
              <FiMail />
            </div>

            <div className="">
              <h4 id='7103505330' className="dynamicStyle font-bold fsize18 sm-fsize16 cust-textColor">
              {data ? data['7103505330'] : 'Lorem Ipsum'}
              </h4>

              <a href="mailto:abcd@abcd.com">
                <p className="fsize16 sm-fsize14 textColor-light">
                  abcd@abcd.com
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="w-full lg:flex md:flex gap-6 lg:pt-20 md:pt-16 pt-12">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.8095320612306!2d72.85291797495573!3d19.116009950715732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7170e07b235%3A0xb59deaaa5b1ce0e6!2sBRAINED!5e0!3m2!1sen!2sin!4v1712748084423!5m2!1sen!2sin"
              className="iframe-size"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div ref={elementRef} className={`fade-right lg:w-1/2 md:w-1/2 w-full lg:pt-0 md:pt-0 pt-6 ${isVisible ? 'visibleright' : ''} `}>
            <h3 id='0226066121' className="dynamicStyle fsize36 sm-fsize24 font-bold lg:mb-8 md:mb-6 mb-4 cust-textColor">
            {data ? data['0226066121'] : 'Lorem Ipsum'}
            </h3>

            <div className="w-full lg:flex md:flex gap-6 mb-4">
              <div className="lg:w-1/2 md:w-1/2 w-full">
                
                <Input placeholder="Name" className="form-control theme-bg themeShadow" />
              </div>

              <div className="lg:w-1/2 md:w-1/2 w-full lg:mt-0 md:mt-0 mt-4">
                <Input placeholder="Email" className="form-control theme-bg themeShadow" />
              </div>
            </div>

            <div className="mb-6">
              <Textarea
                placeholder="Here is a sample placeholder"
                className="form-control theme-bg themeShadow"
              />
            </div>

            <div id='0431613367' className="dynamicStyle mt-8">
              <a href="#" className="buttonAnimate  theme-btn2 w-full text-center clr-fff bg-primary py-3 lg:px-6 md:px-4 px-2 font-semibold cust-bgColor cust-textColor fsize16 sm-fsize16px buttonAnimate bdr-2px">
              {data ? data['0431613367'] : 'Send Message'}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails4;
