import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Image, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard15 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section
      //   className="lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6 bg-grad"
      className={
        colorMode === 'light'
          ? 'lg:px-12 md:px-8 px-5 lg:py-10 md:py-8 py-6 bg-grad theme-bg'
          : 'lg:px-12 md:px-8 px-5 lg:py-10 md:py-8 py-6'
      }
    >
      <div className="container mx-auto overflow-x-hidden lg:px-12 md:px-8 px-5 md:mt-3">
        <div className="lg:w-2/3 md:w-2/3 mx-auto text-center lg:pb-12 md:pb-12 pb-6">
          <h2
            id="9059741550"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold text-primary cust-textColor"
          >
            {data ? data['9059741550'] : 'Welcome to My World'}
          </h2>
          <p
            id="5140663073"
            className="dynamicStyle theme-textColor fsize16 md-fsize16 sm-fsize14 clr-fff textColor-light"
          >
            {data ? data['5140663073'] : 'Welcome to My World'}
          </p>
        </div>
        <div ref={elementRef} className={`fade-bottom grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 ${isVisible ? 'visiblebottom' : ''} `}>
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none p-4  w-full relative'
                : 'p-4 w-full relative'
            }
          >
            <div
              id="2809162205"
              className="dynamicStyle2 flex justify-center w-full "
            >
              <Image
                src={data ? getImage(data['2809162205']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-24 h-24 lg:mb-6 md:mb-6 mb-4 rounded-full border  theme-border p-3 relative"
              />
              {/* <div className="absolute feather-abs">
                <FeatherIcon icon="search" size={40} />
              </div> */}
            </div>
            <h2
              id="5896305252"
              className="dynamicStyle theme-textColor fsize20 md-fsize18 sm-fsize16 text-center cust-textColor clr-fff"
            >
              {data ? data['5896305252'] : 'Welcome to My World'}
            </h2>

            <div className="absolute-line"></div>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none p-4 w-full border-left relative'
                : 'p-4 w-full border-left relative'
            }
          >
            <div
              id="7508598544"
              className="dynamicStyle2 flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data['7508598544']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-24 h-24 lg:mb-6 md:mb-6 mb-4 rounded-full border theme-border  p-3 relative"
              />
              {/* <div className="absolute feather-abs">
                <FeatherIcon icon="search" size={40} />
              </div> */}
            </div>
            <h2
              id="9962896322"
              className="dynamicStyle theme-textColor fsize20 md-fsize18 sm-fsize16 text-center cust-textColor clr-fff"
            >
              {data ? data['9962896322'] : 'Welcome to My World'}
            </h2>
            <div className="absolute-line1"></div>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none p-4 w-full border-left relative'
                : 'p-4 w-full border-left relative'
            }
          >
            <div
              id="3541683835"
              className="dynamicStyle flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data['3541683835']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-24 h-24 lg:mb-6 md:mb-6 mb-4 rounded-full border theme-border  p-3 relative"
              />
              {/* <div className="absolute feather-abs">
                <FeatherIcon icon="search" size={40} />
              </div> */}
            </div>
            <h2
              id="1817480977"
              className="dynamicStyle theme-textColor fsize20 md-fsize18 sm-fsize16 text-center cust-textColor clr-fff"
            >
              {data ? data['1817480977'] : 'Welcome to My World'}
            </h2>
            <div className="absolute-line2"></div>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none p-4 w-full border-left relative'
                : 'p-4 w-full border-left relative'
            }
          >
            <div
              id="8118999094"
              className="dynamicStyle flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data['8118999094']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-24 h-24 lg:mb-6 md:mb-6 mb-4 rounded-full border theme-border  p-3 relative"
              />
              {/* <div className="absolute feather-abs">
                <FeatherIcon icon="search" size={40} />
              </div> */}
            </div>
            <h2
              id="4655462936"
              className="dynamicStyle theme-textColor fsize20 md-fsize18 sm-fsize16 text-center cust-textColor clr-fff"
            >
              {data ? data['4655462936'] : 'Welcome to My World'}
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard15;
