import React, { useEffect, useRef, useState } from 'react';
import { Image, useColorMode } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from 'react-redux';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
const Testimonial12 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <> 
    {getAllTestimonial ? (
    <section className="lg:py-16 md:py-12 py-8">
      <div id="9715257624" className="dynamicStyle2 h-650px sm-h550px relative">
        <Image
          src={data ? getImage(data['9715257624']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
          className="h-650px sm-h550px w-full"
        />

        <div className="absolute top-0 left-0 h-650px sm-h550px flex justify-center items-center w-full">
          <div className="container mx-auto lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5">
            <div className="pb-6">
              <p
                id="2291987393"
                className="dynamicStyle text-primary fsize40 md-fsize32 sm-fsize20 cust-textColor font-semibold"
              >
                {data ? data['2291987393'] : 'Our Clients'}
              </p>
            </div>
            <div className="">
              {/* <div className="swiper-button custswiper image-swiper-button-next">
          <IoIosArrowForward />
        </div>
        <div className="swiper-button custswiper image-swiper-button-prev">
          <IoIosArrowBack />
        </div> */}
              <Swiper
                spaceBetween={20}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                breakpoints={{
                  1536: {
                    slidesPerView: 3.5,
                    spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 3.5,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3.5,
                    spaceBetween: 20,
                  },
                  991: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                  },
                  820: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  425: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                  },
                  325: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                  },
                }}
              >
                {getAllTestimonial &&
                getAllTestimonial.data &&
                getAllTestimonial.data.result &&
                getAllTestimonial.data.result.length > 0
                  ? getAllTestimonial.data.result.map((testimonial, index) => {
                      return (
                        <SwiperSlide className="p-2 bdr-1px-fff">
                          <div className="h-200px mb-4">
                            <Image
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                              src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                              alt="img"
                              className="h-200px w-full rounded object-cover"
                            />
                          </div>
                          <div>
                            <h3 className="fsize24 sm-fsize20 font-semibold clr-fff lg:pb-6 md:pb-6 pb-4">
                              {testimonial.name}
                            </h3>
                            <p className="sm-fsize14 clr-fff lineClamp6">
                              {testimonial.testimonial}
                            </p>
                          </div>
                        </SwiperSlide>
                      );
                    })
                  : 'No Data'}
                {/* <SwiperSlide className="p-2 bdr-1px-fff">
                <div className="h-200px mb-4">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="h-200px w-full rounded object-cover"
                  />
                </div>
                <div>
                  <h3 className="fsize24 sm-fsize20 font-semibold clr-fff lg:pb-6 md:pb-6 pb-4">
                    I'm writing this because
                  </h3>
                  <p className='sm-fsize14 clr-fff lineClamp6'>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ad
                    labore deserunt suscipit facilis quibusdam esse debitis
                    consectetur. Dignissimos impedit optio aliquid quaerat,
                    reiciendis nulla voluptatem, natus voluptatum magnam
                    consequatur iste.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-2 bdr-1px-fff">
                <div className="h-200px mb-4">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="h-200px w-full rounded object-cover"
                  />
                </div>
                <div>
                  <h3 className="fsize24 sm-fsize20 font-semibold clr-fff lg:pb-6 md:pb-6 pb-4">
                    I'm writing this because
                  </h3>
                  <p className='sm-fsize14 clr-fff lineClamp6'>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ad
                    labore deserunt suscipit facilis quibusdam esse debitis
                    consectetur. Dignissimos impedit optio aliquid quaerat,
                    reiciendis nulla voluptatem, natus voluptatum magnam
                    consequatur iste.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-2 bdr-1px-fff">
                <div className="h-200px mb-4">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="h-200px w-full rounded object-cover"
                  />
                </div>
                <div>
                  <h3 className="fsize24 sm-fsize20 font-semibold clr-fff lg:pb-6 md:pb-6 pb-4">
                    I'm writing this because
                  </h3>
                  <p className='sm-fsize14 clr-fff lineClamp6'>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ad
                    labore deserunt suscipit facilis quibusdam esse debitis
                    consectetur. Dignissimos impedit optio aliquid quaerat,
                    reiciendis nulla voluptatem, natus voluptatum magnam
                    consequatur iste.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-2 bdr-1px-fff">
                <div className="h-200px mb-4">
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
                    alt="img"
                    className="h-200px w-full rounded object-cover"
                  />
                </div>
                <div>
                  <h3 className="fsize24 sm-fsize20 font-semibold clr-fff lg:pb-6 md:pb-6 pb-4">
                    I'm writing this because
                  </h3>
                  <p className='sm-fsize14 clr-fff lineClamp6'>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ad
                    labore deserunt suscipit facilis quibusdam esse debitis
                    consectetur. Dignissimos impedit optio aliquid quaerat,
                    reiciendis nulla voluptatem, natus voluptatum magnam
                    consequatur iste.
                  </p>
                </div>
              </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
    ) : (
      ''
    )}
    </>
  );
};

export default Testimonial12;
