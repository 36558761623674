import API from './repositoryFactory';

const get = 'get';

const portfolioRoute = route => {
  return `baseAdminCategory/${route}`;
};

const portfolioCategoryRoute = route => {
  return `portfolio/category/${route}`;
};

export default {
  getAllCategory() {
    return API.post(portfolioCategoryRoute(get));
  },

  getAllPortfolio() {
    return API.post(portfolioRoute(get));
  },
};
