import { Avatar, AvatarGroup, Button, Image } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HeroSection19 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  let selectedStyle = useSelector(state => state.brandDetails.brandProfile.businessThemeVariation);
  console.log(selectedStyle,'ss');
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  return (
    <div className="lg:flex container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-14 py-10 sm-custdirection md-custdirection items-center lg:gap-10 md:gap-8 gap-5">
      <div className="lg:w-7/12 md:w-full w-full">
        <h2
          id="0745685771"
          className="dynamicStyle text-primary theme-textColor fsize50 md-fsize36 sm-fsize24 font-semibold cust-textColor lh-60px md-lh-48 sm-lh-26px"
        >
          {data
            ? data['0745685771']
            : 'Lets Learn New Courses & Gain More Skills'}
        </h2>
        <div className="px-4 lg:py-2 md:py-2 py-1 bdr-left-3px my-4">
          <p
            id="4929292401"
            className="dynamicStyle cust-textColor fsize16 sm-fsize14 textColor-light"
            dangerouslySetInnerHTML={{
              __html: data ? data['4929292401'] : 'no-data',
            }}
          ></p>
        </div>
        <div className="lg:flex md:flex gap-8 lg:py-4 md:py-3 py-3">
          <Button
            size="lg"
            px={12}
            id="1874707923"
            className={
              selectedStyle
                ? 'theme-btn dynamicStyle clr-fff rounded-100px bg-primary cust-bgColor fsize14 sm-fsize14  bdr-2px'
                : 'clr-fff  dynamicStyle rounded-100px bg-primary cust-bgColor fsize14 sm-fsize14 buttonAnimate '
            }
          >
            <span onClick={() => handleClick('1874707923')}>
              {data ? data['1874707923'] : 'Get Course'}
            </span>
          </Button>
          <div className="flex items-center gap-2 lg:mt-0 md:mt-0 mt-3">
            <AvatarGroup size="sm" max={6}>
              <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
              <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
              <Avatar
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
            </AvatarGroup>
            <p className="fsize12 textColor-light">20K+ Enrolment</p>
          </div>
        </div>
        <div className="flex gap-5 ">
          <div>
            <p
              id="0210183450" className="dynamicStyle cust-textColor fsize30 md-fsize20 sm-fsize20 font-semibold"
            >
              {data ? data['0210183450'] : '1000+'}
            </p>
            <p id="0122764783" className="dynamicStyle cust-textColor fsize14">
              {data ? data['0122764783'] : 'Reviews'}
            </p>
          </div>
          <div>
            <p
              id="8562862712" className="dynamicStyle cust-textColor fsize30 md-fsize20 sm-fsize20 font-semibold"
              >
                {data ? data['8562862712'] : '1500'}
            </p>
            <p id="6429580760" className="dynamicStyle cust-textColor fsize14">
              {data ? data['6429580760'] : 'Instructors'}
            </p>
          </div>
        </div>
      </div>
      <div className="lg:ml-0 md:ml-20 lg:w-5/12 md:w-full w-full lg:flex md:flex block lg:justify-end">
        <div
          ref={elementRef}
          className={`lg:mr-0 md:mr-0 mr-0 fade-right img-right-back relative rounded-2xl bg-primary cust-bgColor lg:w-3/4 md:w-1/2 w-11/12 ${
            isVisible ? 'visibleright' : ''
          } `}
        >
          <div
            id="4681986771"
            className="dynamicStyle2 img-right z-10 rounded-2xl w-full"
          >
            <Image
              src={data ? getImage(data['4681986771']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              className="img-right object-cover absolute-cards lg:absolute md:absolute absolute z-20 rounded-2xl lg:w-full md:w-full w-full"
              alt="cards"
              style={{ transform: transform }}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection19;
