import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';

function UploaderBox({ onFileSelect, onPreview, accept }) {
  const handleFileChange = e => {
    const file = e.target.files[0];
    onFileSelect(file);
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        onPreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div className="uploader relative">
        <input
          accept={accept}
          onChange={handleFileChange}
          type="file"
          className="uploader-input cursor-pointer"
        />

        <div className=" absolute w-full h-180px top-0 left-0 flex flex-col justify-center items-center">
          <FeatherIcon icon="upload-cloud" className="text-gray cust-textColor" size={48} />

          <h2 className="fsize14 cust-textColor">
            Drop here or{' '}
            <span className="text-primary cust-textColor">Click here to upload</span>
          </h2>
        </div>
      </div>

      {/* <span className="fsize12">jpg/png files with a size less than 500kb</span> */}
    </div>
  );
}

export default UploaderBox;
