import { Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import VanillaTilt from 'vanilla-tilt';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard12 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  // const items = [
  //   {
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',

  //     title: 'Creativity',

  //     description:
  //       'Duis aute irure dolor in it esse cillum fugiat nulla pari erunt mollit anim id est laborum.',
  //   },

  //   {
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',

  //     title: 'Creativity 1',

  //     description:
  //       'Duis aute irure dolor in it esse cillum fugiat nulla pari erunt mollit anim id est laborum.',
  //   },

  //   {
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',

  //     title: 'Creativity 2',

  //     description:
  //       'Duis aute irure dolor in it esse cillum fugiat nulla pari erunt mollit anim id est laborum.',
  //   },
  // ];
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll('.item-card2'), {
      max: 25,
      speed: 400,
      glare: true,
      'max-glare': 0.5,
    });
  }, []);

  return (
    <div className={colorMode === 'light' ? 'bg-1a2540 theme-bg' : ''}>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8">
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center lg:mb-12 md:mb-12 mb-6">
          <h2
            id="9440199575"
            className="dynamicStyle font-semibold text-primary fsize34 md-fsize24 sm-fsize20 relative inline-block cust-textColor"
          >
            {data ? data['9440199575'] : 'Welcome to my world'}
          </h2>

          <p
            id="9148210071"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 clr-fff textColor-light mt-2 theme-textColor"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['9148210071']
                : 'I m a young tech enthasist and entrepreneur who love to take risk.',
            }}
          ></p>
        </div>

        <div
          id="0478935476"
          className="dynamicStyle w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-6"
        >
          {data && data['0478935476']
            ? data['0478935476'].map(e => {
                return (
                  <div
                    className={
                      colorMode === 'light'
                        ? 'item-card2 text-center themeShadow'
                        : 'item-card2 border-1px text-center themeShadow'
                    }
                    data-tilt
                  >
                    <div className="lg:w-1/4 md:w-2/4 w-1/2 mx-auto">
                      <Image
                        src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="w-20 h-20 mx-auto bg-gray rounded-full"
                      />
                    </div>

                    <div className="">
                      <h3 className="fsize24 sm-fsize20 mt-2 lg:mb-4 md:mb-3 mb-2 font-medium theme-textColor">
                        {e.field2}
                      </h3>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: e.field3,
                        }}
                        className="fsize16 sm-fsize14 clr-b0aac0 "
                      ></p>
                    </div>
                  </div>
                );
              })
            : 'no-data'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard12;
