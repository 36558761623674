import { CheckIcon, CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import { Button, Stack } from '@chakra-ui/react';
import React from 'react';
import { MoreVertical } from 'react-feather';
import { FiEye } from 'react-icons/fi';

const NearbyDueDate = () => {
  return (
    <div className="w-full pt-8">
      <div className="lg:flex md:flex items-baseline gap-2">
        <h2 className="fsize28 sm-fsize20 text-primary">Nearing Due Date</h2>
        <p className="fsize14 sm-fsize12 text-gray">(4 results found)</p>
      </div>
      <div className="lg:mt-12 md:mt-8 mt-6 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
        <div className="borderall rounded-lg py-3 px-4">
          <div className="w-full relative">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="KMP"
              id=""
              className="w-1/2 mx-auto h-32 object-contain"
            />
            <div className="absolute top-right-8px p-1 flex justify-center items-center">
              <MoreVertical className="text-gray" />
            </div>
            <div className="absolute top-left-8px p-0   rounded-full flex justify-center items-center">
              <p className="fsize12 clr-f95c27 font-medium">6 Days left</p>
            </div>
          </div>
          <div className="mt-3">
            <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
              Employe code sample draft
            </h2>
            <p className="text-gray fsize14 sm-fsize12">Microsoft word file</p>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
                <Stack direction="row" spacing={4} width="100%">
                  <Button
                    leftIcon={<DownloadIcon />}
                    variant="solid"
                    fontWeight="400"
                    className="bg-f98c27 textwhite fsize14 rounded-100px w-full"
                  >
                    Download
                  </Button>
                </Stack>
              </div>
              <div className="w-full">
                <Stack direction="row" spacing={4} width="100%">
                  <Button
                    leftIcon={<FiEye />}
                    variant="solid"
                    fontWeight="400"
                    className="bg-053c6d textwhite fsize14 rounded-100px w-full"
                  >
                    Preview
                  </Button>
                </Stack>
              </div>
            </div>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
                <Stack direction="row" spacing={4} width="100%">
                  <Button
                    leftIcon={<CheckIcon />}
                    variant="solid"
                    px={1}
                    fontWeight="400"
                    className="bg-0fba2a clr-0fba2a fsize12 w-full"
                  >
                    Approve
                  </Button>
                </Stack>
              </div>
              <div className="w-full">
                <Stack direction="row" spacing={4} width="100%">
                  <Button
                    leftIcon={<CloseIcon />}
                    px={1}
                    variant="solid"
                    fontWeight="400"
                    className="bg-ec1c24 text-danger fsize12 w-full"
                  >
                    Reject
                  </Button>
                </Stack>
              </div>
            </div>
          </div>
        </div>
        <div className="borderall rounded-lg py-3 px-4">
          <div className="w-full relative">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="KMP"
              id=""
              className="w-1/2 mx-auto h-32 object-contain"
            />
            <div className="absolute top-right-8px p-1 flex justify-center items-center">
              <MoreVertical className="text-gray" />
            </div>
            <div className="absolute top-left-8px p-0   rounded-full flex justify-center items-center">
              <p className="fsize12 clr-04bd00 font-medium">6 Days left</p>
            </div>
          </div>
          <div className="mt-3">
            <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
              Employe code sample draft
            </h2>
            <p className="text-gray fsize14 sm-fsize12">Microsoft word file</p>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
                <Stack direction="row" spacing={4} width="100%">
                  <Button
                    leftIcon={<DownloadIcon />}
                    variant="solid"
                    fontWeight="400"
                    className="bg-f98c27 textwhite fsize14 rounded-100px w-full"
                  >
                    Download
                  </Button>
                </Stack>
              </div>
              <div className="w-full">
                <Stack direction="row" spacing={4} width="100%">
                  <Button
                    leftIcon={<FiEye />}
                    variant="solid"
                    fontWeight="400"
                    className="bg-053c6d textwhite fsize14 rounded-100px w-full"
                  >
                    Preview
                  </Button>
                </Stack>
              </div>
            </div>
            <div className="mt-3 flex gap-2 items-center justify-center w-full">
              <div className="w-full">
                <Stack direction="row" spacing={4} width="100%">
                  <Button
                    leftIcon={<CheckIcon />}
                    variant="solid"
                    px={1}
                    fontWeight="400"
                    className="bg-0fba2a clr-0fba2a fsize12 w-full"
                  >
                    Approve
                  </Button>
                </Stack>
              </div>
              <div className="w-full">
                <Stack direction="row" spacing={4} width="100%">
                  <Button
                    leftIcon={<CloseIcon />}
                    px={1}
                    variant="solid"
                    fontWeight="400"
                    className="bg-ec1c24 text-danger fsize12 w-full"
                  >
                    Reject
                  </Button>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NearbyDueDate;
