import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Text, Image, Box, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section>
      <div className={colorMode === 'light' ? 'bg-secondary theme-bg' : ''}>
        <div className="lg:flex md:flex sm-reverse lg:px-0 md:px-0 px-5 lg:py-0 md:py-8 py-6 items-center ">
          <div
            ref={elementRef} className={`fade-left lg:w-3/5 md:w-3/5 lg:px-20 md:px-8 lg:mt-0 md:mt-0 mt-4 ${isVisible ? 'visibleleft' : ''} `}
          >
            <h2
              id="6270534268"
              className="dynamicStyle fsize34 md-fsize24 sm-fsize20 uppercase text-end text-primary font-semibold"
            >
              {data ? data['6270534268'] : 'What is fit2go.asia about?'}
            </h2>
            <p
              id="7855163857"
              className="dynamicStyle textColor-light clr-fff fsize16 theme-textColor sm-fsize14 opacity-70 lg:mt-2 md:mt-2 mt-2 lg:leading-7 md:leading-7 leading-6"
              dangerouslySetInnerHTML={{
                __html: data ? data['7855163857'] : 'no-data',
              }}
            ></p>
          </div>

          <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
            <div id="2628293436" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['2628293436']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="heroImage"
                className="object-cover w-full sm-h250px minh450px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection4;
