import React, { useState } from 'react';
import { Button, Image } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';

const Assets = () => {
  return (
    <div>
      <div className="lg:px-12 md:px-8 px-5 lg:py-10 py-8 container mx-auto">
        <h2 className="fsize25 text-primary cust-textColor">Assets</h2>

        <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-1 gap-4 mt-8">
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
            />
            <p className="fsize18 text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
            />
            <p className="fsize18 text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
            />
            <p className="fsize18 text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
            />
            <p className="fsize18 text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
            />
            <p className="fsize18 text-gray mt-5 text-center">Folder 1</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assets;
