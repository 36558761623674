import React, { useEffect, useRef, useState } from 'react';
import {
  Image,
  Badge,
  Button,
  Card,
  FormControl,
  FormLabel,
  Select,
  useColorMode,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import {
  getAllBlogs,
  getBlogs,
  getAllCategory,
  setSelectedBlog,
  getBlogsLoadMore,
  setBlogsLoadMore,
} from '../../../redux/blogs/blogs-actions';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { handleScroll } from './fadeeffect/FadeEffect';

const CONSTANT_PER_PAGE = 9;
const CONSTANT_PAGE = 0;

const CardLayout1 = ({ isSideBarOpen, pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // let Blogs = useSelector((state) => state.BlogDetails.Blogs);
  let BlogsLoadMore = useSelector(state => state.BlogDetails.BlogsLoadMore);
  // console.log(Blogs, "checkvalue");
  let allCategory = useSelector(state => state.BlogDetails.Category);
  console.log(allCategory, 'cate');

  const [page, setPage] = useState(CONSTANT_PAGE);
  const [perPage, setPerPage] = useState(CONSTANT_PER_PAGE);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    dispatch(getAllCategory());
  }, []);

  useEffect(() => {
    fetchBlogs();
  }, [
    page,
    // perPage,
    selectedCategory,
  ]);

  const fetchBlogs = async () => {
    let payload = {
      isActive: true,
      offset: page,
      limit: perPage,
    };
    if (selectedCategory && selectedCategory !== 'all') {
      Object.assign(payload, { categoryId: [selectedCategory] });
    }
    let data = await dispatch(getBlogsLoadMore(payload));
    if (data && data.code === 200 && data.data && data.data.totalCount > 0) {
      if (page === 0) {
        dispatch(setBlogsLoadMore(data.data));
      } else {
        dispatch(
          setBlogsLoadMore({
            ...data.data,
            result: [...BlogsLoadMore.result, ...data.data.result],
          })
        );
      }
    } else {
      dispatch(setBlogsLoadMore({}));
    }
    // console.log(Blogs);
    // setFilteredPosts(Blogs)
  };

  const handleLoadMore = () => {
    // setPerPage(perPage + CONSTANT_PER_PAGE);
    setPage(state => state + 1);
  };

  const handleBlogDetail = blogId => {
    dispatch(setSelectedBlog({}));
    navigate(`/blog detail/${blogId}`);
  };
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  // const onSelectCategory =(categoryId) =>{
  //   setSelectedCategory(categoryId)

  //   if(categoryId === 'all'){
  //     setFilteredPosts(allBlog)
  //   }
  //   else {
  // const filtered = allBlog.filter((post)=> post.categoryID[0]._id === categoryId)
  // setFilteredPosts(filtered)
  // console.log(filtered,'id');
  //   }

  // }

  return (
    <section>
      <div className=" relative zindex9 container mx-auto  lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
        <div className="lg:flex md:flex justify-between">
          <h2
            id="6706999526"
            className="dynamicStyle cust-textColor lg:text-3xl text-end md:text-2xl text-xl text-primary font-semibold"
          >
            {data ? data['6706999526'] : 'Lorem ipsum'}
          </h2>

          <FormControl width="25" className="lg:mt-0 md:mt-0 mt-4 cust-textColor">
            <Select
              value={selectedCategory}
              onChange={e => {
                setSelectedCategory(e.target.value);
                // setPerPage(CONSTANT_PER_PAGE);
                setPage(CONSTANT_PAGE);
              }}
            >
              <option value="all">All</option>
              {allCategory &&
                allCategory.category &&
                allCategory.category.length > 0 &&
                allCategory.category.map(item => (
                  <option key={item._id} value={item._id}>
                    {item.categoryName}
                  </option>
                ))}
            </Select>
          </FormControl>
        </div>

        {isLoading ? (
          <div className="flex justify-center align-center">
            <ThreeDots visible={true} color="#ed1b24" radius={9} />
          </div>
        ) : (
          <>
            {BlogsLoadMore &&
            BlogsLoadMore.result &&
            BlogsLoadMore.result.length > 0 ? (
              <div ref={elementRef} className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}>
                <div className="lg:mt-12 md:mt-12 mt-6 shadow-none lg:grid md:grid lg:grid-cols-3  md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-8 gap-4">
                  {BlogsLoadMore.result.map((e, i) => {
                    return (
                      <Card
                        key={e._id}
                        p="2"
                        className={
                          colorMode === 'light'
                            ? 'cursor-pointer themeShadow theme-bg'
                            : 'cursor-pointer themeShadow theme-bg'
                        }
                        
                        onClick={() => handleBlogDetail(e._id)}
                      >
                        <div className='overflow-hidden rounded-xl'>
                        <Image
                          height="275px"
                          src={process.env.REACT_APP_STORAGE_URL + e.image}
                          alt="heroImage"
                          className="w-full object-cover rounded-xl custimghover sm-h250px md-h200px w-full"
                        />
                        </div>
                        <div className="p-2 lg:mt-4 md:mt-4">
                          <div className="flex items-center justify-between mb-4">
                            {e.categoryID &&
                              e.categoryID.length > 0 &&
                              e.categoryID[0].categoryName && (
                                <Badge
                                  borderRadius="full"
                                  px="3"
                                  py="0.5"
                                  colorScheme="teal"
                                >
                                  {e.categoryID[0].categoryName}
                                </Badge>
                              )}
                            <p className="text-gray text-sm">
                              {new Date(e.createdAt).toDateString()}
                            </p>
                          </div>

                          <h4 className="font-semibold lg:text-xl md:text-xl text-lg line-clamp-2 cust-textColor">
                            {e.title}
                          </h4>
                          <p
                            dangerouslySetInnerHTML={{ __html: e.content }}
                            className="mt-2 text8787 line-clamp-1 lg:text-sm md:text-sm text-sm textColor-light"
                          ></p>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="flex justify-center align-center">
                No Blog Found
              </div>
            )}
          </>
        )}
        {BlogsLoadMore &&
          BlogsLoadMore.totalPages &&
          BlogsLoadMore.totalPages - 1 > page && (
            <div className="lg:mt-10 md:mt-10 mt-2  text-center">
              <Button
                className="bg-primary textwhite"
                onClick={handleLoadMore}
                width="200px"
                height="50px"
              >
                LOAD MORE
              </Button>
            </div>
          )}
      </div>
    </section>
  );
};

export default CardLayout1;
