import { DownloadIcon } from '@chakra-ui/icons';
import { Button, Image, Stack } from '@chakra-ui/react';
import React from 'react';
import { FiEye } from 'react-icons/fi';
import FeatherIcon from 'feather-icons-react';

const Approved = () => {
  return (
    <div>
      <div className="w-full pt-8">
        <div className="lg:flex md:flex items-baseline gap-2">
          <h2 className="fsize28 sm-fsize20 text-primary">Approved Files</h2>
          <p className="fsize14 sm-fsize12 text-gray">(4 results found)</p>
        </div>
        <div className="mt-6 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
          <div className="borderall rounded-lg py-3 px-4">
            <div className="w-full relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-1/2 mx-auto h-32 object-contain"
              />
              <div className="absolute top-right-8px p-2 rounded-full flex justify-center items-center">
                <FeatherIcon icon="more-vertical" className="text-gray" />
              </div>
            </div>
            <div className="mt-3">
              <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                Employe code of conduct
              </h2>
              <p className="text-gray fsize14 sm-fsize12">
                Microsoft word file
              </p>
              <div className="mt-3 flex gap-2 items-center justify-center w-full">
                <div className="w-full">
                  <Stack direction="row" spacing={4} width="100%">
                    <Button
                      leftIcon={<DownloadIcon />}
                      colorScheme="primary"
                      fontWeight="400"
                      className="textwhite fsize14 rounded-100px w-full"
                    >
                      Download
                    </Button>
                  </Stack>
                </div>
                <div className="w-full">
                  <Stack direction="row" spacing={4} width="100%">
                    <Button
                      leftIcon={<FiEye />}
                      colorScheme="secondary"
                      fontWeight="400"
                      className=" textwhite fsize14 rounded-100px w-full"
                    >
                      Preview
                    </Button>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
          <div className="borderall rounded-lg py-3 px-4">
            <div className="w-full relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-1/2 mx-auto h-32 object-contain"
              />
              <div className="absolute top-right-8px p-2 rounded-full flex justify-center items-center">
                <FeatherIcon icon="more-vertical" className="text-gray" />
              </div>
            </div>
            <div className="mt-3">
              <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                Employe code of conduct
              </h2>
              <p className="text-gray fsize14 sm-fsize12">
                Microsoft word file
              </p>
              <div className="mt-3 flex gap-2 items-center justify-center w-full">
                <div className="w-full">
                  <Stack direction="row" spacing={4} width="100%">
                    <Button
                      leftIcon={<DownloadIcon />}
                      colorScheme="primary"
                      fontWeight="400"
                      className="textwhite fsize14 rounded-100px w-full"
                    >
                      Download
                    </Button>
                  </Stack>
                </div>
                <div className="w-full">
                  <Stack direction="row" spacing={4} width="100%">
                    <Button
                      leftIcon={<FiEye />}
                      colorScheme="secondary"
                      fontWeight="400"
                      className=" textwhite fsize14 rounded-100px w-full"
                    >
                      Preview
                    </Button>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
          <div className="borderall rounded-lg py-3 px-4">
            <div className="w-full relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-1/2 mx-auto h-32 object-contain"
              />
              <div className="absolute top-right-8px p-2 rounded-full flex justify-center items-center">
                <FeatherIcon icon="more-vertical" className="text-gray" />
              </div>
            </div>
            <div className="mt-3">
              <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                Employe code of conduct
              </h2>
              <p className="text-gray fsize14 sm-fsize12">
                Microsoft word file
              </p>
              <div className="mt-3 flex gap-2 items-center justify-center w-full">
                <div className="w-full">
                  <Stack direction="row" spacing={4} width="100%">
                    <Button
                      leftIcon={<DownloadIcon />}
                      colorScheme="primary"
                      fontWeight="400"
                      className="textwhite fsize14 rounded-100px w-full"
                    >
                      Download
                    </Button>
                  </Stack>
                </div>
                <div className="w-full">
                  <Stack direction="row" spacing={4} width="100%">
                    <Button
                      leftIcon={<FiEye />}
                      colorScheme="secondary"
                      fontWeight="400"
                      className=" textwhite fsize14 rounded-100px w-full"
                    >
                      Preview
                    </Button>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
          <div className="borderall rounded-lg py-3 px-4">
            <div className="w-full relative">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="KMP"
                id=""
                className="w-1/2 mx-auto h-32 object-contain"
              />
              <div className="absolute top-right-8px p-2 rounded-full flex justify-center items-center">
                <FeatherIcon icon="more-vertical" className="text-gray" />
              </div>
            </div>
            <div className="mt-3">
              <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                Employe code of conduct
              </h2>
              <p className="text-gray fsize14 sm-fsize12">
                Microsoft word file
              </p>
              <div className="mt-3 flex gap-2 items-center justify-center w-full">
                <div className="w-full">
                  <Stack direction="row" spacing={4} width="100%">
                    <Button
                      leftIcon={<DownloadIcon />}
                      colorScheme="primary"
                      fontWeight="400"
                      className="textwhite fsize14 rounded-100px w-full"
                    >
                      Download
                    </Button>
                  </Stack>
                </div>
                <div className="w-full">
                  <Stack direction="row" spacing={4} width="100%">
                    <Button
                      leftIcon={<FiEye />}
                      colorScheme="secondary"
                      fontWeight="400"
                      className=" textwhite fsize14 rounded-100px w-full"
                    >
                      Preview
                    </Button>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approved;
