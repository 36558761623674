const initialState = {
  Pages: [],
};
const pages_reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setPagesData':
      return { ...state, Pages: action.payload };

    default:
      return state;
  }
};

export default pages_reducer;
