import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';

const Clients10 = ({ pageName }) => {
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
  
    const { colorMode, toggleColorMode } = useColorMode();
    const data = useSelector(state => state.cms.allSections[pageName]);
    const CTALink = useSelector(state => state.cms.links[pageName]);
    const navigate = useNavigate();
    const link = 'https://example.com';
  
    const handleClick = id => {
      const isHttpsLink =
        CTALink[id].includes('https') || CTALink[id].includes('www');
      if (isHttpsLink) {
        window.open(`${CTALink[id]}`, '_blank');
  
        console.log(link, 'cmsdatacheck');
      } else {
        navigate(`/${CTALink[id]}`);
      }
    };
  
    function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
  return (
    <div className='container mx-auto lg:py-16 md:py-16 py-12 lg:px-12 md:px-8 px-5'>
      <div className="lg:w-2/3 md:w-3/4 w-full mx-auto">
        <h2
          id="5825329058"
          className="dynamicStyle pb-2 fsize34 md-fsize24 sm-fsize24 text-center font-semibold text-secondary cust-textColor"
        >
          {data ? data['5825329058'] : ' Our Clients'}
        </h2>
      </div>
      <div id="1576629861" className="dynamicStyle mt-8">
      <Swiper
          observer={true}
          observeParents={true}
          autoplay={true}
          modules={[Navigation, Autoplay]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 2,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
          }}
        >
            <div className="swiper-button image-swiper-button-prev cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
            {data && data['1576629861']
              ? data['1576629861'].map(e => {
                  return (
                    <SwiperSlide className="">
                    <div>
                      <Image
                        src={e ? getImage(e.field1) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="img"
                        className="rounded-md img-ratio lg:h-32 h-24"
                      />
                    </div>
                    </SwiperSlide>
                  );
                })
              : 'no-data'}
           
           </Swiper>
        </div>
    </div>
  )
}

export default Clients10
