import React, { useEffect, useRef, useState } from 'react';
import { Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection7 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section
      className={colorMode === 'light' ? 'bg-secondary theme-bg' : ''}
    >
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:pt-16 md:pt-10 pt-6 lg:pb-44 md:pb-16 pb-8 ">
        <div
          className="text-center lg:my-8"
          
        >
          <h1
            id="7571514564"
            className="dynamicStyle hero-header lg:py-2 text-primary cust-textColor "
          >
            {data ? data['7571514564'] : 'Lorem Ipsum'}
          </h1>
          <p
            id="1189544402"
            className="dynamicStyle fsize18 sm-fsize16 pb-6 clr-fff textColor-light theme-textColor"
          >
            {data ? data['1189544402'] : 'Lorem Ipsum'}
          </p>
        </div>
        <div  ref={elementRef} className={`fade-bottom  w-80 sm-wfull mx-auto ${isVisible ? 'visiblebottom' : ''} `}>
         <div id="2311560030" className='dynamicStyle2'>
          <Image
            clr-fff
            src={data ? getImage(data['2311560030']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="hero"
            className="br-40px w-full h-450px  sm-h250px object-contain"
          />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection7;
