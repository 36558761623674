import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { getAllTeams } from '../../../redux/teams/teams-action';
import { handleScroll } from './fadeeffect/FadeEffect';

const Team3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  let allTeam = useSelector(state => state.Teams.Teams);
  useEffect(() => {
    dispatch(getAllTeams());
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section className="container mx-auto  lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6">
      <div className="lg:w-2/3 md:w-3/4 w-full mx-auto text-center flex flex-col justify-center lg:pb-12 md:pb-12 pb-6">
        <h1
          id="7787544779"
          className="dynamicStyle cust-textColor fsize34 md-fsize24 font-semibold sm-fsize20 mb-2 text-primary"
        >
          {data ? data['7787544779'] : 'Meet Aur Professional teams'}
        </h1>
        <p
          id="0412520574"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['0412520574']
              : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus',
          }}
          className="dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14 text-center textColor-light"
        ></p>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom  overflow-x-hidden ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            820: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          {allTeam && allTeam.length > 0
            ? allTeam.map(e => {
                return (
                  <SwiperSlide>
                    <Card
                      className={
                        colorMode === 'light'
                          ? 'cursor-pointer box-shadow-none p-2 w-full themeShadow themeShadow-insert theme-bg rounded-lg'
                          : 'cursor-pointer p-2 w-full rounded-lg themeShadow themeShadow-insert theme-bg'
                      }
                    >
                      <div className=" flex justify-center w-full">
                        <Image
                          src={`${process.env.REACT_APP_STORAGE_URL}${e.profilepicture}`}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="imagee"
                          className="teams2-img rounded-full object-cover lg:mb-6 bg-d2d2d2"
                        />
                      </div>
                      <h2 className=" fsize18 font-semibold lg:mb-2 text-center cust-textColor">
                        {e.name}
                      </h2>
                      <p className=" text-gray fsize16 sm-fsize14 text-center textColor-light ">
                        {e.designation}
                      </p>
                    </Card>
                  </SwiperSlide>
                );
              })
            : 'No Data'}
        </Swiper>
      </div>
    </section>
  );
};

export default Team3;
