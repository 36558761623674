import API from './repositoryFactory';

const getProject = 'getProject';
const getUser = 'getUser';

const projectBase = route => {
  return `project/${route}`;
};

export default {
  getProject() {
    return API.post(projectBase(getProject));
  },

  getUser() {
    return API.post(projectBase(getUser));
  },
};
