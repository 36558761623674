import { Image, Button, useColorMode, Card } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Team10 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  let allTeam = useSelector(state => state.Teams.Teams);
  console.log(allTeam);
  const teamCard = [
    {
      id: '01',
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Bonnie Green',
      designation: 'Singer/Songwriter',
    },
    {
      id: '02',
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
      name: 'Tony Kakkar',
      designation: 'Singer/Songwriter',
    },
  ];
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
      <div className="flex justify-between items-center">
        <p
          id="5805912610"
          className="dynamicStyle fsize34 md:fsize24 sm-fsize20 font-semibold text-primary cust-textColor"
        >
          {data ? data['5805912610'] : 'Our Team'}
        </p>
        <Button
          px={8}
          py={4}
          id="1467310414"
          className={
            colorMode === 'light'
              ? 'dynamicStyle bg-primary bdr-0px sm-fsize14 clr-fff theme-btn'
              : 'dynamicStyle cust-bgColor bdr-0px cust-textColor sm-fsize14 theme-btn'
          }
        >
          <span onClick={() => handleClick('1467310414')}>
            {data ? data['1467310414'] : 'View All'}
          </span>
        </Button>
      </div>
      <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-6 lg:mt-12 md:mt-12 mt-6">
        {allTeam && allTeam.length > 0
          ? allTeam.map(e => {
              return (
                <Card
                  className={
                    colorMode === 'light'
                      ? 'p-4 cust-shadow rounded-lg themeShadow themeShadow-insert cursor-pointer theme-bg'
                      : 'p-4 cust-shadow rounded-lg themeShadow cursor-pointer theme-bg'
                  }
                >
                  <div className="w-24 h-24 rounded-full mx-auto ">
                    <Image
                      className="w-24 h-24 rounded-full"
                      src={`${process.env.REACT_APP_STORAGE_URL}${e.profilepicture}`}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="img"
                    />
                  </div>
                  <div className="text-center pt-2">
                    <p className="fsize18 font-semibold cust-textColor">
                      {e.name}
                    </p>
                    <p className="text-gray textColor-light">{e.designation}</p>
                  </div>
                </Card>
              );
            })
          : 'No Data'}
      </div>
    </div>
  );
};

export default Team10;
