let AiConv = [
  {
    role: 'system',
    content: 'You are helpful assistant',
  },
  {
    role: 'user',
    content: 'Name three flowers',
  },
  {
    role: 'assistant',
    content: 'Sure! Three flowers are Roses, Tulips and Dafodils',
  },
];
let studentConv = [
  {
    role: 'system',
    content: 'Roleplay as a student',
  },
  {
    role: 'user',
    content:
      "Good morning class! Today we'll be discussing quadratic equations.",
  },
  {
    role: 'assistant',
    content:
      "Excuse me, can you explain that again? I didn't quite understand.",
  },
  {
    role: 'user',
    content: 'Sure, let me break it down for you.',
  },
  {
    role: 'assistant',
    content: 'Thanks, that helps a lot!',
  },
];

let TeacherConv = [
  {
    role: 'system',
    content: 'Roleplay as a teacher/author',
  },
  {
    role: 'user',
    content: "I'm struggling with my essay. Can you give me some guidance?",
  },
  {
    role: 'assistant',
    content: "Of course, let's start by outlining your main points.",
  },
  {
    role: 'user',
    content: 'Should I include more examples?',
  },
  {
    role: 'assistant',
    content: 'Yes, more examples would strengthen your argument.',
  },
];
let CustomerServ = [
  {
    role: 'system',
    content: 'Roleplay as a customer service executive',
  },
  {
    role: 'user',
    content: "I'm having trouble with my account. Can you assist me?",
  },
  {
    role: 'assistant',
    content: "Of course, I'll do my best to help. What seems to be the issue?",
  },
  {
    role: 'user',
    content: "I'm unable to log in. It keeps saying my password is incorrect.",
  },
  {
    role: 'assistant',
    content:
      'Let me check that for you. Could you please provide me with your username?',
  },
];
let MarketingExec = [
  {
    role: 'system',
    content: 'Roleplay as a marketing executive',
  },
  {
    role: 'user',
    content:
      'We need to boost our sales. Do you have any marketing strategies?',
  },
  {
    role: 'assistant',
    content:
      "Absolutely, let's focus on targeted social media campaigns and email marketing.",
  },
  {
    role: 'user',
    content: 'What about influencer partnerships?',
  },
  {
    role: 'assistant',
    content:
      'Yes, that could definitely expand our reach to a broader audience.',
  },
];
let BuyerConv = [
  {
    role: 'system',
    content: 'Roleplay as a buyer',
  },
  {
    role: 'user',
    content:
      "I'm interested in purchasing your product. Can you tell me more about its features?",
  },
  {
    role: 'assistant',
    content:
      'Certainly, our product offers a range of features designed to improve efficiency and productivity.',
  },
  {
    role: 'user',
    content: 'Does it come with a warranty?',
  },
  {
    role: 'assistant',
    content:
      'Yes, we provide a standard one-year warranty for all our products.',
  },
];
let SellerConv = [
  {
    role: 'system',
    content: 'Roleplay as a seller',
  },
  {
    role: 'user',
    content:
      "I'm interested in selling my used car. Can you help me with the process?",
  },
  {
    role: 'assistant',
    content:
      "Absolutely, let's start by assessing the condition of your car and discussing pricing.",
  },
  {
    role: 'user',
    content: 'Should I get it serviced before selling?',
  },
  {
    role: 'assistant',
    content:
      "It's usually a good idea to ensure it's in the best condition possible to get the best price.",
  },
];
let CustomerConv = [
  {
    role: 'system',
    content: 'Roleplay as a angry customer',
  },
  {
    role: 'user',
    content: 'Hello, How can I help you?',
  },
  {
    role: 'assistant',
    content:
      'I am frustrated and angry that the product I brought is not working',
  },
];
let ContentCreatorConv = [
  {
    role: 'system',
    content: 'Roleplay as a content creator',
  },
  {
    role: 'user',
    content:
      "I'm struggling to come up with new video ideas. Can you help me brainstorm?",
  },
  {
    role: 'assistant',
    content:
      "Sure, let's think about trending topics or something related to your niche.",
  },
  {
    role: 'user',
    content: 'What about incorporating humor into my videos?',
  },
  {
    role: 'assistant',
    content:
      "Humor can definitely engage your audience. It's worth experimenting with!",
  },
];
let DeveloperConv = [
  {
    role: 'system',
    content: 'Roleplay as a developer',
  },
  {
    role: 'user',
    content: "I'm encountering a bug in my code. Can you help me troubleshoot?",
  },
  {
    role: 'assistant',
    content:
      "Of course, let's start by identifying where the issue might be occurring.",
  },
  {
    role: 'user',
    content: "I think it's in the function that calculates user input.",
  },
  {
    role: 'assistant',
    content:
      "Let's review that function and see if we can pinpoint the problem.",
  },
];
let SubscriberConv = [
  {
    role: 'system',
    content: 'Roleplay as a subscriber/member',
  },
  {
    role: 'user',
    content: "I'm having trouble accessing premium content. Can you assist me?",
  },
  {
    role: 'assistant',
    content:
      "Certainly, let's check your subscription status and ensure everything is in order.",
  },
  {
    role: 'user',
    content:
      "I renewed my subscription last week, but I still can't access the content.",
  },
  {
    role: 'assistant',
    content:
      'Let me investigate that for you. It may be a technical issue on our end.',
  },
];
let PartnerConv = [
  {
    role: 'system',
    content: 'Roleplay as a partner',
  },
  {
    role: 'user',
    content:
      'I think we should expand our partnership to include new markets. What do you think?',
  },
  {
    role: 'assistant',
    content:
      "That sounds like a promising idea. Let's discuss the potential opportunities and challenges.",
  },
  {
    role: 'user',
    content:
      "I've identified a few key markets where our product could perform well.",
  },
  {
    role: 'assistant',
    content:
      "Great, let's strategize on how to approach those markets effectively.",
  },
];

export {
  AiConv,
  studentConv,
  TeacherConv,
  CustomerServ,
  MarketingExec,
  BuyerConv,
  SellerConv,
  CustomerConv,
  ContentCreatorConv,
  DeveloperConv,
  SubscriberConv,
  PartnerConv,
};
