import React, { useEffect, useRef } from 'react';

const useParallax = (speed) => {
  const imgRef = useRef(null);
  const parentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const img = imgRef.current;
      const imgParent = parentRef.current;
      if (!img || !imgParent) return;
      const imgY = imgParent.offsetTop;
      const winY = window.scrollY;
      const winH = window.innerHeight;
      const parentH = imgParent.clientHeight;
      const winBottom = winY + winH;
      if (winBottom > imgY && winY < imgY + parentH) {
        const imgBottom = ((winBottom - imgY) * speed);
        const imgTop = winH + parentH;
        const imgPercent = ((imgBottom / imgTop) * 120) + (50 - (speed * 50));
        img.style.top = `${imgPercent}%`;
        img.style.transform = `translate(-50%, -${imgPercent}%)`;
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial call
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [speed]);

  return { imgRef, parentRef };
};

const ParallaxImage = ({ src, speed }) => {
  const { imgRef, parentRef } = useParallax(speed);
  return (
    <div ref={parentRef} style={{ position: 'relative', overflow: 'hidden', height: '100vh' }}>
      <img
        ref={imgRef}
        src={src}
        alt=""
        style={{
         position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: 'auto',
        }}
        data-speed={speed}
      />
    </div>
  );
};



export default ParallaxImage;