import React, { useEffect, useRef, useState } from 'react';
import { useColorMode } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection20 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <section className="">
      <div className="rounded-lg">
        <div className="flex items-center">
          <div
            className={
              colorMode === 'light'
                ? 'lg:flex md:flex items-center w-full'
                : 'lg:flex md:flex items-center w-full cust-media'
            }
          >
            <div
            className={
              colorMode === 'light'
                ? 'lg:w-6/12 md:w-6/12 w-full bg-primary theme-bg about2-h flex items-center justify-center'
                : 'lg:w-6/12 md:w-6/12 w-full about2-h flex items-center justify-center'
            }
>
              <div id="1987668358" className="dynamicStyle2 lg:w-8/12">
                <Image
                  src={data ? getImage(data['1987668358']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="about"
                  className="object-contain w-full lg:h-32 md:h-20 h-16"
                />
              </div>
            </div>
            <div className="lg:w-6/12 md:w-6/12 w-full lg:relative md:relative">
              <div id="6140108478" className="w-full dynamicStyle2">
                <Image
                  src={data ? getImage(data['6140108478']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="hero"
                  className="about2-images object-cover w-full lg:block md:block hidden"
                />
              </div>
              <div
                className={
                  colorMode === 'light'
                    ? 'lg:absolute md:absolute top-0 left-0 about2-images flex items-center w-full lg:py-0 md:py-0 py-6'
                    : 'lg:absolute md:absolute top-0 left-0 about2-images flex items-center w-full lg:py-0 md:py-0 py-6 cust-media'
                }
              >
                <div ref={elementRef} className={`fade-right lg:px-10 md:px-5 px-2 ${isVisible ? 'visibleright' : ''} `}>
                  <h3
                    id="7806932833"
                    className="textColor-light fsize34 md-fsize24 sm-fsize20 font-semibold lg:leading-12 md:leading-9 lg:mb-4 md:mb-4 mb-1 text-primary sm-lh-32px dynamicStyle"
                  >
                    {data ? data['7806932833'] : 'EDUCATE'}
                  </h3>
                  <p
                    id="9610572136"
                    dangerouslySetInnerHTML={{
                      __html: data ? data['9610572136'] : 'no-data',
                    }}
                    className="dynamicStyle textColor-light fsize16 sm-fsize14 text-gray"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection20;
