import React from 'react';
import { Facebook, Instagram } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

export const Footer9 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  
  let brandProfile = useSelector(state => state.brandDetails.brandProfile);
  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }
  return (
    <div className="bg-fffaf0 theme-bg lg:py-10 md:py-10 py-6">
      <div className="container mx-auto lg:px-12 md:px-8 px-5">
        <div className="web-hidden mob-pad">
          <Image
            src={getSecondaryImage(brandProfile)}
            alt="footerlog"
            className="w-48 cursor-pointer"
          />
        </div>
        <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2  md:gap-2 gap-2">
          <NavLink to="/home">
            <p className="cursor-pointer sm-fsize14 textColor-light">Home</p>
          </NavLink>
          <NavLink to="/aboutus">
            <p className="cursor-pointer sm-fsize14 textColor-light">About Us</p>
          </NavLink>
          <NavLink to="/products">
            <p className="cursor-pointer sm-fsize14 textColor-light">Categories</p>
          </NavLink>

          <NavLink to="/contact">
            <p className="cursor-pointer sm-fsize14 textColor-light">Contact Us</p>
          </NavLink>
          <NavLink to="/">
            <p className="cursor-pointer sm-fsize14 textColor-light">Terms & Condition</p>
          </NavLink>
          <NavLink to="/">
            <p className="cursor-pointer sm-fsize14 textColor-light">Privacy Policy</p>
          </NavLink>
        </div>
        <div className="flex items-center justify-between mx-auto lg:pt-10 md:pt-10 pt-8 lg:px-16 md:px-16 px-6 sm-gap-10">
          <div className="mob-hidden">
            <Image
              src={getSecondaryImage(brandProfile)}
              alt="footerlog"
              className="w-48 cursor-pointer"
            />
          </div>
          <div className="flex gap-4">
            <div className="cursor-pointer">
              <Facebook className="icon-w textColor-light" />
            </div>
            <div className="cursor-pointer">
              <Instagram className="icon-w textColor-light" />
            </div>
          </div>
          <div>
            <p id="0041059367" className="dynamicStyle sm-fsize12 textColor-light">
              {data ? data['0041059367'] : 'Lorem ipsum'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer9;
