import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Image, Card, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const GridCard1 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="py-8 lg:py-20 px-5 md:px-12 lg:px-12 container mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <Card
          className={colorMode === 'light' ? 'box-shadow-none' : ''}
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="3461419327" className="dynamicStyle2 w-full overflow-hidden rounded-lg">
            <Image
              src={data ? getImage(data['3461419327']) : ''}
              className="w-full h-40 rounded-lg custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <h3
              className="text-primary fsize20 cust-textColor sm-fsize18 mt-0 font-semibold dynamicStyle"
              id="9902554544"
            >
              {data ? data['9902554544'] : 'Lorem Ipsum'}
            </h3>
            <p
              className="fsize14 col-79 textColor-light dynamicStyle"
              id="2775932328"
              dangerouslySetInnerHTML={{
                __html: data ? data['2775932328'] : 'User Agreement',
              }}
            ></p>
          </div>
        </Card>
        <Card
          className={colorMode === 'light' ? 'box-shadow-none' : ''}
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="3597067657" className="dynamicStyle2 w-full overflow-hidden rounded-lg">
            <Image
              src={data ? getImage(data['3597067657']) : ''}
              className="w-full h-40 rounded-lg custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <h3
              className="text-primary fsize20 cust-textColor sm-fsize18 mt-0 font-semibold dynamicStyle"
              id="0346300864"
            >
              {data ? data['0346300864'] : 'Lorem Ipsum'}
            </h3>
            <p
              className="fsize14 col-79 textColor-light dynamicStyle"
              id="2191999616"
              dangerouslySetInnerHTML={{
                __html: data ? data['2191999616'] : 'User Agreement',
              }}
            ></p>
          </div>
        </Card>
        <Card
          className={colorMode === 'light' ? 'box-shadow-none' : ''}
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="1069633456" className="dynamicStyle2 w-full overflow-hidden rounded-lg">
            <Image
              src={data ? getImage(data['1069633456']) : ''}
              className="w-full h-40 rounded-lg custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <h3
              className="text-primary fsize20 cust-textColor sm-fsize18 mt-0 font-semibold dynamicStyle"
              id="3930927995"
            >
              {data ? data['3930927995'] : 'Lorem Ipsum'}
            </h3>
            <p
              className="fsize14 col-79 textColor-light dynamicStyle"
              id="0451369345"
              dangerouslySetInnerHTML={{
                __html: data ? data['0451369345'] : 'User Agreement',
              }}
            ></p>
          </div>
        </Card>
        <Card
          className={colorMode === 'light' ? 'box-shadow-none' : ''}
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="8860439808" className="dynamicStyle2 w-full overflow-hidden rounded-lg">
            <Image
              src={data ? getImage(data['8860439808']) : ''}
              className="w-full h-40 rounded-lg custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <h3
              className="text-primary fsize20 cust-textColor sm-fsize18 mt-0 font-semibold dynamicStyle"
              id="1280992136"
            >
              {data ? data['1280992136'] : 'Lorem Ipsum'}
            </h3>
            <p
              className="fsize14 col-79 textColor-light dynamicStyle"
              id="3464263367"
              dangerouslySetInnerHTML={{
                __html: data ? data['3464263367'] : 'User Agreement',
              }}
            ></p>
          </div>
        </Card>
        <Card
          className={colorMode === 'light' ? 'box-shadow-none' : ''}
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="5392130466" className="dynamicStyle2 w-full overflow-hidden rounded-lg">
            <Image
              src={data ? getImage(data['5392130466']) : ''}
              className="w-full h-40 rounded-lg custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <h3
              className="text-primary fsize20 cust-textColor sm-fsize18 mt-0 font-semibold dynamicStyle"
              id="8997519667"
            >
              {data ? data['8997519667'] : 'Lorem Ipsum'}
            </h3>
            <p
              className="fsize14 col-79 textColor-light dynamicStyle"
              id="0433352717"
              dangerouslySetInnerHTML={{
                __html: data ? data['0433352717'] : 'User Agreement',
              }}
            ></p>
          </div>
        </Card>
        <Card
          className={colorMode === 'light' ? 'box-shadow-none' : ''}
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="0971903166" className="dynamicStyle2 w-full overflow-hidden rounded-lg">
            <Image
              src={data ? getImage(data['0971903166']) : ''}
              className="w-full h-40 rounded-lg custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <h3
              className="text-primary fsize20 cust-textColor sm-fsize18 mt-0 font-semibold dynamicStyle"
              id="0915657872"
            >
              {data ? data['0915657872'] : 'Lorem Ipsum'}
            </h3>
            <p
              className="fsize14 col-79 textColor-light dynamicStyle"
              id="2577898809"
              dangerouslySetInnerHTML={{
                __html: data ? data['2577898809'] : 'User Agreement',
              }}
            ></p>
          </div>
        </Card>
        <Card
          className={colorMode === 'light' ? 'box-shadow-none' : ''}
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="6765634508" className="dynamicStyle2 w-full overflow-hidden rounded-lg">
            <Image
              src={data ? getImage(data['6765634508']) : ''}
              className="w-full h-40 rounded-lg custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <h3
              className="text-primary fsize20 cust-textColor sm-fsize18 mt-0 font-semibold dynamicStyle"
              id="6746840093"
            >
              {data ? data['6746840093'] : 'Lorem Ipsum'}
            </h3>
            <p
              className="fsize14 col-79 textColor-light dynamicStyle"
              id="3744599205"
              dangerouslySetInnerHTML={{
                __html: data ? data['3744599205'] : 'User Agreement',
              }}
            ></p>
          </div>
        </Card>
        <Card
          className={colorMode === 'light' ? 'box-shadow-none' : ''}
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <div id="3510250230" className="dynamicStyle2 w-full overflow-hidden rounded-lg">
            <Image
              src={data ? getImage(data['3510250230']) : ''}
              className="w-full h-40 rounded-lg custimghover"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="p-2">
            <h3
              className="text-primary fsize20 cust-textColor sm-fsize18 mt-0 font-semibold dynamicStyle"
              id="0000858645"
            >
              {data ? data['0000858645'] : 'Lorem Ipsum'}
            </h3>
            <p
              className="fsize14 col-79 textColor-light dynamicStyle"
              id="1568822469"
              dangerouslySetInnerHTML={{
                __html: data ? data['1568822469'] : 'User Agreement',
              }}
            ></p>
          </div>
        </Card>
      </div>
    </section>
  );
};

export default GridCard1;
