import React from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const GallerySection10 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div className="container mx-auto lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-2 md:gap-4 gap-2">
        <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
          <div id="7154515824" className="dynamicStyle2">
            <Image
              className="lg:h-80 md:h-60 h-52 w-full"
              src={data ? getImage(data['7154515824']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>

          <div className="effectthird lg:h-80 md:h-60 h-52">
            <h2
              id="8498974714"
              className="dynamicStyle fsize20 md-fsize18 sm-fsize16"
            >
              {data
                ? data['8498974714']
                : 'We provide wide variety of fabrics prints.'}
            </h2>

            <p
              id="8381800940"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['8381800940']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle fsize14 sm-fsize13 lineClamp2"
            ></p>
          </div>
        </div>
        <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
          <div id="" className="dynamicStyle2">
            <Image
              className="lg:h-80 md:h-60 h-52 w-full"
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>

          <div className="effectthird lg:h-80 md:h-60 h-52">
            <h2 className="fsize20 md-fsize18 sm-fsize16">
              Lorem ipsum may be
            </h2>

            <p className="fsize14 sm-fsize13 lineClamp2">
              When Layla appears, she brings an eternal summer along. When Layla
              appears, she brings an eternal summer along.
            </p>
          </div>
        </div>
        <div class="effect-lily w-full lg:h-full md:h-full h-52 lg:row-span-2 md:row-span-2 row-span-1">
          <div id="" className="dynamicStyle2 lg:h-full md:h-full h-52">
            <Image
              className="lg:h-full md:h-full h-52 w-full object-cover"
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>

          <div className="effectone">
            <h2 className="fsize21 md-fsize18 sm-fsize16 text-left">
              {' '}
              Lorem ipsum may be
            </h2>

            <p className="fsize14 sm-fsize13 lineClamp2 text-left">
              Lorem ipsum may be used as a placeholder before the final copy is
              available.
            </p>
          </div>
        </div>
        <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
          <div id="" className="dynamicStyle2">
            <Image
              className="lg:h-80 md:h-60 h-52 w-full"
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>

          <div className="effectthird lg:h-80 md:h-60 h-52">
            <h2 className="fsize20 md-fsize18 sm-fsize16">
              Lorem ipsum may be
            </h2>

            <p className="fsize14 sm-fsize13 lineClamp2">
              When Layla appears, she brings an eternal summer along. When Layla
              appears, she brings an eternal summer along.
            </p>
          </div>
        </div>
        <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
          <div id="" className="dynamicStyle2">
            <Image
              className="lg:h-80 md:h-60 h-52 w-full"
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>

          <div className="effectthird lg:h-80 md:h-60 h-52">
            <h2 className="fsize20 md-fsize18 sm-fsize16">
              Lorem ipsum may be
            </h2>

            <p className="fsize14 sm-fsize13 lineClamp2">
              When Layla appears, she brings an eternal summer along. When Layla
              appears, she brings an eternal summer along.
            </p>
          </div>
        </div>
        <div class="effect-lily w-full lg:h-full md:h-full h-52 lg:row-span-2 md:row-span-2 row-span-1">
          <div id="" className="dynamicStyle2 lg:h-full md:h-full h-52">
            <Image
              className="lg:h-full md:h-full h-52 w-full object-cover"
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>

          <div className="effectone">
            <h2 className="fsize21 md-fsize18 sm-fsize16 text-left">
              {' '}
              Lorem ipsum may be
            </h2>

            <p className="fsize14 sm-fsize13 lineClamp2 text-left">
              Lorem ipsum may be used as a placeholder before the final copy is
              available.
            </p>
          </div>
        </div>
        <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
          <div id="" className="dynamicStyle2">
            <Image
              className="lg:h-80 md:h-60 h-52 w-full"
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>

          <div className="effectthird lg:h-80 md:h-60 h-52">
            <h2 className="fsize20 md-fsize18 sm-fsize16">
              Lorem ipsum may be
            </h2>

            <p className="fsize14 sm-fsize13 lineClamp2">
              When Layla appears, she brings an eternal summer along. When Layla
              appears, she brings an eternal summer along.
            </p>
          </div>
        </div>
        <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
          <div id="" className="dynamicStyle2">
            <Image
              className="lg:h-80 md:h-60 h-52 w-full"
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>

          <div className="effectthird lg:h-80 md:h-60 h-52">
            <h2 className="fsize20 md-fsize18 sm-fsize16">
              Lorem ipsum may be
            </h2>

            <p className="fsize14 sm-fsize13 lineClamp2">
              When Layla appears, she brings an eternal summer along. When Layla
              appears, she brings an eternal summer along.
            </p>
          </div>
        </div>
        <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
          <div id="" className="dynamicStyle2">
            <Image
              className="lg:h-80 md:h-60 h-52 w-full"
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>

          <div className="effectthird lg:h-80 md:h-60 h-52">
            <h2 className="fsize20 md-fsize18 sm-fsize16">
              Lorem ipsum may be
            </h2>

            <p className="fsize14 sm-fsize13 lineClamp2">
              When Layla appears, she brings an eternal summer along. When Layla
              appears, she brings an eternal summer along.
            </p>
          </div>
        </div>
        <div class="effect-oscar w-full lg:h-80 md:h-60 h-52">
          <div id="" className="dynamicStyle2">
            <Image
              className="lg:h-80 md:h-60 h-52 w-full"
              src="https://nexuscluster.blob.core.windows.net/server01/brained-section-master/undefined/0.5994069392437318.jpg-eBHRX03U2bDV62_V8LTi6-1714472127"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>

          <div className="effectthird lg:h-80 md:h-60 h-52">
            <h2 className="fsize20 md-fsize18 sm-fsize16">
              Lorem ipsum may be
            </h2>

            <p className="fsize14 sm-fsize13 lineClamp2">
              When Layla appears, she brings an eternal summer along. When Layla
              appears, she brings an eternal summer along.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection10;
