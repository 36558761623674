import { Image } from "@chakra-ui/react";

export default function Avatar({ user, online, channel, isGroupMessage }) {
  const colors = [
    "bg-red-200",
    "bg-lime-200",
    "bg-green-200",
    "bg-purple-200",
    "bg-blue-200",
    "bg-yellow-200",
    "bg-violet-200",
    "bg-pink-200",
    "bg-indigo-200",
    "bg-rose-200",
  ];

  const userIdBase10 = parseInt(channel?._id?.substring(10), 16);
  const colorIndex = userIdBase10 % colors.length;
  const color = colors[colorIndex];

  const getImage = (image) => {
    if (image.includes("data:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  };

  return (
    <div>
      {user?.userImage ? (
        <div className="w-8 h-8 relative rounded-full flex items-center ">
          <Image
            src={getImage(user?.userImage)}
            alt="image"
            className="ml-0 rounded-full"
          />
          {online === true && (
            <div className="absolute w-3 h-3 bg-green-400 usertrue rounded-full border border-white"></div>
          )}
          {online === false && (
            <div className="absolute w-3 h-3 bg-gray-400 usertrue rounded-full border border-white"></div>
          )}
        </div>
      ) : (
        <div
          className={"w-8 h-8 relative rounded-full flex items-center " + color}
        >
          <div className="text-center w-full opacity-70">
            {!channel?.isGroupChat && isGroupMessage
              ? channel?.channelName[0]?.toUpperCase()
              : user?.firstName[0]?.toUpperCase()}
          </div>

          {online === true && (
            <div className="absolute w-3 h-3 bg-green-400 bottom-0 right-0 rounded-full border border-white"></div>
          )}
          {online === false && (
            <div className="absolute w-3 h-3 bg-gray-400 bottom-0 right-0 rounded-full border border-white"></div>
          )}
        </div>
      )}
    </div>
  );
}
