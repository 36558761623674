import React from 'react';
import Marquee from 'react-fast-marquee';

const Marquee2 = () => {
  const marcing = [
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi74.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi75.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi76.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi77.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi78.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi79.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi80.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi81.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi82.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi83.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi84.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi85.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi86.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi87.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi90.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi91.png',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi92.png',
    },
  ];
  return (
    <div>
      <Marquee loop={0} autoFill={true}>
        {marcing.map(e => (
          <img
            src={e.img}
            alt="marquee"
            className="w-10 h-10 filter-b4 object-contain"
          />
        ))}
      </Marquee>
    </div>
  );
};

export default Marquee2;
