import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image } from '@chakra-ui/react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard8 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6">
      <div className="text-center lg:w-1/2 md:w-2/3 w-full mx-auto lg:mb-12 md:mb-12 mb-6">
        <h3
          id="2312621399"
          className="dynamicStyle fsize16 sm-fsize14 font-medium text-primary cust-textColor"
        >
          {data ? data['2312621399'] : 'Lorem Ipsum'}
        </h3>
        <h1
          id="7594297016"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor"
        >
          {data ? data['7594297016'] : 'Lorem Ipsum'}
        </h1>
      </div>
      <div id="1200385485" className="dynamicStyle">
        <div
          ref={elementRef}
          className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-3 lg:gap-8 md:gap-8 gap-6 ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          {data && data['1200385485']
            ? data['1200385485'].map(e => {
                return (
                  <Card className="theme_insertShadow theme-bg w-full flex flex-col items-center cust-shadow rounded-xl lg:px-6 md:px-8 px-5 lg:py-12 md:py-6 py-6">
                    <div className="w-full flex justify-left">
                      <Image
                        src={e ? getImage(e.field1) : ''}
                        alt={e.field1}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        className="object-cover card-img mb-6 "
                      />
                    </div>
                    <div className="text-left w-full">
                      <h1 className="fsize24  sm-fsize18 font-semibold cust-textColor">
                        {e.field2}
                      </h1>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e.field3,
                        }}
                        className=" fsize16 sm-fsize14 font-medium text-gray pt-2 sm-fsize14 text-left"
                      ></p>
                    </div>
                  </Card>
                );
              })
            : 'no-cards'}
        </div>
      </div>
    </section>
  );
};

export default FeatureCard8;
