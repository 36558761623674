import React from 'react';
import KMPreviewer from './components/KMPreviewer';
import ApprovalRequestBanner from './components/ApprovalRequestBanner';
import FileRequestBanner from './components/FileRequestBanner';
import ReviewerTabs from './components/ReviewerTabs';

const Reviewer = () => {
  return (
    <div>
      <FileRequestBanner />
      <ReviewerTabs />
      <ApprovalRequestBanner />
      <KMPreviewer />
    </div>
  );
};

export default Reviewer;
