import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const Founders = ({ pageName }) => {
  const elementRef = useRef(null);
  const elementRef2 = useRef(null);
  const elementRef3 = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  // console.log(data, 'idcheck');
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);
    const cleanp2 = handleScroll(elementRef2, setIsVisible2);
    const cleanp3 = handleScroll(elementRef3, setIsVisible3);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className=''>
      <div className={colorMode === 'light' ? ' theme-bg relative' : 'theme-bg'}>
        <div className="lg:flex md:flex items-center container mx-auto lg:py-20 md:py-20 py-12 lg:px-12 md:px-8 px-5">
          <div className="lg:w-1/2 md:w-1/2 lg:pr-4 md:pr-10">
            <div className="dynamicStyle2 w-full z-50 relative" id="2053287608">
              <Image
                src={data ? getImage(data['2053287608']) : ''}
                className="rounded-sm chua-img md-h-350px sm-h-300px lg:object-contain md:object-contain object-cover w-full"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="chua"
              />
            </div>
          </div>

          <div
            ref={elementRef} className={`fade-right lg:w-1/2 md:w-1/2 lg:pl-10 md:pl-4 lg:mt-0 mt-6 ${isVisible ? 'visibleright' : ''} `}
          >
            <h2
              id="8159973227"
              className="dynamicStyle lg:text-4xl md:text-2xl text-xl font-semibold cust-textColor text-primary"
            >
              {data ? data['8159973227'] : 'no-data'}
            </h2>
            <p
              id="0210835708"
              dangerouslySetInnerHTML={{
                __html: data ? data['0210835708'] : 'no-data',
              }}
              className="dynamicStyle fsize16 pt-3 sm-fsize14 textColor-light text-black theme-textColor font-normal opacity-70 lg:leading-7 md:leading-7 leading-6"
            ></p>
            <p
              id="4556735013"
              dangerouslySetInnerHTML={{
                __html: data ? data['4556735013'] : 'no-data',
              }}
              className="dynamicStyle fsize16 text-black  sm-fsize14 textColor-light theme-textColor opacity-70 lg:leading-7 md:leading-7 leading-6 mt-3"
            ></p>
          </div>
        </div>
        <div className="car-round bg-secondary absolute top-0 left-0 z-10 mob-hidden"></div>
      </div>
      <div className={colorMode === 'light' ? ' theme-bg relative' : ''}
>
        <div className="lg:flex md:flex w-full sm-reverse items-center container mx-auto lg:pb-24 md:pb-24 pb-12 lg:px-12 md:px-8 px-5">
          <div
            ref={elementRef2} className={`fade-left lg:w-1/2 md:w-full lg:pr-10 lg:mt-0 mt-6 ${isVisible ? 'visibleleft' : ''} `}
          >
            <h2
              id="9366882168"
              className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold text-primary"
            >
              {data ? data['9366882168'] : 'no-data'}
            </h2>
            <p
              id="5238377999"
              dangerouslySetInnerHTML={{
                __html: data ? data['5238377999'] : 'no-data',
              }}
              className="dynamicStyle textColor-light fsize16 text-black  theme-textColor fsize16 md-fsize16 sm-fsize14 opacity-70 lg:leading-7 md:leading-7 leading-6 mt-3"
            ></p>
            <p
              id="5324562168"
              dangerouslySetInnerHTML={{
                __html: data ? data['5324562168'] : 'no-data',
              }}
              className="dynamicStyle textColor-light fsize16 text-black  theme-textColor fsize16 md-fsize16 sm-fsize14 opacity-70 lg:leading-7 md:leading-7 leading-6 mt-3"
            ></p>
          </div>
          <div className="lg:w-1/2  md:w-full w-full lg:pl-10 md:pl-10">
            <div id="2142586895" className="dynamicStyle2 w-full z-50 relative">
              <Image
                src={data ? getImage(data['2142586895']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="chua-img sm-h-300px md-h-350px lg:object-contain md:object-contain object-cover w-full"
                alt="chua"
              />
            </div>
          </div>
          <div className="car-round1 bg-secondary absolute top-0 right-0 z-10 mob-hidden"></div>
        </div>
      </div>
    </div>
  );
};

export default Founders;
