import React from 'react';
import Banner from './components/Banner';
import Gridcards from './components/GridCards';
import BreadCumb from './components/BreadCumb';

const Iciciadmin = () => {
  return (
    <div>
      <Banner />
      <BreadCumb />
      <Gridcards />
    </div>
  );
};

export default Iciciadmin;
