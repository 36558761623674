import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const GallerySection2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:py-8 md:py-8 py-6">
      <div className="text-center lg:w-3/5 md:w-3/4 mx-auto flex flex-col justify-center lg:mb-12 md:mb-12 mt-6">
        <h3
          id="8616624332"
          className="dynamicStyle cust-textColor text-primary letter-spacing4px fsize16 md-fsize16 sm-fsize14 font-medium"
        >
          {data ? data['8616624332'] : 'Lorem Ipsum'}
        </h3>
        <h1
          id="6074481296"
          className="dynamicStyle cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold mb-2"
        >
          {data ? data['6074481296'] : 'Lorem Ipsum'}
        </h1>
        <div className="">
          <p
            id="0488999404"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['0488999404']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle textColor-light  mx-auto fsize16 md-fsize16 sm-fsize14 text-center lg:px-0 md:px-0 px-5"
          ></p>
        </div>
      </div>

      <div
        ref={elementRef}
        className={`fade-bottom lg:mt-0 md:mt-8 mt-6 grid lg:grid-cols-3 md:grid-cols-3 gap-8 w-full lg:px-20 md:px-8 px-6 ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <div
          id="7554124230"
          className="dynamicStyle2 lg:col-span-2 md:col-span-2 w-full"
        >
          <Image
            src={data ? getImage(data['7554124230']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="imagee"
            className="w-full object-cover shadow-ffffff br-16px h-280px"
          />
        </div>
        <div id="0770040218" className="dynamicStyle2 w-full">
          <Image
            src={data ? getImage(data['0770040218']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="imagee"
            className="w-full object-cover shadow-ffffff br-16px h-280px"
          />
        </div>
        <div id="7738649951" className="dynamicStyle2 w-full">
          <Image
            src={data ? getImage(data['7738649951']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="imagee"
            className="w-full object-cover shadow-ffffff br-16px h-280px"
          />
        </div>
        <div
          id="0102350837"
          className="dynamicStyle2 lg:col-span-2 md:col-span-2 w-full"
        >
          <Image
            src={data ? getImage(data['0102350837']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="imagee"
            className="w-full object-cover shadow-ffffff br-16px h-280px"
          />
        </div>
      </div>
    </section>
  );
};

export default GallerySection2;
