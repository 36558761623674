import React from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

const GallerySection7 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  let selectedStyle = useSelector(state => state.brandDetails.brandProfile.businessThemeVariation);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  return (
    <div className="lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5">
      <div className="container mx-auto">
        <div className='lg:flex md:flex w-full justify-between'>
            <h4 id="7505463793" className='fsize34 md-fsize24 sm-fsize20 text-primary font-semibold dynamicStyle textColor-light'>{data ? data['7505463793'] : 'Flagship Programs'}</h4>
            <Button
              id="0173421189"
              borderRadius='6'
              className={
                selectedStyle
                  ? 'theme-btn dynamicStyle clr-fff bg-primary cust-bgColor fsize14 sm-fsize14 '
                  : 'clr-fff  dynamicStyle bg-primary cust-bgColor fsize14 sm-fsize14 buttonAnimate'
              }
            >
              <span onClick={() => handleClick('0173421189')}>
                {data ? data['0173421189'] : 'Lorem ipsum'}
              </span>
            </Button>
        </div>
        <div className="lg:flex md:flex grid grid-cols-1 w-full gap-4 lg:mt-12 md:mt-12 mt-6">
          <div className="lg:w-5/12 md:w-5/12 w-full">
            <div class="effect-layla w-full gallery7-img rounded-lg">
              <div id="5209568984" className="w-full dynamicStyle2">
                <Image
                  src={data ? getImage(data['5209568984']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img12"
                  className="w-full gallery7-img"
                />
              </div>

              <div className="effectforth gallery7-img w-full flex items-center">
                <p
                  id="5317439034"
                  className="dynamicStyle fsize16 md-fsize14 sm-fsize13"
                >
                  {data ? data['5317439034'] : 'Flagship Programs'}
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-2/12 md:w-3/12 w-full">
            <div class="effect-lily w-full rounded-lg gallerysecond7-img">
              <div
                className="gradients w-full h-full dynamicStyle2"
                id="6562073298"
              >
                <Image
                  src={data ? getImage(data['6562073298']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img12"
                  className="w-full h-full"
                />
              </div>

              <div className="effectone">
                <h2
                  id="7790279434"
                  className="dynamicStyle fsize14 md-fsize14 sm-fsize13 text-left z-50 relative mb-10"
                >
                  {' '}
                  {data ? data['7790279434'] : 'Flagship Programs'}
                </h2>
              </div>
            </div>
            <div class="effect-lily w-full rounded-lg gallerythird7-img lg:mt-2 md:mt-2 mt-4">
              <div
                className="gradients w-full h-full dynamicStyle2"
                id="9270594567"
              >
                <Image
                  src={data ? getImage(data['9270594567']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img12"
                  className="w-full h-full"
                />
              </div>

              <div className="effectone">
                <h2
                  id="3833422528"
                  className="dynamicStyle fsize16 md-fsize14 sm-fsize13 text-left z-50 relative mb-10"
                >
                  {' '}
                  {data ? data['3833422528'] : 'Flagship Programs'}
                </h2>
              </div>
            </div>
          </div>
          <div className="lg:w-5/12 md:w-5/12 w-full">
            <div class="effect-layla w-full gallery7-img rounded-lg">
              <div id="3195256289" className="w-full dynamicStyle2">
                <Image
                  src={data ? getImage(data['3195256289']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img12"
                  className="w-full gallery7-img"
                />
              </div>

              <div className="effectforth gallery7-img w-full flex items-center">
                <p
                  id="9193952618"
                  className="dynamicStyle fsize16 md-fsize14 sm-fsize13"
                >
                  {data ? data['9193952618'] : 'Flagship Programs'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection7;
