import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const CardImage2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto  lg:py-16 md:py-16 py-8 lg:px-12 md:px-8 px-5">
      <div className="text-center lg:w-2/3 md:w-3/4 w-full mx-auto lg:mb-10 md:mb-10 mb-4">
        <h3
          id="6525176471"
          className="dynamicStyle letter-spacing4px fsize16 md-fsize16 sm-fsize14 font-medium text-primary cust-textColor"
        >
          {data ? data['6525176471'] : 'Lorem Ipsum'}
        </h3>
        <h1
          id="3440132799"
          className="dynamicStyle text-primary fsize34 md-fsize24 sm-fsize20 font-semibold mb-2 cust-textColor"
        >
          {data ? data['3440132799'] : 'Lorem Ipsum'}
        </h1>
        <div className="">
          <p
            id="8150332522"
            dangerouslySetInnerHTML={{
              __html: data ? data['8150332522'] : 'Lorem ipsum dolor',
            }}
            className="dynamicStyle fsize16 sm-fsize14 text-center textColor-light"
          >
            {/* In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content. */}
          </p>
        </div>
      </div>

      <div className="container mx-auto ">
        <div ref={elementRef} className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}>
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <div className="w-full p-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-cover card-img2"
                
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full p-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-cover card-img2"
                
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full p-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-cover card-img2"
                
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full p-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-cover card-img2"
                
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full p-2">
              <Image
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-cover card-img2"
                
              />
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
      </div>
    </section>
  );
};

export default CardImage2;
