import API from '../repositoryFactory';

const getCategory = 'get/ecom_category';

const getCategorys = route => {
  return `forms/${route}`;
};

export default {
  getAllCategorys(payload) {
    return API.post(getCategorys(getCategory), payload);
  },
};
