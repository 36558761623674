import React, { useEffect, useRef, useState } from 'react';
import { useColorMode } from '@chakra-ui/react';
import { Image, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection20 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="bg-secondary cust-bgColor lg:px-12 md:px-6 px-4  lg:py-16 md:py-12 py-4 theme-bg">
      <div ref={elementRef} className={`fade-bottom container mx-auto bg-white rounded-lg ${isVisible ? 'visiblebottom' : ''} `}>
        <div className="w-full lg:relative md:relative about-h rounded-lg themeShadow overflow-hidden">
          <div id="2314653325" className="w-full dynamicStyle2">
            <Image
              src={data ? getImage(data['2314653325']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="hero"
              className="about-h object-cover lg:block md:block hidden"
            />
          </div>
          <div className="lg:absolute md:absolute top-0 left-0 about-h flex items-center">
            <div
              className={
                colorMode === 'light'
                  ? 'lg:flex md:flex items-center gap-8 lg:px-10 md:px-4 px-3 lg:py-0 md:py-0 py-4'
                  : 'lg:flex md:flex items-center gap-8 lg:px-10 md:px-4 px-3 lg:py-0 md:py-0 py-4 cust-media'
              }
            >
              <div className="lg:w-5/12 md:w-5/12 w-full">
                <div id="1689943441" className="w-full dynamicStyle2">
                  <Image
                    src={data ? getImage(data['1689943441']) : ''}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                    alt="about"
                    className="object-cover w-full about-images shadow"
                  />
                </div>
              </div>
              <div className="lg:w-7/12 md:w-7/12 w-full lg:mt-0 md:mt-0 mt-4">
                <h3
                  id="7129321340"
                  className="textColor-light fsize32 md-fsize26 sm-fsize20 font-semibold lg:leading-12 md:leading-9 lg:mb-4 md:mb-4 mb-1 text-dark sm-lh-32px dynamicStyle"
                >
                  {data ? data['7129321340'] : 'EDUCATE'}
                </h3>
                <p
                  id="0610823032"
                  dangerouslySetInnerHTML={{
                    __html: data ? data['0610823032'] : 'no-data',
                  }}
                  className="dynamicStyle textColor-light fsize16 sm-fsize14 text-gray mb-4"
                ></p>
                <Button
                  colorScheme="transparent"
                  px="0"
                  id="2707186619"
                  className="border-0 text-dark textColor-light sm-fsize13 dynamicStyle"
                >
                  <span onClick={() => handleClick('2707186619')}>
                    {data ? data['2707186619'] : 'Lorem ipsum'}
                  </span>
                  <FeatherIcon
                    icon="arrow-right"
                    size="18"
                    className="textColor-light text-dark ml-1"
                  />
                </Button>
              </div>
            </div>
          </div>
          <div
          className={
            colorMode === 'light'
              ? 'absolute bottom-0 left-0 w-full lg:p-4 p-3 lg:block md:block hidden bg-primary theme-bg'
              : 'absolute bottom-0 left-0 w-full lg:p-4 p-3 lg:block md:block hidden cust-bgColor theme-bg'
          }
></div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection20;
