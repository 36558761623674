import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection15 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="relative h-400px sm-h-250px ">
      <div id="2012467775" className="dynamicStyle2 cus-hbanner w-full">
        <Image
          src={data ? getImage(data['2012467775']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="hero"
          className="w-full h-400px sm-h-250px object-cover "
        />
      </div>

      <div className="absolute top-0 w-full h-400px sm-h-250px lg:flex md:flex items-center flex justify-center lg:px-0 md:px-0 px-5 lg:py-0 md:py-0 py-4">
        <div ref={elementRef} className={`fade-bottom w-full flex flex-col justify-center items-center ${isVisible ? 'visiblebottom' : ''} `} >
          <h3
            id="6522614182"
            className="dynamicStyle fsize50 md-fsize40 sm-fsize28 font-semibold text-center text-white"
          >
            {data ? data['6522614182'] : 'Lorem ipsum'}
          </h3>
        </div>
      </div>
    </section>
  );
};

export default HeroSection15;
