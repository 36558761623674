import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image, useColorMode } from '@chakra-ui/react';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const Boardofdirectors2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 lg:py-16 py-8 px-5">
        <h2
          id="9045398871"
          className="dynamicStyle lg:w-2/3 md:w-3/4 w-full mx-auto cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold text-center text-primary"
        >
          {data ? data['9045398871'] : 'Key Management Team'}
        </h2>

        <div  ref={elementRef} className={`fade-bottom mt-6 lg:mt-12 md:mt-12 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 grid gap-8 ${isVisible ? 'visiblebottom' : ''} `}>
          <div
          className={
            colorMode === 'light'
              ? 'border-1px rounded-lg overflow-hidden themeShadow themeShadow-insert theme-bg'
              : ' rounded-lg overflow-hidden themeShadow theme-bg'
          }
          
       
          >
            <div id="3688270895" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['3688270895']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full h-40 object-contain lg:h-52"
                alt="board"
                
              />
            </div>
            <div className="lg:p-5 md:p-4 p-3">
              <h6
                id="2207494494"
                className="dynamicStyle text-dark text-center cust-textColor font-semibold fsize18 sm-fsize16"
              >
                {data ? data['2207494494'] : 'Mr Dinesh Khara'}
              </h6>
              <p
                id="1369953031"
                className="dynamicStyle col-79 text-center fsize13 mt-1 textColor-light"
              >
                {data
                  ? data['1369953031']
                  : 'Nominee Director & Chairman of the Board'}
              </p>
            </div>
          </div>
          <div
            className={
            colorMode === 'light'
              ? 'border-1px rounded-lg overflow-hidden themeShadow themeShadow-insert theme-bg'
              : ' rounded-lg overflow-hidden themeShadow theme-bg'
          }
          
          >
            <div id="7100296289" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['7100296289']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full h-40 object-contain lg:h-52"
                alt="board"
                
                
              />
            </div>
            <div className="lg:p-5 md:p-4 p-3">
              <h6
                id="9309426218"
                className="dynamicStyle text-dark text-center cust-textColor font-semibold fsize18 sm-fsize16"
              >
                {data ? data['9309426218'] : 'Mr Dinesh Khara'}
              </h6>
              <p
                id="6278829433"
                className="dynamicStyle col-79 text-center fsize13 mt-1 textColor-light"
              >
                {data
                  ? data['6278829433']
                  : 'Nominee Director & Chairman of the Board'}
              </p>
            </div>
          </div>
          <div
            className={
            colorMode === 'light'
              ? 'border-1px rounded-lg overflow-hidden themeShadow themeShadow-insert theme-bg'
              : ' rounded-lg overflow-hidden themeShadow theme-bg'
          }
           
          >
            <div id="1330311467" className="dynamicStyle2 w-full">
              <Image
                src={data ? getImage(data['1330311467']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full h-40 object-contain lg:h-52"
                alt="board"
                
              />
            </div>
            <div className="lg:p-5 md:p-4 p-3">
              <h6
                id="9943564981"
                className="dynamicStyle text-dark text-center cust-textColor font-semibold fsize18 sm-fsize16"
              >
                {data ? data['9943564981'] : 'Mr Dinesh Khara'}
              </h6>
              <p
                id="8513718892"
                className="dynamicStyle col-79 text-center fsize13 mt-1 textColor-light"
              >
                {data
                  ? data['8513718892']
                  : 'Nominee Director & Chairman of the Board'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Boardofdirectors2;
