import React from 'react';
import { useColorMode, Image, Card } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports'

const FeatureCard17 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-12 py-8">
      <div className="text-center wid-70 sm-wfull mx-auto lg:mb-12 md:mb-12 mb-6">
        <h1 id="9030506734"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold mb-2 text-primary cust-textColor">
          {data ? data['9030506734'] : 'Lorem Ipsum'}
        </h1>
        <p id="6118972404"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['6118972404']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle fsize16 md-fsize16 sm-fsize14 textColor-light text-black">
          {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem
          commodi cum veritatis iusto nisi. Mollitia quo placeat explicabo nihil
          doloremque? */}
        </p>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2">
        <div className="box-shadow-none p-2  w-full sm-bdr-leftright">
          <div id="8563785585"
            className="dynamicStyle2 flex justify-center w-full ">
            <Image
               src={data ? getImage(data['8563785585']) : ''}
               fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
               alt="img"
              className="w-44 h-28 p-3"
            />
          </div>
        </div>
        <div className="box-shadow-none p-2 border-left-1px sm-bdr-leftright  w-full ">
        <div id="8768143307"
            className="dynamicStyle2 flex justify-center w-full ">
            <Image
               src={data ? getImage(data['8768143307']) : ''}
               fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
               alt="img"
              className="w-44 h-28 p-3"
            />          </div>
        </div>
        <div className="box-shadow-none p-2 border-left-1px sm-bdr-leftright w-full ">
        <div id="7510861586"
            className="dynamicStyle2 flex justify-center w-full ">
            <Image
               src={data ? getImage(data['7510861586']) : ''}
               fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
               alt="img"
              className="w-44 h-28 p-3"
            />
          </div>
        </div>
        <div className="box-shadow-none p-2 border-left-1px sm-bdr-leftright w-full ">
        <div id="7032110147"
            className="dynamicStyle2 flex justify-center w-full ">
            <Image
               src={data ? getImage(data['7032110147']) : ''}
               fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
               alt="img"
              className="w-44 h-28 p-3 "
            />
          </div>
        </div>
      </div>
      <div id="9571757470"
          className="dynamicStyle grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-6 lg:pt-12 md:lg-12 pt-6">
        {data && data['9571757470']
            ? data['9571757470'].map((e, index) => {
                return (
        <div
          className={
            colorMode === 'light'
              ? 'themeShadow theme-bg rounded-lg'
              : 'themeShadow theme-bg rounded-lg'
          }
        >
          <div className="w-full">
            <Image
              className="lg:h-60 h-52 w-full lg:pb-4 md:pb-4 pb-2 sm-w-full bradius-card-top"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
            />
          </div>
          <div className="text-center px-4">
            <p className="mb-2 font-semibold sm-fsize14 cust-textColor text-black">
              Camping Area
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 textColor-light text-black">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Excepturi totam rerum ducimus fugiat accusantium, aliquid quidem
              voluptates nemo assumenda soluta.
            </p>
          </div>
        </div>
         );
        })
      : 'no-card'}
        {/* <div
          className={
            colorMode === 'light'
              ? 'themeShadow theme-bg rounded-lg'
              : 'themeShadow theme-bg rounded-lg'
          }
        >
          <div className="w-full">
            <Image
              className="lg:h-60 h-52 w-full lg:pb-4 md:pb-4 pb-2 sm-w-full bradius-card-top"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
            />
          </div>
          <div className="text-center px-4">
            <p className="mb-2 font-semibold sm-fsize14 cust-textColor text-black">
              Camping Area
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 textColor-light text-black">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Excepturi totam rerum ducimus fugiat accusantium, aliquid quidem
              voluptates nemo assumenda soluta.
            </p>
          </div>
        </div>
        <div
          className={
            colorMode === 'light'
              ? 'themeShadow theme-bg rounded-lg'
              : 'themeShadow theme-bg rounded-lg'
          }
        >
          <div className="w-full">
            <Image
              className="lg:h-60 h-52 w-full lg:pb-4 md:pb-4 pb-2 sm-w-full bradius-card-top"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
            />
          </div>
          <div className="text-center px-4">
            <p className="mb-2 font-semibold sm-fsize14 cust-textColor text-black">
              Camping Area
            </p>
            <p className="fsize14 md-fsize12 sm-fsize12 lg:mb-4 md:mb-4 mb-2 textColor-light text-black">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Excepturi totam rerum ducimus fugiat accusantium, aliquid quidem
              voluptates nemo assumenda soluta.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FeatureCard17;
