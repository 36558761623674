import React from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Button, Image } from '@chakra-ui/react';

const NoPageFound1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section className=" h-100vh relative flex items-center ">
      <div className="container mx-auto lg:px-12 px-4 lg:py-20 py-8">
        <div className="relative error-404 mx-auto">
          <div id="6207814490" className="dynamicStyle2 w-full">
            <Image
              src={data ? getImage(data['6207814490']) : ''}
              className="error-404"
              alt="women"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            />
          </div>
          <div className="absolute error-404  top-0 flex left-0 justify-center mt-32">
            <div>
              <h4 className="fsize50 text-center text-white font-semibold">
                404
              </h4>
              <p className="fsize16 text-center text-white font-semibold">
                Page not found
              </p>
            </div>
          </div>
        </div>

        <p
          className="fsize16 text-primary text-center  font-semibold mx-auto w-7/12 dynamicStyle"
          id="3046051906"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['3046051906']
              : ' publishing and graphic design, Lorem ipsum is a placeholder text',
          }}
        ></p>
        <div className="flex justify-center">
          <Button
            variant="outline"
            colorScheme="primary"
            className="mt-6 text-primary sm-fsize14 dynamicStyle"
            id="3049055996"
            size="lg"
          >
            {data ? data['3049055996'] : 'Lorem Ipsum'}
          </Button>
        </div>
      </div>

      <div className="absolute top-0 right-0 mar-right">
        <Image
          src="https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi49.png"
          className="error-img"
          alt="error-left"
        />
      </div>
      <div className="absolute bottom-0 left-0 mar-left">
        <Image
          src="https://nexuscluster.blob.core.windows.net/server01/sbi-foundation/sbi49.png"
          className="error-img"
          alt="error-left"
        />
      </div>
    </section>
  );
};

export default NoPageFound1;
