const initialState = {
    Teams: [],
  };
  const TeamDetails = (state = initialState, action) => {
    switch (action.type) {
      case "setTeam":
        return { ...state, Teams: action.payload };
      default:
        return state;
    }
  };
  
  export default TeamDetails;
  