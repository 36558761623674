import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';

const Counter3 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className="bg-primary lg:py-10 py-8 relative">
      <h4
        id="3840752950"
        className="dynamicStyle fsize36 sm-fsize24 text-center font-semibold lg:pb-12 md:pb-8 pb-4"
      >
        {data ? data['3840752950'] : 'Lorem ipsum'}
      </h4>
      <div className="container mx-auto lg:px-12 md:px-8 px-5">
        <div className=" lg:mt-0 mt-5 lg:flex  grid grid-cols-2 justify-center  lg:gap-20 md:gap-14 gap-4">
          <div
            className="flex gap-4 items-center"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div className="dynamicStyle2" id="3545210609">
              <Image
                src={data ? getImage(data['3545210609']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="impacted"
                className="lg:w-20 w-12 lg:h-20 h-12"
              />
            </div>
            <div>
              <h6
                className="fsize30 sm-fsize17 text-white font-semibold dynamicStyle"
                id="3628316915"
              >
                {data ? data['3628316915'] : 'Lorem Ipsum'}
              </h6>
              <p
                className="fsize18 sm-fsize14 text-white dynamicStyle"
                id="6791268700"
              >
                {data ? data['6791268700'] : 'Lorem Ipsum'}
              </p>
            </div>
          </div>

          <div
            className="flex gap-3 items-center"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div className="dynamicStyle2" id="2426304908">
              <Image
                src={data ? getImage(data['2426304908']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="impacted"
                className="lg:w-20 w-12 lg:h-20 h-12"
              />
            </div>
            <div>
              <h6
                className="fsize30 sm-fsize17 text-white font-semibold dynamicStyle"
                id="8313369831"
              >
                {data ? data['8313369831'] : 'Lorem Ipsum'}
              </h6>
              <p
                className="fsize18 sm-fsize14 text-white dynamicStyle"
                id="8979777430"
              >
                {data ? data['8979777430'] : 'Lorem Ipsum'}
              </p>
            </div>
          </div>

          <div
            className="flex gap-3 items-center"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div className="dynamicStyle2 " id="3009100123">
              <Image
                src={data ? getImage(data['3009100123']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="impacted"
                className="lg:w-20 w-12 lg:h-20 h-12"
              />
            </div>
            <div>
              <h6
                className="fsize30 sm-fsize17 text-white font-semibold dynamicStyle"
                id="9041628679"
              >
                {data ? data['9041628679'] : 'Lorem Ipsum'}
              </h6>
              <p
                className="fsize18 sm-fsize14 text-white dynamicStyle"
                id="9162886254"
              >
                {data ? data['9162886254'] : 'Lorem Ipsum'}
              </p>
            </div>
          </div>

          <div
            className="flex gap-3 items-center"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <div className="dynamicStyle2" id="1429200638">
              <Image
                src={data ? getImage(data['1429200638']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="impacted"
                className="lg:w-20 w-12 lg:h-20 h-12"
              />
            </div>
            <div>
              <h6
                className="fsize30 sm-fsize17 text-white font-semibold dynamicStyle"
                id="1490195351"
              >
                {data ? data['1490195351'] : 'Lorem Ipsum'}
              </h6>
              <p
                className="fsize18 sm-fsize14 text-white dynamicStyle"
                id="0899561604"
              >
                {data ? data['0899561604'] : 'Lorem Ipsum'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Counter3;
