import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import React from 'react';
function ScrollToTop() {
  const { pathname } = useLocation();
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return <></>;
}

export default ScrollToTop;
