import React, { useEffect, useRef, useState } from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const CardImage5 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
      <div className="text-center lg:w-2/3 md:w-3/4 w-full mx-auto">
        <p id="0007414156" className="dynamicStyle text-primary fsize16 md-fsize16 sm-fszie14 font-medium cust-textColor">
          {data ? data['0007414156'] : 'About us'}
        </p>
        <p
          id="6656700922"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-medium pb-2 cust-textColor"
        >
          {data ? data['6656700922'] : 'Lorem Ipsum'}
        </p>
      </div>
      <div className=" w-full text-center">
        <p
          id="4294615986"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['4294615986']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle fsize16 md-fsize16 sm-fsize14 textColor-light"
        >
          {/* Established in the year <span className="text-primary">2007</span>, We “
          <span className="text-primary">Jasmeena Print</span> ”, engaged as the
          foremost 
          <span className="text-primary">
            Manufacturer, Wholesaler and Exporter
          </span>
           of 
          <span className="text-primary">
            Printed Cotton Fabric and Polyester Print Fabric.
          </span>
           Our products are high in demand due to their premium quality and
          affordable prices. Furthermore, we ensure to timely deliver these
          products to our clients, through this, we have gained a huge clients
          base in the market.
        </p>
        <p className="sm-fsize14">
          Under the visionary guidance of 
          <span className="text-primary">Mr. Himanshu Patel </span>our
          organization is succeeding towards higher strides of growth in the
          industry. */}
        </p>
      </div>
      <div ref={elementRef} className={`fade-bottom lg:flex md:flex lg:gap-10 md:gap-5 justify-evenly lg:pt-12 md:pt-12 pt-6 ${isVisible ? 'visiblebottom' : ''} `}>
        <div
          id="2720286230"
          className="dynamicStyle2 sm-pb-4 w-full overflow-hidden"
          
        >
          <Image
            className="fab-wh md-fab-wh custimghover object-contain"
            src={data ? getImage(data['2720286230']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="image"
            
          />
        </div>
        <div
          id="2328975408"
          className="dynamicStyle2 sm-pb-4 w-full overflow-hidden"
          
        >
          <Image
            className="fab-wh md-fab-wh custimghover object-contain"
            src={data ? getImage(data['2328975408']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="image"
            
          />
        </div>
        <div
          id="0338463378"
          className="dynamicStyle2 sm-pb-4 w-full overflow-hidden"
          
        >
          <Image
            className="fab-wh md-fab-wh custimghover object-contain"
            src={data ? getImage(data['0338463378']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="image"
            
          />
        </div>
      </div>
    </section>
  );
};

export default CardImage5;
