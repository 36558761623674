import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import { useSelector, useDispatch } from 'react-redux/es/exports';
import { Badge, Card } from '@chakra-ui/react';
import {
  getBlogsWithoutUpadate,
  getBlogs,
  setSelectedBlog,
} from '../../../redux/blogs/blogs-actions';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

const Relevantsuggestion = ({ pageName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { blogId } = useParams();
  const data = useSelector(state => state.cms.allSections[pageName]);
  // let Blogs = useSelector((state) => state.BlogDetails.Blogs);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  let selectedBlog = useSelector(state => state.BlogDetails.selectedBlog);
  let relevantBlogs = useSelector(state => state.BlogDetails.RelevantBlogs);
  // const [blogId, setBlogId] = useState(window.location.pathname.split("/")[2]);
  useEffect(() => {
    // handleGetAllBlog(window.location.pathname.split("/")[2]);
    if (selectedBlog._id === blogId) {
      handleGetAllBlog();
    }
  }, [selectedBlog._id]);

  const handleGetAllBlog = async () => {
    try {
      setIsLoading(true);
      let payload = {
        isActive: true,
        offset: 0,
        limit: 12,
      };

      if (selectedBlog && selectedBlog.categoryID) {
        let categoryIds = selectedBlog.categoryID.map(cat => cat._id);
        Object.assign(payload, { categoryId: categoryIds });
      }

      let data = await dispatch(getBlogsWithoutUpadate(payload));
      setIsLoading(false);
      if (
        data &&
        data.code === 200 &&
        data.data &&
        data.data.result &&
        data.data.result.length > 0
      ) {
        let filterBlog = data.data.result.filter(blog => {
          return blog._id !== blogId;
        });
        setBlogData(filterBlog);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlogDetail = blogId => {
    dispatch(setSelectedBlog({}));
    navigate(`/blog detail/${blogId}`);
  };

  return (
    <section className="relative">
      <div className="container zindex9 relative mx-auto  lg:py-16 md:py-16 py-8 lg:px-12 md:px-8 px-5">
        <div>
          <h2
            id="1070975299"
            className="dynamicStyle cust-textColor font-semibold text-center lg:text-3xl md:text-2xl text-2xl  lg:pb-3 md:pb-3 pb-3"
          >
            {data ? data['1070975299'] : 'RELEVANT SUGGESTIONS'}
          </h2>
          <p
            id="8724411089"
            className="dynamicStyle textColor-light text-center text-gray lg:text-base md:text-base text-sm lg:pb-4 md:pb-4"
          >
            {data
              ? data['8724411089']
              : 'Visualize Your Fitness Journey With Fit2GoAsia'}
          </p>
        </div>

        {isLoading ? (
          <div className="flex justify-center align-center">
            <ThreeDots visible={true} color="#ed1b24" radius={9} />
          </div>
        ) : (
          <>
            {blogData && blogData.length > 0 ? (
              <div className="lg:mt-4 md:mt-4">
                <Swiper
                  spaceBetween={25}
                  slidesPerView={3.2}
                  observer={true}
                  observeParents={true}
                  modules={[Navigation]}
                  className="mySwiper p-2"
                  autoplay={{
                    delay: '1000',
                  }}
                  loop
                  navigation={{
                    nextEl: '.image-swiper-button-next',
                    prevEl: '.image-swiper-button-prev',
                  }}
                  breakpoints={{
                    1536: {
                      slidesPerView: 3.2,
                      spaceBetween: 25,
                    },
                    1280: {
                      slidesPerView: 3.2,
                      spaceBetween: 25,
                    },
                    1024: {
                      slidesPerView: 3.1,
                      spaceBetween: 25,
                    },
                    991: {
                      slidesPerView: 2.8,
                      spaceBetween: 25,
                    },
                    767: {
                      slidesPerView: 2,
                      spaceBetween: 25,
                    },
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 25,
                    },
                    425: {
                      slidesPerView: 1,
                      spaceBetween: 25,
                    },
                    325: {
                      slidesPerView: 1,
                      spaceBetween: 25,
                    },
                  }}
                >
                  {blogData.map((e, i) => {
                    return (
                      <SwiperSlide key={e._id}>
                        <Card
                          p="2"
                          className="cursor-pointer"
                          onClick={() => handleBlogDetail(e._id)}
                        >
                          <img
                            height="400px"
                            src={process.env.REACT_APP_STORAGE_URL + e.image}
                            alt="heroImage"
                            className="w-full object-cover rounded-xl h-52 tb-h280px sm-h250px object-cover"
                          />
                          <div className="p-2">
                            <div className="flex items-center justify-between mb-2 fsize12">
                              <Badge
                                borderRadius="full"
                                px="3"
                                py="0.5"
                                colorScheme="teal"
                              >
                                {e.categoryID[0].categoryName}
                              </Badge>
                              <p className="text-gray text-sm fsize12">
                                {new Date(e.createdAt).toDateString()}
                              </p>
                            </div>
                            <h4 className="font-bold  lg:mt-2 md:mt-2 text-lg cust-textColor">
                              {e.title}
                            </h4>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e.content,
                              }}
                              className="mt-2 text8787 line-clamp-1 lg:text-base md:text-base text-sm"
                            ></p>
                          </div>
                        </Card>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            ) : (
              <div className="flex justify-center align-center cust-textColor">
                No Blog Found
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default Relevantsuggestion;
