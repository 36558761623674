import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Checkbox, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { handleScroll } from './fadeeffect/FadeEffect';

const ContactForm1 = ({pageName}) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className={colorMode === 'light' ? 'bg-primary theme-bg' : 'theme-bg'}>
      <div className="container mx-auto lg:px-16 md:px-12 px-5 lg:py-16 md:py-16 py-8">
        <div className="lg:flex lg:justify-between gap-4 w-full">
          <div ref={elementRef} className={`fade-left lg:w-1/2 lg:mb-0 md:mb-6 ${isVisible ? 'visibleleft' : ''} `}>
            <p
            id="2815266821"
              className="dynamicStyle lg:w-11/12 text-primary fsize86 md-fsize42 md-lh-48 sm-fsize24 font-semibold sm-lead text-white"
          
            >
               {data ? data['2815266821'] : 'Lorem Ipsum generate'}
            
            </p>
          </div>
          <Card
          className={
            colorMode === 'light'
              ? 'lg:w-1/2 contactcss themeShadow theme-bg lg:mt-0 md:mt-0 mt-4'
              : 'lg:w-1/2 contactcss themeShadow theme-bg lg:mt-0 md:mt-0 mt-4'
          }
          >
            <p id="7038901551"
              className="dynamicStyle lg:mb-4 md:mb-4 mb-2 letter-spacing4px fsize24 sm-fsize18 font-semibold clr-ddd9d8">
            {data ? data['7038901551'] : 'Lorem Ipsum generate'}
            </p>
            <form>
              <div className="lg:mb-10 mb-3">
                <input
                  type="text"
                  placeholder="Full Name*"
                  className={
                      colorMode === 'light'
                        ? 'inputFeild theme-bg themeShadow'
                        : 'inputFeild-dark cust-bgColor theme-bg themeShadow'
                    }
                />
              </div>
              <div className="lg:mb-10 mb-3 w-full lg:flex gap-8">
                <div className="w-full lg:mb-0 md:mb-3 mb-4">
                  <input
                    type="text"
                    placeholder="Phone number*"
                    
                    className={
                      colorMode === 'light'
                        ? 'inputFeild theme-bg themeShadow'
                        : 'inputFeild-dark cust-bgColor theme-bg themeShadow'
                    }
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="Email Id*"
                    className={
                      colorMode === 'light'
                        ? 'inputFeild theme-bg themeShadow'
                        : 'inputFeild-dark cust-bgColor theme-bg themeShadow'
                    }
                  />
                </div>
              </div>
              <div className="lg:mb-6 mb-3">
                <input
                  type="text"
                  placeholder="Your Message*"
                  className={
                      colorMode === 'light'
                        ? 'inputFeild theme-bg themeShadow'
                        : 'inputFeild-dark cust-bgColor theme-bg themeShadow'
                    }
                />
              </div>
              <div className="lg:mb-10 mb-4">
                <Checkbox>
                  <span className="fsize14 text-dark cust-textColor">
                    Check to subscribe to our Newsletter
                  </span>
                </Checkbox>
              </div>
              <Button
                
                id="7398466773"
                className={
                  colorMode === 'light'
                    ? 'dynamicStyle theme-btn2 bg-primary fsize16 font-semibold border-1px clr-fff w-full lg:py-6 md:py-4 py-2 buttonAnimate bdr-2px'
                    : 'dynamicStyle theme-btn2 fsize16 font-semibold border-1px  w-full lg:py-6 md:py-4 py-2 bdr-2px'
                }
                
              >
                {data ? data['7398466773'] : 'Enquire Now'}
              </Button>
            </form>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default ContactForm1;
