import {
  ChevronDownIcon,
  CloseIcon,
  HamburgerIcon,
  Search2Icon,
} from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import HeroSection1 from '../components/HeroSection1';
import HeroSection2 from '../components/HeroSection2';
import HeroSection3 from '../components/HeroSection3';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Header6 = () => {
  let menuData = useSelector(state => state.menuState.menu);
  console.log(menuData, 'SACHIN');
  let brandProfile = useSelector(state => state.brandDetails.brandProfile);

  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png'
      : 'https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png';
  }

  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      // Adjust this value based on when you want the navbar to become fixed
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [megaMenuVisible, setmegaMenuVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleMegaMenuDropdown = () => {
    setmegaMenuVisible(!megaMenuVisible);
  };

  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <div>
      <div className="header6 relative">
        <div className="header-6-sub-menu">
          <div className="container mx-auto">
            <div className="header-6-sub-menu-container lg:px-0 md:px-0 px-5 lg:py-0 md:py-6 py-4">
              <ul className="flex lg:mb-0 md:mb-0 mb-3 list-none">
                <li className="px-2">English</li>
                <li className="px-2">
                  <FormControl display="flex" alignItems="center" gap={2}>
                    <Switch size="sm" id="email-alerts" />
                    <FormLabel htmlFor="email-alerts" mb="0">
                      High Contrast
                    </FormLabel>
                  </FormControl>
                </li>
              </ul>
              <div className="">
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    className="fsize14"
                    placeholder="Search Here..."
                  />
                  <InputRightElement width="3rem">
                    <Button h="1.75rem" size="sm">
                      <Search2Icon />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        <div className={`header-6-menu ${isFixed ? 'header-6-Sticky' : ''}`}>
          <div className="container mx-auto">
            <div className="header-6-menu-container">
              <div className="flex items-center li-height sm-w-100 lg:px-0 md:px-0 px-5">
                <div className="sm-w-50 ">
                  <NavLink to="">
                    <Image
                      src={getPrimaryImage(brandProfile)}
                      alt="logo"
                      className="w-32 h-16 object-contain "
                    />
                  </NavLink>
                </div>

                <div className="lg:hidden md:hidden block sm-w-50">
                  <div className=" flex justify-end  ">
                    <Button onClick={onOpenMenu}>
                      <HamburgerIcon />
                    </Button>
                    <Drawer
                      isOpen={isOpenMenu}
                      placement="right"
                      onClose={onCloseMenu}
                      finalFocusRef={btnRef}
                    >
                      <DrawerContent maxWidth="95%">
                        <DrawerHeader>
                          <NavLink to="">
                            <Image
                              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt=""
                              className="w-32 h-8 mr-12 "
                            />
                          </NavLink>{' '}
                          <DrawerCloseButton top="16px" />
                        </DrawerHeader>
                        <DrawerBody>
                          <ul className="list-none subMenu ">
                          {menuData
                    ? menuData.map((e, i) => (
                        <>
                        {e.menutype === 'page' && e.subMenu.length < 1 ? (
                             <li key={i} className="px-4 py-2  li-height bottom-border-ebebeb">
                               <NavLink to={`/${e.pageData[0].page}`}>
                                {e.menuName}
                              </NavLink>
                            </li>
                        ) : (
                            ''
                          )}
                          {e.menutype === 'page' && e.subMenu.length > 0 ? (
                           
                           <li
                             className="px-4 py-2  li-height bottom-border-ebebeb"
                             onMouseEnter={toggleDropdown}
                             onMouseLeave={toggleDropdown}
                           >
                               <NavLink>
                               {e.menuName} <ChevronDownIcon  />
                             </NavLink>
                          
                             {dropdownVisible && (
                               <ul className="header-6-dropdown ">
                                 <li className="header-6-dropdown-item bg-white text-black">
                                   <a href="#team" className="">
                                     Team
                                   </a>
                                 </li>
                                 <li className="header-6-dropdown-item bg-white text-black">
                                   <a href="#careers" className="">
                                     Careers
                                   </a>
                                 </li>
                               </ul>
                             )}
                           </li>
                       ) : (
                           ''
                         )}
                          </>
                    ))
                    : ''}
                    
                            <li
                              className="px-4 py-2  li-height bottom-border-ebebeb"
                              onMouseEnter={toggleMegaMenuDropdown}
                              onMouseLeave={toggleMegaMenuDropdown}
                            >
                              Menu 3 <ChevronDownIcon />
                              {megaMenuVisible && (
                                <div className="header-6-megaMenu grid grid-cols-4 ">
                                  <div className="header-6-megaMenu-menu">
                                    <h3 className="font-medium text-primary">
                                      Mega Menu{' '}
                                    </h3>

                                    <ul className="list-none">
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 1
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 2
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 3
                                        </NavLink>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="header-6-megaMenu-menu">
                                    <h3 className="font-medium text-primary">
                                      Mega Menu{' '}
                                    </h3>

                                    <ul className="list-none">
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 1
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 2
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 3
                                        </NavLink>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="header-6-megaMenu-menu">
                                    <h3 className="font-medium text-primary">
                                      Mega Menu{' '}
                                    </h3>

                                    <ul className="list-none">
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 1
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 2
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 3
                                        </NavLink>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="header-6-megaMenu-menu">
                                    <h3 className="font-medium text-primary">
                                      Mega Menu{' '}
                                    </h3>

                                    <ul className="list-none">
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 1
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 2
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to=""
                                          className="header-6-megaMenu-menu-link"
                                        >
                                          Link 3
                                        </NavLink>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </li>
                          </ul>
                        </DrawerBody>
                      </DrawerContent>
                    </Drawer>
                  </div>
                </div>
                <ul className="list-none subMenu flex items-center ">
                  {menuData
                    ? menuData.map((e, i) => (
                        <>
                          {e.menutype === 'page' && e.subMenu.length < 1 ? (
                            <li
                              key={i}
                              className="px-3 flex items-center li-height "
                            >
                              <NavLink to={`/${e.pageData[0].page}`}>
                                {e.menuName}
                              </NavLink>
                            </li>
                          ) : (
                            ''
                          )}

                          {e.menutype === 'page' && e.subMenu.length > 0 ? (
                            <li
                              className="px-2 flex items-center li-height "
                              onMouseEnter={toggleDropdown}
                              onMouseLeave={toggleDropdown}
                            >
                              <NavLink>
                              {e.menuName} <ChevronDownIcon className="ml-1" />
                              </NavLink>
                              {dropdownVisible && (
                                <ul className="header-6-dropdown">
                                  {e.subMenu.map(sub => {
                                    return (
                                      <li className="header-6-dropdown-item bg-white text-black">
                                        <NavLink to={`/${sub.pageData[0].page}`}>
                                          {sub.menuName}
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </li>
                          ) : (
                            ''
                          )}

                          {/* {e.menutype === 'page' && e.subMenu.length > 0 ? (
                              <>
                                <p onMouseEnter={toggleDropdown}
                                  onMouseLeave={toggleDropdown}>
                                <NavLink
                                  
                                >
                                  {e.menuName}
                                  <ChevronDownIcon className="ml-1" />
                                </NavLink>
                                </p>
                                {dropdownVisible && (
                                  <ul className="header-6-dropdown">
                                    {e.subMenu.map(sub => {
                                      return (
                                        <li className="header-6-dropdown-item bg-white text-black">
                                          <NavLink to="">
                                            {sub.menuName}
                                          </NavLink>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </>
                            ) : (
                              ''
                            )} */}
                        </>
                      ))
                    : ''}
                  <li
                    className="px-4 flex items-center li-height "
                    onMouseEnter={toggleMegaMenuDropdown}
                    onMouseLeave={toggleMegaMenuDropdown}
                  >
                    <NavLink>
                      Menu 3 <ChevronDownIcon className="ml-1" />
                    </NavLink>
                    {megaMenuVisible && (
                      <div className="header-6-megaMenu grid lg:grid-cols-4 md:grid-cols-2">
                        <div className="header-6-megaMenu-menu">
                          <div className="flex items-center mb-3">
                            <Image
                              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt=""
                              className="w-8 h-8"
                            />
                            <h3 className="ml-2 font-medium text-primary">
                              Mega Menu{' '}
                            </h3>
                          </div>
                          <ul className="list-none">
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 1
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 2
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 3
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="header-6-megaMenu-menu">
                          <div className="flex items-center mb-3">
                            <Image
                              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt=""
                              className="w-8 h-8"
                            />
                            <h3 className="ml-2 font-medium text-primary">
                              Mega Menu{' '}
                            </h3>
                          </div>
                          <ul className="list-none">
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 1
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 2
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 3
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="header-6-megaMenu-menu">
                          <div className="flex items-center mb-3">
                            <Image
                              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt=""
                              className="w-8 h-8"
                            />
                            <h3 className="ml-2 font-medium text-primary">
                              Mega Menu{' '}
                            </h3>
                          </div>
                          <ul className="list-none">
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 1
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 2
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 3
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="header-6-megaMenu-menu">
                          <div className="flex items-center mb-3">
                            <Image
                              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt=""
                              className="w-8 h-8"
                            />
                            <h3 className="ml-2 font-medium text-primary">
                              Mega Menu{' '}
                            </h3>
                          </div>
                          <ul className="list-none">
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 1
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 2
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to=""
                                className="header-6-megaMenu-menu-link"
                              >
                                Link 3
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
              <ul className="flex items-center list-none">
                <li className="px-2">Login</li>
                <li className="pl-2">
                  <Button fontWeight={500} className="fsize14">
                    Start free or get a demo
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <HeroSection1 />
      <HeroSection2 />
      <HeroSection3 />
    </div>
  );
};

export default Header6;
