import React from 'react';
import { Image } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
} from '@chakra-ui/react';

const Assets = () => {
  return (
    <div>
      <div className="lg:px-12 md:px-8 px-5 lg:py-10 py-8 container mx-auto">
        <h2 className="fsize25 sm-fsize20 text-primary ">Assets</h2>

        <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-4 mt-8">
          <div>
            <div className="relative">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="w-full lg:h-40 md:h-32 h-32"
              />
              <div className="mt-2 absolute top-0 right-0">
                <Popover>
                  <PopoverTrigger>
                    <Button colorScheme="transparent">
                      <div className="p-2 bg-white rounded-full">
                        <FeatherIcon
                          icon="lock"
                          className="text-primary"
                          size="17"
                        />
                      </div>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody className="custborbot">
                      <div className="p-2">
                        <h5 className="fsize16 text-primary font-600">
                          Limited Access
                        </h5>
                        <p className="fsize13 text-gray">
                          You don't have access to this folder.
                        </p>
                      </div>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
            <p className="fsize18 text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <div className="relative">
              <Image
                src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="img"
                className="w-full lg:h-40 md:h-32 h-32"
              />
              <div className="mt-2 absolute top-0 right-0">
                <Popover>
                  <PopoverTrigger>
                    <Button colorScheme="transparent">
                      <div className="p-2 bg-white rounded-full">
                        <FeatherIcon
                          icon="lock"
                          className="text-primary"
                          size="17"
                        />
                      </div>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody className="custborbot">
                      <div className="p-2">
                        <h5 className="fsize16 text-primary font-600">
                          Limited Access
                        </h5>
                        <p className="fsize13 text-gray">
                          You don't have access to this folder.
                        </p>
                      </div>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
            <p className="fsize18 text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
            />
            <p className="fsize18 text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
            />
            <p className="fsize18 text-gray mt-5 text-center">Folder 1</p>
          </div>
          <div>
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="img"
              className="w-full lg:h-40 md:h-32 h-32"
            />
            <p className="fsize18 text-gray mt-5 text-center">Folder 1</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assets;
