import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllTeams } from '../../../redux/teams/teams-action';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const Teams2 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  let allTeam = useSelector(state => state.Teams.Teams);
  useEffect(() => {
    dispatch(getAllTeams());
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6">
      <div className="container mx-auto lg:flex md:flex block items-center ">
        <div className="w-40 sm-wfull lg:px-5 md:px-0 px-0">
          <Swiper
            spaceBetween={30}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper container relative overflow-x-hidden"
            autoplay={{
              delay: '1000',
            }}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            breakpoints={{
              1536: {
                slidesPerView: 1.5,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 1.5,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 1.5,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 1.5,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {allTeam && allTeam.length > 0
              ? allTeam.map(e => {
                  return (
                    <SwiperSlide>
                      <Card
                        className={
                          colorMode === 'light'
                            ? 'border p-2 w-full rounded-lg themeShadow theme-bg'
                            : 'p-2 w-full rounded-lg themeShadow theme-bg'
                        }
                      >
                        <div className=" overflow-hidden w-full">
                          <Image
                            src={`${process.env.REACT_APP_STORAGE_URL}${e.profilepicture}`}
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            alt="teams-card"
                            className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
                          />
                        </div>

                        <p className=" fsize18 font-semibold text-center lg:mb-2 cust-textColor lg:mt-0 md:mt-0 mt-2">
                          {e.name}
                        </p>
                        <p className=" fsize14 text-center textColor-light">
                          {e.designation}
                        </p>
                      </Card>
                    </SwiperSlide>
                  );
                })
              : 'No Data'}
          </Swiper>
        </div>
        <div
          ref={elementRef}
          className={`fade-right w-60 sm-wfull lg:px-12 md:px-8 px-5 lg:pt-0 md:pt-0 pt-4 ${
            isVisible ? 'visibleright' : ''
          } `}
        >
          <h1
            id="9239265230"
            className="dynamicStyle mb-2 cust-textColor fsize36 md-fsize24 sm-fsize20  lg:w-4/5 md:4/5 font-semibold text-primary"
          >
            {data ? data['9239265230'] : 'Meet Aur Professional teams'}
          </h1>
          <p
            id="8804742678"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['8804742678']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14 lg:leading-7 md:leading-7 leading-6"
          >
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            cursus consectetur euismod aenean. */}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Teams2;
