import API from '../repositoryFactory';

const get = 'getForms';
const getSchema = 'getSchema';
const getInputByFormId = 'getInputByFormId';
const getSchemaByFormId = 'getSchemaByFormId';

const getForms = route => {
  return `cmsforms/${route}`;
};

export default {
  getAllForms(payload) {
    return API.post(getForms(get), payload);
  },
  getFormsSchema(payload) {
    return API.post(getForms(getSchema), payload);
  },
  getInputByFormId(payload) {
    return API.post(getForms(getInputByFormId), payload);
  },

  getSchemaByFormId(payload) {
    return API.post(getForms(getSchemaByFormId), payload);
  },
};
