import API from "./repositoryFactory";

const addNewsletter = "addNewsletter";
const getNewsletter = "getNewsletter";
const updateNewsletter = "updateNewsletter";
const deleteNewsletter = "deleteNewsletter";

const newsletterRoute = (route) => {
  return `newsletter/${route}`;
};

export default {
  addNewsletter(payload) {
    return API.post(newsletterRoute(addNewsletter), payload);
  },
  getNewsletter(payload) {
    return API.post(newsletterRoute(getNewsletter), payload);
  },
  updateNewsletter(payload) {
    return API.post(newsletterRoute(updateNewsletter), payload);
  },
  deleteNewsletter(payload) {
    return API.post(newsletterRoute(deleteNewsletter), payload);
  },
};
