import API from '../repositoryFactory';

const getCategory = 'get/elearning_category';
const getCourse = 'get/elearning_courses';
const getProducts = route => {
  return `forms/${route}`;
};

export default {
  getAllElearningCategory(payload) {
    return API.post(getProducts(getCategory), payload);
  },
  getAllCourses(payload) {
    return API.post(getProducts(getCourse), payload);
  },
};
