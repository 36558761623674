import apis from '../../apis/client/Forms/ecom_Tags';

export const getAllTags = payload => {
  return async dispatch => {
    try {
      let payload = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let { data } = await apis.getAllTags(payload);
      if (data) dispatch(setAllTags(data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setAllTags = data => {
  return {
    type: 'SET_ALL_TAGS',
    payload: data,
  };
};
