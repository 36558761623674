import apis from '../../apis/client/Forms/ecom_Category';

export const getAllCategorys = payload => {
  return async dispatch => {
    try {
      let payload = {
        isActive: true,
        isDeleted: false,
        isFinalize: true,
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let { data } = await apis.getAllCategorys(payload);
      if (data) dispatch(setAllCategory(data.data));
    } catch (error) {}
  };
};

export const setAllCategory = data => {
  return {
    type: 'SET_ALL_CATEGORY',
    payload: data,
  };
};
