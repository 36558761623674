import { Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Team10 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  let allTeam = useSelector(state => state.Teams.Teams);
    // const teamCard = [
    //   {
    //     id: '01',
    //     img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    //     name: 'Bonnie Green',
    //     designation: 'Singer/Songwriter',
    //   },
    //   {
    //     id: '02',
    //     img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    //     name: 'Tony Kakkar',
    //     designation: 'Singer/Songwriter',
    //   },
    // ];
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
      <div className="lg:w-2/3 md:w-2/3 mx-auto text-center">
        <p
          id="7212976469"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold pb-2 cust-textColor text-primary"
        >
          {data ? data['7212976469'] : 'Meet Our Team Member'}
        </p>
        <p
          id="7177456823"
          dangerouslySetInnerHTML={{
            __html: data ? data['7177456823'] : 'no-data',
          }}
          className="dynamicStyle textColor-light"
        >
          {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Temporibus,
          ipsam.consectetur adipisicing elit */}
        </p>
      </div>
      <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 lg:gap-10 md:gap-8 gap-4 lg:mt-12 md:mt-12 mt-6">
      {allTeam && allTeam.length > 0
            ? allTeam.map(e => {
          return (
            <div
            
            className={
              colorMode === 'light'
                ? 'p-4 themeShadow themeShadow-insert cursor-pointer'
                : 'p-4 themeShadow cursor-pointer'
            }
            >
              <div
              
              className="w-24 h-24 rounded-full mx-auto">
                <Image
                  className="w-24 h-24 rounded-full"
                  src={`${process.env.REACT_APP_STORAGE_URL}${e.profilepicture}`}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                />
              </div>
              <div className="text-center pt-2">
                <p className="fsize18 font-semibold cust-textColor">{e.name}</p>
                <p className="text-gray textColor-light">{e.designation}</p>
              </div>
            </div>
          );
        })
        : 'No Data'}
      </div>
    </div>
  );
};

export default Team10;
