import React, { useEffect, useRef, useState } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection16 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();

  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const handleErorrImage = e => {
    e.target.src =
      'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg';
  };

  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-5">
      <div className="lg:w-2/3 md:w-3/4 w-full mx-auto text-center lg:pb-8 md:pb-8 pb-4">
        <p
          id="4384588611"
          className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-primary font-medium cust-textColor"
        >
          {data ? data['4384588611'] : 'Lorem ipsum'}
        </p>
        <p
          id="7231049814"
          className="dynamicStyle fsize34 md-fsize24 font-semibold sm-fsize20 cust-textColor"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['7231049814']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
        ></p>
      </div>
      <div>
        <div className="lg:flex md:flex  items-center gap-4 lg:px-12 md:px-0 px-0">
          <div className="lg:w-1/2 md:w-1/2 lg:py-0 md:py-8 py-4">
            <div className=" lg:mt-8 md:mt-8">
              <h2
                id="7156060658"
                className="cust-textColor fsize34 md-fsize24 sm-fsize20 text-primary  text-end font-semibold dynamicStyle"
              >
                {data
                  ? data['7156060658']
                  : 'STAY INFORMED WITH OUR LATEST ARTICLES'}
              </h2>
              <p
                id="1641654251"
                dangerouslySetInnerHTML={{
                  __html: data ? data['1641654251'] : 'no-data',
                }}
                className="textColor-light  mt-2 fsize16 sm-fsize14 dynamicStyle"
              ></p>
            </div>
            <div className="flex lg:gap-6 md:gap-6 gap-4 pt-6">
              <Button
                className={
                  colorMode === 'light'
                    ? 'dynamicStyle theme-btn bg-primary buttonAnimate btn-mdsize clr-fff bdr-2px'
                    : 'dynamicStyle theme-btn cust-bgColor btn-mdsize bdr-2px'
                }
                id="9042320843"
              >
                <span onClick={() => handleClick('9042320843')}>
                  {data ? data['9042320843'] : 'Lorem ipsum'}
                </span>
              </Button>
            </div>
          </div>
          <div
            ref={elementRef}
            className={`fade-right lg:w-1/2 md:w-1/2 grid grid-cols-2 lg:gap-8 md:gap-6 gap-4 justify-end w-full lg:py-10 md:py-6 py-6  ${
              isVisible ? 'visibleright' : ''
            } `}
          >
            <div className="w-full ">
              <div id="9856898056" className="dynamicStyle2 ">
                <Image
                  src={data ? getImage(data['9856898056']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="heroImage"
                  className="object-cover w-full lg:h-40 md:h-40 h-32 rounded-lg"
                />
              </div>
              <div id="6634269119" className="dynamicStyle2 ">
                <Image
                  src={data ? getImage(data['6634269119']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="heroImage"
                  className="object-cover w-full lg:h-40 md:h-40 h-32 rounded-lg mt-6"
                />
              </div>
            </div>
            <div className="w-full mt-6">
              <div id="0535198992" className="dynamicStyle2 ">
                <Image
                  src={data ? getImage(data['0535198992']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="heroImage"
                  className="object-cover w-full lg:h-40 md:h-40 h-32 rounded-lg"
                />{' '}
              </div>
              <div id="1159401137" className="dynamicStyle2 ">
                <Image
                  src={data ? getImage(data['1159401137']) : ''}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="heroImage"
                  className="object-cover w-full lg:h-40 md:h-40 h-32 rounded-lg mt-6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection16;
