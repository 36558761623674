const initialState = {
  SubCategorys: [],
};

export const ecom_Sub_Category = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_SUB_CATEGORY':
      return {
        ...state,
        SubCategorys: action.payload,
      };
    

    default:
      return state;
  }
};

export default ecom_Sub_Category;
