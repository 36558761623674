import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import FeatherIcon from 'feather-icons-react';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard9 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  console.log(data['2539121293'], 'text');
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8">
      
      <div className="lg:w-7/12 md:w-9/12 mx-auto">
      <h4
        className="fsize34 md-fsize24 sm-fsize20 font-semibold text-center dynamicStyle text-primary cust-textColor mb-2"
        id="3079268107"
      >
        {data ? data['3079268107'] : 'Meet Aur Professional teams'}
      </h4>
        <p
          id="7085612576"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['7085612576']
              : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus',
          }}
          className="fsize16 sm-fsize14 textColor-light font-medium text-center dynamicStyle"
        ></p>
      </div>
      <div className="lg:mt-12 md:mt-12 mt-6 dynamicStyle" id="2539121293">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-8 gap-6 ">
          {data && data['2539121293']
            ? data['2539121293'].map(e => {
                return (
                  <Card
                    ref={elementRef}
                    className={`fade-bottom w-full rounded-lg relative hover-images themeShadow theme-bg ${
                      isVisible ? 'visiblebottom' : ''
                    } `}
                  >
                    {' '}
                    <div
                      className={
                        colorMode === 'light'
                          ? 'lg:h-72 md:h-60 h-52 w-full rounded-lg borderfeature'
                          : 'lg:h-72 md:h-60 h-52 w-full rounded-lg border-1px '
                      }
                    >
                      <Image
                        src={e ? getImage(e.field4) : ''}
                        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                        alt="imagee"
                        className="lg:h-72 md:h-60 h-52 w-full object-cover rounded-lg filter-b5 bg-d2d2d2 featureimage"
                      />
                    </div>
                    <div
                      className={
                        colorMode === 'light'
                          ? 'absolute top-0 left-0 lg:p-4 md:p-4 p-3 colorcard'
                          : 'absolute top-0 left-0 lg:p-4 md:p-4 p-3 colorcard cust-textColor'
                      }
                    >
                      <div className="flex items-center gap-1 ">
                        <FeatherIcon icon="folder" size={15} className="flex" />
                        <p className=" fsize15 font-medium sm-fsize14">
                          {e.field1}
                        </p>
                      </div>
                      <h2 className=" fsize22 md-fsize20 sm-fsize18 font-semibold lg:mt-2 mt-1 ">
                        {e.field2}
                      </h2>
                    </div>
                    <div
                      className={
                        colorMode === 'light'
                          ? 'absolute bottom-0 left-0 lg:p-4 md:p-4 p-3 colorcard'
                          : 'absolute bottom-0 left-0 lg:p-4 md:p-4 p-3 colorcard cust-textColor'
                      }
                    >
                      <div className="flex items-center gap-1 ">
                        <FeatherIcon icon="clock" size={15} className="flex" />
                        <p className=" fsize15 font-medium sm-fsize14">
                          {e.field3}
                        </p>
                      </div>
                    </div>
                  </Card>
                );
              })
            : 'no-data'}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard9;
