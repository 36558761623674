import React from 'react';

const Banner = () => {
  return (
    <div className="relative">
      <img
        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
        alt="home-banner"
        className="h-250px filter-b4 w-full"
      />
      <div className="w-full  absolute top-0 left-0 h-250px flex justify-start items-center">
        <div className="container mx-auto lg:px-12 md:px-8 px-6">
          <div className="text-start mb-8">
            <h3 className="fsize36 sm-fsize21 font-semibold text-white">
              Help & Support
            </h3>
            <p className=" text-white">
              Effortlessly resolve all queries related to this portal
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
