import { Card, Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { FaQuoteRight } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ReactStars from 'react-rating-stars-component';
import { Autoplay, Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux';
const Testimonial10 = ({ pageName }) => {
  let getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  const data = useSelector(state => state.cms.allSections[pageName]);

  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <div className={colorMode === 'light' ? 'bg-grad theme-bg' : 'theme-bg'}>
      <div className="lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-8 container m-auto">
        <div class="text-center lg:mx-auto md:mx-auto lg:w-3/4 md:w-3/4 w-full pt-5 lg:pb-8 md:pb-8 pb-5">
          <h2
            id="5353521682"
            class="dynamicStyle cust-textColor fsize34 mb-2 sm-fsize24 text-primary font-semibold"
          >
            {data ? data['5353521682'] : 'What our clients says'}
          </h2>
          
            <p
              id="6623076561"
              className="dynamicStyle sm-fsize14 textColor-light"
              dangerouslySetInnerHTML={{
                __html: data ? data['6623076561'] : 'no-data',
              }}
            ></p>
         
        </div>
        <div>
          <Swiper
            spaceBetween={30}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper lg:p-2 p-1"
            breakpoints={{
              1536: {
                slidesPerView: 2.5,
                spaceBetween: 0,
              },
              1440: {
                slidesPerView: 2.5,
                spaceBetween: 0,
              },
              1280: {
                slidesPerView: 2.5,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 0,
              },
              991: {
                slidesPerView: 2.5,
                spaceBetween: 12,
              },
              820: {
                slidesPerView: 1.7,
                spaceBetween: 12,
              },
              768: {
                slidesPerView: 1.7,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 12,
              },
              425: {
                slidesPerView: 1.5,
                spaceBetween: 12,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
            }}
          >
            {getAllTestimonial &&
            getAllTestimonial.data &&
            getAllTestimonial.data.result &&
            getAllTestimonial.data.result.length > 0
              ? getAllTestimonial.data.result.map((testimonial, index) => {
                  return (
                    <SwiperSlide>
                      <div className="lg:px-8 border-left relative ">
                        <div className="lg:mt-16 md:mt-12 mt-14 relative ">
                          <Card
                            className={
                              colorMode === 'light'
                                ? 'rounded-xl bg-white theme-bg border-2px-primary pt-14 lg:pb-8 md:pb-6 pb-4 lg:px-8 md:px-6 px-4 relative z-30'
                                : 'rounded-xl bg-white theme-bg  themeShadow pt-14 lg:pb-8 md:pb-6 pb-4 lg:px-8 md:px-6 px-4 relative z-30'
                            }
                          >
                            <div className="flex justify-center items-center">
                              <p className="text-center fsize20 sm-fsize18 font-bold cust-textColor">
                                {testimonial.name}
                              </p>
                              <div className="hr "></div>
                              <ReactStars
                                count={1}
                                size={24}
                                activeColor="#ffd700 fsize20 sm-fsize16"
                              />
                              <p className="ml-1 fsize18 sm-fsize16 cust-textColor">
                                {' '}
                                4.5
                              </p>
                            </div>
                            <div className="flex gap-4 justify-center items-center lg:pt-4 md:pt-4 pt-2">
                              <div>
                                <p className="lineClam4 textColor-light text-center fsize16 sm-fsize14 font-medium cust-textColor">
                                  {testimonial.testimonial}
                                </p>
                              </div>
                            </div>
                          </Card>
                          <div className="testimonial10-img z-40 flex justify-center w-full">
                            <div className="w-24 h-24 rounded-full flex items-center justify-center ">
                              <Image
                                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                                className={
                                  colorMode === 'light'
                                    ? 'w-24 h-24 border-2px-primary rounded-full'
                                    : 'w-24 h-24  rounded-full'
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="absolute-line5"></div>
                      </div>
                    </SwiperSlide>
                  );
                })
              : 'No Data'}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonial10;
