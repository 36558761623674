import React, { useState } from 'react';
import { Button, Image, Input, useDisclosure } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
} from '@chakra-ui/react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { FiCheck, FiEye, FiLock, FiUsers } from 'react-icons/fi';
import { MoreVertical } from 'react-feather';
import UploaderBox from '../../../../../../../components/UploaderBox';

const Assets = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h5 className="text-white fsize17">Add Folder</h5>
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <div className="py-3 px-1">
              <div className="">
                <label className="text-primary">File Name</label>
                <Input placeholder="Enter" className="mt-2" />
              </div>
              <div className="mt-3">
                <label className="text-primary">
                  Select Approver (Select Maximum 2)
                </label>
                <Select placeholder="Select option" className="mt-2">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </div>
              <div className="mt-3">
                <label className="text-primary">
                  Select Reviewer (Optional)
                </label>
                <Select placeholder="Select option" className="mt-2">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </div>
              <div className="mt-3">
                <label className="text-primary">Select Co-author</label>
                <Select placeholder="Select option" className="mt-2">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </div>
              <div className="mt-5">
                <UploaderBox accept="image/*" />
              </div>
              <div className="flex items-center gap-3 mt-5">
                <Button colorScheme="primary" className="w-full">
                  Submit
                </Button>
                <Button colorScheme="secondary" className="w-full">
                  Cancel
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="lg:px-12 md:px-8 px-5 lg:py-10 py-8 container mx-auto">
        <div className="w-full">
          <div className="flex justify-between w-full">
            <div className="lg:flex md:flex items-baseline gap-2">
              <h2 className="fsize28 sm-fsize20 text-primary">Assets</h2>
              <p className="fsize14 sm-fsize12 text-gray">(4 results found)</p>
            </div>
            <div>
              <Button
                variant="outline"
                className="text-primary"
                onClick={onOpen}
              >
                <FeatherIcon icon="download" className="mr-2" size="16" />
                Upload
              </Button>
            </div>
          </div>
          <div className="lg:mt-12 md:mt-8 mt-6 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-6 gap-4">
            <div className="borderall rounded-lg py-3 px-4 relative">
              <div className="w-full ">
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="KMP"
                  id=""
                  className="w-1/2 mx-auto h-32 object-contain"
                />
              </div>
              <div className="mt-3">
                <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                  Employe code of conduct
                </h2>
                <p className="text-gray fsize14 sm-fsize12">
                  Microsoft word file
                </p>
                <div className="mt-3 flex gap-2 items-center justify-center w-full">
                  <div className="w-full">
                    <Stack direction="row" spacing={4} width="100%">
                      <Button
                        leftIcon={<DownloadIcon />}
                        variant="solid"
                        fontWeight="400"
                        colorScheme="primary"
                        className=" textwhite fsize14 rounded-100px w-full"
                      >
                        Download
                      </Button>
                    </Stack>
                  </div>
                  <div className="w-full">
                    <Stack direction="row" spacing={4} width="100%">
                      <Button
                        leftIcon={<FiEye />}
                        variant="solid"
                        fontWeight="400"
                        colorScheme="secondary"
                        className=" textwhite fsize14 rounded-100px w-full"
                      >
                        Preview
                      </Button>
                    </Stack>
                  </div>
                </div>
              </div>
              <div className="absolute top-0 right-0 mt-3">
                <Menu>
                  <MenuButton as={Button} colorScheme="transparent" h={2} p={0}>
                    <MoreVertical className="text-primary" />
                  </MenuButton>
                  <MenuList>
                    <MenuItem>Version</MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>
            <div className="borderall rounded-lg py-3 px-4 relative">
              <div className="w-full ">
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="KMP"
                  id=""
                  className="w-1/2 mx-auto h-32 object-contain"
                />
              </div>
              <div className="mt-3">
                <h2 className="text-primary fsize16 md-fsize14 sm-fsize14">
                  Employe code of conduct
                </h2>
                <p className="text-gray fsize14 sm-fsize12">
                  Microsoft word file
                </p>
                <div className="mt-3 flex gap-2 items-center justify-center w-full">
                  <div className="w-full">
                    <Stack direction="row" spacing={4} width="100%">
                      <Button
                        leftIcon={<DownloadIcon />}
                        variant="solid"
                        fontWeight="400"
                        colorScheme="primary"
                        className=" textwhite fsize14 rounded-100px w-full"
                      >
                        Download
                      </Button>
                    </Stack>
                  </div>
                  <div className="w-full">
                    <Stack direction="row" spacing={4} width="100%">
                      <Button
                        leftIcon={<FiEye />}
                        variant="solid"
                        fontWeight="400"
                        colorScheme="secondary"
                        className=" textwhite fsize14 rounded-100px w-full"
                      >
                        Preview
                      </Button>
                    </Stack>
                  </div>
                </div>
              </div>
              <div className="absolute top-0 right-0 mt-3">
                <Menu>
                  <MenuButton as={Button} colorScheme="transparent" h={2} p={0}>
                    <MoreVertical className="text-primary" />
                  </MenuButton>
                  <MenuList>
                    <MenuItem>Version</MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assets;
