import { Button, Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';
import UserEffect from './usereeffect/UserEffect';

const AboutSection24 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="container mx-auto lg:px-12 md:px-8 lg:py-28 md:py-20 px-5 py-12">
      <div className="lg:flex md:flex  items-center sm-custdirection">
        <div className="lg:w-1/2 md:w-1/2 w-full">
          <p
            id="7426127951"
            className="dynamicStyle fsize16 sm-fsize14 font-medium textColor-light"
          >
            {data ? data['7426127951'] : 'Call for more'}
          </p>
          <h2
            id="6921626981"
            className="dynamicStyle lg:w-1/2 md:w-4/5 w-2/4 py-1 fsize34 md-fsize24 sm-fsize20 font-semibold text-primary cust-textColor"
          >
            {data ? data['6921626981'] : 'Go Travel. Discover. Remember Us !!'}
          </h2>
          <p
            id="4485695135"
            className="dynamicStyle textColor-light fsize16 sm-fsize14 mt-2 lg:mr-12 lg:leading-7 md:leading-7 leading-6"
            dangerouslySetInnerHTML={{
              __html: data ? data['4485695135'] : 'no-data',
            }}
          ></p>

          <Button
            colorScheme="primary"
            className="dynamicStyle lg:mt-8 md:mt-8 mt-6 theme-btn text-primary clr-fff fsize16 cust-textColor sm-fsize14 buttonAnimate bdr-2px"
            id="4093324675"
          >
            <span onClick={() => handleClick('4093324675')}>{data ? data['4093324675'] : 'Know More'}</span>
          </Button>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-6">
          <div ref={elementRef} className={`fade-right relative w-full h-400px sm-h300px ${isVisible ? 'visibleright' : ''} `}>
            <div  className=" absolute top-0 lg:right-16 right-8 flex items-center h-400px sm-h300px">
              <div id='9624553085' className="dynamicStyle2">
              <Image
                src={data ? getImage(data['9624553085']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="hero"
                className="h-300px sm-h250px w-400px sm-wid-90per ml-auto rounded-sm"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
              </div>
            </div>
            <div
              className={
                colorMode === 'light'
                  ? 'bg-primary ml-auto w-200px h-400px sm-h300px rounded-sm'
                  : 'bg-191919 ml-auto w-200px h-400px sm-h300px rounded-sm'
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection24;
