import { Image } from '@chakra-ui/react';
import React from 'react';
import Rating from 'react-rating';
import FeatherIcon from 'feather-icons-react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

const Testimonial15 = () => {
  return (
    <section className="bg-3d3d3d cust-bgColor">
      <div className="w-full lg:flex md:flex lg:gap-12 md:gap-12 gap-4 container mx-auto lg:py-16 md:py-16 lg:px-12 md:px-8 px-5">
        <div className="w-2/3 bg-fcfcfc2e p-4">
          <Swiper
            spaceBetween={20}
            className="mySwiper py-2"
            breakpoints={{
              1536: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              820: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
          >
            <div className="swiper-button  image-swiper-button-prevz bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-nextz bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
            <div className="flex gap-6 w-full">
              <div className="w-1/2">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  className="testimonial16-img"
                />
              </div>
              <div className="w-1/2 lg:py-8">
                <p className="fsize24 md-fsize20 sm-fsize18 font-medium clr-fff">
                  Lorem Ipsum
                </p>
                <div className="lg:mb-2">
                  <Rating
                    initialRating={3}
                    readonly={true}
                    emptySymbol={
                      <FeatherIcon
                        icon="star"
                        className="mr-1 md-star-18 sm-star-16 "
                        size={20}
                      />
                    }
                    fullSymbol={
                      <FeatherIcon
                        icon="star"
                        className="mr-1 fillstroke sg"
                        size={20}
                      />
                    }
                    fractions={2}
                  />
                </div>
                <p className="fsize16 clr-fff font-light">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Quibusdam deleniti nemo accusantium itaque molestiae quod
                  soluta quidem, laboriosam ex placeat?
                </p>
              </div>
            </div>
            </SwiperSlide>
            
          </Swiper>
        </div>
        <div className="w-1/3 lg:py-12">
          <h2 className="lg:pb-4 md:pb-3 pb-2 clr-fff fsize34 md-fsize24 sm-fsize20">
            What Our Client Says
          </h2>
          <p className="clr-fff fsize16 font-light">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iste ullam
            labore voluptatibus officia repellendus.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Testimonial15;
