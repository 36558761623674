import {
  Button,
  Card,
  FormControl,
  FormLabel,
  Image,
  Switch,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { handleScroll } from './fadeeffect/FadeEffect';

const SubscriptionPlan4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="">
      {/* <div
        className={
          colorMode === 'light'
            ? 'bg-grad lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
            : 'lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8'
        }
      >
        <div className="">
          <div className="lg:w-1/2 md:w-2/3 w-full mx-auto text-center lg:my-8 md:my-6 my-4">
            <h2 className="text-primary fsize36 md-fsize28 sm-fsize24 font-medium lg:mb-4 md:mb-3 mb-2 cust-textColor">
              Pricing Plans
            </h2>
            <p className="fsize16 sm-fsize14 textColor-light">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Hic sunt
              perspiciatis non, fugit totam eum incidunt facere consequuntur cum
              maxime.
            </p>
          </div>
        </div>
      </div> */}
      <div className="w-full lg:relative md:relative plan-h overflow-hidden">
        <div id="2808691396" className="w-full dynamicStyle2">
          <Image
            src={data ? getImage(data['2808691396']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="hero"
            className="plan-h object-cover lg:block md:block hidden"
          />
        </div>

        <div className="lg:absolute md:absolute top-0 left-0 plan-h ">
          <div className="container mx-auto lg:px-28 md:px-20 px-5 lg:py-20 md:py-16 py-8">
          <div className="mb-16">
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <FormLabel
                id="4637188811"
                htmlFor="email-alerts"
                mb="0"
                className="dynamicStyle fsize16 text-black cust-textColor"
              >
                {data ? data['4637188811'] : 'Monthly'}
              </FormLabel>
              <label className='toogle tog-true'>
                <input type='checkbox' className='toogle-checkbox' />
                <div className='toogle-btn'></div>
              </label>
              <FormLabel
                id="2734031313"
                htmlFor="email-alerts"
                ml="4"
                mb="0"
                className="dynamicStyle fsize16 text-black cust-textColor"
              >
                {data ? data['2734031313'] : 'Annually'}
              </FormLabel>
            </FormControl>
          </div>
          <div ref={elementRef} className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-y-0 lg:gap-x-0 md:gap-x-4 md:gap-y-4 gap-y-4 gap-x-0 text-center ${isVisible ? 'visiblebottom' : ''} `}>
            <Card
              borderRadius={2}
              className="bg-white p-8 border-1px planHover"
            >
              <h3 className="font-medium fsize30 sm-fsize22 text-black cust-textColor ">
                Enterprise
              </h3>
              <p className="text-glow fsize54 sm-fsize40 font-medium text-black cust-textColor lg:py-6 md:py-4 py-2">
                $99
              </p>
              <p className="w-2/3 mx-auto text-black font-medium textColor-light fsize18 sm-fsize16">
                {' '}
                Per Contribution / Monthly Billing
              </p>
              <div className="lg:my-8 md:my-8 my-4">
                <button
                  className={
                    colorMode === 'light'
                      ? 'planButton font-medium  text-black py-2 px-4'
                      : 'border-1px planButtonDark font-medium cust-bgColor text-black cust-textColor py-2 px-4'
                  }
                >
                  Get Enterprise Plan
                </button>
              </div>
              <ul className="m-0 p-0 ">
                <li className="flex items-center justify-center mb-2">
                  <span className="inline-block text-black textColor-light  textColor-light">
                    <FaCheck />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>

                <li className="flex items-center justify-center  mb-2">
                  <span className="inline-block text-black textColor-light  textColor-light">
                    <FaCheck />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>

                <li className="flex items-center justify-center  mb-2">
                  <span className="inline-block text-black textColor-light  textColor-light">
                    <FaCheck />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>

                <li className="flex items-center justify-center  mb-2">
                  <span className="inline-block text-black textColor-light textColor-light">
                    <FaTimes />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>
              </ul>
            </Card>
            <Card
              borderRadius={2}
              className="bg-white p-8  border-1px planHover2 planHover"
            >
              <h3 className="font-medium fsize30 sm-fsize22 text-black cust-textColor ">
                Enterprise
              </h3>
              <p className="text-glow fsize54 sm-fsize40 font-medium text-black cust-textColor lg:py-6 md:py-4 py-2">
                $99
              </p>
              <p className="w-2/3 mx-auto text-black font-medium textColor-light fsize18 sm-fsize16">
                {' '}
                Per Contribution / Monthly Billing
              </p>
              <div className="lg:my-8 md:my-8 my-4">
                <button
                  className={
                    colorMode === 'light'
                      ? 'planButton font-medium  text-black py-2 px-4'
                      : 'border-1px planButtonDark font-medium cust-bgColor text-black cust-textColor py-2 px-4'
                  }
                >
                  Get Enterprise Plan
                </button>
              </div>
              <ul className="m-0 p-0 ">
                <li className="flex items-center justify-center mb-2">
                  <span className="inline-block text-black textColor-light  textColor-light">
                    <FaCheck />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>

                <li className="flex items-center justify-center  mb-2">
                  <span className="inline-block text-black textColor-light  textColor-light">
                    <FaCheck />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>

                <li className="flex items-center justify-center  mb-2">
                  <span className="inline-block text-black textColor-light  textColor-light">
                    <FaCheck />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>

                <li className="flex items-center justify-center  mb-2">
                  <span className="inline-block text-black textColor-light textColor-light">
                    <FaTimes />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>
              </ul>
            </Card>
            <Card
              borderRadius={2}
              className="bg-white p-8  border-1px planHover"
            >
              <h3 className="font-medium fsize30 sm-fsize22 text-black cust-textColor ">
                Enterprise
              </h3>
              <p className="text-glow fsize54 sm-fsize40 font-medium text-black cust-textColor lg:py-6 md:py-4 py-2">
                $99
              </p>
              <p className="w-2/3 mx-auto text-black font-medium textColor-light fsize18 sm-fsize16">
                {' '}
                Per Contribution / Monthly Billing
              </p>
              <div className="lg:my-8 md:my-8 my-4">
                <button
                  className={
                    colorMode === 'light'
                      ? 'planButton font-medium  text-black py-2 px-4'
                      : 'border-1px planButtonDark font-medium cust-bgColor text-black cust-textColor py-2 px-4'
                  }
                >
                  Get Enterprise Plan
                </button>
              </div>
              <ul className="m-0 p-0 ">
                <li className="flex items-center justify-center mb-2">
                  <span className="inline-block text-black textColor-light  textColor-light">
                    <FaCheck />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>

                <li className="flex items-center justify-center  mb-2">
                  <span className="inline-block text-black textColor-light  textColor-light">
                    <FaCheck />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>

                <li className="flex items-center justify-center  mb-2">
                  <span className="inline-block text-black textColor-light  textColor-light">
                    <FaCheck />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>

                <li className="flex items-center justify-center  mb-2">
                  <span className="inline-block text-black textColor-light textColor-light">
                    <FaTimes />
                  </span>

                  <p className="textColor-light textColor-light mb-0 ml-5 text-black fsize16 sm-fsize14 font-semibold">
                    All Limited Links
                  </p>
                </li>
              </ul>
            </Card>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan4;
