import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Image, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const Team12 = ({ pageName }) => {

    const { colorMode, toggleColorMode } = useColorMode();
    const data = useSelector(state => state.cms.allSections[pageName]);
    let allTeam = useSelector(state => state.Teams.Teams);
    console.log(allTeam);
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
      <p id="8397064358"
          className="dynamicStyle fsize36 md-fsize32 sm-fsize20 font-semibold cust-textColor pl-2">
        {data ? data['8397064358'] : 'Team Structure'}
      </p>

      <div className="mt-4">
        <Swiper
        observer={true}
        observeParents={true}
        modules={[Navigation]}
        navigation={{
          nextEl: '.image-swiper-button-next',
          prevEl: '.image-swiper-button-prev',
        }}
          spaceBetween={30}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            820: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2.2,
              spaceBetween: 15,
            },
            640: {
              slidesPerView: 1.8,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1.8,
              spaceBetween: 12,
            },
            320: {
              slidesPerView: 1.9,
              spaceBetween: 12,
            },
          }}
        >
             {/* <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div> */}
          {allTeam && allTeam.length > 0
          ? allTeam.map(e => {
              return (
          <SwiperSlide className="">
            <div className='relative'>
            <Image
              src={`${process.env.REACT_APP_STORAGE_URL}${e.profilepicture}`}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              className="rounded-xl h-350px sm-h250px w-full object-cover"
            />
            <div 
             className={
                colorMode === 'light'
                  ? 'absl-team12 bg-f3f6f6 rounded-lg px-4 py-2 themeShadow-insert theme-bg cust-shadow'
                  : 'absl-team12 bg-f3f6f6 rounded-lg px-4 py-2 themeShadow theme-bg cust-bgColor'
              }>
                <p className='fsize18 md-fsize16 sm-fsize15 font-semibold cust-textColor'>{e.name}</p>
                <p className='sm-fsize14 textColor-light'>{e.designation}</p>
            </div>
            </div>
          </SwiperSlide>
          );
        })
      : 'No Data'}
        </Swiper>
      </div>
    </div>
  );
};

export default Team12;
