import React, { useEffect, useRef, useState } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import UserEffect from './usereeffect/UserEffect';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section>
      {' '}
      <div className="lg:flex md:flex container mx-auto sm-reverse lg:py-16 md:py-16 lg:px-12 md:px-8 px-5">
        <div className="lg:w-1/2 md:w-1/2 lg:py-12 md:py-0 py-6 relative zindex9 flex flex-col justify-center">
          <p
            className={
              colorMode === 'light'
                ? 'dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium text-primary '
                : 'dynamicStyle fsize16 md-fsize16 sm-fsize14 font-medium text-white'
            }
            id="2551488587"
          >
            {data ? data['2551488587'] : 'Lorem Ipsum'}
          </p>
          <h2
            id="3949541632"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 cust-textColor font-bold pt-2 lg:leading-12 md:leading-12 heading-lh leading-6"
          >
            {data ? data['3949541632'] : 'Lorem Ipsum'}
          </h2>
          <p
            id="1829268878"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['1829268878']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle fsize16 sm-fsize14 lg:mt-4 md:mt-4 mt-2 textColor-light lg:leading-7 md:leading-7 leading-6"
          ></p>

          <Button
            width="180px"
            height="50px"
            className="bg-primary cust-bgColor lg:mt-8 md:mt-8 mt-6 theme-btn zindex9 relative mt-6 textwhite sm-btntextwh dynamicStyle buttonAnimate bdr-2px"
            id="7567082298"
          >
            <span onClick={() => handleClick('7567082298')}>
              {data ? data['7567082298'] : 'Lorem Ipsum'}
            </span>
          </Button>
        </div>
        <div className="lg:w-1/2 md:w-1/2 lg:pl-8 md:pl-8  lg:mt-0 md:mt-0 mt-12">
          <div
            ref={elementRef}
            className={`fade-right w-full relative zindex9 ${
              isVisible ? 'visibleright' : ''
            } `}
          >
            <div id="0993103317" className="dynamicStyle2">
              <Image
                src={data ? getImage(data['0993103317']) : ''}
                alt="heroImage"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                className="w-full sm-h250px rounded-lg md-h-350px minh450px object-cover"
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection3;
