import { Button, Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection26 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
      <div className="lg:flex md:flex gap-10">
        <div className="lg:w-2/5 md:w-2/5 flex items-center lg:text-left md:text-left text-center">
          <div className="">
            <h2
              id="8875853016"
              className="dynamicStyle fsize56 md-fsize40 sm-fsize24 font-semibold text-primary cust-textColor"
            >
              {data ? data['8875853016'] : 'Our Clients'}
            </h2>
            <p
              id="5984871874"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['5984871874']
                  : 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
              }}
              className="dynamicStyle fsize18 md-fsize16 sm-fsize14 pt-2 textColor-light"
            >
              {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. */}
            </p>
            <hr className="lg:my-6 md:my-4 my-4 bdr-bottom-line md-bdr-bottom-line sm-bdr-bottom-line" />
            <Button
              px={8}
              py={6}
              variant="outline"
              id="7638497469"
              className={
                colorMode === 'light'
                  ? 'dynamicStyle bg-primary clr-fff bdr-0px sm-fsize14 theme-btn lg:mt-2 md:mt-2'
                  : 'dynamicStyle theme-btn  cust-textColor sm-fsize14 '
              }
            >
              <span onClick={() => handleClick('7638497469')}>
                {data ? data['7638497469'] : 'View More'}
              </span>
            </Button>
          </div>
        </div>
        <div className="lg:w-3/5 md:w-3/5 grid grid-cols-2 lg:gap-12 md:gap-8 gap-4 lg:pt-0 md:pt-0 pt-10">
          <div
            id="0875963964"
            className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
          >
            <Image
              className="h-180px sm-h-150px w-full rounded-3xl"
              src={data ? getImage(data['0875963964']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <div
            id="6558271122"
            className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
          >
            <Image
              className="h-180px sm-h-150px w-full rounded-3xl"
              src={data ? getImage(data['6558271122']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <div
            id="1674629836"
            className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
          >
            <Image
              className="h-180px sm-h-150px w-full rounded-3xl"
              src={data ? getImage(data['1674629836']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <div
            id="7721590849"
            className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
          >
            <Image
              className="h-180px sm-h-150px w-full rounded-3xl"
              src={data ? getImage(data['7721590849']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <div
            id="9089718966"
            className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
          >
            <Image
              className="h-180px sm-h-150px w-full rounded-3xl"
              src={data ? getImage(data['9089718966']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <div
            id="5222349064"
            className="dynamicStyle2 h-180px sm-h-150px w-full rounded-3xl"
          >
            <Image
              className="h-180px sm-h-150px w-full rounded-3xl"
              src={data ? getImage(data['5222349064']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection26;
