import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto lg:py-14 md:py-16 py-8">
      <div className="text-center  flex flex-col justify-center lg:mb-12 md:mb-12 mb-6 lg:p-0 md:p-0 p-6">
        <h3
          id="4555909181"
          className="dynamicStyle fise16 md-fsize16 sm-fsize14 font-medium text-primary cust-textColor"
        >
          {data ? data['4555909181'] : 'Lorem ipsum'}
        </h3>
        <h1
          id="7966782544"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold mb-2 cust-textColor"
        >
          {data ? data['7966782544'] : 'Lorem ipsum'}
        </h1>
        <div className="lg:w-2/3 md:w-2/3  mx-auto">
          <p
            id="1418872247"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['1418872247']
                : ' publishing and graphic design, Lorem ipsum is a placeholder text',
            }}
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 textColor-light text-center lg:px-8 md:px-6"
          ></p>
        </div>
      </div>
      <div id="4618848862" className="dynamicStyle">
        <div
          ref={elementRef}
          className={`fade-bottom   grid lg:grid-cols-2 md:grid-cols-2 lg:grid-cols-1 lg:mt-5 md:mt-5 w-full lg:gap-6 md:gap-8 gap-6 lg:px-12 md:px-8 px-5 ${
            isVisible ? 'visiblebottom' : ''
          } `}
        >
          {data && data['4618848862']
            ? data['4618848862'].map(e => {
                return (
                  <Card>
                    <div
                    className={
                      colorMode === 'light'
                        ? 'custShadow1 themeShadow-insert themeShadow theme-bg h-300px md-h-100 sm-h-full lg:flex lg:gap-8 md:gap-8 gap-4 justify-center items-center lg:py-8 md:py-8 py-4 lg:px-6 md:px-6 px-3 '
                        : 'custShadow1 themeShadow theme-bg h-300px md-h-100 sm-h-full lg:flex lg:gap-8 md:gap-8 gap-4 justify-center items-center lg:py-8 md:py-8 py-4 lg:px-6 md:px-6 px-3 '
                    }
                    >
                      <div className=" w-30 md-w-full lg:h-full md:h-auto flex justify-center items-center lg:mb-0 md:mb-0 mb-4 lg:mx-0 md:mx-0 mx-auto">
                        <Image
                          src={e ? getImage(e.field1) : ''}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                          alt="imagee"
                          className="lg:h-32 md:h-32 h-24 lg:w-32 md:w-32 w-24  object-cover"
                        />
                      </div>
                      <div className="w-70 md-w-100 lg:text-left md:text-left text-center">
                        <h2 className="text-start fsize24 sm-fsize18 lg:mb-4 md:my-4 mb-2 font-semibold cust-textColor">
                          {e.field2}
                        </h2>
                        {/* {e.field3} */}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: e.field3,
                          }}
                          className="text-start fsize16 sm-fsize14 textColor-light"
                        ></p>
                      </div>
                    </div>
                  </Card>
                );
              })
            : 'no-data'}
        </div>
      </div>
    </section>
  );
};

export default FeatureCard3;
