import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { handleScroll } from './fadeeffect/FadeEffect';

const HeroSection12 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className=" ">
      <div className=" container mx-auto  h-580px md-h-450 sm-h-auto">
        <div ref={elementRef} className={`fade-bottom h-580px md-h-450 sm-h-auto w-60 md-w-80 sm-wfull mx-auto flex flex-col justify-center items-center lg:px-0 md:px-0 px-5 ${isVisible ? 'visiblebottom' : ''} `}>
          <p
            id="7389880150"
            className="dynamicStyle fsize16 md-fsize16 sm-fsize14 letter-spacing4px font-medium text-primary cust-textColor"
          >
            {data ? data['7389880150'] : 'SBI Foundation'}
          </p>
          <h1
            id="4230572427"
            className="dynamicStyle fsize64 sm-fsize22 font-bold mb-4 text-center sm-lh-32px cust-textColor"
          >
            {data ? data['4230572427'] : 'SBI Foundation'}
          </h1>

          <p
            id="6246076629"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['6246076629']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14 text-center "
          ></p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection12;
