import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { handleScroll } from "./fadeeffect/FadeEffect";
import { Volume, Volume1, VolumeX } from "react-feather";
function HeroSectionVideo({ pageName }) {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  const handleVideoMute = () => {
    setIsMuted((state) => !state);
  };
  return (
    <div className="bg-secondary">
      <div className="relative ">
        <div className="relative">
          <video
            width="100%"
            loop
            autoPlay
            muted={isMuted}
            className=" herovideo-height"
          >
            <source
              src="https://nimbuscluster.blob.core.windows.net/server01/detailing_pal/home_banner.mp4"
              type="video/mp4"
            />
          </video>

          <button
            onClick={() => handleVideoMute()}
            class="absolute bottom-3 cust-colormic right-3 rounded-full"
          >
            <div class="flex justify-center m-auto">
              {isMuted ? (
                <VolumeX className=" w-6 h-6 text-white" />
              ) : (
                <Volume1 className=" w-6  h-6 text-white" />
              )}
            </div>
          </button>
        </div>

        {/* <div
          ref={elementRef}
          className={`fade-bottom absolute  h-full w-full bckdrop  top-0 left-0 flex sm-reverse sm-align-center lg:px-0 px-5 lg:py-0 md:py-8 py-6 items-center ${
            isVisible ? "visiblebottom" : ""
          } `}
        >
          <div className=" lg:px-20 md:px-8 lg:mt-0 md:mt-0 mt-4 text-center w-full h-full justify-center flex items-center">
            <div className="lg:w-3/4 md:w-3/4 w-full mx-auto">
              <h2
                id="0901954745"
                className="dynamicStyle fsize35 sm-fsize20  text-white  font-semibold"
              >
                {data ? data["0901954745"] : "Lorem Ipsum"}
              </h2>
              <p
                id="0202637486"
                className="dynamicStyle text-white fsize20 sm-fsize14 opacity-70 lg:mt-4 md:mt-4 mt-2 lg:leading-7 md:leading-7 leading-6"
                dangerouslySetInnerHTML={{
                  __html: data ? data["0202637486"] : "no-data",
                }}
              ></p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default HeroSectionVideo;
