import React from 'react';
import Banner from './components/Banner';
import BreadCumb from './components/BreadCumb';
import Gridcards from './components/Gridcards';

const Reviewer = () => {
  return (
    <div>
      <Banner />
      <BreadCumb />
      <Gridcards />
    </div>
  );
};

export default Reviewer;
