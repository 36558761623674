import { Button, Image, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const AboutSection25 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
      <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-8 pt-20">
        <div
          id="4505459523"
          className="dynamicStyle2 h-450px sm-h300px relative lg:pr-10"
        >
          <Image
            className="h-full w-full rounded-2xl"
            src={data ? getImage(data['4505459523']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="img"
          />
          <div className="bg-f4f4f4 absolute-mid w-80 p-4 rounded-lg cust-bgColor">
            <p
              id="7018403729"
              className="dynamicStyle fsize20 sm-fsize18 font-semibold lg:pb-2 md:pb-2 pb-1 cust-textColor"
            >
              {data ? data['7018403729'] : 'Architect'}
            </p>
            <p
              id="6123751134"
              dangerouslySetInnerHTML={{
                __html: data ? data['6123751134'] : 'no-data',
              }}
              className="dynamicStyle textColor-light md-fsize15 sm-fsize14"
            >
              {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio,
              debitis? */}
            </p>
          </div>
        </div>
        <div className="lg:pl-10">
          <h2
            id="4287071657"
            className="dynamicStyle fsize40 md-fsize30 sm-fsize24 font-semibold cust-textColor text-primary"
          >
            {data ? data['4287071657'] : 'Lorem ipsum dolor sit amet.'}
          </h2>
          <p
            id="5765773937"
            dangerouslySetInnerHTML={{
              __html: data ? data['5765773937'] : 'no-data',
            }}
            className="dynamicStyle py-4 sm-fsize14 textColor-light"
          >
            {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. Distinctio
            facere dignissimos aspernatur. Iure aspernatur. */}
          </p>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 lg:gap-10 md:gap-6 lg:py-4 md:py-4 py-2">
            <div>
              <p
                id="8598394968"
                className="dynamicStyle fsize32 md:fsize26 sm-fsize20 font-semibold lg:pb-4 md:pb-4 pb-2 cust-textColor"
              >
                {data ? data['8598394968'] : '10+'}
              </p>
              <p
                id="1656007197"
                className="dynamicStyle textColor-light sm-fsize14"
              >
                {data ? data['1656007197'] : 'Lorem ipsum dolor sit amet.'}
              </p>
            </div>
            <div>
              <p
                id="1335154759"
                className="dynamicStyle fsize32 md:fsize26 sm-fsize20 font-semibold lg:pb-4 md:pb-4 pb-2 cust-textColor"
              >
                {data ? data['1335154759'] : '5+'}
              </p>
              <p
                id="0057880329"
                className="dynamicStyle textColor-light sm-fsize14"
              >
                {data ? data['0057880329'] : 'Lorem ipsum dolor sit amet.'}
              </p>
            </div>
            <div>
              <p
                id="8946909013"
                className="dynamicStyle fsize32 md:fsize26 sm-fsize20 font-semibold lg:pb-4 md:pb-4 pb-2 cust-textColor"
              >
                {data ? data['8946909013'] : '500+'}
              </p>
              <p
                id="8659499098"
                className="dynamicStyle textColor-light sm-fsize14"
              >
                {data ? data['8659499098'] : 'Lorem ipsum dolor sit amet.'}
              </p>
            </div>
          </div>
          <div className="flex gap-4">
            <Button
              px={8}
              py={6}
              id="5079496182"
              className={
                colorMode === 'light'
                  ? 'dynamicStyle bg-primary theme-btn bdr-0px mt-6 sm-fsize14 clr-fff'
                  : 'dynamicStyle theme-btn bdr-0px mt-6 cust-textColor sm-fsize14 '
              }
            >
              <span onClick={() => handleClick('5079496182')}>
                {data ? data['5079496182'] : 'Button'}
              </span>
            </Button>
            <Button
              px={8}
              py={6}
              variant="outline"
              className={
                colorMode === 'light'
                  ? 'dynamicStyle theme-btn bdr-0px mt-6 sm-fsize14'
                  : 'dynamicStyle theme-btn cust-bgColor bdr-0px mt-6 cust-textColor sm-fsize14 '
              }
            >
              <span onClick={() => handleClick('3589890269')}>
                {data ? data['3589890269'] : 'Button'}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection25;
