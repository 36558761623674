import { Image } from '@chakra-ui/react';
import React, { useState } from 'react';
import ApprovalResults from './ApprovalResults';
import NearbyDueDate from './NearbyDueDate';
import ApprovalFiles from './ApprovalFiles';
import RejectedFiles from './RejectedFiles';

const ReviewerTabs = () => {
  const [tab, setTab] = useState('01');
  const tabOptions = [
    {
      id: '01',
      name: 'Pending Approvals',
      desc: 'Microsoft word file',
      img: 'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      id: '02',
      name: 'Nearing Due Date',
      desc: 'Microsoft word file',
      img: 'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      id: '03',
      name: 'Approved Files',
      desc: 'Microsoft word file',
      img: 'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      id: '04',
      name: 'Rejected Files',
      desc: 'Microsoft word file',
      img: 'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-18 py-6">
      <div className="w-full grid grid-cols-5 gap-4">
        {tabOptions.map(tab => (
          <div
            key={tab.id}
            className={
              tab === tab.id
                ? ' bg-white cust-shadow  p-4 rounded-lg cursor-pointer'
                : ' border p-4 rounded-lg cursor-pointer'
            }
            onClick={() => setTab(tab.id)}
          >
            <div className="w-24 h-24 mx-auto">
              <Image src={tab.img} alt={tab.name} className="h-20 w-full" />
            </div>
            <div className="mt-2 text-center">
              <h1 className="text-primary fsize18 sm-fsize15 sm-fsize16">
                {tab.name}
              </h1>
              <p className="fsize14">{tab.desc}</p>
            </div>
          </div>
        ))}
      </div>
      <div>
        {tab === '01' ? <ApprovalResults /> : ''}
        {tab === '02' ? <NearbyDueDate /> : ''}
        {tab === '03' ? <ApprovalFiles /> : ''}
        {tab === '04' ? <RejectedFiles /> : ''}
      </div>
    </div>
  );
};

export default ReviewerTabs;
