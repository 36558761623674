import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import React from 'react';
import FeatherIcon from 'feather-icons-react';

const BreadCumb = () => {
  return (
    <div className="bg-053c6d lg:px-28 md:px-8 px-5 lg:py-8 lg:flex md:flex justify-between items-center md:py-6 py-8">
      <div>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="#" className="textwhite sm-fsize12">
              CLG Use Directory
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#" className="textwhite sm-fsize12">
              Knowledge Management Portal
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#" className="textwhite sm-fsize12">
              Assets
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div>
        <Button className="bg-white text-primary lg:mt-0 md:mt-0 mt-3 sm-fsize13">
          <FeatherIcon icon="download" mr={1} size="16" className="mr-1" />
          Upload
        </Button>
      </div>
    </div>
  );
};

export default BreadCumb;
