import React, { useEffect, useRef, useState } from 'react';
import { Image, Button, useColorMode } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux/es/exports';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from '../../components/fadeeffect/FadeEffect';
import {
  getAllCourses,
  selectedCourse,
} from '../../../../redux/elearningProduct/products-action';
import { useDispatch } from 'react-redux';

const ProductSection4 = ({ pageName }) => {
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
    // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const getAllCourse = useSelector(state => state.elearningProduct.Course);
  console.log(getAllCourse, 'getAllCourse');
  const selectedCourseData = useSelector(
    state => state.elearningProduct.selectedCourse
  );
  console.log(selectedCourseData, 'selectedCourseData');
  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);
  const navigate = useNavigate();
  const link = 'https://example.com';
  let selectedStyle = useSelector(
    state => state.brandDetails.brandProfile.businessThemeVariation
  );
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const handleProduct = course => {
    navigate('/elearning-productdetails');
    dispatch(selectedCourse(course));
  };

  return (
    <div
      className={
        (colorMode === 'light' ? 'bg-f8f8f8' : '') +
        ' ' +
        (selectedStyle && colorMode !== 'dark' ? 'theme-bg' : '')
      }
    >
      <div className="lg:px-12 md:px-8 px-5 lg:py-20 md:py-16 py-8 container mx-auto">
        <div className="w-60 sm-wfull mx-auto text-center lg:mb-8 md:mb-8 mb-4">
          <h2
            id="5439610828"
            className="dynamicStyle font-semibold text-primary cust-textColor fsize34 md-fsize24 sm-fsize20  relative inline-block"
          >
            {data ? data['5439610828'] : 'Lorem Ipsum'}
          </h2>

          <p
            id="8249626393"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['8249626393']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
            className="dynamicStyle fsize16 sm-fsize14 mt-2 textColor-light"
          ></p>
        </div>
        <div
          ref={elementRef}
          className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
        >
          <Swiper
            spaceBetween={30}
            observer={true}
            observeParents={true}
            className="mySwiper py-2"
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 2,
                spaceBetween: 12,
              },
              820: {
                slidesPerView: 2.3,
                spaceBetween: 16,
              },
              425: {
                slidesPerView: 1.1,
                spaceBetween: 12,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 12,
              },
            }}
          >
            {getAllCourse &&
            getAllCourse.result &&
            getAllCourse.result.length > 0
              ? getAllCourse.result.map((course, index) => {
                  return (
                    <SwiperSlide>
                      <div
                        key={index}
                        className={
                          (colorMode === 'light'
                            ? 'rounded-lg cursor-pointer theme-radius themeShadow'
                            : 'cust-bgColor rounded-lg  cursor-pointer') +
                          ' ' +
                          (selectedStyle === 'Default' && colorMode !== 'dark'
                            ? 'cust-shadow'
                            : '')
                        }
                        onClick={() => handleProduct(course)}
                      >
                        <div className="relative image-product overflow-hidden rounded-t theme-productDesign">
                          <Image
                            className="img-h-220 w-full opct themeimg-radius"
                            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                            src={`${process.env.REACT_APP_STORAGE_URL}${course.image}`}
                            alt="img"
                          />
                          <div className="absolute left-0 top-0 flex gap-2 p-2 bg-fff">
                            <div className="">
                              <p className="fsize14 textColor-light">4.5/5</p>
                            </div>
                            <div>
                              <FeatherIcon
                                className="filled-blk "
                                icon="star"
                                size={18}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="p-4">
                          <div className="flex justify-between  text-b2b2b2">
                            <p className="fsize14 font-medium">12 Lessons</p>
                            <p className=" fsize14  font-medium">
                              {course.totalhours} hours
                            </p>
                          </div>
                          <div className="py-3">
                            <p className="fsize16 sm-fsize16 font-semibold textColor-light lineClamp1">
                              {course['course name']}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <p className="fsize18 sm-fsize14 font-semibold cust-textColor">
                              ₹ {course['actual rate']}/-
                            </p>
                            <div className="flex gap-4">
                              <FeatherIcon
                                className=" cust-textColor"
                                icon="heart"
                                size={20}
                              />
                              <FeatherIcon
                                className="cust-textColor "
                                icon="shopping-cart"
                                size={20}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              : 'no-data'}
          </Swiper>
        </div>
        <div className="flex justify-center pt-12">
          <Button
            className={
              selectedStyle
                ? 'dynamicStyle cust-bgColor  theme-btn  clr-fff  bdr-2px'
                : 'dynamicStyle cust-bgColor  clr-fff buttonAnimate bdr-2px'
            }
            id="1415956570"
            px="10"
            py="6"
            colorScheme="primary"
          >
            <span onClick={() => handleClick('1415956570')}>
              {data ? data['1415956570'] : 'Lorem Ipsum'}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductSection4;
