import React from 'react';
import { useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const FeatureCard19 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className={colorMode === 'light' ? 'bg-3d3d3d theme-bg' : 'cust-bgColor'}>
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
        <div className="w-3/4 mx-auto text-center">
          <h3 id="9669239642"
            className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold text-primary text-white">
            {data ? data['9669239642'] : 'Lorem ipsum dolor sit'}
          </h3>
        </div>
        <div  id="5118232884"
          className="dynamicStyle grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 lg:mt-12 md:mt-12 mt-6">
        {data && data['5118232884']
            ? data['5118232884'].map((e, index) => {
                return (
          <div className="p-6 bdr-gray-2px rounded-lg theme-border-0 themeShadow themeShadow-insert">
            <p
              className={
                colorMode === 'light'
                  ? 'text-white fsize18 font-semibold pb-4 pt-8 theme-textColor'
                  : 'fsize18 font-semibold pb-4 pt-8 cust-textColor '
              }
            >
              {e.field1}
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray-400 sm-fsize14'
                  : 'textColor-light sm-fsize14'
              }
              dangerouslySetInnerHTML={{
                __html: e.field2,
              }}
            >
              {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus nihil et culpa maxime.
              Necessitatibus, sunt. */}
            </p>
          </div>
          );
        })
      : 'no-card'}
          {/* <div className="p-6 bdr-gray-2px rounded-lg">
            <p
              className={
                colorMode === 'light'
                  ? 'text-white fsize18 font-semibold pb-4 pt-8'
                  : 'fsize18 font-semibold pb-4 pt-8 cust-textColor'
              }
            >
              Lorem Ipsum
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray-400 sm-fsize14'
                  : 'textColor-light sm-fsize14'
              }
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus nihil et culpa maxime.
              Necessitatibus, sunt.
            </p>
          </div>
          <div className="p-6 bdr-gray-2px rounded-lg">
            <p
              className={
                colorMode === 'light'
                  ? 'text-white fsize18 font-semibold pb-4 pt-8'
                  : 'fsize18 font-semibold pb-4 pt-8 cust-textColor'
              }
            >
              Lorem Ipsum
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray-400 sm-fsize14'
                  : 'textColor-light sm-fsize14'
              }
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus nihil et culpa maxime.
              Necessitatibus, sunt.
            </p>
          </div>
          <div className="p-6 bdr-gray-2px rounded-lg">
            <p
              className={
                colorMode === 'light'
                  ? 'text-white fsize18 font-semibold pb-4 pt-8'
                  : 'fsize18 font-semibold pb-4 pt-8 cust-textColor'
              }
            >
              Lorem Ipsum
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray-400 sm-fsize14'
                  : 'textColor-light sm-fsize14'
              }
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus nihil et culpa maxime.
              Necessitatibus, sunt.
            </p>
          </div>
          <div className="p-6 bdr-gray-2px rounded-lg">
            <p
              className={
                colorMode === 'light'
                  ? 'text-white fsize18 font-semibold pb-4 pt-8'
                  : 'fsize18 font-semibold pb-4 pt-8 cust-textColor'
              }
            >
              Lorem Ipsum
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray-400 sm-fsize14'
                  : 'textColor-light sm-fsize14'
              }
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus nihil et culpa maxime.
              Necessitatibus, sunt.
            </p>
          </div>
          <div className="p-6 bdr-gray-2px rounded-lg">
            <p
              className={
                colorMode === 'light'
                  ? 'text-white fsize18 font-semibold pb-4 pt-8'
                  : 'fsize18 font-semibold pb-4 pt-8 cust-textColor'
              }
            >
              Lorem Ipsum
            </p>
            <p
              className={
                colorMode === 'light'
                  ? 'text-gray-400 sm-fsize14'
                  : 'textColor-light sm-fsize14'
              }
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              eveniet! Necessitatibus, eius hic accusamus nihil et culpa maxime.
              Necessitatibus, sunt.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard19;
