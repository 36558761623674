import React, { useEffect, useRef, useState } from 'react';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Image } from '@chakra-ui/react';
// import 'swiper/css';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux/es/exports';
import Marquee from 'react-fast-marquee';

const Clients3 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const Client = [
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
    {
      img: 'https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
    },
  ];
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);

  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 4.5,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          speed: 4000,
          autoplaySpeed: 4000,
          cssEase: 'linear',
        },
      },
    ],
  };

  return (
    <div><div className="w-full">
    <div className='flex justify-center items-center'>
      <div>
        
      </div>
    <div className='bdr-top lg:w-1/5 md:w-1/5 mob-hidden relative'>
      <div className='hr-dot'>

      </div>
    </div>
    <h2
      id="5369058283"
      className="dynamicStyle lg:w-3/5 md:w-3/5 w-4/5 pb-2 fsize24 md-fsize24 sm-fsize18 text-center font-semibold cust-textColor"
    >
      {data ? data['5369058283'] : ' Our Clients'}
    </h2>
    <div className='bdr-top lg:w-1/5 md:w-1/5 mob-hidden relative'>
    <div className='hr-dot1'>

</div>
    </div>
    </div>
    <p
      id="5186270664"
      className="dynamicStyle fsize16 md-fsize16 sm-fsize14 text-center textColor-light"
      dangerouslySetInnerHTML={{
        __html: data
          ? data['5186270664']
          : 'We are united by our passion for innovation and our commitment',
      }}
    ></p>
  </div>
    <div className="container mx-auto lg:py-16 md:py-16 py-12 lg:px-12 md:px-8 px-5">
      
      <div
        ref={elementRef}
        className={`fade-bottom ${isVisible ? 'visiblebottom' : ''} `}
      >
        <div id="0339448071" className="dynamicStyle lg:mt-12 md:mt-12 mt-8">
        <Marquee loop={0} autoFill={true}>
            {data && data['0339448071']
              ? data['0339448071'].map(e => {
                  return (
                    <div className='mx-2'>
                      <Image
                      src={e ? getImage(e.field1) : ''}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="img"
                      className="rounded-md img-ratio lg:h-40 md:h-32 h-24"
                    />
                    </div>
                  );
                })
              : 'no-data'}
          </Marquee>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Clients3;
