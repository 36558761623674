import React, { useState } from 'react';
import { Image } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';

import {
  getElementById,
  updateCms,
  updateSectionsCss,
  updateSectionsClass,
  updateSections,
  updateImage,
  uploadImageToCloud,
} from '../redux/cms/cms-actions';
import { useDispatch, useSelector } from 'react-redux/es/exports';

const Typography = props => {
  const dispatch = useDispatch();
  const [resetData, setResetData] = useState(false);
  const [isData, setIsData] = useState(false);
  const [styling, setstyling] = useState({});
  const [styleString, setStyleString] = useState('');
  const [classObj, setclassObj] = useState({});
  const [classString, setClassString] = useState('');
  const [borderRadius, setBorderRadius] = useState([0, 0, 0, 0]);
  const [borderWidth, setBorderWidth] = useState(0);
  const [normalClass, setNormalClass] = useState({
    'border-radius': [0, 0, 0, 0],
    border: 1,
    'border-color': '',
  });
  const [hoverlClass, setHoverlClass] = useState({
    'background-color': '',
    color: '',
  });
  const [dataMounted, setDataMounted] = useState(false);
  let cssData = useSelector(state => state.cms.allSectionsCss);

  //<==================Reset Fields================>
  if (!resetData) {
    setclassObj({});
    setNormalClass({
      'border-radius': [0, 0, 0, 0],
      border: 1,
      'border-color': '',
    });
    setHoverlClass({
      'background-color': '',
      color: '',
    });
    setResetData(true);
  }

  //<==================For Single Css================>
  async function handleCssInput(event, index) {
    let newData = props.sectionsData;

    let allStyle = [];
    allStyle = styleString.split(';');
    let stylingData = '';

    let newStyle = [];
    let RegexString = new RegExp(`^${index}`);
    // console.log(
    //   allStyle,
    //   RegexString,
    //   allStyle.some(x => RegexString.test(x)),
    //   'styleeee'
    // );

    let data = '';
    let postFix = '';
    let styleProperties = [
      'margin-top',
      'margin-right',
      'margin-left',
      'margin-bottom',
      'padding-left',
      'padding-right',
      'padding-top',
      'padding-bottom',
    ];

    let buttonProperties = [
      'radius-top',
      'radius-right',
      'radius-left',
      'radius-bottom',
    ];
    if (styleProperties.includes(index)) {
      data = event.target.value;
      postFix = 'px';
    } else if (index === 'color') {
      data = event.target.value;
      postFix = '';
    } else {
      data = event;
      postFix = '';
    }

    if (allStyle.some(x => RegexString.test(x))) {
      for (let i = 0; i < allStyle.length; i++) {
        if (allStyle[i] && RegexString.test(allStyle[i])) {
          allStyle[i] = index + ':' + data + postFix;
        }
      }
      stylingData = stylingData + allStyle.join(';');
    } else {
      if (styleProperties.includes(index)) {
        stylingData = styleString + index + ':' + event.target.value + 'px;';
      } else if (index === 'color') {
        stylingData = styleString + index + ':' + event.target.value + ';';
      } else {
        stylingData = styleString + index + ':' + event + ';';
      }
    }

    let styles = styling;
    styles[index] = data;

    setstyling(styles);
    setStyleString(stylingData);

    newData.css = stylingData;
    props.updateProps(newData);

    let payload = {
      section: newData.section,
      elementId: newData.fieldId,
      css: stylingData,
      pageName: newData.pageName,
    };
    await dispatch(updateSectionsCss(payload));
  }

  async function handleClassInput(event, index) {
    let newData = props.sectionsData;

    let buttonProperties = [
      'radius-top-left',
      'radius-top-right',
      'radius-bottom-left',
      'radius-bottom-right',
    ];
    let classOb = classObj;
    classOb[`.dynamic_${props.sectionsData.fieldId}`] = {};
    classOb[`.dynamic_${props.sectionsData.fieldId}:hover`] = {};

    if (buttonProperties.includes(index)) {
      if (index === 'radius-top-left') {
        let temp = normalClass;
        temp['border-radius'][0] = event.target.value;
        setNormalClass(temp);
      }
      if (index === 'radius-top-right') {
        let temp = normalClass;
        temp['border-radius'][1] = event.target.value;
        setNormalClass(temp);
      }
      if (index === 'radius-bottom-right') {
        let temp = normalClass;
        temp['border-radius'][2] = event.target.value;
        setNormalClass(temp);
      }
      if (index === 'radius-bottom-left') {
        let temp = normalClass;
        temp['border-radius'][3] = event.target.value;
        setNormalClass(temp);
      }
    } else if (['border'].includes(index)) {
      let temp = normalClass;
      temp['border'] = event.target.value;

      setNormalClass(temp);
    } else if (['color', 'background-color'].includes(index)) {
      let temp = hoverlClass;
      temp[index] = event.target.value;
      setHoverlClass(temp);
    }

    classOb[`.dynamic_${props.sectionsData.fieldId}`][
      'border-radius'
    ] = `${normalClass['border-radius'][0]}px ${normalClass['border-radius'][1]}px ${normalClass['border-radius'][2]}px ${normalClass['border-radius'][3]}px !important;`;
    classOb[`.dynamic_${props.sectionsData.fieldId}`][
      'border'
    ] = `${normalClass['border']}px solid;`;
    classOb[`.dynamic_${props.sectionsData.fieldId}:hover`]['color'] =
      hoverlClass['color'] + '!important' + ';';
    classOb[`.dynamic_${props.sectionsData.fieldId}:hover`][
      'background-color'
    ] = hoverlClass['background-color'] + '!important' + ';';

    // classOb[`.dynamic_${props.sectionsData.fieldId}`] = normalClass;
    // classOb[`.dynamic_${props.sectionsData.fieldId}:hover`] = hoverlClass;
    let keys = Object.keys(classOb);
    let classStringTemp = '';
    for (let i = 0; i < keys.length; i++) {
      classStringTemp =
        classStringTemp + `${keys[i]}${JSON.stringify(classOb[keys[i]])}`;
    }

    setclassObj(classOb);
    setClassString(classStringTemp);
    let tempClasOb = {};
    tempClasOb[`.dynamic_${props.sectionsData.fieldId}`] = normalClass;
    tempClasOb[`.dynamic_${props.sectionsData.fieldId}:hover`] = hoverlClass;
    newData['class'] = classStringTemp;
    newData['classRef'] = tempClasOb;
    props.updateProps(newData);
    let payload = {
      section: props.sectionsData.section,
      elementId: props.sectionsData.fieldId,
      class: classStringTemp,
      classRef: tempClasOb,
      pageName: props.sectionsData.pageName,
    };
    await dispatch(updateSectionsClass(payload));
  }

  if (!styleString && !Object.keys(styling).length && !!props.sectionsData) {
    let styleString = props.sectionsData.css;
    let styleObj = {};
    if (styleString) {
      setStyleString(props.sectionsData.css);
      let styleArray = styleString.split(';');

      if (styleArray) {
        for (let i = 0; i < styleArray.length; i++) {
          if (styleArray[i]) {
            let keyValue = styleArray[i].split(':');
            keyValue[1] = keyValue[1].includes('px')
              ? keyValue[1].split('px')[0]
              : keyValue[1];
            styleObj[keyValue[0]] = keyValue[1];
          }
        }
      }
      if (!!styleObj) setstyling(styleObj);
    }
  }

  if (
    !!props.sectionsData &&
    props.sectionsData.class &&
    props.sectionsData.classRef &&
    !dataMounted
  ) {
    let checkHover = /:hover/;
    let tempOb = props.sectionsData.classRef;
    let keys = Object.keys(tempOb);

    for (let i = 0; i < keys.length; i++) {
      if (checkHover.test(keys[i])) {
        setHoverlClass(tempOb[keys[i]]);
      } else {
        setNormalClass(tempOb[keys[i]]);
      }
    }
    setDataMounted(true);
    let classObj1 = {};
    classObj1[`.dynamic_${props.sectionsData.fieldId}`] = {};
    classObj1[`.dynamic_${props.sectionsData.fieldId}:hover`] = {};
    setclassObj(classObj1);
  } else if (!Object.keys(classObj).length) {
    let classObj1 = classObj;
    classObj1[`.dynamic_${props.sectionsData.fieldId}`] = {};
    classObj1[`.dynamic_${props.sectionsData.fieldId}:hover`] = {};
    setclassObj(classObj1);
  }

  return (
    <div className=" rounded-lg p-6 sidecomponent-scroll">
      <div className="p-6 bg-white rounded-lg">
        <div>
          <h3 className="fsize16 font-semibold text-3d3b3b">Styling</h3>
          <div className="mt-5">
            <div className="flex justify-between w-full mt-2 px-1">
              <div className="flex items-center">
                {' '}
                <p className="fsize14  flex items-center">Padding </p>
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/admin/image/web.png"
                  alt="image"
                  className="ml-2 w-5"
                />
              </div>
              <p className="fsize14 ">px</p>
            </div>
            <div className="grid grid-cols-5 rounded-lg mt-2">
              <input
                placeholder="0"
                className="input-lightgray"
                value={styling['padding-top']}
                onChange={event => {
                  handleCssInput(event, 'padding-top');
                }}
              />

              <input
                placeholder="0"
                className="input-lightgray"
                value={styling['padding-right']}
                onChange={event => {
                  handleCssInput(event, 'padding-right');
                }}
              />

              <input
                placeholder="0"
                className="input-lightgray"
                value={styling['padding-bottom']}
                onChange={event => {
                  handleCssInput(event, 'padding-bottom');
                }}
              />

              <input
                placeholder="0"
                className="input-lightgray"
                value={styling['padding-left']}
                onChange={event => {
                  handleCssInput(event, 'padding-left');
                }}
              />

              <div>
                <div className="iconsetshre bg-eb flex justify-center items-center h-full">
                  <FeatherIcon icon="link" size={18} />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-5 mt-2 ">
              <p className="fsize14 text-gray text-center">TOP</p>
              <p className="fsize14 text-gray text-center">RIGHT</p>
              <p className="fsize14 text-gray text-center">BOTTOM</p>
              <p className="fsize14 text-gray text-center">LEFT</p>
            </div>
          </div>
          <div className="mt-5">
            <div className="flex justify-between w-full mt-2 px-1">
              <div className="flex items-center">
                {' '}
                <p className="fsize14  flex items-center">Margin </p>
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/admin/image/web.png"
                  alt="image"
                  className="ml-2 w-5"
                />
              </div>
              <p className="fsize14 ">px</p>
            </div>
            <div className="grid grid-cols-5 rounded-lg mt-2">
              <input
                placeholder="0"
                className="input-lightgray"
                value={styling['margin-top']}
                onChange={event => {
                  handleCssInput(event, 'margin-top');
                }}
              />

              <input
                placeholder="0"
                className="input-lightgray"
                value={styling['margin-right']}
                onChange={event => {
                  handleCssInput(event, 'margin-right');
                }}
              />

              <input
                placeholder="0"
                className="input-lightgray"
                value={styling['margin-bottom']}
                onChange={event => {
                  handleCssInput(event, 'margin-bottom');
                }}
              />

              <input
                placeholder="0"
                className="input-lightgray"
                value={styling['margin-left']}
                onChange={event => {
                  handleCssInput(event, 'margin-left');
                }}
              />

              <div>
                <div className="iconsetshre bg-eb flex justify-center items-center h-full">
                  <FeatherIcon icon="link" size={18} />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-5 mt-2 ">
              <p className="fsize14 text-gray text-center">TOP</p>
              <p className="fsize14 text-gray text-center">RIGHT</p>
              <p className="fsize14 text-gray text-center">BOTTOM</p>
              <p className="fsize14 text-gray text-center">LEFT</p>
            </div>
          </div>
          <div className="mt-5">
            <div className="flex  w-full mt-2 px-1">
              <div className="flex items-center">
                {' '}
                <p className="fsize14  flex items-center">Alignment </p>
                <Image
                  src="https://nexuscluster.blob.core.windows.net/server01/admin/image/web.png"
                  alt="image"
                  className="ml-2 w-5"
                />
              </div>
            </div>
            <div className="grid grid-cols-5 rounded-lg mt-2">
              <div
                className={`custborder-1 p-3 hoverbg-primary  flex justify-center items-center h-full ${
                  styling['text-align'] === 'right' ? 'bg-primary' : ''
                }`}
                id="align-right"
                onClick={() => handleCssInput('right', 'text-align')}
              >
                <FeatherIcon icon="align-right" size={18} />
              </div>
              <div
                className={`custborder-1 p-3 hoverbg-primary  flex justify-center items-center h-full ${
                  styling['text-align'] === 'center' ? 'bg-primary' : ''
                }`}
                id="align-center"
                onClick={() => handleCssInput('center', 'text-align')}
              >
                <FeatherIcon icon="align-center" size={18} />
              </div>
              <div
                className={`custborder-1 p-3 hoverbg-primary  flex justify-center items-center h-full ${
                  styling['text-align'] === 'left' ? 'bg-primary' : ''
                }`}
                id="align-left"
                onClick={() => handleCssInput('left', 'text-align')}
              >
                <FeatherIcon icon="align-left" size={18} />
              </div>
              <div
                className={`custborder-1 p-3 hoverbg-primary  flex justify-center items-center h-full ${
                  styling['text-align'] === 'justify' ? 'bg-primary' : ''
                }`}
                id="align-justify"
                onClick={() => handleCssInput('justify', 'text-align')}
              >
                <FeatherIcon icon="align-justify" size={18} />
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full mt-10 px-1">
            <div className="flex items-center">
              {' '}
              <p className="fsize14  flex items-center">Typography </p>
            </div>
            <div
              onClick={() => props.setisShowTypoFunc(true)}
              className="rounded-md custborder-1 p-3  flex justify-center items-center h-full"
            >
              <FeatherIcon icon="edit" className="cursor-pointer" size={18} />
            </div>
          </div>
          <div className="flex justify-between w-full mt-10 px-1">
            <div className="flex items-center">
              {' '}
              <p className="fsize14  flex items-center">Text Color </p>
            </div>
            <div className="   flex justify-center items-center h-full">
              <input
                type="color"
                placeholder="0"
                className="h-12 rounded-md"
                value={styling['color']}
                onInput={event => {
                  handleCssInput(event, 'color');
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {props.sectionsData && props.sectionsData.contentType === 'Button' ? (
        <div className="p-6 bg-white rounded-lg mt-4">
          <div>
            <h3 className="fsize16 font-semibold text-3d3b3b">
              Background & Borders
            </h3>
            <div className="mt-5">
              <div className="flex justify-between w-full mt-2 px-1">
                <div className="flex items-center">
                  {' '}
                  <p className="fsize14  flex items-center">Border Width </p>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/admin/image/web.png"
                    alt="image"
                    className="ml-2 w-5"
                  />
                </div>
                <p className="fsize14 ">px</p>
              </div>
              <div className="grid grid-cols-5 rounded-lg mt-2">
                <input
                  placeholder="0"
                  className="input-lightgray"
                  value={normalClass['border']}
                  onChange={event => {
                    handleClassInput(event, 'border');
                  }}
                />

                <div>
                  <div className="iconsetshre bg-eb flex justify-center items-center h-full">
                    <FeatherIcon icon="link" size={18} />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="flex justify-between w-full mt-2 px-1">
                <div className="flex items-center">
                  {' '}
                  <p className="fsize14  flex items-center">Border Radius </p>
                  <Image
                    src="https://nexuscluster.blob.core.windows.net/server01/admin/image/web.png"
                    alt="image"
                    className="ml-2 w-5"
                  />
                </div>
                <p className="fsize14 ">px</p>
              </div>
              <div className="grid grid-cols-5 rounded-lg mt-2">
                <input
                  placeholder="0"
                  className="input-lightgray"
                  value={normalClass['border-radius'][0]}
                  onInput={event => {
                    handleClassInput(event, 'radius-top-left');
                  }}
                />

                <input
                  placeholder="0"
                  className="input-lightgray"
                  value={normalClass['border-radius'][1]}
                  onChange={event => {
                    handleClassInput(event, 'radius-top-right');
                  }}
                />

                <input
                  placeholder="0"
                  className="input-lightgray"
                  value={normalClass['border-radius'][2]}
                  onChange={event => {
                    handleClassInput(event, 'radius-bottom-right');
                  }}
                />

                <input
                  placeholder="0"
                  className="input-lightgray"
                  value={normalClass['border-radius'][3]}
                  onChange={event => {
                    handleClassInput(event, 'radius-bottom-left');
                  }}
                />

                <div>
                  <div className="iconsetshre bg-eb flex justify-center items-center h-full">
                    <FeatherIcon icon="link" size={18} />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-5 mt-2 ">
                <p className="fsize14 text-gray text-center">TOP</p>
                <p className="fsize14 text-gray text-center">RIGHT</p>
                <p className="fsize14 text-gray text-center">BOTTOM</p>
                <p className="fsize14 text-gray text-center">LEFT</p>
              </div>
            </div>
            <div className="flex justify-between w-full mt-10 px-1">
              <div className="flex items-center">
                {' '}
                <p className="fsize14  flex items-center">Hover Text Color </p>
              </div>
              <div className="   flex justify-center items-center h-full">
                <input
                  type="color"
                  placeholder="0"
                  className="h-12 rounded-md"
                  value={hoverlClass['color']}
                  onInput={event => {
                    handleClassInput(event, 'color');
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between w-full mt-10 px-1">
              <div className="flex items-center">
                {' '}
                <p className="fsize14  flex items-center">
                  Hover Background Color{' '}
                </p>
              </div>
              <div className="   flex justify-center items-center h-full">
                <input
                  type="color"
                  placeholder="0"
                  className="h-12 rounded-md"
                  value={hoverlClass['background-color']}
                  onInput={event => {
                    handleClassInput(event, 'background-color');
                  }}
                />
              </div>
            </div>
            <div className="flex items-center w-full mt-10 px-1">
              <div className="flex items-center w-6/12">
                {' '}
                <p className="fsize14  flex items-center">Hover Animation</p>
              </div>
              <div className="w-6/12  flex justify-center items-center h-full">
                {' '}
                <select
                  aria-label="Default select example"
                  className="p-2 border rounded-lg w-full"
                >
                  <option>Grow</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Typography;
