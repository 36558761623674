import {
  Button,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalHeader,
  // ModalCloseButton,
  // ModalBody,
  // ModalFooter,
  // FormControl,
  // FormLabel,
  // Select,
  // Textarea,
  // Input,
  // InputGroup,
  // InputRightElement,
  // Popover,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverBody,
  Image,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
// import FeatherIcon from 'feather-icons-react';
// import { FaUserCheck, FaRegThumbsUp } from 'react-icons/fa';

// import { Navigation } from 'swiper/modules';
// import ReactStars from 'react-rating-stars-component';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { setIsEditUserProfile } from '../../../../redux/auth/auth-action';

// import Facebook from '../../../../assets/images/custom/fb.png';
// import Youtube from '../../../../assets/images/custom/youTube.png';
// import X from '../../../../assets/images/custom/x.png';
// import Instagram from '../../../../assets/images/custom/instagram.png';
import UserEditProfile1 from './UserEditProfile1';
// import { getTestimonial } from 'redux/custom/testimonial/testimonial-actions';
const UserProfilePage1 = () => {
  const dispatch = useDispatch();

  const [selectedTrainer, setSelectedTrainer] = useState({});
  // const [selectedValue, setSelectedValue] = useState('text');
  // const [comment, setComment] = useState(false);
  // const [commentValue, setCommentValue] = useState('');
  // const [displayComment, setDisplayComment] = useState(false);

  let userDetail = useSelector(state => state.AuthDetails.user);
  let IsEditUserProfile = useSelector(
    state => state.AuthDetails.IsEditUserProfile
  );
  // let testimonal = useSelector((state) => state.testimonial.Testimonials);
  // console.log(testimonal, "text");
  // const buttonLabels = [
  //   'Gym Trainer',
  //   '2 years experience',
  //   'Cardiovascular',
  //   'Functional',
  //   'Yoga',
  //   'Gym Trainer',
  //   'Functional',
  //   'Yoga',
  //   '2 years experience',
  //   'Cardiovascular',
  //   'Gym Trainer',
  //   '2 years experience',
  //   'Yoga',
  //   'Cardiovascular',
  //   'Functional',
  // ];

  // const timeSlots = [
  //   '10.00am-12.30pm',
  //   '3.00am-5.30pm',
  //   '3.00am-5.30pm',
  //   '11.00am-12.30pm',
  //   '3.00am-5.30pm',
  //   // Add more time slots as needed
  // ];

  // const langaugeSlots = [
  //   'Hindi',
  //   'English',
  //   'Spanish',
  //   'Singaporean',

  // ];

  // const {
  //   isOpen: isOpenPost,
  //   onOpen: onOpenPost,
  //   onClose: onClosePost,
  // } = useDisclosure();
  // const btnRef = React.useRef();

  const [displayedComments, setDisplayedComments] = useState(3);

  // const loadMoreContents = () => {
  //   setDisplayedComments(displayedComments + 3);
  // };
  const setIsEditUserProfileHandler = () => {
    dispatch(setIsEditUserProfile(!IsEditUserProfile));
  };
  // const totalCommentsData = [
  //   {
  //     name: 'John Tender',
  //     rating: '*****',
  //     content:
  //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...',
  //     helpfulCount: 0,
  //     replyCount: 0,
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //   },
  //   {
  //     name: 'John Tender 2',
  //     rating: '**',
  //     content:
  //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...',
  //     helpfulCount: 0,
  //     replyCount: 0,
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //   },
  //   {
  //     name: 'John Tender 3',
  //     rating: '***',
  //     content:
  //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...',
  //     helpfulCount: 0,
  //     replyCount: 0,
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //   },
  //   {
  //     name: 'John Tender 3',
  //     rating: '***',
  //     content:
  //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...',
  //     helpfulCount: 0,
  //     replyCount: 0,
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //   },
  //   {
  //     name: 'John Tender 3',
  //     rating: '***',
  //     content:
  //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...',
  //     helpfulCount: 0,
  //     replyCount: 0,
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //   },
  //   {
  //     name: 'John Tender 3',
  //     rating: '***',
  //     content:
  //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...',
  //     helpfulCount: 0,
  //     replyCount: 0,
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //   },
  //   {
  //     name: 'John Tender 3',
  //     rating: '***',
  //     content:
  //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...',
  //     helpfulCount: 0,
  //     replyCount: 0,
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //   },
  //   {
  //     name: 'John Tender 3',
  //     rating: '***',
  //     content:
  //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...',
  //     helpfulCount: 0,
  //     replyCount: 0,
  //     imageUrl:
  //       'https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg',
  //   },
  //   // Add more comment objects as needed
  // ];
  useEffect(() => {
    // let payload = {
    //   userId: userDetail._id,
    // };
    // dispatch(getTestimonial(payload));
  }, []);
  return (
    <>
      {IsEditUserProfile ? (
        <UserEditProfile1 />
      ) : (
        <div className="">
          {/* profile  */}
          <section>
            {/* <div className="w-full ">
              <Image
                src={`${process.env.REACT_APP_STORAGE_URL}${
                  userDetail && userDetail.coverImage
                }`}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                alt="user-background"
                className="lg:rounded-lg md:rounded-lg w-full object-cover h-300px "
              />
            </div> */}
            <div className="lg:flex md:flex block gap-8 items-center justify-between bg-e7ebeb br-20px p-4 ">
              <div className="lg:flex md:flex items-center gap-4 ">
                <div className="">
                  <Image
                    src={`${process.env.REACT_APP_STORAGE_URL}${
                      userDetail && userDetail.userImage
                    }`}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                    alt="profile"
                    className="mx-auto object-cover relative z-10 rounded-full border6px-fff h-32 w-32"
                  />
                </div>
                <div className="lg:mb-4 lg:text-left md:text-left text-center">
                  <h2 className="fsize24 font-semibold">
                    {`${userDetail ? userDetail.firstName : ''} ${
                      userDetail ? userDetail.lastName : ''
                    }`}
                  </h2>
                  {/* <h2 className="fsize24 font-semibold">Maneesh Sharma</h2> */}
                  <div className="lg:flex  items-center gap-1">
                    <p className="clr-9c9c9c fsize16 sm-fsize14  font-medium">
                      {userDetail.location ? selectedTrainer.location : 'India'}
                    </p>
                    <div className="hr1 lg:block md:hidden hidden"></div>
                    <div className="lg:flex md:flex items-center lg:gap-0 gap-4">
                      {/* <p className="clr-9c9c9c fsize16 sm-fsize14  font-medium">
                        {testimonal && testimonal.length > 0
                          ? testimonal.length
                          : 0}{' '}
                        REVIEW
                      </p> */}
                      {/* <p className="flex items-center justify-center gap-2 lg:ml-8 clr-9c9c9c fsize16 sm-fsize14  font-medium">
                        <FaUserCheck className="clr-ec1c24" /> 10K ACTIVE
                        MEMBERS
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex lg:flex-row md:flex-col justify-center gap-4 ">
                <Button
                  width="120px"
                  height="fit-content"
                  colorScheme="primary"
                  className="lg:mt-0 md:mt-0 mt-4 fsize14 lg:py-3 md:py-3 py-2"
                  onClick={() => setIsEditUserProfileHandler()}
                >
                  EDIT
                </Button>
              </div>
            </div>
          </section>

          {/* usercard */}
          <section className="lg:mt-8 md:mt-8 mt-8">
            <div className="w-full lg:flex md:flex ">
              <div className="w-full">
                <div className="lg:hidden md:hidden block text-right mb-2">
                  {/* <div className="flex justify-end  gap-2">
                      <a href="http://" target="_blank" rel="noopener noreferrer">
                        <Image src={Youtube} alt="youtube" className="w-8 h-8" />
                      </a>
                      <a href="http://" target="_blank" rel="noopener noreferrer">
                        <Image
                          src={Instagram}
                          alt="instagram"
                          className="w-8 h-8"
                        />
                      </a>
                      <a href="http://" target="_blank" rel="noopener noreferrer">
                        <Image
                          src={Facebook}
                          alt="facebook"
                          className="w-8 h-8"
                        />
                      </a>
                      <a href="http://" target="_blank" rel="noopener noreferrer">
                        <Image src={X} alt="x" className="w-8 h-8" />
                      </a>
                    </div> */}
                </div>
                <Tabs variant="soft-rounded" colorScheme="green">
                  <div className="flex justify-between items-center bottom-border-ebebeb">
                    <TabList className=" pb-2">
                      <Tab className="lg:px-4  rounded-sm">About me</Tab>

                      {/* <Tab className="lg:px-4   rounded-sm">Reviews</Tab> */}
                    </TabList>
                    {/* <div className="flex mob-hidden gap-2">
                        <a
                          href="http://"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={Youtube}
                            alt="youtube"
                            className="w-8 h-8"
                          />
                        </a>
                        <a
                          href="http://"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={Instagram}
                            alt="instagram"
                            className="w-8 h-8"
                          />
                        </a>
                        <a
                          href="http://"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={Facebook}
                            alt="facebook"
                            className="w-8 h-8"
                          />
                        </a>
                        <a
                          href="http://"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image src={X} alt="x" className="w-8 h-8" />
                        </a>
                      </div> */}
                  </div>

                  <TabPanels>
                    <TabPanel className="px-0px">
                      <div className="flex flex-col gap-4">
                        <div className="rounded-lg bg-f4f4f4 p-1">
                          <p className=" px-2 py-1.5 uppercase font-semibold">
                            Bio
                          </p>
                          <div className="bg-white  rounded-lg">
                            <p className="clr-515151 px-2 py-1 fsize14 lh-26px sm-lh-22px">
                              {userDetail && userDetail
                                ? userDetail.bio
                                : 'no-data'}
                            </p>
                          </div>
                        </div>

                        {/* <div className="rounded-lg bg-f4f4f4 p-1">
                            <p className=" px-2 py-1.5 uppercase font-semibold">
                              Other Details
                            </p>
                            <div className="rounded-lg bg-white">
                              <div
                                className="grid lg:grid-cols-2 md:grid-cols-1
                         grid-cols-1 gap-4 p-2"
                              >
                                <div >
                                  <h2 className="font-bold text-gray fsize12 uppercase">
                                    Preferred Location
                                  </h2>
                                  <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                                    <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                      Your Gym
                                    </p>
                                    <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                      My Gym
                                    </p>
                                    <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                      Park/Public space
                                    </p>
                                  </div>
                                </div>
  
                                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-2">
                                  <div >
                                    <h2 className="font-bold text-gray fsize12 uppercase">
                                      Training Type
                                    </h2>
                                    <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                                      <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                        Online
                                      </p>
                                      <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                        Offline
                                      </p>
                                    </div>
                                  </div>
  
                                  <div >
                                    <h2 className="font-bold text-gray fsize12 uppercase">
                                      Experience
                                    </h2>
                                    <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                                      <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                        2year
                                      </p>
                                    </div>
                                  </div>
  
                                  <div >
                                    <h2 className="font-bold text-gray fsize12 uppercase">
                                      Age
                                    </h2>
                                    <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                                      <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                        25 years
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        {/* <div className="rounded-lg bg-f4f4f4 p-1 sm-ml-8px">
                            <h2 className="  px-2 py-1.5 uppercase font-semibold">
                              Your Fitness Goals
                            </h2>
                            <div className="py-2 px-2">
                              <div className="flex flex-wrap gap-4">
                                {userDetail &&
                                  userDetail.length > 0 &&
                                  userDetail.fitnessGoal.map((fg, index) => (
                                    <Button
                                      key={index}
                                      className="fsize14 bg-fff"
                                    >
                                      {fg}
                                    </Button>
                                  ))}
                              </div>
                            </div>
                          </div> */}
                      </div>
                    </TabPanel>

                    <TabPanel className="px-0px w-full">
                      <div className="pt-1">
                        {/* <div className="flex justify-between items-center">
                            <h1 className="font-semibold uppercase fsize20 sm-fsize16">
                              [4,129 ratings] 5000 students
                            </h1>
                            <p className="flex font-semibold uppercase fsize18 sm-fsize16">
                              {`[${
                                userDetail.rating > 0 ? userDetail.rating : 0
                              }]`}{" "}
                              <ReactStars
                                count={1}
                                value={1}
                                size={20}
                                edit={false}
                                activeColor="#ffd700"
                              />
                            </p>
                          </div> */}
                        {/* <div className="pt-1">
                            {testimonal &&
                            testimonal.result &&
                            testimonal.result.length > 0 ? (
                              testimonal.result.map((e) => {
                                return (
                                  <div className="mb-6 lg:flex md:flex flex-start w-full gap-3">
                                    <div className="w-full">
                                      <div className="bg-f4f4f4 p-2 rounded-lg">
                                        <div className="flex justify-between">
                                          <div className="flex items-center gap-2">
                                            <img
                                              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                              alt="logo"
                                              className="w-6 h-6 rounded-full "
                                            />
                                            <h2 className="font-semibold fsize16 uppercase">
                                              {e.name}
                                            </h2>
                                          </div>
                                          <p>{e.rating} Rating</p>
                                        </div>
                                        <p className="fsize14 mt-1">
                                          {e.testimonial}
                                        </p>
                                      </div>
                                      <div className="flex items-center gap-8 mt-2">
                                        <div className="flex gap-2">
                                          <FaRegThumbsUp />
                                          <p className="fsize12">Helpful(0)</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-center">No Reviews</p>
                            )}
                          </div>
                          {testimonal.result && testimonal.result.length > 0 && (
                            <h2
                              onClick={loadMoreContents}
                              className="cursor-pointer text-primary uppercase fsize16 underline"
                            >
                              LOAD MORE COMMENTS
                            </h2>
                          )} */}
                      </div>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default UserProfilePage1;
