import React from 'react';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const FeatureCard18 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
      <div>
        <p
          id="5890484276"
          className="dynamicStyle fsize34 md-fsize24 sm-fsize20 font-semibold cust-textColor text-primary"
        >
          {data ? data['5890484276'] : ' Our Services'}
        </p>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-10 lg:mt-14 md:mt-14 mt-6">
        <div className="bg-e9e7e7 p-6 relative rad-rev mt-6 cust-bgColor themeShadow theme-bg">
          <p id="8429783702" className="dynamicStyle pt-8 cust-textColor">
            {data ? data['8429783702'] : 'Lorem ipsum dolor sit amet.'}
          </p>
          <div id="5419639947" className="dynamicStyle2 absl-rad w-16 h-16">
            <Image
              className="w-16 h-16"
              src={data ? getImage(data['5419639947']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
        </div>
        <div className="bg-e9e7e7 p-6 relative rad-rev mt-6 cust-bgColor theme_insertShadow theme-bg">
          <p id="1550381770" className="dynamicStyle pt-8 cust-textColor">
            {data ? data['1550381770'] : 'Lorem ipsum dolor sit amet.'}
          </p>
          <div id="5494971025" className="dynamicStyle2 absl-rad w-16 h-16">
            <Image
              className="w-16 h-16"
              src={data ? getImage(data['5494971025']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
        </div>
        <div className="bg-e9e7e7 p-6 relative rad-rev mt-6 cust-bgColor themeShadow theme-bg">
          <p id="2641733401" className="dynamicStyle pt-8 cust-textColor">
            {data ? data['2641733401'] : 'Lorem ipsum dolor sit amet.'}
          </p>
          <div id="4941746181" className="dynamicStyle2 absl-rad w-16 h-16">
            <Image
              className="w-16 h-16"
              src={data ? getImage(data['4941746181']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
        </div>
        <div className="bg-e9e7e7 p-6 relative rad-rev mt-6 cust-bgColor theme_insertShadow theme-bg">
          <p id="1700256239" className="dynamicStyle pt-8 cust-textColor">
            {data ? data['1700256239'] : 'Lorem ipsum dolor sit amet.'}
          </p>
          <div id="0267273098" className="dynamicStyle2 absl-rad w-16 h-16">
            <Image
              className="w-16 h-16"
              src={data ? getImage(data['0267273098']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard18;
