import React from 'react';
import { Button, Image, useColorMode } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux/es/exports';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

export const HeroSectionSwiper2 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const { colorMode, toggleColorMode } = useColorMode();
  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  return (
    <section className="lg:pb-16 md:pb-12 pb-6">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        breakpoints={{
          1536: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
          325: {
            slidesPerView: 1,
            spaceBetween: 12,
          },
        }}
      >
        <SwiperSlide>
          <div className="relative">
            <div
              id="7544886159"
              className="dynamicStyle2 lg:flex md:flex justify-end w-full"
            >
              {/* src={require("../../../assets/images/Award-Banner.jpg")} */}
              <Image
                className="lg:w-3/4 md:w-3/4 img-h lg:mr-24 md:mr-8 mr-4"
                src={data ? getImage(data['7544886159']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
            <div
              className={
                colorMode === 'light'
                  ? 'lg:p-14 md:p-14 p-4 lg:w-1/2 md:w-1/2 w-9/12 absl bg-secondary theme-bg'
                  : 'cust-bgColor lg:p-14 md:p-14 p-4 lg:w-1/2 md:w-1/2 w-9/12 absl'
              }
            >
              <p
                id="5880825648"
                className="dynamicStyle lg:text-5xl md:text-3xl sm-fsize22 font-semibold text-primary cust-textColor"
              >
                {data
                  ? data['5880825648']
                  : 'Exclusive Deals of Fabric Collection'}
              </p>
              <p
                id="6902025237"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['6902025237']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle lg:py-4 md:py-4 py-3 lg:text-base md:text-base text-base text-primary textColor-light "
              ></p>

              <Button
                id="6042777790"
                className="dynamicStyle theme-btn buttonAnimate bdr-2px lg:px-4 md:px-4 lg:py-2 md:py-2 px-3 py-1 font-semibold  md:text-base sm-fsize14 cust-textColor"
              >
                <span onClick={() => handleClick('6042777790')}>
                  {data ? data['6042777790'] : 'Explore Now'}
                </span>
              </Button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <div
              id="9322866124"
              className="dynamicStyle2 lg:flex md:flex justify-end w-full"
            >
              {/* src={require("../../../assets/images/Award-Banner.jpg")} */}
              <Image
                className="lg:w-3/4 md:w-3/4 img-h lg:mr-24 md:mr-8 mr-4"
                src={data ? getImage(data['9322866124']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
            <div
              className={
                colorMode === 'light'
                  ? 'lg:p-14 md:p-14 p-4 lg:w-1/2 md:w-1/2 w-9/12 absl bg-secondary theme-bg'
                  : 'cust-bgColor lg:p-14 md:p-14 p-4 lg:w-1/2 md:w-1/2 w-9/12 absl'
              }
            >
              <p
                id="0948837614"
                className="dynamicStyle lg:text-5xl md:text-3xl sm-fsize22 font-semibold text-primary cust-textColor"
              >
                {data
                  ? data['0948837614']
                  : 'Exclusive Deals of Fabric Collection'}
              </p>
              <p
                id="4164428297"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['4164428297']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle lg:py-4 md:py-4 py-3 lg:text-base md:text-base text-base text-primary textColor-light"
              ></p>

              <Button
                id="5976995170"
                className="dynamicStyle theme-btn lg:px-4 md:px-4 lg:py-2 md:py-2 px-3 py-1 buttonAnimate bdr-2px font-semibold md:text-base sm-fsize14 cust-textColor"
              >
                <span onClick={() => handleClick('5976995170')}>
                  {data ? data['5976995170'] : 'Explore Now'}
                </span>
              </Button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <div
              id="5316912018"
              className="dynamicStyle2 lg:flex md:flex justify-end w-full"
            >
              {/* src={require("../../../assets/images/Award-Banner.jpg")} */}
              <Image
                className="lg:w-3/4 md:w-3/4 img-h lg:mr-24 md:mr-8 mr-4"
                src={data ? getImage(data['5316912018']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="img"
              />
            </div>
            <div
              className={
                colorMode === 'light'
                  ? 'lg:p-14 md:p-14 p-4 lg:w-1/2 md:w-1/2 w-9/12 absl bg-secondary theme-bg'
                  : 'cust-bgColor lg:p-14 md:p-14 p-4 lg:w-1/2 md:w-1/2 w-9/12 absl'
              }
            >
              <p
                id="9603027520"
                className="dynamicStyle fsize40 md-fsize32 sm-fsize24 font-semibold text-primary cust-textColor"
              >
                {data
                  ? data['9603027520']
                  : 'Exclusive Deals of Fabric Collection'}
              </p>
              <p
                id="8596515534"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data['8596515534']
                    : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                }}
                className="dynamicStyle pt-2 fsize16 sm-fsize14  textColor-light"
              ></p>

              <div className="lg:mt-8 md:mt-8 mt-6">
                <Button
                  id="2867093589"
                  className="dynamicStyle theme-btn lg:px-4 md:px-4 lg:py-2 md:py-2 px-3 py-1 buttonAnimate bdr-2px font-semibold md:text-base sm-fsize14 cust-textColor"
                >
                  <span onClick={() => handleClick('2867093589')}>
                    {data ? data['2867093589'] : 'Explore Now'}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};
export default HeroSectionSwiper2;
