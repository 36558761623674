import React, { useEffect, useRef, useState } from 'react';
import { Star } from 'react-feather';
import { Image, Card } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Rating from 'react-rating';
import FeatherIcon from 'feather-icons-react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { handleScroll } from './fadeeffect/FadeEffect';
import { Autoplay, Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { getTestimonal } from '../../../redux/testimonial/testimonial-action';

const Testimonial14 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const dispatch = useDispatch();
  const getAllTestimonial = useSelector(state => state.testimonial.Testimonial);
  console.log(getAllTestimonial, 'getAllTestimonial');
  useEffect(() => {
    dispatch(getTestimonal());
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <>
      {getAllTestimonial ? (
        <section className="container mx-auto lg:py-16 md:py-12 lg:px-12 md:px-8 py-8 px-4">
          <div className="lg:w-2/3 md:w-2/3 w-full mx-auto text-center lg:pb-12 md:pb-12 pb-6">
            <h2
              id=""
              className="dynamicStyle text-primary fsize38 md-fsize30 sm-fsize22 cust-textColor pb-1 font-semibold"
            >
              Inspatational Stories
            </h2>
            <h2
              id=""
              className="dynamicStyle text-gray fsize26 md-fsize20 sm-fsize16 textColor-light pb-2 font-semibold"
            >
              From Our participant
            </h2>
            
          </div>
          <div
            ref={elementRef}
            className={`fade-bottom  ${isVisible ? 'visiblebottom' : ''} `}
          >
            {/* <div className="swiper-button custswiper image-swiper-button-next">
          <IoIosArrowForward />
        </div>
        <div className="swiper-button custswiper image-swiper-button-prev">
          <IoIosArrowBack />
        </div> */}
            <Swiper
              spaceBetween={5}
              navigation={true}
              className='myswiper lg:px-2 px-2 overflow-y-visible'
              modules={[Navigation]}
              breakpoints={{
                1536: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                1280: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                991: {
                  slidesPerView: 2.5,
                  spaceBetween: 25,
                },
                820: {
                  slidesPerView: 1.8,
                  spaceBetween: 25,
                },
                768: {
                  slidesPerView: 1.8,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                325: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
              }}
            >
              {getAllTestimonial &&
              getAllTestimonial.data &&
              getAllTestimonial.data.result &&
              getAllTestimonial.data.result.length > 0
                ? getAllTestimonial.data.result.map((testimonial, index) => {
                    return (
                      <SwiperSlide className="">
                        <Card
                          key={index}
                          className="cust-shadow lg:p-12 md:p-8 p-4 rounded-xl theme-radius themeShadow-insert theme-radius themeShadow theme-bg"
                        >
                          <div className="lg:pb-4 md:pb-4 pb-2">
                          <div className="cust-textColor">
                              <Rating
                                initialRating={3}
                                className="flex_rating"
                                readonly={true}
                                emptySymbol={
                                  <FeatherIcon
                                    icon="star"
                                    className="mr-1 textColor-light"
                                    size={18}
                                  />
                                }
                                fullSymbol={
                                  <FeatherIcon
                                    icon="star"
                                    className="mr-1 fillstroke"
                                    size={18}
                                  />
                                }
                                fractions={2}
                              />
                            </div>
                            <p className="fsize16 sm-fsize14 cust-textColor mt-4 lineClamp3 textColor-light">
                              {testimonial.testimonial}
                            </p>
                          </div>
                              <div className='mt-2'>
                                <p className="cust-textColor">
                                  {testimonial.name}
                                </p>
                                <p className="fsize12 textColor-light">
                                  {new Date(
                                    testimonial.createdAt
                                  ).toDateString()}{' '}
                                  • 6 min read
                                </p>
                              </div>
                              <Image
                                  className="lg:w-20 lg:h-20 w-12 h-12 minus-absolute rounded-full absolute bottom-0 right-0 lg:mx-6 md:mx-4 mx-2"
                                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                                  src={`${process.env.REACT_APP_STORAGE_URL}${testimonial.image}`}
                                  alt="testimonial-image"
                                />
                        </Card>
                      </SwiperSlide>
                    );
                  })
                : 'No Data'}
            </Swiper>
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default Testimonial14;
