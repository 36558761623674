import { Button, Image } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HeroSection26 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);

  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="relative h-700px sm-h600px w-full">
      <div id='0253032284' className="dynamicStyle2">
      <Image
        src={data ? getImage(data['0253032284']) : ''}
        fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
        className="filter-b4 relative h-700px sm-h600px w-full object-cover"
        alt='hero-section'
      />
      </div>
      <div className="absolute top-0 left-0 w-full flex justify-center items-center h-700px sm-h600px">
        <div className="text-center">
          <p id="6414838372" className="dynamicStyle tranparent-hero-title">
            {data ? data['6414838372'] : 'Travel'}
          </p>
          <p
            id="0881226179"
            className="dynamicStyle fsize20 sm-fsize14 clr-fff lg:py-8 md:py-6 py-4 letter-spacing8px sm-letter-spacing4px"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['0881226179']
                : 'Find your dream and destinations',
            }}
          ></p>
          <p id="9359747912" className="dynamicStyle tranparent-hero-title">
            {data ? data['9359747912'] : 'With Us'}
          </p>
          <Button
            id="8559895089"
            variant="outline"
            borderRadius={2}
            px={16}
            py={6}
            colorScheme="primary"
            className="dynamicStyle clr-fff lg:mt-16 md:mt-12 mt-8"
          >
            <span onClick={() => handleClick('8559895089')}>
              {data ? data['8559895089'] : 'Enquire Now'}
            </span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection26;
