import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Button, Image, AvatarGroup, Avatar, Card, useColorMode } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { getData } from '../../../redux/forms/forms-action';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { handleScroll } from './fadeeffect/FadeEffect';

const FeatureCard7 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();

  const dispatch = useDispatch();
  const data = useSelector(state => state.cms.allSections[pageName]);
  const CTALink = useSelector(state => state.cms.links[pageName]);
  const navigate = useNavigate();
  const link = 'https://example.com';

  const handleClick = id => {
    const isHttpsLink =
      CTALink[id].includes('https') || CTALink[id].includes('www');
    if (isHttpsLink) {
      window.open(`${CTALink[id]}`, '_blank');

      console.log(link, 'cmsdatacheck');
    } else {
      navigate(`/${CTALink[id]}`);
    }
  };
  const [cards, setCards] = useState([]);

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else if (image) {
      return process.env.REACT_APP_STORAGE_URL + image;
    } else {
      return `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
    }
  }
  function errorImage(e) {
    e.target.src = `${process.env.REACT_APP_STORAGE_URL}default/images/default2.jpg`;
  }
  const getCardDetails = async () => {
    let payload = {
      projectId: process.env.REACT_APP_ID,
      projectName: process.env.REACT_APP_COMMUNITY_NAME,
      paginated: true,
      limit: 3,
      offset: 0,
    };
    // let data = await dispatch(getData("startup_registration", payload));
    console.log(data, 'cards');
    setCards(data.result);
  };
  useEffect(() => {
    getCardDetails();
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <div className="lg:flex md:flex gap-4 lg:pb-12 md:pb-12 pb-6">
        <div className="lg:w-1/2 md:w-1/2">
          <h4
            className="text-primary fsize16 md-fsize16 sm-fsize14 cust-textColor font-medium dynamicStyle"
            id="1013185111"
          >
            {data ? data['1013185111'] : 'Deals'}
          </h4>
          <h2
            className="fsize34 md-fsize24 sm-fsize20 font-semibold dynamicStyle cust-textColor"
            id="0286698457"
          >
            {data
              ? data['0286698457']
              : 'We are the best Startup raise company'}
          </h2>
        </div>
        <div className="lg:w-1/2 md:w-1/2">
          <p
            className="dynamicStyle textColor-light"
            id="2296737000"
            dangerouslySetInnerHTML={{
              __html: data
                ? data['2296737000']
                : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            }}
          ></p>
        </div>
      </div>
      <div
        ref={elementRef}
        className={`fade-bottom grid lg:grid-cols-3 md:grid-cols-2  lg:gap-10 md:gap-10 gap-6 ${
          isVisible ? 'visiblebottom' : ''
        } `}
      >
        <Card className="box-shdw rounded-2xl p-4 lg:pt-10 md:pt-10 pt-4 themeShadow theme-bg">
          <div className="py-4">
            <Image
              className="h-24 lg:mb-4 md:mb-4 mb-2 mx-auto"
              // src={getImage(card['company logo'])}
              src={data ? getImage(data['']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
              onError={event => errorImage(event)}
            />
          </div>
          <div className="flex justify-evenly items-center ">
            {/* <p className="fsize20 font-semibold cust-textColor">{card['company name']}</p>
                
                Company Name*/}
            <p className="fsize20 sm-fsize18 font-semibold cust-textColor">
              Company Name
            </p>
            {/* <p className="text-gray cust-textColor">{card["typeOfFunding"]}</p> */}
          </div>
          <div className="py-4">
            <p className="fsize14 text-gray textColor-light text-center">
              {/* {card['companydescription']} */} company Description
            </p>
          </div>
          <div className="flex justify-center gap-8 mt-4">
            <div className="text-center">
              <p className="fsize14 text-gray cust-textColor mb-1">Stage</p>
              {/* <p className="fsize20 font-semibold cust-textColor">{card['stageOfStartup']}</p> */}
              <p className="fsize20 sm-fsize18 font-semibold cust-textColor">
                Test
              </p>
            </div>
            <div className="text-center">
              <p className="fsize14 text-gray cust-textColor mb-1">
                Round Size
              </p>
              {/* <p className="fsize20 font-semibold cust-textColor">{card['fundingamount']}</p> */}
              <p className="fsize20 sm-fsize18 font-semibold cust-textColor">
                45
              </p>
            </div>
            <div className="text-center">
              <p className="fsize14 text-gray cust-textColor mb-1">Team</p>
              <AvatarGroup size="sm" max={2}>
                <Avatar
                  name="Ryan Florence"
                  src="https://bit.ly/ryan-florence"
                />
                <Avatar
                  name="Segun Adebayo"
                  src="https://bit.ly/sage-adebayo"
                />
                <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                <Avatar
                  name="Prosper Otemuyiwa"
                  src="https://bit.ly/prosper-baba"
                />
                <Avatar
                  name="Christian Nwamba"
                  src="https://bit.ly/code-beast"
                />
              </AvatarGroup>
            </div>
          </div>
          <div className="pt-6 pb-4 flex justify-center gap-8">
            <Button className="fsize14 textprimary cust-textColor buttonAnimate bdr-2px">
              {/* {card['companylocation']} */} Company Location
            </Button>
          </div>
        </Card>
      </div>
      <div className="text-center lg:pt-10 md:pt-10 pt-6">
        <Button
          id="0955757696"
          className={
            colorMode === 'light'
              ? 'dynamicStyle bg-primary theme-btn clr-fff  sm-px-1rem sm-fsize14 buttonAnimate bdr-2px'
              : 'dynamicStyle theme-btn cust-bgColor sm-px-1rem sm-fsize14  bdr-2px'
          }

          px="10"
        >
          {/* <Link to="/Portfolio Page"> */}
          <span onClick={() => handleClick('0955757696')}>
            {' '}
            {data ? data['0955757696'] : ' View More'}
            {/* </Link> */}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default FeatureCard7;
