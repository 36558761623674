import React from 'react';
import { useSelector } from 'react-redux';
import { Image, useColorMode } from '@chakra-ui/react';

const FeatureCard14 = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="lg:px-12 md:px-8 px-5 lg:py-8 md:py-8 py-6">
      <div className="text-center container mx-auto flex flex-col justify-center lg:pb-10">
        <h4
          id="6817001878"
          className="dynamicStyle cust-textColor fsize36 font-semibold sm-fsize20 lg:mb-0 md:mb-0 mb-2"
        >
          {data ? data['6817001878'] : 'Welcome to My World'}
        </h4>
      </div>

      <div className="container mx-auto overflow-x-hidden lg:px-12 md:px-8 px-5 md:mt-3">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none lg:p-4 p-2 w-full border-side'
                : 'lg:p-4 p-2 w-full border-side'
            }
          >
            <div
              id="5669989720"
              className="dynamicStyle flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data['5669989720']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-16 h-16 lg:mb-4 mb-2 rounded-full border theme-border p-2"
              />
            </div>
            <h2
              id="5133048696"
              className="dynamicStyle fsize16 font-semibold text-center cust-textColor"
            >
              {data ? data['5133048696'] : 'Welcome to My World'}
            </h2>
            <p
              id="4298231803"
              className="dynamicStyle text-gray fsize14 text-center textColor-light "
            >
              {data ? data['4298231803'] : 'Welcome to My World'}
            </p>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none lg:p-4 p-2 w-full border-side'
                : 'lg:p-4 p-2 w-full border-side'
            }
          >
            <div
              id="7434461036"
              className="dynamicStyle flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data['7434461036']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-16 h-16 lg:mb-4 mb-2 rounded-full border theme-border p-2"
              />
            </div>
            <h2
              id="1025943301"
              className="dynamicStyle fsize16 font-semibold text-center cust-textColor"
            >
              {data ? data['1025943301'] : 'Welcome to My World'}
            </h2>
            <p
              id="1471258871"
              className="dynamicStyle text-gray fsize14 text-center textColor-light "
            >
              {data ? data['1471258871'] : 'Welcome to My World'}
            </p>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none lg:p-4 p-2 w-full border-side'
                : 'lg:p-4 p-2 w-full border-side'
            }
          >
            <div
              id="5562643848"
              className="dynamicStyle flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data['5562643848']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-16 h-16 lg:mb-4 mb-2 rounded-full border theme-border p-2"
              />
            </div>
            <h2
              id="4092643905"
              className="dynamicStyle fsize16 font-semibold text-center cust-textColor"
            >
              {data ? data['4092643905'] : 'Welcome to My World'}
            </h2>
            <p
              id="1600091020"
              className="dynamicStyle text-gray fsize14 text-center textColor-light "
            >
              {data ? data['1600091020'] : 'Welcome to My World'}
            </p>
          </div>
          <div
            className={
              colorMode === 'light'
                ? 'box-shadow-none lg:p-4 p-2 w-full border-side'
                : 'lg:p-4 p-2 w-full border-side'
            }
          >
            <div
              id="6465752461"
              className="dynamicStyle flex justify-center w-full"
            >
              <Image
                src={data ? getImage(data['6465752461']) : ''}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="image"
                className="w-16 h-16 lg:mb-4 mb-2 rounded-full border theme-border p-2"
              />
            </div>
            <h2
              id="5707044702"
              className="dynamicStyle fsize16 font-semibold text-center cust-textColor"
            >
              {data ? data['5707044702'] : 'Welcome to My World'}
            </h2>
            <p
              id="2777215142"
              className="dynamicStyle text-gray fsize14 text-center textColor-light "
            >
              {data ? data['2777215142'] : 'Welcome to My World'}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard14;
