import html2PDF from 'jspdf-html2canvas';

export const ExporttoPdf = async ref => {
  if (ref) {
    const container = document.createElement('div');
    container.style.padding = '50px';

    Array.from(ref.current.children).forEach(child => {
      container.appendChild(child.cloneNode(true));
    });

    document.body.appendChild(container);

    html2PDF(container, {
      jsPDF: {
        format: 'a4',
      },
      imageType: 'image/jpeg',
      output: 'generate.pdf',
    }).then(() => {
      document.body.removeChild(container);
    });
  }
};
