import React from 'react';

const ApprovalRequestBanner = () => {
  return (
    <div className="relative">
      <img
        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
        alt="home-banner"
        className="h-250px filter-b4 w-full"
      />
      <div className="w-full lg:px-28 md:px-8 p-6 absolute top-0 left-0 h-250px flex justify-start items-center">
        <div className="text-start mb-8">
          <h1 className="fsize36 sm-fsize21 font-semibold text-white">
            Approval Request
          </h1>
          <p className=" text-white">
            Effortlessly resolve all queries related to this portal
          </p>
        </div>
      </div>
    </div>
  );
};

export default ApprovalRequestBanner;
