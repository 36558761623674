import React from 'react';
import Banner from './components/Banner';
import BreadCumb from './components/BreadCumb';
import Swipercard from './components/Swipercard';
import Gridcards from './components/Gridcards';

const Bussiness = () => {
  return (
    <div>
      <Banner />
      <BreadCumb />
      <Swipercard />
      <Gridcards />
    </div>
  );
};

export default Bussiness;
