import React from 'react';
import Banner from './components/Banner';
import Folder from './components/Folder';

const Approval = () => {
  return (
    <div>
      <Banner />
      <Folder />
    </div>
  );
};

export default Approval;
