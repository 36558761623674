import API from './repositoryFactory';

const get = 'get';

const gatewayBase = route => {
  return `payment/gateway/${route}`;
};

export default {
  getGateway(payload) {
    return API.post(gatewayBase(get), payload);
  },
};
