import API from './repositoryFactory';

const createStripeCheckout = 'createCheckoutSession';

const stripeBase = route => {
  return `payment/stripe/${route}`;
};

export default {
  createStripeCheckout(payload) {
    return API.post(stripeBase(createStripeCheckout), payload);
  },
};
