import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
const CategoriesSection1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="container mx-auto lg:flex md:flex lg:gap-16 md:gap-8 lg:px-12 md:px-8 px-5">
      <div className="wid-70 grid grid-cols-2 lg:gap-4 md:gap-4 gap-2 lg:py-12 md:py-12 py-4 ">
        <div
          id="4525094276"
          className="dynamicStyle2 w-full"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <Image
            className="img-wh object-cover"
            src={data ? getImage(data['4525094276']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="image"
          />
        </div>
        <div
          id="8854343997"
          className="dynamicStyle2 w-full"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <Image
            className="img-wh object-cover"
            src={data ? getImage(data['8854343997']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="image"
          />
        </div>
        <div
          id="6687611188"
          className="dynamicStyle2 w-full"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <Image
            className="img-wh object-cover"
            src={data ? getImage(data['6687611188']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="image"
          />
        </div>
        <div
          id="3785248192"
          className="dynamicStyle2 w-full"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <Image
            className="img-wh object-cover"
            src={data ? getImage(data['3785248192']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="image"
          />
        </div>
        <div
          id="0702681228"
          className="dynamicStyle2 w-full"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <Image
            className="img-wh object-cover"
            src={data ? getImage(data['0702681228']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="image"
          />
        </div>
        <div
          id="6168663725"
          className="dynamicStyle2 w-full"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <Image
            className="img-wh object-cover"
            src={data ? getImage(data['6168663725']) : ''}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
            alt="image"
          />
        </div>
      </div>
      <div className="wid-30 lg:px-8 md:px-4 px-6 lg:py-12 lg:py-12 py-6 bg-fffaf0">
        <p id="8005565455" className="dynamicStyle font-semibold text-primary">
          {data ? data['8005565455'] : 'Lorem Ipsum'}
        </p>
        <p
          id="4264969459"
          className="dynamicStyle fsize34 md-fsize26  font-medium sm-fsize22 clr-191E22"
        >
          {data ? data['4264969459'] : 'Lorem Ipsum'}
        </p>
        <p
          id="4826842366"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['4826842366']
              : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          }}
          className="dynamicStyle lg:py-4 md:py-4 py-2 text-555555 sm-fsize14"
        >
          {/* Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit amet
          elementum. Proin bibendum sollicitudin feugiat. */}
        </p>
        {/* <ul className="disc px-4">
          <li className="lg:pb-4 pb-2 disc-r font-medium sm-fsize14 clr-191E22">
            African Wax Fabrics
          </li>
          <li className="lg:pb-4 pb-2 disc-r font-medium sm-fsize14 clr-191E22">
            Printed Cotton Fabric
          </li>
          <li className="lg:pb-4 pb-2 disc-r font-medium sm-fsize14 clr-191E22">
            Tie Dye
          </li>
          <li className="lg:pb-4 pb-2 disc-r font-medium sm-fsize14 clr-191E22">
            Nighty Printed Cotton Fabric
          </li>
          <li className="lg:pb-4 md:pb-4 pb-2 disc-r font-medium sm-fsize14 clr-191E22">
            Real Wax Printed Fabrics
          </li>
        </ul> */}
      </div>
    </section>
  );
};

export default CategoriesSection1;
