import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { ArrowRight } from 'react-feather';
import { Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';

const AddBanner3 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    Aos.init();
  });
  return (
    <section className="container mx-auto lg:flex md:flex h-750 lg:px-12 md:px-8 px-5">
      <div
        id="4613632026"
        className="dynamicStyle2 lg:w-1/2 md:w-5/12 sm-pb-4 w-full"
      >
        <Image
          className="h-750"
          src={data ? getImage(data['4613632026']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          alt="img"
        />
      </div>
      <div className="lg:w-1/2 md:w-7/12 ">
        <div className="lg:flex md:flex h-1/2 sm-pb-4">
          <div
            className="lg:w-1/2 md:w-1/2 lg:px-10 md:px-4 px-4 lg:py-16 md:py-4 py-6 bg-fffaf0"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <p
              id="8100811620"
              className="dynamicStyle fsize24 md-fsize18 sm-fsize18 lg:pb-4 md:pb-2 pb-2 clr-0E1317"
            >
              {data ? data['8100811620'] : 'African Wax Fabrics'}
            </p>
            <p
              id="3648425937"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['3648425937']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle lg:pb-4 md:pb-4 pb-2 md-fsize12 sm-fsize14 clr-0E1317"
            >
              {/* Ut ultricies imperdiet sodales. Aliquam fringilla aliquam ex sit
              amet elementum. Proin bibendum sollicitudin feugiat. */}
            </p>
            <div
              id="1755782686"
              className="dynamicStyle flex lg:w-1/2 items-center cursor-pointer text-primary"
            >
              <p className="md-fsize14 sm-fsize14">
                {data ? data['1755782686'] : 'View All'}
              </p>
              <ArrowRight className="ml-2 vw-arrow" />
            </div>
          </div>

          <div
            id="7394577531"
            className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full"
          >
            <Image
              className="h-full"
              src={data ? getImage(data['7394577531']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
        </div>
        <div className="flex h-1/2 flex-rev">
          <div
            id="3979729733"
            className="dynamicStyle2 lg:w-1/2 md:w-1/2 w-full"
          >
            <Image
              className="h-full"
              src={data ? getImage(data['3979729733']) : ''}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
              alt="img"
            />
          </div>
          <div
            className="lg:w-1/2 md:w-1/2 lg:px-10 md:px-4 px-4 lg:py-16 md:py-4 py-6 bg-fffaf0"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <p
              id="8948716338"
              className="dynamicStyle fsize24 md-fsize18 sm-fsize18 lg:pb-4 md:pb-2 pb-2 clr-0E1317"
            >
              {data ? data['8948716338'] : 'African Wax Fabrics'}
            </p>
            <p
              id="4761726601"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['4761726601']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
              className="dynamicStyle lg:pb-4 md:pb-4 pb-2 md-fsize12 sm-fsize14 clr-0E1317"
            ></p>
            <div
              id="1067489719"
              className="dynamicStyle flex lg:w-1/2 items-center cursor-pointer text-primary"
            >
              <p className="md-fsize14 sm-fsize14">
                {data ? data['1067489719'] : 'View All'}
              </p>
              <ArrowRight className="ml-2 vw-arrow" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddBanner3;
