import { Button, Input, Textarea } from '@chakra-ui/react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const SurveyForms = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const [value, setValue] = useState();
  useEffect(() => {}, []);
  return (
    <section className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-12 md:py-12 py-6">
      <div className="lg:flex md:flex gap-8">
        <div className="lg:w-1/2 md:w-1/2">
          <p className="text-gray-400 font-medium">Lorem Ipsum</p>
          <h2 className="fsize34 md-fsize28 sm-fsize20 font-semibold">
            Lorem ipsum dolor sit amet consectetur
          </h2>
          <p className="lg:pt-4 md:pt-4 pt-2 lg:pb-0 md:pb-0 pb-4 sm-fsize14">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt
            officia sint provident aut! Asperiores vero est atque accusantium
            iste voluptas recusandae quisquam, perspiciatis a, officiis vel esse
            autem delectus. Quidem facilis alias ipsam? Beatae, quod. Omnis nam
            tempora sunt expedita?
          </p>
        </div>
        <div className="lg:w-1/2 md:w-1/2 cust-shadow p-4 lg:mx-8 md:mx-8 rounded-lg">
          <Input placeholder="First Name" className="w-full" />
          <Input placeholder="Email Address" className="w-full my-4" />
          <div className="">
            <Input
              placeholder="Mobile Number"
              type="number"
              className="w-full mb-4"
            />
          </div>
          <Textarea
            placeholder="Enter text"
            type="textarea"
            className="w-full mb-4"
          />
          <Button colorScheme="primary" className="w-full">
            Send
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SurveyForms;
