import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Card, Image, useColorMode } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { getAllTeams } from '../../../redux/teams/teams-action';
import { handleScroll } from './fadeeffect/FadeEffect';

const Team4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  let allTeam = useSelector(state => state.Teams.Teams);
  useEffect(() => {
    dispatch(getAllTeams());
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto md:px-6 lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-8">
      <div className="text-center lg:mb-12 md:mb-12 mb-6 lg:w-1/2 md:w-full w-full mx-auto">
        <h1
          id="4425122477"
          className="dynamicStyle cust-textColor text-primary fsize34 md-fsize24 sm-fsize20 mb-2 font-semibold"
        >
          {data ? data['4425122477'] : 'Meet Aur Professional teams'}
        </h1>

        <p
          id="4132953528"
          className="dynamicStyle textColor-light fsize16 md-fsize16 sm-fsize14"
        >
          {data ? data['4132953528'] : 'Lorem Ipsum'}
        </p>
      </div>

      <div  ref={elementRef} className={`fade-bottom grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 md:gap-6 gap-8 ${isVisible ? 'visiblebottom' : ''} `}>
        {allTeam && allTeam.length > 0
          ? allTeam.map(e => {
              return (
                <Card
                  className={
                    colorMode === 'light'
                      ? 'border p-2 w-full rounded-lg themeShadow cust-shadow theme-bg'
                      : 'p-2 w-full rounded-lg themeShadow theme-bg'
                  }
                >
                  <div className=" overflow-hidden w-full">
                    <Image
                      src={`${process.env.REACT_APP_STORAGE_URL}${e.profilepicture}`}
                      fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                      alt="teams-card"
                      className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
                    />
                  </div>

                  <p className=" fsize18 font-semibold text-center lg:mb-2 lg:mt-0 md:mt-0 mt-2 cust-textColor">
                    {e.name}
                  </p>
                  <p className=" fsize14 text-center textColor-light">
                    {e.designation}
                  </p>
                </Card>
              );
            })
          : 'No Data'}
      </div>
    </section>
  );
};

export default Team4;
