import apis from '../../apis/client/index';
export const createCheckoutSession = payload => {
  let result;
  return async dispatch => {
    try {
      // console.log("getProject");
      // let payload = {
      //   projectName: process.env.REACT_APP_COMMUNITY_NAME,
      // };
      let { data } = await apis.stripe.createStripeCheckout(payload);
      if (data) result = data;
    } catch (error) {
      console.log(error);
    }
    return result;
  };
};
