import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaPinterest,
  FaTelegramPlane,
  FaYoutube,
} from 'react-icons/fa';
import { useSelector } from 'react-redux';

const Footer10 = ({ pageName}) => {
    const data = useSelector(state => state.cms.allSections[pageName]);
    
  return (
    <section className="bg-theme theme-bg">
      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-10 py-8">
        <div className="w-5/6 mx-auto grid lg:grid-cols-4 grid-cols-2 lg:gap-4 gap-x-4 gap-y-8 pb-8 bottom-border-ebebeb">
          <div className="">
            <p className="font-semibold mb-6 fsize18 cust-textColor">Links</p>
            <ul className="list-none">
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li">Link</li>
              </a>
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li">Link</li>
              </a>
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li">Link</li>
              </a>
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li">Link</li>
              </a>
            </ul>
          </div>
          <div className="">
            <p className="font-semibold mb-6 fsize18 cust-textColor">Popular Beeds</p>
            <ul className="list-none">
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li">Link</li>
              </a>
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li">Link</li>
              </a>
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li">Link</li>
              </a>
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li">Link</li>
              </a>
            </ul>
          </div>
          <div className="">
            <p className="font-semibold mb-6 fsize18 cust-textColor">Supports</p>
            <ul className="list-none">
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li ">Link</li>
              </a>
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li">Link</li>
              </a>
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li">Link</li>
              </a>
              <a href="/" className="">
                <li className="fsize14 pb-3 textColor-light anchor-li">Link</li>
              </a>
            </ul>
          </div>
          <div className="">
            <p className="font-semibold mb-6 fsize18 cust-textColor">Sign Up</p>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type="email"
                className="fsize14 bg-fff cust-bgColor"
                placeholder="Enter Email"

              />
              <InputRightElement width="3.3rem">
                <Button h="1.75rem" size="sm">
                  <FaTelegramPlane />
                </Button>
              </InputRightElement>
            </InputGroup>
            <div className="flex gap-3 mt-4">
              <div className="textColor-light rounded-full w-8 h-8 footer-icon">
                <FaFacebookF />
              </div>

              <div className="textColor-light rounded-full w-8 h-8 footer-icon">
                <FaInstagram />
              </div>
              <div className="textColor-light rounded-full w-8 h-8 footer-icon">
                <FaYoutube />
              </div>
              <div className="textColor-light rounded-full w-8 h-8 footer-icon">
                <FaPinterest />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-3 text-center">
          <p className="fsize12 cust-textColor">@2020 Brained. All rights reserved.</p>
        </div>
      </div>
    </section>
  );
};

export default Footer10;
