import { Image } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HeroSection28 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  

  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="relative inline-block h-450px sm-h300px w-full img-container">
      <div id='3000243784' className="dynamicStyle2">
        <Image
          src={data ? getImage(data['3000243784']) : ''}
          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
          className="relative h-450px sm-h300px w-full object-cover"
          alt='hero-section'
        />
      </div>
      <div className="absolute z-10 top-0 left-0 flex w-full items-center h-450px sm-h300px">
        <div className="container mx-auto">
          <div className="lg:w-1/2 md:w-8/12 w-full lg:pl-20 md:pl-20 pl-10">
            <h4
              id="1192846446"
              className="dynamicStyle fsize34 md-fsize28 sm-fsize20 font-semibold clr-fff"
            >
              {data
                ? data['1192846446']
                : 'Lorem ipsum dolor sit amet consectetur.'}
            </h4>
            <p
              id="1083269149"
              className="dynamicStyle sm-fsize14 fsize16 clr-fff lg:py-4 md:py-4 py-2"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data['1083269149']
                  : ' Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              }}
            ></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection28;
