const initialState = {
  Portfolio: [],
  Category: [],
};

const AllPortfolioDetails = (state = initialState, action) => {
  switch (action.type) {
    case 'setPortfolio':
      return {
        ...state,
        Portfolio: action.payload,
      };
    case 'setCategory':
      return {
        ...state,
        Category: action.payload,
      };
    default:
      return state;
  }
};

export default AllPortfolioDetails;
