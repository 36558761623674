import React from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Image } from '@chakra-ui/react';

const Bullets1 = ({ pageName }) => {
  const data = useSelector(state => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes('blob:')) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section>
      <div className="container mx-auto lg:px-12 md:px-8 py-8 px-5 lg:py-16">
        <p
          id="4981079605"
          className="dynamicStyle fsize18 sm-fsize15 text-secondary font-semibold"
        >
          {data ? data['4981079605'] : 'SBIF gram Seva'}
        </p>
        <h5
          id="7119205092"
          className="dynamicStyle fsize30 sm-fsize18 font-semibold text-primary"
        >
          {data
            ? data['7119205092']
            : 'Working in areas across 8 distint themes'}
        </h5>

        <p
          id="4926007979"
          dangerouslySetInnerHTML={{
            __html: data
              ? data['4926007979']
              : 'SBIF Jivanam is dedicated to transforming underserved communities by prioritizing 8 distinct areas',
          }}
          className="dynamicStyle fsize16 sm-fsize14 col-79 mt-3"
        ></p>
        {/* <ul className="mt-2">
          <li className="list-dot fsize16 sm-fsize14 col-79 mt-2">
            <span className="font-semibold col-14 mr-2">
              Primary Healthcare:
            </span>
            Emphasizing preventive care and equal access to essential services
            for lasting well-being.
          </li>
          <li className="list-dot fsize16 sm-fsize14 col-79 mt-2">
            <span className="font-semibold col-14 mr-2">Cancer Care:</span>
            Providing support for cancer awareness, diagnosis, and financial
            assistance to enhance the lives of patients and families.
          </li>
          <li className="list-dot fsize16 sm-fsize14 col-79 mt-2">
            <span className="font-semibold col-14 mr-2">Palliative Care:</span>
            Alleviating suffering and promoting dignity for those facing serious
            illnesses to improve overall quality of life.
          </li>
          <li className="list-dot fsize16 sm-fsize14 col-79 mt-2">
            <span className="font-semibold col-14 mr-2">Cancer Care:</span>
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content. Lorem ipsum may be
            used as a placeholder before final copy is available.
          </li>
          <li className="list-dot fsize16 sm-fsize14 col-79 mt-2">
            <span className="font-semibold col-14 mr-2">Eye Care:</span>
            Addressing the need for accessible eye care through awareness, early
            detection, and affordable services for a better quality of life.
          </li>
          <li className="list-dot fsize16 sm-fsize14 col-79 mt-2">
            <span className="font-semibold col-14 mr-2">
              Infrastructural Support:
            </span>
            Upgrading hospitals, equipment, and establishing healthcare
            infrastructure in underserved areas to ensure quality services.
          </li>
          <li className="list-dot fsize16 sm-fsize14 col-79 mt-2">
            <span className="font-semibold col-14 mr-2">Mental Health:</span>
            Promoting awareness, providing support, and combating stigma
            associated with mental health issues for overall well-being.
          </li>
          <li className="list-dot fsize16 sm-fsize14 col-79 mt-2">
            <span className="font-semibold col-14 mr-2">Organ Donation:</span>
            Advocating and facilitating organ donation processes to support
            individuals involved in transplantation.
          </li>
          <li className="list-dot fsize16 sm-fsize14 col-79 mt-2">
            <span className="font-semibold col-14 mr-2">
              Technology and Innovation Support:
            </span>
            Backing research initiatives for new treatments and technologies to
            advance healthcare practices for sustainable community impact.
          </li>
        </ul> */}
      </div>
    </section>
  );
};

export default Bullets1;
