
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Button, Input, Textarea,useColorMode } from '@chakra-ui/react';
const ContactForm3 = ({ pageName }) => {
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
  
    const { colorMode, toggleColorMode } = useColorMode();
    const data = useSelector(state => state.cms.allSections[pageName]);
    const CTALink = useSelector(state => state.cms.links[pageName]);
    const navigate = useNavigate();
    const link = 'https://example.com';
  
    function getImage(image) {
      if (image && image.includes('blob:')) {
        return image;
      } else {
        return process.env.REACT_APP_STORAGE_URL + image;
      }
    }
    const handleClick = id => {
      const isHttpsLink =
        CTALink[id].includes('https') || CTALink[id].includes('www');
      if (isHttpsLink) {
        window.open(`${CTALink[id]}`, '_blank');
  
        console.log(link, 'cmsdatacheck');
      } else {
        navigate(`/${CTALink[id]}`);
      }
    };
  return (
    <div className="container mx-auto lg:py-12 md:py-10 py-8 lg:px-12 md:px-8 px-5">
      <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-6 md:gap-6 gap-4 mb-10">
        <div className="bdr-d5d2d2-1px bg-f4f5f6 cust-bgColor p-4 flex justify-center items-center">
          <div className="">
            <FeatherIcon icon="phone" className="mx-auto fill-icon lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6" />
            <p className="pt-4 cust-textColor sm-fsize14">9865457856</p>
          </div>
        </div>
        <div className="bdr-d5d2d2-1px bg-f4f5f6 cust-bgColor p-4 flex justify-center items-center">
          <div className="">
            <FeatherIcon icon="mail" className="mx-auto fill-icon lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6" />
            <p className="pt-4 cust-textColor sm-fsize14">abc@gmail.com</p>
          </div>
        </div>
        <div className="bdr-d5d2d2-1px bg-f4f5f6 cust-bgColor p-4 flex justify-center items-center">
          <div className="text-center">
            <FeatherIcon icon="map-pin" className="mx-auto fill-icon lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6" />
            <p className="pt-4 cust-textColor sm-fsize14">
              SBI Foundation, No. 35, Ground FloorThe Arcade, World Trade
              Center, Cuffe Parade Mumbai 400035
            </p>
          </div>
        </div>
      </div>
      <div className="lg:flex md:flex gap-10">
        <div className="lg:w-1/2 md:w-1/2 sm-wfull lg:mb-0 md:mb-0 mb-10">
          <div className="mb-4">
            <p className="pb-2 cust-textColor">Name</p>
            <Input placeholder="Enter your name" className="" />
          </div>
          <div className="mb-4">
            <p className="pb-2 cust-textColor">Email</p>
            <Input placeholder="Enter your email id" className="" />
          </div>
          <div className="mb-4">
            <p className="pb-2 cust-textColor">Message</p>
            <Textarea placeholder="Enter your message" className="" />
          </div>
         
          <Button
          px={8}
          py={4}
          
          className={
            colorMode === 'light'
              ? 'dynamicStyle bg-primary bdr-0px sm-fsize14 clr-fff theme-btn'
              : 'dynamicStyle cust-bgColor bdr-0px cust-textColor sm-fsize14 theme-btn'
          }
        >
          <span onClick={() => handleClick('')}>
            Send Message
          </span>
        </Button>
        </div>
        <div className="lg:w-1/2 md:w-1/2 sm-wfull">
          <div className='mb-4'>
          <h3 className="fsize28 sm-fsize20 font-semibold pb-2 cust-textColor">Contact Info</h3>
          <p className='text-gray-400 sm-fsize14 textColor-light'>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia
            debitis amet repellendus unde nihil explicabo!
          </p>
          </div>
          <div className='mb-4'>
            <p className='text-primary font-semibold fsize18 sm-fsize16 pb-2'>Email</p>
            <p className='pb-1 sm-fsize14 textColor-light'>abc@gmail.com</p>
            <p className='sm-fsize14 textColor-light'>xyz@gmail.com</p>
          </div>
          <div className='mb-4'>
            <p className='text-primary font-semibold fsize18 sm-fsize16 pb-2'>Phone</p>
            <p className='pb-1 sm-fsize14 textColor-light'>+91 9856548765</p>
            <p className='sm-fsize14 textColor-light'>+91 8956547856</p>
          </div>
          <div className='mb-4'>
            <p className='text-primary font-semibold fsize18 sm-fsize16 pb-4'>Visit social profile</p>
            <div className='flex gap-4'>
           <div className='bdr-d5d2d2-1px rounded-full p-3'>
           <FeatherIcon icon="linkedin" className="lg:w-6 lg:h-6 md:w-6 md:w-6 md:h-6 w-4 h-4 cust-textColor" />
           </div>
           <div className='bdr-d5d2d2-1px rounded-full p-3'>
           <FeatherIcon icon="instagram" className="lg:w-6 lg:h-6 md:w-6 md:w-6 md:h-6 w-4 h-4 cust-textColor" />
           </div>
           <div className='bdr-d5d2d2-1px rounded-full p-3'>
           <FeatherIcon icon="facebook" className="lg:w-6 lg:h-6 md:w-6 md:w-6 md:h-6 w-4 h-4 cust-textColor" />
           </div>
           <div className='bdr-d5d2d2-1px rounded-full p-3'>
           <FeatherIcon icon="twitter" className="lg:w-6 lg:h-6 md:w-6 md:w-6 md:h-6 w-4 h-4 cust-textColor" />
           </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm3;
